// import React from 'react'
// import PerfectScrollbar from "react-perfect-scrollbar"
// import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
// import { useTheme } from '@emotion/react';
// import BarChart from 'ui-components/BarChart';
// import LineChart from 'ui-components/LineChart';
// import { containerRef } from '../../../utils';

// const Performance = () => {
//     const theme = useTheme()

//     const charts = [
//         {
//             name: 'Monthly Active User',
//             percentages: [
//                 { label: 'Apr. 2024', percentage: '100' },
//                 { label: 'May 2024', percentage: '150' },
//                 { label: 'June 2024', percentage: '200' },
//                 { label: 'July 2024', percentage: '180' }, // Drop
//                 { label: 'Aug. 2024', percentage: '250' },
//                 { label: 'Sept. 2024', percentage: '230' }, // Drop
//                 { label: 'Oct. 2024', percentage: '300' }
//             ]
//         },
//         {
//             name: 'Monthly Orders',
//             percentages: [
//                 { label: 'Apr. 2024', percentage: '500' },
//                 { label: 'May 2024', percentage: '540' },
//                 { label: 'June 2024', percentage: '620' },
//                 { label: 'July 2024', percentage: '600' }, // Drop
//                 { label: 'Aug. 2024', percentage: '650' },
//                 { label: 'Sept. 2024', percentage: '620' }, // Drop
//                 { label: 'Oct. 2024', percentage: '700' }
//             ]
//         },
//         {
//             name: 'Monthly Revenue',
//             currency: 'INR',
//             percentages: [
//                 { label: 'Apr. 2024', percentage: '10000000', currency: 'INR' },
//                 { label: 'May 2024', percentage: '11000000', currency: 'INR' },
//                 { label: 'June 2024', percentage: '12000000', currency: 'INR' },
//                 { label: 'July 2024', percentage: '11500000', currency: 'INR' }, // Drop
//                 { label: 'Aug. 2024', percentage: '13000000', currency: 'INR' },
//                 { label: 'Sept. 2024', percentage: '12500000', currency: 'INR' }, // Drop
//                 { label: 'Oct. 2024', percentage: '14000000', currency: 'INR' }
//             ]
//         },
//         {
//             name: 'Monthly Burn',
//             currency: 'INR',
//             percentages: [
//                 { label: 'Apr. 2024', percentage: '1000000', currency: 'INR' },
//                 { label: 'May 2024', percentage: '1100000', currency: 'INR' },
//                 { label: 'June 2024', percentage: '1050000', currency: 'INR' }, // Drop
//                 { label: 'July 2024', percentage: '1150000', currency: 'INR' },
//                 { label: 'Aug. 2024', percentage: '1120000', currency: 'INR' }, // Drop
//                 { label: 'Sept. 2024', percentage: '1200000', currency: 'INR' },
//                 { label: 'Oct. 2024', percentage: '1180000', currency: 'INR' } // Drop
//             ]
//         }

//     ]

//     const ChartCard = ({ data: cardData }) => {
//         return (
//             <Grid item xs={12} sm={6}>
//                 <Card sx={{ borderRadius: "10px", border: `1px solid ${theme.palette.customColors.b50}`, overflow: 'hidden', boxShadow: 'none' }}>
//                     <CardHeader
//                         sx={{ background: theme.palette.customColors.white, borderBottom: `1px solid ${theme.palette.customColors.b50}`, "& > .MuiCardHeader-avatar": { maxWidth: { xs: "43%", md: "50%" }, marginRight: "8px" }, "& > .MuiCardHeader-action": { maxWidth: "50%" } }}
//                         avatar={
//                             <Box display={"flex"} sx={{ gap: 1, alignItems: "center" }} maxWidth="100%">
//                                 <Typography fontWeight={700} width="100%" fontSize={{ xs: "0.8rem", md: "0.9rem" }} color={theme.palette.customColors.g300} sx={{ wordBreak: 'break-all', overflowWrap: "break-word", whiteSpace: "normal" }}>
//                                     {cardData?.name && cardData?.name != "" ? cardData?.name : '-'} {cardData?.currency && "(" + cardData?.currency + ")"}
//                                 </Typography>
//                             </Box>
//                         }
//                     />
//                     <CardContent sx={{ paddingBottom: "0 !important" }}>
//                         {cardData.currency ? <BarChart graphData={cardData?.percentages} height='100%' width="100%" /> : <LineChart graphData={cardData?.percentages} height='100%' width="100%" />}
//                     </CardContent>
//                 </Card>
//             </Grid>
//         )
//     }



//     return (
//         <>
//             <Grid container xs={12} pt={2} pb={2} spacing={2} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
//                 <PerfectScrollbar
//                     containerRef={containerRef}
//                     style={{ height: "calc(100vh - 200px)" }}>
//                     <Grid container xs={12} padding={{ xs: "0 0rem 1rem 1rem", md: "0 1rem 2rem 2rem" }} spacing={2} justifyContent={"space-between"}>
//                         {
//                             charts?.map((chart, index) => <ChartCard data={chart} key={index} />)
//                         }
//                     </Grid>

//                 </PerfectScrollbar>
//             </Grid>
//         </>
//     )
// }

// export default Performance


import BreadcrumbHeader from 'layout/BreadcrumbsHeader';
import React, { useEffect, useState } from 'react'
import ThemeBGWrapper from 'ui-components/ThemeBGWrapper';
import { ReactComponent as CloseIcon } from "assets/close_icon.svg";
import PerfectScrollbar from "react-perfect-scrollbar"
import { capitalizedSentence, containerRef, downloadFile } from 'utils/index';
import { Autocomplete, Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Grid, IconButton, Menu, MenuItem, Paper, Select, Skeleton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import BarChart from 'ui-components/BarChart';
import LineChart from 'ui-components/LineChart';
import { B300, BLUEBORDER } from 'ui-components/CustomButton';
import { Add, CalendarMonth, DataArray, DeleteOutlineRounded, DoneRounded, Download, Edit, EditOutlined, FileDownloadOutlined, FileUploadOutlined, Filter, FilterAlt, GraphicEq, InsertInvitation, MoreVert, PublishedWithChanges, Settings, SettingsOutlined, Speed, TableBarOutlined, TableChart, TableChartOutlined, Timeline, TimelineOutlined, UploadFile, ViewColumnOutlined } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ModalComponent from 'ui-components/ModalComponent';
import { AddConfiguration, DeleteMetricDataByID, ExportFetchAllMetricsData, ExportMetricsTemplateFile, FetchAllConfigurationTemplates, FetchAllGraphs, FetchAllMetricsData, FetchSavedConfigurationTemplates } from 'api/performance';
import { useSelector } from 'react-redux';
import CustomToast from 'ui-components/CustomToast';
import toast from 'react-hot-toast';
import AsyncTableListing from 'ui-components/AsyncTableListing';
import CustomTabs from 'ui-components/CustomTabs';
import { makeStyles } from '@mui/styles';
import { TabContext, TabPanel } from '@mui/lab';
import CustomLabel from 'ui-components/CustomLabel';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import format from 'date-fns/format';
import CustomInputField from 'ui-components/CustomInputField';
import Cookies from 'js-cookie';
import NoData from 'ui-components/NoData';
import setConfigPlaceholderImage from "assets/no_data_images/no_data_image.png"

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        "&": {
            padding: "3rem"
        }
        // '& .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer' : {marginLeft: '2rem', gap: '5rem'}
    },
    selectBox: {
        "& > .MuiSelect-select.MuiSelect-multiple": {
            padding: "13.5px 14px"
        },
        "& > fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.customColors.b50 + " !important"
        }
    },
    DateRangePicker: {
        '& > .rs-picker-input-group > input': {
            fontSize: "1rem",
            padding: '13.2px 15px',
        },
        '& .rs-input-group:focus-within': {
            borderColor: theme.palette.customColors.b50,
            boxShadow: 'none',
            outline: 'none',
            borderRadius: '4px'


        },
        '& .rs-input-group': {
            borderColor: theme.palette.customColors.b50 + " !important",
            // borderRadius:'8px'
        }


    }
    //     '& .MuiTabs-root > .Mui-disabled.MuiTabs-scrollButtons': {
    //         display: 'none !important',
    //         transition: 'all 1s ease'
    //     },
    // }
}));

const Performance = ({ startup_id }) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()
    const classes = useStyles();
    // const [searchParams, setSearchParams] = useSearchParams();
    const CookiesTabValue = Cookies.get("performance_tab")
    const [duration, setDuration] = React.useState(null)
    const [value, setValue] = React.useState(CookiesTabValue || 'data')
    const [filterKeys, setFilterKeys] = React.useState(["all"]);
    const [selectedValues, setSelectedValues] = React.useState(filterKeys);

    // console.log(duration,"duration")

    const handleTabChange = (event, newValue) => {
        // console.log("value", newValue)
        setValue(newValue);
        Cookies.set("performance_tab", newValue)
        // setSearchParams({ ...searchParams, tab: newValue })
    };


    const startupDetails = useSelector((state) => state?.startupDetails?.data);

    // const { data: configurationData, isLoading: isLoadingConfigurationData, refetch: refetchConfigurationData } = FetchAllConfigurationTemplates();
    const { data: metricsData, isLoading: isLoadingMetricsData, refetch: refetchMetricsData } = FetchAllMetricsData({ startup_id: startup_id, filterKeys, fromDate: duration?.from_date, toDate: duration?.to_date });
    const { mutateAsync: metricsDataExcelAsync, isLoading: isDownloadingExcelMetricData } = ExportFetchAllMetricsData();
    // console.log(metricsData, "metricsData")
    const { data: savedConfigurationData, isLoading: isLoadingSavedConfigurationData, refetch: refetchSavedConfigurationData } = FetchSavedConfigurationTemplates(startup_id);
    // console.log({ startup_id: startup_id, filterKeys, fromDate: duration?.from_date, toDate: duration?.to_date })

    const { data: graphData, isLoading: isLoadingGraphData, refetch: refetchGraphData } = FetchAllGraphs({ startup_id: startup_id ,fromDate: duration?.from_date, toDate: duration?.to_date });
    // console.log(graphData,"graphData")

    const [showConfigurations, setShowConfigurations] = useState(false);


    const charts = [
        {
            name: 'Monthly Active User',
            type: "Count",
            isCurrency: false,
            percentages: [
                { label: 'Apr. 2024', percentage: '100' },
                { label: 'May 2024', percentage: '150' },
                { label: 'June 2024', percentage: '200' },
                { label: 'July 2024', percentage: '180' }, // Drop
                { label: 'Aug. 2024', percentage: '250' },
                { label: 'Sept. 2024', percentage: '230' }, // Drop
                { label: 'Oct. 2024', percentage: '300' }
            ]
        },
        {
            name: 'Monthly Revenue',
            currency: 'INR',
            isCurrency: true,
            percentages: [
                { label: 'Apr. 2024', percentage: '10000000', currency: 'INR' },
                { label: 'May 2024', percentage: '11000000', currency: 'INR' },
                { label: 'June 2024', percentage: '12000000', currency: 'INR' },
                { label: 'July 2024', percentage: '11500000', currency: 'INR' }, // Drop
                { label: 'Aug. 2024', percentage: '13000000', currency: 'INR' },
                { label: 'Sept. 2024', percentage: '12500000', currency: 'INR' }, // Drop
                { label: 'Oct. 2024', percentage: '14000000', currency: 'INR' }
            ]
        },
        {
            name: 'Monthly Orders',
            type: "Count",
            isCurrency: false,
            percentages: [
                { label: 'Apr. 2024', percentage: '500' },
                { label: 'May 2024', percentage: '540' },
                { label: 'June 2024', percentage: '620' },
                { label: 'July 2024', percentage: '600' }, // Drop
                { label: 'Aug. 2024', percentage: '650' },
                { label: 'Sept. 2024', percentage: '620' }, // Drop
                { label: 'Oct. 2024', percentage: '700' }
            ]
        },
        {
            name: 'Monthly Burn',
            currency: 'INR',
            isCurrency: true,
            percentages: [
                { label: 'Apr. 2024', percentage: '1000000', currency: 'INR' },
                { label: 'May 2024', percentage: '1100000', currency: 'INR' },
                { label: 'June 2024', percentage: '1050000', currency: 'INR' }, // Drop
                { label: 'July 2024', percentage: '1150000', currency: 'INR' },
                { label: 'Aug. 2024', percentage: '1120000', currency: 'INR' }, // Drop
                { label: 'Sept. 2024', percentage: '1200000', currency: 'INR' },
                { label: 'Oct. 2024', percentage: '1180000', currency: 'INR' } // Drop
            ]
        }

    ]


    const Actions = () => {
        return (
            <Box display={'flex'} gap={1}>

                {isLoadingMetricsData ?
                    <>
                        <B300 className='skeleton' sx={{
                            // bgcolor: theme.palette.customColors.indigo600,
                            // border: `1px solid ${theme.palette.customColors.indigo600}`,
                            padding: '10px 16px',
                            fontWeight: 600,
                            minWidth: isSmallScreen ? "0" : '64px',
                            height: '50px',
                            width: '175px',

                        }}></B300>
                    </> :
                    <>
                        <B300
                            onClick={() => { setShowConfigurations(true) }}
                            sx={{
                                bgcolor: theme.palette.customColors.indigo600,
                                border: `1px solid ${theme.palette.customColors.indigo600}`,
                                padding: '10px 16px',
                                fontWeight: 600,
                                minWidth: isSmallScreen ? "0" : '64px',
                                '& > .MuiButton-icon.MuiButton-startIcon': {
                                    marginRight: isSmallScreen ? 0 : '8px',
                                    marginLeft: isSmallScreen ? 0 : '-4px'
                                }
                            }}
                            startIcon={<Settings fontSize='1.25rem' />}
                        >
                            {!isSmallScreen ?
                                "Configure Metrics" : 'Configure'}
                            {/* (savedConfigurationData?.data?.length > 0 ? "Update " : "Set ")  */}
                        </B300>
                        {/* {
                            !isLoadingSavedConfigurationData && savedConfigurationData?.data?.length > 0
                                ?
                                <B300
                                    onClick={() => {
                                        Cookies.remove("metric_id");
                                        navigate("edit_performance")
                                    }}
                                    sx={{
                                        bgcolor: theme.palette.customColors.indigo600,
                                        border: `1px solid ${theme.palette.customColors.indigo600}`,
                                        padding: '10px 16px',
                                        fontWeight: 600,
                                        minWidth: isSmallScreen ? "0" : '64px',
                                        '& > .MuiButton-icon.MuiButton-startIcon': {
                                            marginRight: isSmallScreen ? 0 : '8px',
                                            marginLeft: isSmallScreen ? 0 : '-4px'
                                        }
                                    }}
                                    startIcon={<Add fontSize='1.25rem' />}
                                >
                                    {!isSmallScreen ? "Add Data" : 'Add'}
                                </B300>
                                :
                                <></>
                        } */}

                    </>

                }

            </Box>
        )
    }

    const ChartCard = ({ data: cardData, isCurrency, type, formattingUnit }) => {
        // console.log(cardData, isCurrency, 'chaa')
        return (
            <Grid item xs={12} sm={6}
            // xl={4}
            >
                <Card sx={{ borderRadius: "10px", border: `1px solid ${theme.palette.customColors.b50}`, overflow: 'hidden', boxShadow: 'none' , height: '100%'}}>
                    <CardHeader
                        sx={{ background: theme.palette.customColors.white, borderBottom: `1px solid ${theme.palette.customColors.b50}`, "& > .MuiCardHeader-avatar": { maxWidth: { xs: "100%", md: "100%" }, marginRight: "8px" }, "& > .MuiCardHeader-action": { maxWidth: "00%" } }}
                        avatar={
                            <Box display={"flex"} sx={{ gap: 1, alignItems: "center" }} maxWidth="100%">
                                <Typography fontWeight={700} width="100%" fontSize={{ xs: "0.8rem", md: "0.9rem" }} color={theme.palette.customColors.g300} sx={{ wordBreak: 'break-all', overflowWrap: "break-word", whiteSpace: "normal" }}>
                                    {cardData?.name && cardData?.name != "" ? cardData?.name : '-'} {cardData?.currency ? "(" + cardData?.currency + ")" : (type === "Percentage" ? "(%)" : "(" + type + ")")}
                                </Typography>
                            </Box>
                        }
                        // action={
                        //     <IconButton onClick={() => { navigate("edit_performance") }} sx={{}}>
                        //         <Edit fontSize={"0.9rem"} />
                        //     </IconButton>}
                    />
                    <CardContent sx={{ paddingBottom: "0 !important", height: "calc(100% - 5rem)" }}>
                        {isCurrency ? <BarChart graphData={cardData?.percentages} height='100%' width="100%" formattingUnit={formattingUnit} /> : <LineChart graphData={cardData?.percentages} height='100%' width="100%" formattingUnit={formattingUnit} />}
                    </CardContent>
                </Card>
            </Grid>
        )
    }


    // useEffect(() => {
    //     if (addConfigurationResponse && addConfigurationResponse?.data?.statusCode == 200) {
    //         setShowConfigurations(false)
    //         refetchSavedConfigurationData()
    //         toast.success(
    //             <CustomToast message={addConfigurationResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
    //             {
    //                 duration: 2000,
    //                 position: "top-right",
    //             }
    //         );
    //     }
    // }, [addConfigurationResponse?.data?.statusCode]);

    // const data = {
    //     "startupId": "startup123",
    //     "headers": ["Month", "Monthly Revenue", "Burn Rate", "Runway", "Active Users", "Churn Rate"],
    //     "tableData": [
    //         {
    //             "Month": "Jan 24",
    //             "Monthly Revenue": "10,00,000 INR",
    //             "Burn Rate": "50,000 INR",
    //             "Runway": "12 Months",
    //             "Active Users": "1,000",
    //             "Churn Rate": "5%"
    //         },
    //         {
    //             "Month": "Feb 24",
    //             "Monthly Revenue": "12,00,000 INR",
    //             "Burn Rate": "60,000 INR",
    //             "Runway": "10 Months",
    //             "Active Users": "1,200",
    //             "Churn Rate": "4%"
    //         }
    //     ]
    // }

    // console.log(filterKeys?.includes("all"),"filterkayes")

    const hasAllFilter = filterKeys?.includes("all") || filterKeys?.length === 0;
    const configIsAvailable = savedConfigurationData?.data && savedConfigurationData?.data?.length > 0;
    let referenceArray = configIsAvailable ? savedConfigurationData?.data?.map(item => item?.keySlug) : []

    const allFilterColumns = !isLoadingSavedConfigurationData && configIsAvailable ? [{ Header: "Month", accessor: "month", minWidth: 100, align: 'start', fixed: true },
        ...savedConfigurationData?.data
            ?.map((item, index) => {
                // console.log(item, index)
                return ({ Header: capitalizedSentence(String(item?.key)?.replaceAll("_", " ")) + (item?.unit ? item?.unit === "Percentage" ? " (%)" : ` (${item?.unit})` : ""), accessor: item?.keySlug, minWidth: 150, align: 'center' })
            })] : []
    
        const FilteredColumns = configIsAvailable ? [{ Header: "Month", accessor: "month", minWidth: 150, align: 'start', fixed: true },
        ...filterKeys
            ?.map((item, index) => {
                // console.log(item, index)
                let currentUnit = savedConfigurationData?.data?.find(element => element?.keySlug === item)?.unit
                // console.log(, "dd", index, item)
                return ({ Header: capitalizedSentence(String(item)?.replaceAll("_", " ")) + (currentUnit ? currentUnit === "Percentage" ? "(%)" : ` (${currentUnit})` : ""), accessor: (item), minWidth: 100, align: 'center' })
            })] : []
    
        const columns = hasAllFilter ? allFilterColumns : FilteredColumns;

        const filteredGraphData = hasAllFilter ? graphData : graphData?.filter(item => filterKeys?.includes(item?.key_slug))

    // console.log(columns, 'columns', configIsAvailable)
    // const columns =
    //     savedConfigurationData?.data && savedConfigurationData?.data?.length > 0 ?
    //         [{ Header: "Month", accessor: "month", minWidth: 150, align: 'start' },
    //         ...(filterKeys ? savedConfigurationData?.data : filterKeys)
    //             ?.map(item => {
    //                 return ({ Header: capitalizedSentence(String(item)?.replaceAll("_", " ")), accessor: (item), minWidth: 150, align: 'start' })
    //             }), { Header: "Actions", accessor: "actions", minWidth: 150, align: 'start' }] : []

    // const RowActions = ({ row }) => {
    //     return <div style={{ display: "flex", justifyContent: 'end', marginLeft: 'auto' }}>
    //         <Button sx={{ minWidth: "1rem" }}>
    //             {/* <MoreOptionsIcon /> */}
    //             <EditOutlined
    //                 sx={{ color: theme.palette.customColors.indigo700 }}
    //                 onClick={() => {
    //                     Cookies.set("metric_id", row?.original?.metrics_id)
    //                     navigate("edit_performance")
    //                 }}
    //             />
    //         </Button>
    //         <Button
    //             sx={{ minWidth: "1rem" }}
    //             onClick={() => { setCurrentRow(row?.original); handleOpenDeletePopup() }}
    //         // onClick={() => handleOpenDeletePopup(row?.original)}
    //         >
    //             {/* <MoreOptionsIcon /> */}
    //             <DeleteOutlineRounded
    //                 sx={{ color: theme.palette.customColors.indigo700 }} />
    //         </Button>
    //     </div>
    // }


    const FilterSelectOptions = savedConfigurationData?.data && Array.isArray(savedConfigurationData?.data) ? [
        // { label: "Select a Field", value: null },
        ...savedConfigurationData?.data?.map((item, index) => ({
            label: item?.key,
            value: item?.keySlug,
            display_order: item?.display_order || null
        }))]
        : []
    // console.log(savedConfigurationData?.data ? savedConfigurationData?.data?.map(item => ({
    //     label: item,
    //     value: (item)?.toLowerCase()?.replaceAll(" ", "_")
    // }))
    //     : [], "savedConfigurationData?.data")


    // No Need
    // React.useEffect(() => {
    //     if (!isLoadingSavedConfigurationData) {
    //         filterKeys?.length === 0 && setFilterKeys(Array.isArray(FilterSelectOptions) &&
    //             FilterSelectOptions ? FilterSelectOptions?.map(item => item?.value) : []);
    //         // console.log(, "ffff")
    //     }
    // }, [isLoadingSavedConfigurationData])

    const [showFilterPopup, setShowFilterPopup] = React.useState(false);
    const [showColumnPopper, setShowColumnPopper] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, key) => {
        setAnchorEl(event.currentTarget);
        // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
        // console.log(event,"event")
        setAnchorEl(null);
    };


    return (
        <>

            <ModalComponent open={showFilterPopup} onClose={() => setShowFilterPopup(false)} style={{ maxWidth: 680, width: "100%" }}>
                <Paper
                    sx={{
                        background: theme.palette.customColors.whiteBG,
                        borderRadius: "8px",
                    }}
                >
                    <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
                        <CloseIcon width={40} height={40} onClick={() => setShowFilterPopup(false)} />
                    </IconButton>
                    <Grid display={"grid"} container padding={"1.5rem"}>
                        <Grid item xs={12} mb={'1.5rem'}>
                            <Typography
                                sx={{
                                    fontSize: "1.5rem",
                                    fontWeight: 700,
                                    lineHeight: "28px",
                                    color: theme.palette.customColors.g300,
                                }}
                            >
                                Edit columns
                            </Typography>
                        </Grid>
                        {/* {console.log(selectedValues, "selectedValues")} */}
                        <Grid xs={12}>
                            <CustomLabel>SELECT COLUMNS</CustomLabel>
                            {/* {console.log(showColumnPopper, "showColumnPopper")} */}
                            <Select
                                disableUnderline
                                className={classes.selectBox}
                                value={selectedValues}
                                fullWidth
                                multiple
                                open={showColumnPopper}
                                renderValue={(selected) => (
                                    <div style={{ display: "flex", gap: "0.2rem", flexWrap: "wrap" }}>
                                        {selected.map((value) => (
                                            <Chip style={{borderRadius:'4px'}}
                                                key={value}
                                                label={
                                                    value == 'all'
                                                        ? 'All'
                                                        : FilterSelectOptions.find((option) => option.value === value)?.label
                                                }
                                                size='medium'
                                                variant='outlined'
                                            // onDelete={() => handleRemoveChip(value)}
                                            />
                                        ))}
                                    </div>
                                )}
                                onChange={(event) => {
                                    // const isAllIncluded = event.target.value.includes('all');
                                    // if(isAllIncluded){
                                    //     setShowColumnPopper(false);
                                    // }
                                    // const handleChange = (event) => {
                                    // const hasAllDeals = event.target.value.includes('all');
                                    // if (hasAllDeals) {
                                    //     event.target.value = event.target.value.filter((item) => item === 'all');
                                    // }
                                    // else if (selectedValues?.includes("all")){

                                    // }
                                    // setSearchStatus(false)
                                    // setSelectedValues(event.target.value);

                                    // };
                                    // const selectedValues = e.target.value;

                                    // if(selectedValues?.includes("all")){
                                    //     setFilterKeys(selectedValues?.filter(item => item !== "all"))
                                    // }


                                    // if (selectedValues?.includes("all") && filterKeys?.includes("all") === false) {
                                    //     setFilterKeys(["all"]);
                                    // }
                                    // else if (!selectedValues?.includes("all") && filterKeys?.includes("all")) {
                                    //     setFilterKeys(selectedValues?.filter(item => item !== "all"));
                                    // }
                                    // else {
                                    //     if (selectedValues.length > 0) {
                                    //         setFilterKeys(selectedValues);
                                    //     }
                                    // }
                                    // Ensure at least one item is selected

                                }}
                                onClose={() => {
                                    setShowColumnPopper(false);
                                }}
                                onClickCapture={(e) => {
                                    setShowColumnPopper(true);
                                    // console.log("opening")
                                }}
                            >
                                <MenuItem
                                    sx={{
                                        backgroundColor: selectedValues.includes("all") ? "#eae8fb" : "inherit",
                                        "&:hover": {
                                            backgroundColor: selectedValues.includes("all") ? "#d6d3f0" : "#f5f5f5",
                                        },
                                        "&.Mui-selected": {
                                            backgroundColor: "#eae8fb !important",
                                            // fontWeight: "bold",
                                        },
                                    }}
                                    key={"All"} value={"all"} onClick={() => {
                                        // console.log("closing 1")
                                        const hasAllDeals = selectedValues.includes('all');
                                        if (hasAllDeals) {
                                            setSelectedValues(selectedValues?.filter(item => item?.includes('all')))
                                        }
                                        else {
                                            setSelectedValues(["all"])
                                        };
                                        setShowColumnPopper((old) => !old);
                                        // console.log("closing 2")
                                    }}>
                                    All
                                </MenuItem>
                                {Array.isArray(FilterSelectOptions) &&
                                    FilterSelectOptions?.map((item) => (
                                        <MenuItem
                                            key={item.value}
                                            value={item.value}
                                            sx={{
                                                backgroundColor: selectedValues.includes(item?.value) ? "#eae8fb" : "inherit",
                                                "&:hover": {
                                                    backgroundColor: selectedValues.includes(item?.value) ? "#d6d3f0" : "#f5f5f5",
                                                },
                                                "&.Mui-selected": {
                                                    backgroundColor: "#eae8fb !important",
                                                    // fontWeight: "bold",
                                                },
                                            }}
                                            onClick={() => {
                                                const hasCurrentItem = selectedValues.includes(item?.value);
                                                let removeAllValues = selectedValues?.filter(element => !element?.includes('all'))
                                                if (hasCurrentItem) {
                                                    // console.log(removeAllValues?.filter(element => element !== item?.value), item?.value, "removeAllValues1")
                                                    setSelectedValues(removeAllValues?.filter(element => element !== item?.value))
                                                }
                                                else {
                                                    setSelectedValues([...removeAllValues, item?.value])
                                                }
                                            }}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                        <Grid xs={12} item container justifyContent={"center"} spacing={2}>
                            <Grid item xs={6} lg={3} mt={'1.5rem'} display={'flex'} justifyContent={'center'}>
                                <BLUEBORDER
                                    sx={{
                                        textTransform: "none",
                                        padding: 2,
                                        borderWidth: 1,
                                        borderRadius: "8px",
                                    }}
                                    onClick={() => {
                                        setSelectedValues(['all'])
                                        setFilterKeys(['all'])
                                        // setShowFilterPopup(false)
                                    }}
                                    fullWidth
                                >
                                    Reset
                                </BLUEBORDER>
                            </Grid>
                            <Grid item xs={6} lg={3} mt={'1.5rem'} display={'flex'} justifyContent={'center'}>
                                <B300
                                    sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
                                    fullWidth
                                    onClick={() => {
                                        if (selectedValues?.length === 0) {
                                            setFilterKeys(['all'])
                                            setSelectedValues(['all'])
                                        }
                                        else {
                                            if (selectedValues?.length === FilterSelectOptions?.length) {
                                                setFilterKeys(['all'])
                                                setSelectedValues(['all'])
                                            }
                                            else {
                                                setFilterKeys(selectedValues?.sort((a, b) => referenceArray.indexOf(a) - referenceArray.indexOf(b)));
                                            }
                                        }
                                        setShowFilterPopup(false)
                                        // console.log(selectedValues,"selectedValues")
                                    }}
                                >
                                    Update
                                </B300>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </ModalComponent>
            {(isLoadingSavedConfigurationData || isLoadingMetricsData || isLoadingGraphData) ? <>
                <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                    <Box className='skeleton' minHeight={"90vh"} sx={{ borderRadius: "8px", border: `1px solid ${theme.palette.customColors.b50} ` }} marginTop={0}>

                    </Box>
                </Grid>
            </> : <>

                {(savedConfigurationData?.data && savedConfigurationData?.data?.length === 0) || (metricsData?.tableData && metricsData?.tableData?.length === 0)  ? <>
                    <Grid height={'75vh'} width={{ xs: '90vw', sm: '95vw' }} alignItems={'center'} justifyContent="center" display='flex'>

                        <NoData
                            placeHolderImage={setConfigPlaceholderImage}
                            placeHolderSx={{ width: "300px" }}
                            title='No Performance Data'
                            subTitle='It seems like there is no performance data available at the moment.'
                        />


                    </Grid> </> :
                    <TabContext value={value}>
                        <Grid container xs={12}
                            // padding={{ xs: "0 1rem 1rem 1rem", md: "0 2rem 1rem 2rem" }}
                            justifyContent={"space-between"}>
                            <Grid item xs={12} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }} marginRight={"0rem"} >
                                <Grid xs={12} container sx={{ bgcolor: 'white', borderRadius: '8px 8px 0 0', borderInline: `1px solid ${theme.palette.customColors.b50} `, borderBlockStart: `1px solid ${theme.palette.customColors.b50} ` }}>
                                    <Grid item xs={8}>
                                        <CustomTabs
                                            variant="submenu"
                                            titleStyle={{ fontSize: '1rem' }}
                                            tabStyle={{ padding: '0.5rem 1rem 0rem' }}
                                            sx={{ border: 'none' }}
                                            bgColor={"transparent"}
                                            data={
                                                [
                                                    { value: "data", label: `Data`, count: 10, read: false, icon: TableChartOutlined },
                                                    // { value: "graphs", label: `Graphs`, count: 10, read: false, icon: GraphicEq },
                                                    { value: "graphs", label: `Graphs`, count: 10, read: false, icon: TimelineOutlined },
                                                ]
                                            } value={value} handleChange={handleTabChange}
                                            isSmallScreen={isSmallScreen} className={classes.scrollTabs} key={"Tabs Tabs"} indicatorFullSize={false} />
                                    </Grid>
                                    <Grid item xs={4} pt={0.75} pr={1}>

                                    </Grid>
                                </Grid>
                                {/* {value === "data" &&  */}
                                <Grid xs={12} container justifyContent={"space-between"} padding={"0.5rem"} sx={{ bgcolor: 'white', borderRadius: '0 0', borderInline: `1px solid ${theme.palette.customColors.b50} `, borderBlockStart: `1px solid ${theme.palette.customColors.b50} ` }}>
                                    <Grid xs={12} container justifyContent={"space-between"} spacing={2}
                                    >
                                        <Grid item xs={12} sm={6} md={6} lg={4} height={"min-content"}>
                                            <DateRangePicker
                                                className={`${classes.DateRangePicker} `}
                                                ranges={[]}
                                                style={{ width: '100%' }}
                                                placeholder="Filter by date range"
                                                format="dd-MMM-yyyy"
                                                hoverRange="month"
                                                onChange={(value) => {
                                                    setDuration(
                                                        value !== null
                                                            ? { from_date: moment(value[0]?.setUTCDate(1))?.toISOString(), to_date: moment(value[1].setUTCDate(1))?.toISOString() }
                                                            : null
                                                    );
                                                }}
                                                value={
                                                    duration !== null
                                                        ? [new Date(duration?.from_date), new Date(duration?.to_date)]
                                                        : null
                                                }
                                                renderValue={([from_date, to_date]) => {
                                                    return (
                                                        format(from_date, 'dd-MMM-yyyy') + ' - ' + format(to_date, 'dd-MMM-yyyy')
                                                    );
                                                }}
                                            // caretAs={() => <InsertInvitation sx={{color: "#000"}} />}
                                            />
                                        </Grid>
                                        {<Grid item xs={12} sm={6} md={6} lg={8} marginRight={"-1rem"} justifyContent={"flex-end"} display={"flex"} alignItems={"center"} gap={1}>
                                            {<Tooltip title="Edit Columns">
                                                <IconButton sx={{ marginBlock: 'auto', borderRadius: "4px", minWidth: "1rem" }} onClick={() => {
                                                    setShowFilterPopup(true);
                                                }} >
                                                    <Settings />
                                                </IconButton>
                                            </Tooltip>}
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                {/* } */}
                                {value === "data" &&
                                    <>
                                        {isLoadingMetricsData ? <>
                                            <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0 !important" }}>
                                                <Box className='skeleton' minHeight={"60vh"} sx={{ borderRadius: "0 0 8px 8px", border: `1px solid ${theme.palette.customColors.b50} ` }} marginTop={0}>

                                                </Box>
                                            </Grid>
                                        </> :
                                            <Grid xs={12} container justifyContent={"space-between"} sx={{ bgcolor: 'white', borderRadius: '0 0 8px 8px', borderInline: `1px solid ${theme.palette.customColors.b50} `, borderBlockStart: `1px solid ${theme.palette.customColors.b50} ` }}>
                                                <AsyncTableListing
                                                    columns={columns}
                                                    data={metricsData?.tableData}
                                                    sx={{ borderRadius: "0 0 8px 8px", maxHeight: "50vh" }}
                                                    // RowActions={RowActions}
                                                    pagination={false}
                                                    maxHeight={"50vh"}
                                                    // maxHeight={{ xs: "62vh", xl: '90vh' }}
                                                    noDataTitle={"No Data Available"}
                                                    noDataSubTitle={"It seems like there are no data available at the moment."}
                                                    noDataPlaceHolderImage={setConfigPlaceholderImage}
                                                    noDataPlaceHolderSx={{ width: "300px" }}
                                                // actions={() => <>{metricsData?.tableData?.length === 0 ? <Box display={"flex"} gap={"0.5rem"}>
                                                //     <B300 onClick={() => { setShowConfigurations(true) }} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600} `, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Settings fontSize='1.25rem' />}>
                                                //         {!isSmallScreen ? "Configure Metrics" : 'Configure'}
                                                //     </B300>
                                                //     <B300
                                                //         onClick={() => {
                                                //             Cookies.remove("metric_id");
                                                //             navigate("edit_performance");
                                                //         }}
                                                //         sx={{
                                                //             bgcolor: theme.palette.customColors.indigo600,
                                                //             border: `1px solid ${theme.palette.customColors.indigo600} `,
                                                //             padding: '10px 16px',
                                                //             fontWeight: 600,
                                                //             minWidth: isSmallScreen ? "0" : '64px',
                                                //             '& > .MuiButton-icon.MuiButton-startIcon': {
                                                //                 marginRight: isSmallScreen ? 0 : '8px',
                                                //                 marginLeft: isSmallScreen ? 0 : '-4px'
                                                //             }
                                                //         }}
                                                //         startIcon={<Add fontSize='1.25rem' />}
                                                //     >
                                                //         {!isSmallScreen ? "Add Data" : "Add"}
                                                //     </B300>
                                                // </Box> : <></>}</>}
                                                />
                                            </Grid>}
                                    </>
                                    }
                                {value === "graphs" &&
                                    <Grid xs={12} container sx={{ position: 'relative' }}>
                                        {isLoadingGraphData ? <>
                                            <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0 !important" }}>
                                                <Box className='skeleton' minHeight={"100%"} sx={{ borderRadius: "0 0 8px 8px", border: `1px solid ${theme.palette.customColors.b50} ` }} marginTop={0}>

                                                </Box>
                                            </Grid>
                                        </>
                                            : <Grid container xs={12} sx={{ bgcolor: 'white', borderRadius: '0 0 8px 8px', borderInline: `1px solid ${theme.palette.customColors.b50} `, borderBlockStart: `1px solid ${theme.palette.customColors.b50} `, overflowY: filteredGraphData && filteredGraphData?.length > 0 ? "auto" : 'hidden', maxHeight: { xs: "calc(100vh - 290px)", xl: "calc(100vh - 310px)" } }} padding="1rem">

                                                {
                                                    filteredGraphData && filteredGraphData?.length > 0 ?
                                                        <Grid container xs={12} maxWidth={"100%"}

                                                            // padding={{ xs: "0 0rem 1rem 1rem", md: "0 1rem 2rem 2rem" }}
                                                            spacing={2}
                                                            justifyContent={"space-between"}

                                                        >
                                                            {
                                                                filteredGraphData?.map(item => {
                                                                    const isCurrency = item?.Unit !== "Percentage" && item?.Unit !== "Count" && item?.Unit !== "Months";
                                                                    const currentItem = {
                                                                        name: item?.name,
                                                                        isCurrency: isCurrency,
                                                                        percentages: item?.data?.map(element => {
                                                                            const includeCurrency = isCurrency ? { currency: item?.Unit } : {};
                                                                            return { label: element?.month, percentage: element.value, ...includeCurrency }
                                                                        })
                                                                    }
                                                                    if (isCurrency) {
                                                                        currentItem.currency = item?.Unit;
                                                                    }
                                                                    {/* else { */ }
                                                                    currentItem.type = item?.Unit
                                                                    {/* } */ }

                                                                    return currentItem
                                                                })?.map((chart, index) => <ChartCard data={chart} key={index} isCurrency={chart?.isCurrency} type={chart?.type} formattingUnit={savedConfigurationData?.data?.length > 0 ? [...new Set(savedConfigurationData?.data?.filter(item => !["Months", "Count", "Percentage"].includes(item?.unit)).map(item => item?.unit))]
                                                                                        : 'INR'} />)
                                                            }
                                                        </Grid> : <Grid container xs={12} maxWidth={"100%"}

                                                            // padding={{ xs: "0 0rem 1rem 1rem", md: "0 1rem 2rem 2rem" }}
                                                            spacing={2}
                                                            justifyContent={"space-between"}
                                                            sx={{ filter: 'blur(3px)' }}

                                                        >
                                                            {
                                                                charts?.map((chart, index) => <ChartCard data={chart} key={index} isCurrency={chart?.isCurrency} type={chart?.type} />)
                                                            }
                                                        </Grid>
                                                }
                                            </Grid>}
                                        {/* {console.log(filteredGraphData, filteredGraphData?.length)} */}
                                        {!(filteredGraphData && filteredGraphData?.length > 0) && <Grid position={"absolute"} top={0} left={0} right={0} bottom={0}>
                                            <NoData
                                                // placeHolderImage={setConfigPlaceholderImage}
                                                title={"No Data Available"}
                                                subTitle={"It seems like there are no data available at the moment."}
                                                // placeHolderImage={setConfigPlaceholderImage}
                                                placeHolderSx={{ width: "300px" }}
                                                actions={() => <>{filteredGraphData?.length === 0 ? <Box display={"flex"} gap={"0.5rem"}>
                                                    <B300 onClick={() => { setShowConfigurations(true) }} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600} `, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Settings fontSize='1.25rem' />}>
                                                        {!isSmallScreen ? "Configure Metrics" : 'Configure'}
                                                    </B300>
                                                    <B300
                                                        onClick={() => {
                                                            Cookies.remove("metric_id");
                                                            navigate("edit_performance");
                                                        }}
                                                        sx={{
                                                            bgcolor: theme.palette.customColors.indigo600,
                                                            border: `1px solid ${theme.palette.customColors.indigo600} `,
                                                            padding: '10px 16px',
                                                            fontWeight: 600,
                                                            minWidth: isSmallScreen ? "0" : '64px',
                                                            '& > .MuiButton-icon.MuiButton-startIcon': {
                                                                marginRight: isSmallScreen ? 0 : '8px',
                                                                marginLeft: isSmallScreen ? 0 : '-4px'
                                                            }
                                                        }}
                                                        startIcon={<Add fontSize='1.25rem' />}
                                                    >
                                                        {!isSmallScreen ? "Add Data" : "Add"}
                                                    </B300>
                                                </Box> : <></>}</>}
                                            />
                                        </Grid>}
                                    </Grid>
                                }
                            </Grid>
                            {/* <TabPanel value='data'
                                                sx={{
                                                    width: '100%',
                                                    padding: { xs: '1rem 1rem 1rem 3rem', md: '1rem 1rem 1rem 2rem', xl: '1rem 1rem 1rem 3rem' }
                                                    //  padding: { xs: "0rem 2rem 1rem 2rem", md: '0rem 2rem 1rem 2rem' } 
                                                }}> */}
                            {/* {value === "data" && <Grid container xs={12}
                                                // padding={{xs: 0, md: "0rem 2rem 0rem 0rem", lg: 0}}
                                                padding={{ xs: "0 0rem 1rem 1rem", md: "0 0rem 2rem 2rem", lg: "1rem 0rem 2rem 2rem" }}
                                                spacing={2} justifyContent={"space-between"}>
                                                <AsyncTableListing columns={columns} data={metricsData?.tableData} sx={{ borderRadius: "0 0 8px 8px" }} RowActions={RowActions} pagination={false} />
                                            </Grid>} */}
                            {/* </TabPanel> */}
                            {/* <TabPanel value='graphs' sx={{ maxHeight: "calc(100vh - 120px)", overflowY: 'auto', width: '100%', marginTop: '1rem' }}> */}
                            {/* <PerfectScrollbar
                                                    containerRef={containerRef}
                                                    style={{ height: "calc(100vh - 120px)" }}> */}
                            {/* <Grid container xs={12}
                                                    padding={{ xs: "0 0rem 1rem 1rem", md: "0 1rem 2rem 2rem" }}
                                                    spacing={2} justifyContent={"space-between"}>
                                                    {
                                                        charts?.map((chart, index) => <ChartCard data={chart} key={index} />)
                                                    }
                                                </Grid> */}
                            {/* </PerfectScrollbar> */}
                            {/* </TabPanel> */}
                        </Grid>
                    </TabContext>}


            </>}
        </>
    )
}

export default Performance


// const configurationData = [
//     {
//         id: 1,
//         category: "Financial Matrics",
//         name: "Monthly Revenue",
//         selected: 'true',
//         fields: [
//             { label: "Monthly Revenue", default_unit: "currency" }
//         ]
//     },
//     {
//         id: 2,
//         category: "Financial Matrics",
//         name: "Burn Rate",
//         selected: 'true',
//         fields: [
//             { label: "Burn Rate", default_unit: "currency" }
//         ]
//     },
//     {
//         id: 3,
//         category: "Financial Matrics",
//         name: "Runway",
//         selected: 'true',
//         fields: [
//             { label: "Runway", default_unit: "months" }
//         ]
//     },
//     {
//         id: 4,
//         category: "Financial Matrics",
//         name: "Gross Margin",
//         fields: [
//             { label: "Gross Margin", default_unit: "currency" }
//         ]
//     },
//     {
//         id: 5,
//         category: "Financial Matrics",
//         name: "EBITDA (Earnings Before Interest, Taxes, Depreciation, Amortization)",
//         fields: [
//             { label: "EBITDA (Earnings Before Interest, Taxes, Depreciation, Amortization)", default_unit: "currency" }
//         ]
//     },
//     {
//         id: 6,
//         category: "Financial Matrics",
//         name: "Net Profit Margin",
//         fields: [
//             { label: "Net Profit Margin", default_unit: "percentage" }
//         ]
//     }
// ]

// const configurationData = [
//     {
//         "templateId": "template1",
//         "categoryName": "Financial Metrics",
//         "metrics": [
//             {
//                 "key": "Monthly Revenue",
//                 "keySlug": "monthly_revenue",
//                 "defaultUnit": "Currency"
//             },
//             {
//                 "key": "Burn Rate",
//                 "keySlug": "burn_rate",
//                 "defaultUnit": "Currency"
//             },
//             {
//                 "key": "Runway",
//                 "keySlug": "runway",
//                 "defaultUnit": "Months"
//             },
//             {
//                 "key": "Gross Margin",
//                 "keySlug": "gross_margin",
//                 "defaultUnit": "Currency"
//             },
//             {
//                 "key": "EBITDA",
//                 "keySlug": "ebitda",
//                 "defaultUnit": "Currency"
//             },
//             {
//                 "key": "Net Profit Margin",
//                 "keySlug": "net_profit_margin",
//                 "defaultUnit": "Percentage"
//             }
//         ]
//     },
//     {
//         "templateId": "template2",
//         "categoryName": "Growth Metrics",
//         "metrics": [
//             {
//                 "key": "Customer Acquisition",
//                 "keySlug": "customer_acquisition",
//                 "defaultUnit": "Count"
//             },
//             {
//                 "key": "Churn Rate",
//                 "keySlug": "churn_rate",
//                 "defaultUnit": "Percentage"
//             },
//             {
//                 "key": "CLTV",
//                 "keySlug": "cltv",
//                 "defaultUnit": "Currency"
//             },
//             {
//                 "key": "User Engagement",
//                 "keySlug": "user_engagement",
//                 "defaultUnit": "Percentage"
//             },
//             {
//                 "key": "Conversion Rate",
//                 "keySlug": "conversion_rate",
//                 "defaultUnit": "Percentage"
//             }
//         ]
//     }
// ]