import React, { useRef } from "react";
import BreadcrumbHeader from "../../layout/BreadcrumbsHeader";
import { ReactComponent as NameTypeIcon } from "../../assets/rounds_icons/name_type_round_icon.svg";
import { ReactComponent as RoundSizeIcon } from "../../assets/rounds_icons/round_size_val_round_icon.svg";
import { ReactComponent as CurrentInvestorsIcon } from "../../assets/rounds_icons/current_investors_round_icon.svg";
import { ReactComponent as FundUtilizationIcon } from "../../assets/rounds_icons/fund_util_round_icon.svg";
import { ReactComponent as CurrentTractionIcon } from "../../assets/rounds_icons/current_traction_round_icon.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/rounds_icons/documents_round_icon.svg";
import { ReactComponent as FAQIcon } from "../../assets/rounds_icons/faq_round_icon.svg";
import { ReactComponent as ExitStrategyIcon } from "../../assets/rounds_icons/exit_strategy_round_icon.svg";
import { ReactComponent as AddIcon } from "../../assets/rounds_icons/plus_icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rounds_icons/delete_icon.svg";
import { ReactComponent as EditIcon } from "../../assets/rounds_icons/edit_icon.svg";
import { ReactComponent as UploadDocumentsIcon } from "../../assets/rounds_icons/upload_documents_icon.svg";
import { ReactComponent as SavingIcon } from "../../assets/saving_icon.svg";
import TopIcon from "../../assets/rounds_icons/TopIcon.svg";
// import TopIcon from "../../assets/rounds_icons/top_icon.svg";
import BottomIcon from "../../assets/rounds_icons/BottomIcon.svg";
// import BottomIcon from "../../assets/rounds_icons/bottom_icon.svg";
import RoundStepsBackground from "../../assets/rounds_icons/round_list_bottom.png";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  IconButton
} from "@mui/material";
import {
  BLUEBORDER,
  SECONDARY,
  B300 as CustomButton,
  WHITEBG,
} from "../../ui-components/CustomButton";
import { useTheme } from "@emotion/react";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../ui-components/formik/CustomInputField";
import CustomLabel from "../../ui-components/CustomLabel";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  FetchAllCurrencyLists,
  FetchAllRoundsSteps,
  CreateRound as CreateRoundAPI,
  UploadRoundDocuments,
  FetchAllStages,
  FetchAllSecurityTypes,
  FetchAllRoundStatus,
  FetchRoundDetails,
  DeleteRoundDocument,
  FetchAllDocumentTypes,
  FetchAllRoundTypes,
} from "../../api";
import {
  Done,
  Edit,
  ForkLeft,
  KeyboardArrowDownRounded,
  Upload,
  Warning,
  WarningOutlined,
  WarningRounded,
} from "@mui/icons-material";

// import docxImage from "../../assets/Docx.png";
import CustomMenu from "../../ui-components/CustomMenu";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PieChart from "../../ui-components/PieChart";
import CustomRichTextEditor from "../../ui-components/formik/CustomRichTextEditor";
import * as Yup from "yup";
import ThemeBGWrapper from "../../ui-components/ThemeBGWrapper";
import toast from "react-hot-toast";
import moment from "moment";
// import Round1 from "../../assets/rounds_icons/Component 1.png";
// import Round2 from "../../assets/rounds_icons/Component 2.png";
// import Round3 from "../../assets/rounds_icons/Component 3.png";
// import Round4 from "../../assets/rounds_icons/Component 4.png";
// import Round5 from "../../assets/rounds_icons/Component 5.png";
import Round1 from "../../assets/stage_icons/preSeed.png";
import Round2 from "../../assets/stage_icons/seed.png";
import Round3 from "../../assets/stage_icons/seriesA.png";
import Round4 from "../../assets/stage_icons/seriesB.png";
import Round5 from "../../assets/stage_icons/ipo.png";
import CustomInfoIcon from "../../ui-components/CustomInfoIcon";
import { TabContext } from "@mui/lab";
import CustomDatePicker from "../../ui-components/formik/CustomDatePicker";
import CustomToast from "../../ui-components/CustomToast";
import { containerRef, getFileIcon, handleFormSubmit } from "../../utils";
import ClearIcon from '../../assets/doc_icons/close_icon.svg'
import { ReactComponent as InfoIcon } from '../../assets/help-circle.svg';
import CustomValidationText from "../../ui-components/CustomValidationText";
import Cookies from "js-cookie";

const currencyInputProps = {
  inputMode: "numeric", // Use numeric keyboard on mobile devices
  pattern: "[0-9]*", // Only allow digits 0-9
  // title: "Please enter a number between 0 and 9",
  style: {
    // textAlign: 'center', // Center the text in the input
  },
  min: 0,
  onKeyDown: (event) => {
    // Prevent the minus sign (-) from being entered
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Get the input value
    const inputValue = event.key;

    // Allow only numeric characters (0-9) and certain control keys
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(inputValue)) {
      // If not, prevent the key from being entered
      event.preventDefault();
    }
  },
  onInput: (event) => {
    // Get the input value
    const inputValue = event.target.value;

    // Remove non-numeric characters using a regular expression
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Update the input field value with the filtered numeric value
    event.target.value = numericValue;
  },
  onKeyDown: (event) => {
    // Prevent the input of minus sign (key code 189)
    if (event.keyCode === 189 || event.key === "-") {
      event.preventDefault();
    }
  },
  disableUnderline: true,
};

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#e0e0e0",
  "&.Mui-checked": {
    color: "#7c4dff",
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
  },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});



const roundImages = [
  Round1, Round2, Round3, Round4, Round5
]


const CreateRound = () => {
  const theme = useTheme();
  const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const navigate = useNavigate();
  let stepIndex = new URLSearchParams(window.location.search).get("page");
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentIndex, setCurrentIndex] = React.useState(Number(stepIndex) || 0);
  // const { id: round_id } = useParams();
  const round_id = Cookies.get("round_id");
  const [roundID, setRoundID] = React.useState(round_id || null);
  const isEditMode = !!round_id

  const { data: roundDetails, isLoading: isLoadingRoundDetails, refetch: refetchRoundDetails } = FetchRoundDetails(round_id);
  // console.log(roundDetails,'roundDetails')

  const { data: currencyList, isLoading: isLoadingCurrency } =
    FetchAllCurrencyLists();
  const { data: roundSteps, isLoading: isLoadingRoundsSteps } =
    FetchAllRoundsSteps();
  const {
    mutateAsync: createRoundAsync,
    isLoading: isCreatingRound,
    status: createRoundStatus,
    error: createRoundError,
    data: createRoundResponse
  } = CreateRoundAPI();
  const {
    mutateAsync: deleteRoundDocumentAsync,
    isLoading: isdeletingRoundDocument,
    status: deleteRoundDocumentStatus,
    error: deleteRoundDocumentError,
    data: deleteRoundDocumentResponse
  } = DeleteRoundDocument();
  const { data: round_types, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllRoundTypes()
  const { data: document_types, isRefetching: isRefetchingDocumentTypes, isLoading: isLoadingDocumentTypes, refetch: refetchDocumentTypes } = FetchAllDocumentTypes()
  const { data: security_types, isRefetching: isRefetchingSecurityTypes, isLoading: isLoadingSecurityTypes, refetch: refetchSecurityTypes } = FetchAllSecurityTypes()
  const { data: round_statuses, isRefetching: isRefetchingRoundStatuses, isLoading: isLoadingRoundStatuses, refetch: refetchRoundStatuses } = FetchAllRoundStatus()
  const {
    mutateAsync: uploadDocumentsAsync,
    isLoading: isUploadingDocuments,
    status: uploadDocumentsStatus,
    error: uploadDocumentsError,
    data: uploadDocumentsResponse
  } = UploadRoundDocuments();

  const round_steps = [
    {
      // name: "NAME & TYPE",
      // sub_title: "Provide round name, type of round and type of security.",
      image_url: NameTypeIcon,
    },
    {
      // name: "ROUND SIZE & VALUATION",
      // sub_title: "Provide details about the funding amount and valuation.",
      image_url: RoundSizeIcon,
    },
    {
      // name: "CURRENT INVESTORS",
      // sub_title: "Provide details about the current investors in the startup.",
      image_url: CurrentInvestorsIcon,
    },
    {
      // name: "FUND UTILIZATION",
      // sub_title: "Provide details about fund utilization.",
      image_url: FundUtilizationIcon,

    },
    {
      // name: "CURRENT TRACTION & RUNWAY",
      // sub_title: "Provide details about current traction and runway.",
      image_url: CurrentTractionIcon,
    },
    {
      // name: "DOCUMENTS",
      // sub_title: "Upload the basic document for your startup.",
      image_url: DocumentsIcon,
    },
    {
      // name: "FAQS",
      // sub_title: "Provide some basic information about your startup.",
      image_url: FAQIcon,
    },
    {
      // name: "EXIT STRATEGY & OTHER DETAILS",
      // sub_title: "Provide some basic information about your startup.",
      image_url: ExitStrategyIcon,
    },
  ];

  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    let timeoutId;
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      if (bottomRef.current) {
        const ps = bottomRef.current._container;
        const scrollDistance = ps.scrollHeight - ps.scrollTop; // Distance to scroll
        const step = 10; // The amount to scroll each frame
        const duration = 300; // Duration of the scroll in milliseconds
        const interval = duration / (scrollDistance / step);

        const smoothScroll = setInterval(() => {
          if (ps.scrollTop + ps.clientHeight < ps.scrollHeight) {
            ps.scrollTop += step;
          } else {
            clearInterval(smoothScroll); // Stop scrolling once the bottom is reached
          }
        }, interval);
      }
    }, 100);
  }

  const validationObj =
    currentIndex == 0
      ? {
        round_name: Yup.string().required("Round name is required").max(30),
        round_type: Yup.string().required("Please select round type"),
        security_type: Yup.string().required("Please select security type"),
      }
      : currentIndex == 1
        ? {
          round_highlights: Yup.string().required("Funding round highlights is required").test(
            'no-html-tags',
            'Funding round highlights is required',
            (value) => {
              const plainText = value?.replace(/<[^>]*>/g, "");
              return plainText && plainText.trim().length > 0;
            }
          ),
          total_round_size: Yup.string().required("Total round size is required"),
          round_valuation: Yup.string().required("Round valuation is required"),
          round_valuation_cap: Yup.string(),
          minimum_cheque_size: Yup.string().required("Minimum cheque size is required"),
          received_termsheet: Yup.boolean().required("required"),
          committed_funding_amount: Yup.string().required("Committed funding amount is required"),
          currency_id: Yup.string().required("Currency is required"),
          currency: Yup.string().required("Currency is required"),
        }
        : currentIndex == 2
          ? {
            investors: Yup.array().of(Yup.object().shape({
              // name: Yup.string().required("Investor Name is Required"), email: Yup.string()
            }))
          }
          : currentIndex == 3
            ? {
              fund_utilization_highlights: Yup.string().required("Fund utilization highlights is required").test(
                'no-html-tags',
                'Fund utilization highlights is required',
                (value) => {
                  const plainText = value?.replace(/<[^>]*>/g, "");
                  return plainText && plainText.trim().length > 0;
                }
              ),
              fund_allocation_percentages: Yup.array().of(Yup.object().shape(
                { name: Yup.string().required("Fund allocation name is required"), percentage: Yup.number().required("Fund allocation percentage is required") },
              )),
            }
            : currentIndex == 4
              ? {
                traction_highlights: Yup.string().required("Traction highlights is required").test(
                  'no-html-tags',
                  'Traction highlights is required',
                  (value) => {
                    const plainText = value?.replace(/<[^>]*>/g, "");
                    return plainText && plainText.trim().length > 0;
                  }
                ),
                key_metrics: Yup.array().of(Yup.object().shape({
                  heading: Yup.string().required("Heading is required"),
                  properties: Yup.array().of(Yup.object().shape(
                    { key: Yup.string().required("Key is required"), value: Yup.string().required("Value is required") },
                  )),
                },
                )),
              }
              : currentIndex == 5
                ? {
                  // documents: Yup.array().of(
                  //   Yup.object().shape({
                  //     name: Yup.string().required('Name is Required'),
                  //     mis_reports: Yup.mixed().when('_id', {
                  //       is: (value) => value === undefined || value === null,
                  //       then: Yup.mixed()
                  //         .test('file-upload', 'Please upload a file', (value) => value !== null)
                  //         .required('Document is required'),
                  //     }),

                  //     editable: Yup.bool(),
                  //     type: Yup.string(),
                  //   })
                  // ),
                }

                : currentIndex == 6
                  ? {
                    faqs: Yup.array().of(Yup.object().shape({
                      question: Yup.string(),
                      answer: Yup.string(),
                    },
                    )),
                  }
                  : currentIndex == 7
                    ? {
                      exit_highlights: Yup.string().required("Exit highlights is required").test(
                        'no-html-tags',
                        'Exit highlights is required',
                        (value) => {
                          const plainText = value?.replace(/<[^>]*>/g, "");
                          return plainText && plainText.trim().length > 0;
                        }
                      ),
                      runway_left: Yup.number().typeError("Runway is required").required("Runway is required"),
                      monthly_net_burn: Yup.string().required("Monthly net burn is required"),
                      round_start_date: Yup.date()
                        // .nullable() // Allows the field to be null
                        .required("Round start date is required") // Validates that the field is either null or a date
                      ,
                      round_closing_date: Yup.date()
                        // .nullable() // Allows the field to be null
                        .required("Round closing date is required") // Validates that the field is either null or a date
                      ,
                      round_status: Yup.string().required("Round status is required"),
                    }
                    : {};

  const validationSchema = Yup.object({ ...validationObj });

  // console.log(roundDetails, 'roundDetails')

  const initialValues = {
    // step 0
    round_name: "",
    round_type: '',
    security_type: "",
    // step 1
    round_highlights: "",
    total_round_size: 0,
    round_valuation: 0,
    round_valuation_cap: 0,
    minimum_cheque_size: "",
    received_termsheet: false,
    committed_funding_amount: "",
    currency_id: "",
    currency: "$",
    // step 2
    investors: [{ name: "", email: "" }],
    // step 3
    fund_utilization_highlights: "",
    fund_allocation_percentages: [
      { name: "", percentage: 0 },
    ],
    // step 4
    traction_highlights: "",
    key_metrics: [
      {
        heading: "",
        properties: [
          { key: "", value: "" },
          { key: "", value: "" },
          { key: "", value: "" },
        ],
      },
    ],
    // step 5
    documents: [
      {
        title: "MIS Report",
        document: null,
        editable: false,
        type: "",
        document_type: null,
      },
    ],
    // step 6
    faqs: [
      {
        question: "",
        answer: "",
      },
    ],
    // step 7
    exit_highlights: "",
    runway_left: 'select_runway_left',
    monthly_net_burn: "",
    round_start_date: null,
    round_closing_date: null,
    round_status: "",
  }

  const formik = useFormik({
    initialValues: !isLoadingRoundDetails && isEditMode ? roundDetails : initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      const { documents: currentDocuments, ...selectedValues } = values;

      if (currentIndex == 5) {

        // console.log(documents,"documents")

        let formData = new FormData();
        let documents = currentDocuments?.filter(doc => doc?.document?.size != undefined);
        // formData.append(`slug`, details?.slug);

        if (documents?.length > 0) {
          documents?.forEach((doc, index) => {
            if (doc?.document?.size) {
              doc?._id && formData.append(`id[${index}]`, doc?._id);
              formData.append(`title[${index}]`, doc?.title);
              doc?.document && formData.append('document', doc?.document);
              // doc?.document && formData.append(`document_type[${index}]`, doc?.document_type);
            }
            // else {
            //   formData.append(`title[${index}]`, doc?.title);
            //   doc?._id && formData.append(`id[${index}]`, doc?._id);
            //   // doc?._id && formData.append(`document_type[${index}]`, doc?.document_type);
            // }
          });
        }


        // if (documents.length === 1) {
        //   // console.log()
        //   if (documents[0]?.document?.size) {
        //     formData.append(`title[${0}]`, documents[0]?.title);
        //     documents[0]?.document && formData.append(`document`, documents[0]?.document);
        //     // documents[0]?.document && formData.append(`document_type[0]`, documents[0]?.document_type);
        //   } else {
        //     formData.append(`title[${0}]`, documents[0]?.title);
        //     documents[0]?._id && formData.append(`id[${0}]`, documents[0]?._id);
        //     // documents[0]?._id && formData.append(`document_type[0]`, documents[0]?.document_type);
        //   }
        // }
        if (roundID && roundID != null) {
          uploadDocumentsAsync({ payload: formData, _id: roundID })
        }
      }
      else {

        const savedData = currentIndex == 0
          ? {
            round_name: selectedValues?.round_name,
            round_type: selectedValues?.round_type,
            security_type: selectedValues?.security_type,
          }
          : currentIndex == 1
            ? {
              round_highlights: selectedValues?.round_highlights,
              total_round_size: selectedValues?.total_round_size,
              round_valuation: selectedValues?.round_valuation,
              round_valuation_cap: selectedValues?.round_valuation_cap,
              minimum_cheque_size: selectedValues?.minimum_cheque_size,
              received_termsheet: selectedValues?.received_termsheet,
              committed_funding_amount: selectedValues?.committed_funding_amount,
              currency_id: selectedValues?.currency_id,
              // currency: selectedValuescurrency,
            }
            : currentIndex == 2
              ? {
                investors: selectedValues?.investors?.filter(item => String(item?.name)?.trim() != "")
              }
              : currentIndex == 3
                ? {
                  fund_utilization_highlights: selectedValues?.fund_utilization_highlights,
                  fund_allocation_percentages: selectedValues?.fund_allocation_percentages,
                }
                : currentIndex == 4
                  ? {
                    traction_highlights: selectedValues?.traction_highlights,
                    key_metrics: selectedValues?.key_metrics,
                  }
                  : currentIndex == 6
                    ? {
                      faqs: selectedValues?.faqs?.filter(item => (item?.question)?.trim() != ""),
                    }
                    : currentIndex == 7
                      ? {
                        exit_highlights: selectedValues?.exit_highlights,
                        runway_left: selectedValues?.runway_left,
                        monthly_net_burn: selectedValues?.monthly_net_burn,
                        round_start_date: selectedValues?.round_start_date,
                        round_closing_date: selectedValues?.round_closing_date,
                        // round_status: selectedValues?.round_status,
                      }
                      : {}

        if (roundID && roundID != null) {
          createRoundAsync({ _id: roundID, startup_id: roundDetails?.startup_id, ...savedData }).then(res => {
            refetchRoundDetails()
          });
        }
        else {
          createRoundAsync({ ...savedData, startup_id: roundDetails?.startup_id }).then(res => {
            setRoundID(res?.data?.data?._id);
            // refetchRoundDetails
          });
        }
      }
    },
  });

  const Actions = () => {
    return (
      <>
        {(isCreatingRound || isUploadingDocuments) && matchesUpMd && <Typography sx={{ marginRight: "1rem" }} display={"flex"} alignItems={'center'} gap={1} fontSize={"14px"} fontWeight={600} color={theme.palette.customColors.g200}>
          <SavingIcon width={24} height={24} className="saving-icon" />
          <span>Saving...</span>
        </Typography>}
        <SECONDARY
          sx={{
            background: theme?.palette?.customColors.indigo50,
            color: theme?.palette?.customColors?.indigo700,
            fontWeight: 600,
            fontSize: "14px",
            padding: "10px 16px",
            display: { xs: "none", md: 'inline-block' }
          }}
        >
          Need help?
        </SECONDARY>
        <IconButton sx={{ display: { xs: "inline-block", md: 'none' }, padding: "5px" }}><InfoIcon style={{ width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', gap: "4px", marginBlock: 'auto', stroke: '#444CE7' }} /></IconButton>
      </>
    );
  };


  // const fund_allocation_percentages_length =
  //   formik.values?.fund_allocation_percentages?.length;
  const fundAllocationValues = formik.values?.fund_allocation_percentages || [];

  // Calculate the total percentage
  const totalPercentage = fundAllocationValues.reduce(
    (total, item) => total + Number(item.percentage || 0),
    0
  );

  // Handle change for percentage input
  const handlePercentageChange = (index, value) => {
    const newPercentage = Number(value);

    // Calculate the new total if the current input is changed
    const newTotal =
      totalPercentage - Number(fundAllocationValues[index].percentage) + newPercentage;

    // Allow the change only if the new total does not exceed 100%
    if (newTotal <= 100) {
      formik.setFieldValue(`fund_allocation_percentages.${index}.percentage`, newPercentage);
    }
  };
  const key_metrics_length = formik.values?.key_metrics?.length;

  // const totalPercentage = formik.values?.fund_allocation_percentages && fund_allocation_percentages_length > 0  ? formik.values?.fund_allocation_percentages?.reduce((total, item) => {
  //   return total + Number(item?.percentage || 0);
  // }, 0) : 0;

  // console.log(currentIndex, "currentIndex")

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    formik.setFieldValue(`documents.${index}.document`, file);
  };

  // const fileExtensionSwitch = (key) => {
  //   console.log(key, "key");
  //   switch (key) {
  //     // case "pdf":
  //     // case "":

  //     default:
  //       return docxImage;
  //   }
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (current) => {
    formik.setFieldValue("documents", [
      ...formik.values?.documents,
      {
        title: current?.name,
        document: null,
        editable: false,
        type: "",
        // document_type: current?.value,
      },
    ]);
    // window.document
    //   .getElementById("scrollable-form-round")
    //   .scrollTo(0, window.document.getElementById("scrollable-form-round")?.firstChild?.scrollHeight);
    scrollToBottom();
  };

  React.useEffect(() => {
    if (!isLoadingCurrency) {
      formik.setFieldValue("currency_id", roundDetails?.currency_id || currencyList[0]?._id)
      // console.log(roundDetails?.currency_id, currencyList[0], "rcu")
      formik.setFieldValue("currency", currencyList?.find(item => roundDetails?.currency_id === item?._id)?.code || currencyList[0]?.code);
      // console.log(currencyList?.find(item => roundDetails?.currency_id === item?._id)?.code, currencyList[0]?.code, "rcu")
    }
  }, [isLoadingCurrency, isLoadingRoundDetails])

  React.useEffect(() => {
    if (!isLoadingDocumentTypes && !isLoadingRoundDetails) {
      formik.setFieldValue("documents.0.document_type", document_types ? document_types[0]?.value : null)
    }
  }, [isLoadingDocumentTypes, isLoadingRoundDetails])

  const MenuItems = [
    // ...document_types
    // { name: "The Summary Report", value: "the_summary_report" },
    // { name: "Trend Report", value: "trend_report" },
    // { name: "Monthly MIS Report", value: "monthly_mis_report" },
    // { name: "Exception Reports", value: "exception_reports" },
    // { name: "Financial MIS Report", value: "financial_mis_report" },
    // { name: "Inventory MIS Report", value: "inventory_mis_report" },
    // { name: "Sales MIS Report", value: "sales_mis_report" },
    // { name: "Budget MIS Report", value: "budget_mis_report" },
    // { name: "Fund Flow Statement Report", value: "fund_flow_statement_report" },
    // {
    //   name: "Actual Vs Budget MIS Report",
    //   value: "actual_vs_budget_mis_report",
    // },
    // { name: "Others", value: "others" },
  ];

  const [openFundModal, setOpenFundModal] = React.useState(false);

  React.useEffect(() => {
    if (uploadDocumentsStatus == "success") {


      window.document
        .getElementById("scrollable-form")
        .scrollTo(0, 0);

      // if (currentIndex == 7) {
      //   toast.success("Round Details Saved!", 3000)
      //   setTimeout(() => {
      //     navigate("/rounds");
      //   }, 3000)
      // }
      // else {
      setCurrentIndex(currentIndex + 1);
      navigate(`/backrr_startups/edit-round?page=${currentIndex + 1}` , {replace: true});
      refetchRoundDetails()
      // setSearchParams({ page: currentIndex + 1 });
      // }
    }
    else if (uploadDocumentsStatus == "error") {
      toast.error(<CustomToast message={uploadDocumentsError?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />, 3000)
      // console.log("error", uploadDocumentsError, uploadDocumentsResponse,uploadDocumentsStatus)
    }
  }, [uploadDocumentsStatus]);

  React.useEffect(() => {
    if (createRoundResponse && createRoundResponse?.data?.statusCode == 200) {
      window.document
        .getElementById("scrollable-form")
        .scrollTo(0, 0);

      if (currentIndex === round_steps?.length - 1) {
        // toast.success(
        //   <CustomToast message={"Round Details Saved!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />
        //   , 3000)
        setTimeout(() => {
          navigate(-1);
        }, 1000)
      }
      else {
        // console.log(createRoundResponse?.data?.data?._id,"createRoundResponse?.data?.data?._id")
        Cookies.set("round_id", createRoundResponse?.data?.data?._id)
        setCurrentIndex(currentIndex + 1);
        createRoundResponse?.data?.data?._id && navigate(`/backrr_startups/edit-round?page=${currentIndex + 1}`, {replace: true});
        refetchRoundDetails()
        // setSearchParams({ page: currentIndex + 1 });
        // currentIndex == formik.values?.currentStep && formik.setFieldValue("currentStep", currentIndex + 1);
      }
    }
    else if (createRoundResponse && createRoundResponse?.data?.statusCode != 200) {
      // toast.error(
      //   <CustomToast message={createRoundError?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
      //   {
      //     duration: 2000,
      //     position: "top-right",
      //   }
      // );
      // toast.error("Internal Server Error")
      // console.log("error", createRoundError)
    }
  }, [createRoundResponse?.data?.statusCode]);

  const isLoading = isLoadingCurrency || (isLoadingRoundDetails && isEditMode) || isLoadingRoundStatuses || isLoadingRoundTypes || isLoadingRoundsSteps || isLoadingSecurityTypes || isLoadingDocumentTypes;

  // if (isLoading) {
  //   return <Modal open={isLoading == true}>
  //     <ThemeBGWrapper style={{
  //       display: 'grid', height: '100vh',
  //       width: "100vw",
  //       placeItems: 'center'
  //     }}>
  //       <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} />
  //     </ThemeBGWrapper>
  //   </Modal>
  // }

  const handleChangeValue = (newValue) => {
    navigate(`/backrr_startups/edit-round?page=${newValue}`, {replace: true});
  }

  return (
    <ThemeBGWrapper>
      <Modal
        open={openFundModal}
        onClose={() => {
          setTimeout(() => setOpenFundModal(false), 200);
        }}
      >
        <Paper
          sx={{
            background: theme?.palette?.customColors?.whiteBG,
            position: "fixed",
            top: 0,
            right: 0,
            height: "100%",
            borderRadius: "24px 0 0 24px",
            padding: "1rem 2rem",
          }}
        >
          <Typography
            onClick={() => {
              setTimeout(() => setOpenFundModal(false), 200);
            }}
            sx={{
              color: theme?.palette?.customColors?.b300,
              fontWeight: 700,
              paddingRight: 1,
              ":focus": { opacity: "0.8" },
              cursor: "pointer",
              position: "absolute",
              top: 20,
              right: 5,
              textTransform: "none",
            }}
            variant="button"
          >
            Back
          </Typography>
          <Box marginBottom={"0.5rem"}>
            <Typography
              variant="h6"
              // fontSize="1rem"
              color={theme.palette.customColors.g300}
              fontWeight={700}
              textAlign={"start"}
            >
              Key Metrics in Graphs
            </Typography>
            <Typography
              variant="subtitle2"
              color={theme.palette.customColors.g200}
              fontSize="0.875rem"
            >
              Detailed key metrics about fund utilization.
            </Typography>
          </Box>
          <Divider />
          <PieChart graphData={formik.values?.fund_allocation_percentages?.filter(item => item?.name != "" && item?.percentage != "" && item?.percentage != 0)} />
        </Paper>
      </Modal>
      <BreadcrumbHeader
        title={isLoadingRoundDetails ? "Loading..." : `Edit (${matchesUpMd ? roundDetails?.round_name : 'Round'})`}
        Actions={Actions}
      />
      {isLoading ?
        <Grid container xs={12} display={"flex"} height={"100%"} maxHeight={"85vh"} sx={{ padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" }, width: '100%' }}>
          <Grid item xs={12} className="skeleton" height={"100%"} width={"100%"} borderRadius={"24px"}>

          </Grid>
        </Grid> :
        <>
          <Grid
            container
            xs={12}
            sx={{
              // height: "calc(100vh - 118px)",
              height: '100%',
              maxHeight: { xs: "90%", sm: "93%", md: '85vh' },
              overflow: "hidden",
              padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" },
            }}
          >
            <Grid
              xs={12}
              item
              container
              sx={{
                backgroundColor: "#FFFAF5",
                borderRadius: { xs: "24px", sm: "24px" },
                height: "100%",
              }}
            >
              <Grid
                item
                sx={{
                  position: "relative",
                  backgroundColor: "#ADA2ED",
                  borderRadius: `24px ${currentIndex === 0 ? "0" : "24px"} 24px 24px`,
                  overflow: "hidden",
                  height: "100%",
                  width: { xs: '0%', md: '40%', lg: '40%', xl: '37.9%' },
                  // display: {xs : 'none', md: 'block'}
                }}
              // xs={12}
              // // sm={5}
              // md={6}
              // lg={4.6}
              >
                <PerfectScrollbar
                  containerRef={containerRef}
                  style={{
                    minHeight: "85vh",
                    height: "100%",
                    width: "100%",
                    maxHeight: "calc(100vh - 285px)",
                    paddingRight: "1rem",
                    marginTop: 0,
                    paddingBottom: "1rem",
                  }}
                  className="scrollbar-container"
                >
                  <TabContext value={currentIndex}>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={currentIndex}
                      onChange={(event, newValue) => {
                        // (roundID != null && newValue !== currentIndex) || isEditMode
                        // ? 
                        setCurrentIndex(newValue);
                        handleChangeValue(newValue);
                        // setSearchParams({ page: newValue });
                        // : toast.error(
                        //   "Kindly provide the necessary details for the current step in order to proceed to the next one.",
                        //   {
                        //     icon: (
                        //       <WarningOutlined sx={{ fill: "orange !important" }} />
                        //     ),
                        //     duration: 5000,
                        //     position: "top-right",
                        //   }
                        // );
                      }}
                      sx={{
                        overflow: 'visible',
                        "& .MuiTabs-flexContainer": {
                          padding: "1.5rem 1rem",
                          paddingRight: 0,
                        },
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                        "& .MuiTab-root": {
                          position: "relative",
                          // display: "flex",
                          padding: "0.3rem 1rem",
                          paddingRight: 0,
                          minHeight: 70,
                          maxHeight: 100,
                          alignItems: "center",
                          width: "100%",
                          // maxWidth: 500,
                          maxWidth: '100%',
                          gap: "0.5rem",
                          justifyContent: "flex-start",
                          textAlign: "start",
                          borderRadius: "128px 0 0 128px",
                          overflow: 'visible',
                          paddingRight: '1rem',
                          display: 'flex', flexDirection: 'row'
                        },
                        "& .MuiTab-root.Mui-selected": {
                          backgroundColor: "#FFFAF5",
                          zIndex: 3,
                        },
                        width: "100%",
                        paddingBottom: '2rem'
                      }}
                    >
                      {roundSteps &&
                        roundSteps?.length > 0 &&
                        roundSteps
                          ?.map((currentEl, index) => {
                            let Icon = round_steps[index]?.image_url;
                            return (
                              <Tab
                                key={index}
                                disabled={window.location.pathname?.includes("create_round")}
                                disableRipple
                                icon={<Box
                                  sx={{
                                    background:
                                      index === currentIndex ? "#FEF0C7" : "transparent",
                                    borderRadius: "64px",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                >
                                  <Icon
                                    style={{
                                      fill:
                                        index === currentIndex
                                          ? "#F79009"
                                          : theme.palette.customColors.g300,
                                      width: "20px",
                                      height: "20px",
                                      padding: "10px",
                                    }}
                                  />
                                </Box>}
                                label={
                                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>

                                    <Box>
                                      <Typography
                                        variant="h6"
                                        fontSize="1rem"
                                        color={theme.palette.customColors.g300}
                                        fontWeight={700}
                                        textAlign={"start"}
                                        display="flex"
                                        alignItems="center"
                                        gap='4px'
                                      >
                                        {currentEl?.name}
                                        {/* {index === 3 && (
                                          <>
                                            <CustomInfoIcon defaultTitle="FUND UTILIZATION" position="bottom-center" />
                                          </>
                                        )
                                        } */}
                                        {/* {index === 4 &&(
                               <>
                               <CustomInfoIcon defaultTitle="Current Traction & Runway" />
                             </>
                              )
                              } */}

                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        color={theme.palette.customColors.g200}
                                        fontSize="0.875rem"
                                        // noWrap
                                        // wra
                                        textTransform={"none"}
                                      >
                                        {currentEl?.sub_title}
                                      </Typography>
                                    </Box>
                                    {index === currentIndex && (
                                      <>
                                        {/* {currentIndex != 0 && */}
                                        <Box
                                          sx={{
                                            position: "absolute",
                                            top: "-1.87rem",
                                            right: 0,
                                            width: "30px",
                                            height: "30px",
                                            overflow: "hidden",
                                            background: `url(${TopIcon})`,
                                            backgroundSize: "cover",
                                            backgroundBlendMode: "multiply",
                                          }}
                                        />
                                        {/* } */}
                                        {/* {currentIndex != round_steps?.length - 1 &&  */}
                                        <Box
                                          sx={{
                                            position: "absolute",
                                            bottom: "-1.87rem",
                                            right: 0,
                                            width: "30px",
                                            height: "30px",
                                            overflow: "hidden",
                                            background: `url(${BottomIcon})`,
                                            backgroundSize: "cover",
                                            backgroundBlendMode: "multiply",
                                          }}
                                        />
                                        {/* } */}
                                      </>
                                    )}
                                  </Box>
                                }
                                sx={{
                                  zIndex: index === 3 ? 3 : 2,
                                  display: 'grid',
                                  // paddingRight: 0
                                  // "&:hover": {
                                  //   backgroundColor: "#FFFAF5",
                                  // },
                                }}
                              />
                            )
                          })}
                    </Tabs>
                  </TabContext>
                </PerfectScrollbar>
                <img
                  src={RoundStepsBackground}
                  style={{
                    width: "100%",
                    height: "240px",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                  }}
                />
              </Grid>

              <Grid
                item
                container
                // xs={12}
                // // sm={5}
                // md={6}
                // lg={7.4}
                sx={{ width: { xs: "100%", md: '60%', lg: '60%', xl: '62.1%' }, padding: { xs: '1rem', md: '2rem', lg: "2.5rem" } }}
                gap={3}
                marginTop={0}
                display={"flex"}
                alignItems={"flex-start"}
                alignContent={"space-around"}
                justifyContent={"space-between"}
                // padding={"2.5rem"}
                paddingBottom={0}
              >
                <FormikProvider value={formik}>
                  <Form
                    onSubmit={(event) => event.preventDefault()}
                    style={{ width: "100%", height: "100%", maxHeight: matchUpXl ? "calc(100vh - 310px)" : matchUpLg ? "calc(100vh - 290px)" : matchesUpMd ? "calc(100vh - 285px)" : "calc(-220px + 100vh)", overflow: "hidden" }}
                  >
                    {!matchesUpMd &&
                      <Box width={"100%"}>
                        {roundSteps &&
                          roundSteps?.length > 0 ?
                          <>
                            <Typography marginInline={"auto"} flexWrap={"wrap"} variant="h6"
                              fontSize="1rem"
                              color={theme.palette.customColors.g300}
                              fontWeight={700} textAlign={"center"} marginBottom={1}>
                              <span>{roundSteps[currentIndex]?.name} ({`${currentIndex + 1}/${roundSteps?.length}`})</span>
                              <Divider sx={{ marginTop: 1, marginBottom: 2, marginInline: 'auto', maxWidth: '50%' }} />
                            </Typography>
                          </>
                          : ''}
                      </Box>
                    }
                    {currentIndex == 5 && (
                      <Grid
                        item
                        container
                        xs={12}
                        // pl={"1rem"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        rowGap={1}
                      >
                        <CustomLabel sx={{ marginBottom: 0 }}>UPLOAD DOCUMENTS</CustomLabel>
                        <CustomMenu
                          anchorEl={anchorEl}
                          handleClick={handleClick}
                          handleClose={handleClose}
                          setAnchorEl={setAnchorEl}
                          items={[...document_types]}
                        >
                          <WHITEBG
                            sx={{
                              border: `1px solid ${theme.palette.customColors.g100}`,
                              borderRadius: "8px",
                            }}
                            endIcon={<KeyboardArrowDownRounded />}
                            onClick={handleOpenMenu}
                          >
                            Add Documents
                          </WHITEBG>
                        </CustomMenu>
                        {/* <ActionButton /> */}
                      </Grid>
                    )}
                    <PerfectScrollbar
                      ref={bottomRef}
                      containerRef={containerRef}
                      style={{
                        minHeight: currentIndex == 5 ? "56vh" : "60vh",
                        height: "100%",
                        width: "98%",
                        maxHeight: currentIndex == 5 ? "calc(-330px + 100vh)" : "calc(100vh - 285px)",
                        paddingRight: "1rem",
                        marginTop: 0,
                        paddingBottom: "1rem",
                        // paddingLeft: "1rem",
                        overflow: 'hidden'
                      }}
                      className="scrollbar-container"
                      id="scrollable-form"
                    >
                      {/* {console.log(formik.values?.round_highlights?.replace(/<[^>]*>/g, ""),"round")} */}
                      <Grid
                        item
                        container
                        xs={12}
                        paddingBottom={{ xs: 1, md: currentIndex == 0 || currentIndex == 7 ? 2 : 0 }}
                        // sx={{ width: "100%" }}
                        justifyContent={"space-between"}
                        columnSpacing={"0.5rem"}
                      >
                        {currentIndex == 0 && (
                          <>
                            <Grid item xs={12} sm={12} height={"min-content"}>
                              <CustomInputField
                                name={`round_name`}
                                label={"ROUND NAME"}
                                placeholder={"Enter round name"}
                                value={formik.values?.round_name}
                                onChange={(e) =>
                                  (e.target.value?.length <= 30) && formik.setFieldValue(
                                    `round_name`,
                                    e.target.value?.charAt(0)?.toUpperCase() +
                                    e.target.value?.slice(1)
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} height={"min-content"}>
                              <CustomLabel>SELECT ROUND TYPE
                              </CustomLabel>
                              <Grid
                                display={"flex"}
                                justifyContent={"space-between"}
                                // flexWrap={"wrap"}
                                item
                                container
                                xs={12}
                                gap={"1rem"}
                              >
                                {round_types?.map((type, index) => {
                                  return (
                                    <Grid
                                      role={"button"}
                                      item
                                      sx={{ height: { xs: "7rem", sm: "7rem", md: "7rem", lg: "7rem", xl: "6.5rem" } }}
                                      xs={5.5}
                                      sm={3.7}
                                      md={3.6}
                                      xl={2.5}
                                    >
                                      <button
                                        type="button"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            "round_type",
                                            type?.value
                                          );
                                        }}
                                        style={{
                                          background:
                                            formik.values?.round_type == type?.value
                                              ? theme?.palette?.customColors?.y50
                                              : theme?.palette?.customColors
                                                ?.whiteBG,
                                          padding: "12px",
                                          paddingRight: 0,
                                          minHeight: 100,
                                          height: "100%",
                                          borderRadius: "8px",
                                          border: `1px solid ${formik.values?.round_type == type?.value
                                            ? theme?.palette?.customColors?.y300
                                            : theme?.palette?.customColors?.g75
                                            }`,
                                          boxShadow: "0px 1px 2px 0px #1018280D",
                                          cursor: "pointer",
                                          fontWeight: "0.875rem",
                                          color: theme.palette.customColors.g200,
                                          width: "100%",
                                          display: "flex",
                                          position: 'relative',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        <span style={{ fontSize: '0.875rem', position: 'absolute', left: 5, top: 5 }}>{type?.name}</span>
                                        <img src={type?.image_url}
                                          //, width: '100%', height: "100%", left: 0, bottom: 0 position: 'absolute
                                          style={{ backgroundBlendMode: 'multiply', ...type?.size, marginLeft: 'auto' }} />
                                      </button>
                                    </Grid>
                                  );
                                })}
                                <Grid
                                  // role={"button"}
                                  item
                                  sx={{ height: { xs: "7rem", sm: "7rem", md: "7rem", lg: "7rem", xl: "6.5rem" } }}
                                  xs={5.5}
                                  sm={3.7}
                                  md={3.6}
                                  xl={2.5}
                                >

                                </Grid>
                              </Grid>
                              {formik.errors?.round_type && formik.touched?.round_type && (
                                <CustomValidationText value={formik.errors?.round_type} />
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              height={"min-content"}
                              marginTop={2}
                              paddingBottom={1}
                            >
                              <CustomLabel>SELECT SECURITY TYPE

                              </CustomLabel>
                              <Grid
                                display={"flex"}
                                justifyContent={"space-between"}
                                flexWrap={"wrap"}
                                item
                                container
                                xs={12}
                                gap={"1rem"}
                              >
                                {security_types?.map((security, index) => {
                                  return (
                                    <Grid
                                      // role={"button"}
                                      item
                                      xs={5.5}
                                      sm={3.7}
                                      md={3.6}
                                      lg={3.7}
                                    >
                                      <button
                                        type="button"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            "security_type",
                                            security?.value
                                          );
                                        }}
                                        style={{
                                          background:
                                            formik.values?.security_type ==
                                              security?.value
                                              ? theme?.palette?.customColors?.y50
                                              : theme?.palette?.customColors
                                                ?.whiteBG,
                                          padding: "12px 16px",
                                          height: 55,
                                          borderRadius: "8px",
                                          display: "grid",
                                          placeItems: "center",
                                          border: `1px solid ${formik.values?.security_type ==
                                            security?.value
                                            ? theme?.palette?.customColors?.y300
                                            : theme?.palette?.customColors?.g75
                                            }`,
                                          boxShadow: "0px 1px 2px 0px #1018280D",
                                          cursor: "pointer",
                                          fontSize: "0.875rem",
                                          color: theme.palette.customColors.g200,
                                          width: "100%",
                                        }}
                                      >
                                        {security?.name}
                                      </button>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              {formik.errors.security_type && formik.touched.security_type && (
                                <CustomValidationText value={formik.errors.security_type} />
                              )}
                            </Grid>
                          </>
                        )}
                        {currentIndex == 1 && (
                          <>
                            <Grid item xs={12} sm={12} height={"min-content"}>
                              <CustomRichTextEditor
                                name="round_highlights"
                                label={
                                  <Box display="flex" alignItems="center" gap="4px">
                                    ROUND HIGHLIGHTS
                                    {/* <CustomInfoIcon defaultTitle="ROUND HIGHLIGHTS " /> */}
                                  </Box>
                                }
                                placeholder={"Enter funding round highlights"}
                                value={formik.values?.round_highlights}
                                handleChange={(newValue) =>
                                  formik.setFieldValue("round_highlights", newValue)
                                }
                              />
                              {formik.errors.round_highlights && formik.touched.round_highlights && (
                                <CustomValidationText value={formik.errors.round_highlights} />
                              )}
                            </Grid>
                            {/* <Grid item xs={12} sm={12} height={"min-content"}>
                          <CustomInputField
                            minRows={5.1}
                            multiline
                            name={`round_highlights`}
                            label={<>ROUND HIGHLIGHTS </>}
                            placeholder={"Enter round highlights"}
                            value={formik.values?.round_highlights}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `round_highlights`,
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid> */}
                            {!isLoadingCurrency && currencyList && <>
                              <Grid item xs={12} lg={4} mt={2} height={"min-content"}>
                                <CustomInputField
                                  // inputProps={{ ...currencyInputProps }}
                                  InputProps={{
                                    ...currencyInputProps,
                                    startAdornment: (
                                      <Box sx={{ padding: "0 0 0 1rem" }}>
                                        {formik.values.currency}
                                      </Box>
                                    ),
                                    endAdornment: (
                                      !isLoadingCurrency && currencyList && <Select
                                        className="selecttt"
                                        sx={{
                                          "::before": { border: "none !important" },
                                          border: "none !important",
                                        }}
                                        value={formik.values?.currency_id}
                                        defaultValue={roundDetails?.currency_id}
                                        onChange={(e) => {
                                          // console.log(currencyList?.find(item => item?._id === e.target.value),"edefe")
                                          formik.setFieldValue(
                                            "currency",
                                            currencyList?.find(item => item?._id === e.target.value)?.code
                                          );
                                          formik.setFieldValue(
                                            "currency_id",
                                            e.target.value
                                          )
                                        }
                                        }
                                      >
                                        {/* <MenuItem value={"$"}>USD</MenuItem>
                                  {<MenuItem value={"₹"}>INR</MenuItem>} */}
                                        {currencyList?.map((item) => (
                                          <MenuItem type="button" value={item?._id}>
                                            {item?.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    ),
                                  }}
                                  name={`total_round_size`}
                                  label={"TOTAL ROUND SIZE"}
                                  placeholder={"Enter Total round size"}
                                  value={formik.values?.total_round_size}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `total_round_size`,
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} mt={{ xs: 0, lg: 2 }} height={"min-content"}>
                                <CustomInputField
                                  InputProps={{
                                    ...currencyInputProps,
                                    startAdornment: (
                                      <Box sx={{ padding: "0 0 0 1rem" }}>
                                        {formik.values.currency}
                                      </Box>
                                    ),
                                  }}
                                  name={`round_valuation`}
                                  label={
                                    <Box display="flex" alignItems="center" gap="4px">
                                      ROUND VALUATION FLOOR
                                      {/* <CustomInfoIcon defaultTitle="ROUND VALUATION" /> */}
                                    </Box>
                                  }
                                  placeholder={"Enter round valuation floor"}
                                  value={formik.values?.round_valuation}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `round_valuation`,
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} mt={{ xs: 0, lg: 2 }} height={"min-content"}>
                                <CustomInputField
                                  InputProps={{
                                    ...currencyInputProps,
                                    startAdornment: (
                                      <Box sx={{ padding: "0 0 0 1rem" }}>
                                        {formik.values.currency}
                                      </Box>
                                    ),
                                  }}
                                  name={`round_valuation_cap`}
                                  label={
                                    <Box display="flex" alignItems="center" gap="4px">
                                      ROUND VALUATION CAP
                                      {/* <CustomInfoIcon defaultTitle="ROUND VALUATION" /> */}
                                    </Box>
                                  }
                                  placeholder={"Enter round valuation cap"}
                                  value={formik.values?.round_valuation_cap}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `round_valuation_cap`,
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} height={"min-content"}>
                                <CustomInputField
                                  InputProps={{
                                    ...currencyInputProps,
                                    startAdornment: (
                                      <Box sx={{ padding: "0 0 0 1rem" }}>
                                        {formik.values.currency}
                                      </Box>
                                    ),
                                  }}
                                  name={`minimum_cheque_size`}

                                  label={
                                    <Box display="flex" alignItems="center" gap="4px">
                                      MINIMUM CHEQUE SIZE
                                      {/* <CustomInfoIcon defaultTitle=" MINIMUM CHEQUE SIZE" /> */}
                                    </Box>
                                  }
                                  placeholder={"Enter minimum cheque size"}
                                  // placeholder={"10,000.00"}
                                  value={formik.values?.minimum_cheque_size}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `minimum_cheque_size`,
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} lg={8} height={"min-content"}>
                                <CustomInputField
                                  InputProps={{
                                    ...currencyInputProps,
                                    startAdornment: (
                                      <Box sx={{ padding: "0 0 0 1rem" }}>
                                        {formik.values.currency}
                                      </Box>
                                    ),
                                  }}
                                  name={`committed_funding_amount`}
                                  label={
                                    <Box display="flex" alignItems="center" gap="4px">
                                      What funding amount has been committed so far?
                                      {/* <CustomInfoIcon defaultTitle="Funding Amount" /> */}
                                    </Box>
                                  }
                                  placeholder={"20,000"}
                                  value={formik.values?.committed_funding_amount}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `committed_funding_amount`,
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} lg={12} height={"min-content"}>
                                <CustomLabel>
                                  HAVE YOU ALREADY RECEIVED ANY TERMSHEETS?
                                </CustomLabel>
                                <RadioGroup
                                  sx={{ flexDirection: "row", marginBottom: 1 }}
                                  name="received_termsheet"
                                  value={formik.values.received_termsheet}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `received_termsheet`,
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<CustomRadio />}
                                    label={"Yes"}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<CustomRadio />}
                                    label={"No"}
                                  />
                                </RadioGroup>
                                {/* {formik.errors.received_termsheet && formik.touched.received_termsheet && (
                                  <CustomValidationText value={formik.errors.received_termsheet} />
                                )} */}
                              </Grid>
                            </>}
                          </>
                        )}
                        {currentIndex == 2 && (
                          <FieldArray
                            name="investors"
                            render={(arrayHelpers) => (
                              <>
                                {formik.values?.investors &&
                                  formik.values?.investors?.length > 0 &&
                                  formik.values?.investors?.map(
                                    (investor, index) => {
                                      const filled =
                                        investor?.name !=
                                        "" ||
                                        investor?.email != "";
                                      return (
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            height={"min-content"}
                                          >
                                            <CustomInputField
                                              name={`investors.${index}.name`}
                                              label={<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                <span>INVESTOR NAME</span>
                                                {!filled ? (
                                                  index == index ? (
                                                    <span
                                                      onClick={() => {
                                                        arrayHelpers.remove(index);
                                                      }}
                                                      style={{
                                                        color: "red",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      Delete
                                                    </span>
                                                  ) : (
                                                    <span
                                                      onClick={() => { }}
                                                      style={{
                                                        color: "red",
                                                        height: 20.8,
                                                        width: 38.74,
                                                      }}
                                                    ></span>
                                                  )
                                                ) : (
                                                  <span
                                                    onClick={() => {
                                                      formik.setFieldValue(
                                                        `investors.${index}.name`,
                                                        ""
                                                      );
                                                      formik.setFieldValue(
                                                        `investors.${index}.email`,
                                                        ""
                                                      );
                                                    }}
                                                    style={{
                                                      color: "red",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    Clear
                                                  </span>
                                                )}
                                              </Box>}
                                              placeholder={"Enter investor name"}
                                              value={investor?.name}
                                              onChange={(e) =>
                                                formik.setFieldValue(
                                                  `investors.${index}.name`,
                                                  e.target.value
                                                )
                                              }
                                              fullWidth={true}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            height={"min-content"}
                                            sx={{
                                              position: "relative",
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <CustomInputField
                                              name={`investors.${index}.email`}
                                              label={
                                                <>
                                                  INVESTOR EMAIL{" "}
                                                  {/* <span
                                                    style={{
                                                      color:
                                                        theme?.palette?.customColors
                                                          ?.g100,
                                                    }}
                                                  >
                                                    (Optional)
                                                  </span>{" "} */}
                                                </>
                                              }
                                              placeholder={"Enter investor email"}
                                              value={investor?.email}
                                              onChange={(e) =>
                                                formik.setFieldValue(
                                                  `investors.${index}.email`,
                                                  e.target.value
                                                )
                                              }
                                              fullWidth={true}
                                            />
                                            {/* <span
                                          style={{
                                            color:
                                              theme?.palette?.customColors
                                                ?.g100,
                                            position: "absolute",
                                            bottom: 10,
                                          }}
                                        >
                                          This will not be visible to anyone
                                          else
                                        </span> */}
                                          </Grid>
                                          {index ==
                                            formik.values.investors?.length - 1 && (
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                height={"min-content"}
                                              >
                                                <WHITEBG
                                                  startIcon={<AddIcon />}
                                                  sx={{
                                                    borderRadius: "8px",
                                                    borderWidth: "1px",
                                                    padding: "10px 20px 10px 20px",
                                                    marginBottom: 2,
                                                    borderColor:
                                                      theme?.palette?.customColors
                                                        ?.g100,
                                                    color:
                                                      theme?.palette?.customColors
                                                        ?.g300,
                                                    fontWeight: 700,
                                                  }}
                                                  onClick={() => {
                                                    arrayHelpers.insert(index + 1, {
                                                      name: "",
                                                      email: "",
                                                    });
                                                    scrollToBottom()
                                                  }
                                                  }
                                                >
                                                  Add investor
                                                </WHITEBG>
                                              </Grid>
                                            )}
                                        </>
                                      );
                                    }
                                  )}
                                {formik.values.investors?.length == 0 && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    height={"min-content"}
                                  >
                                    <WHITEBG
                                      startIcon={<AddIcon />}
                                      sx={{
                                        borderRadius: "8px",
                                        borderWidth: "1px",
                                        padding: "10px 20px 10px 20px",
                                        marginBottom: 2,
                                        borderColor:
                                          theme?.palette?.customColors
                                            ?.g100,
                                        color:
                                          theme?.palette?.customColors
                                            ?.g300,
                                        fontWeight: 700,
                                      }}
                                      onClick={() => {
                                        arrayHelpers.insert(0, {
                                          name: "",
                                          email: "",
                                        });
                                        scrollToBottom()
                                      }
                                      }
                                    >
                                      Add investor
                                    </WHITEBG>
                                  </Grid>
                                )}
                              </>
                            )}
                          />

                        )}
                        {currentIndex == 3 && (
                          <>
                            <Grid item xs={12} sm={12} height={"min-content"}>
                              <CustomRichTextEditor
                                name="fund_utilization_highlights"
                                label={
                                  <Box display="flex" alignItems="center" gap='4px'>
                                    FUND UTILIZATION HIGHLIGHTS{" "}

                                    {/* <CustomInfoIcon defaultTitle="FUND UTILIZATION HIGHLIGHTS" /> */}
                                  </Box>

                                }
                                placeholder={"Enter fund utilization highlights"}
                                value={formik.values?.fund_utilization_highlights}
                                handleChange={(newValue) =>
                                  formik.setFieldValue(
                                    "fund_utilization_highlights",
                                    newValue
                                  )
                                }
                              />
                              {formik.errors.fund_utilization_highlights && formik.touched.fund_utilization_highlights && (
                                <CustomValidationText value={formik.errors.fund_utilization_highlights} />
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: 1,
                                  color: "#0f1319",
                                  marginBottom: "8px",
                                  fontSize: "0.875rem",
                                  marginTop: '1rem',
                                  flexWrap: 'wrap'
                                }}
                              >
                                <CustomLabel
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box width={"fit-content"}>
                                    FUND UTILIZATION METRICS ( IN PERCENTAGE )
                                  </Box>
                                </CustomLabel>
                                <Typography
                                  onClick={() =>
                                    setTimeout(() => {
                                      setOpenFundModal(true);
                                    }, 200)
                                  }
                                  sx={{
                                    color: theme?.palette?.customColors?.b300,
                                    fontWeight: 700,
                                    paddingRight: 1,
                                    ":focus": { opacity: "0.8" },
                                    cursor: "pointer",
                                    textTransform: "none",
                                  }}
                                  variant="button"
                                >
                                  View in Graph
                                </Typography>
                              </Box>
                            </Grid>
                            <FieldArray
                              name="fund_allocation_percentages"
                              render={(arrayHelpers) => (
                                <>
                                  {fundAllocationValues.length > 0 &&
                                    fundAllocationValues.map((item, index) => (
                                      <React.Fragment key={index}>
                                        <Grid item xs={12} sm={6} height={"min-content"}>
                                          <CustomInputField
                                            sx={{ borderRadius: '4px' }}
                                            placeholder={'e.g. Tech & Product'}
                                            name={`fund_allocation_percentages.${index}.name`}
                                            value={item.name}
                                            onChange={(e) =>
                                              formik.setFieldValue(`fund_allocation_percentages.${index}.name`, e.target.value)
                                            }
                                            fullWidth={true}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          container
                                          xs={12}
                                          sm={6}
                                          display={"flex"}
                                          flexWrap={"nowrap"}
                                          height={"min-content"}
                                          columnGap={"0.5rem"}
                                        >
                                          <Grid item xs={12} height={"min-content"}>
                                            <CustomInputField
                                              sx={{ borderRadius: '4px' }}
                                              InputProps={{ ...currencyInputProps }}
                                              name={`fund_allocation_percentages.${index}.percentage`}
                                              value={item.percentage}
                                              onChange={(e) =>
                                                handlePercentageChange(index, e.target.value)
                                              }
                                              fullWidth
                                            />
                                          </Grid>
                                          {index !== 0 && (
                                            <Grid item height={"min-content"}>
                                              <BLUEBORDER
                                                sx={{
                                                  padding: '14px 15px',
                                                  background: 'white',
                                                  border: `1px solid ${theme?.palette?.customColors?.g75}`,
                                                  borderRadius: '4px',
                                                  maxHeight: "3rem"
                                                }}
                                                onClick={() => arrayHelpers.remove(index)}
                                              >
                                                <DeleteIcon />
                                              </BLUEBORDER>
                                            </Grid>
                                          )}
                                        </Grid>
                                        {fundAllocationValues.length - 1 === index && totalPercentage < 100 && (
                                          <Grid item xs={12} sm={12} height={"min-content"}>
                                            <WHITEBG
                                              startIcon={<AddIcon />}
                                              sx={{
                                                borderRadius: '8px',
                                                borderWidth: '1px',
                                                padding: '10px 20px 10px 20px',
                                                marginBottom: 2,
                                                borderColor: theme?.palette?.customColors?.g100,
                                                color: theme?.palette?.customColors?.g300,
                                                fontWeight: 700,
                                              }}
                                              onClick={() => {
                                                arrayHelpers.insert(index + 1, {
                                                  name: '',
                                                  percentage: 0,
                                                });
                                                scrollToBottom()
                                              }
                                              }
                                            >
                                              Add more
                                            </WHITEBG>
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    ))}
                                  {fundAllocationValues.length === 0 && (
                                    <Grid item xs={12} sm={12} height={"min-content"}>
                                      <WHITEBG
                                        startIcon={<AddIcon />}
                                        sx={{
                                          borderRadius: '8px',
                                          borderWidth: '1px',
                                          padding: '10px 20px 10px 20px',
                                          marginBottom: 2,
                                          borderColor: theme?.palette?.customColors?.g100,
                                          color: theme?.palette?.customColors?.g300,
                                          fontWeight: 700,
                                        }}
                                        onClick={() => {
                                          arrayHelpers.insert(0, {
                                            name: '',
                                            percentage: 0,
                                          });
                                          scrollToBottom()
                                        }
                                        }
                                      >
                                        Add more
                                      </WHITEBG>
                                    </Grid>
                                  )}
                                </>
                              )}
                            />

                          </>
                        )}
                        {currentIndex == 4 && (
                          <>
                            <Grid item xs={12} sm={12} mb={2} height={"min-content"}>
                              <CustomRichTextEditor
                                name="traction_highlights"
                                label={
                                  <Box display="flex" alignItems="center" gap='4px'>
                                    TRACTION HIGHLIGHTS
                                    {/* <CustomInfoIcon defaultTitle="TRACTION HIGHLIGHTS " /> */}
                                  </Box>
                                }
                                placeholder={"Enter traction highlights"}
                                value={formik.values?.traction_highlights}
                                handleChange={(newValue) =>
                                  formik.setFieldValue(
                                    "traction_highlights",
                                    newValue
                                  )
                                }
                              />
                              {formik.errors.traction_highlights && formik.touched.traction_highlights && (
                                <CustomValidationText value={formik.errors.traction_highlights} />
                              )}
                            </Grid>
                            <CustomLabel sx={{ marginLeft: '0.5rem' }}>KEY METRICS</CustomLabel>
                            <FieldArray
                              name="key_metrics"
                              render={(arrayHelpers) => (
                                <Grid item xs={12} container rowGap={2}>
                                  {formik.values?.key_metrics &&
                                    formik.values?.key_metrics?.length > 0 &&
                                    formik.values?.key_metrics?.map(
                                      (item, index) => {
                                        return (
                                          <Grid
                                            xs={12}
                                            item
                                            container
                                            sx={{
                                              background:
                                                theme.palette.customColors.whiteBG,
                                              position: 'relative'
                                            }}
                                            border={`1px solid ${theme.palette.customColors.g75}`}
                                            padding={"8px"}
                                            borderRadius={"8px"}
                                            gap={1}
                                          >
                                            {formik.values?.key_metrics?.length > 1 && <img style={{ position: 'absolute', top: "-0.5rem", right: "-0.5rem", height: "1.2rem", width: "1.2rem", cursor: 'pointer' }} src={ClearIcon} onClick={() => {
                                              arrayHelpers?.remove(index)
                                            }} />}
                                            <Grid
                                              item
                                              container
                                              xs={12}
                                              sm={12}
                                              columnGap={1}
                                              height={"min-content"}
                                              position={"relative"}
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                              >
                                                <CustomInputField
                                                  sx={{
                                                    borderRadius: "4px",
                                                    marginBottom: 0,
                                                  }}
                                                  placeholder={"Enter heading"}
                                                  name={`key_metrics.${index}.heading`}
                                                  value={item?.heading}
                                                  onChange={(e) =>
                                                    formik.setFieldValue(
                                                      `key_metrics.${index}.heading`,
                                                      e.target.value
                                                        ?.charAt(0)
                                                        ?.toUpperCase() +
                                                      e.target.value?.slice(1)
                                                    )
                                                  }
                                                  fullWidth={true}
                                                />
                                              </Grid>
                                            </Grid>
                                            <FieldArray
                                              name={`key_metrics.${index}.properties`}
                                              render={(helpers) => (
                                                <>
                                                  <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    sm={12}
                                                    height={"min-content"}
                                                    gap={"0.5rem"}
                                                  >
                                                    <Grid
                                                      item
                                                      container
                                                      xs={index !== 0 ? 12 : 12}
                                                      height={"min-content"}
                                                      gap={"0.5rem"}
                                                    >
                                                      {formik.values?.key_metrics[
                                                        index
                                                      ]?.properties?.map(
                                                        (currentProperty, idx) => {
                                                          return (
                                                            <>
                                                              <Grid
                                                                item
                                                                container
                                                                xs={12}
                                                                display={"flex"}
                                                                spacing={"0.5rem"}
                                                                height={
                                                                  "min-content"
                                                                }
                                                              >
                                                                <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={6}
                                                                  lg={6}
                                                                  height={
                                                                    "min-content"
                                                                  }
                                                                >
                                                                  <CustomInputField
                                                                    sx={{
                                                                      borderRadius:
                                                                        "4px",
                                                                      marginBottom: 0,
                                                                    }}
                                                                    name={`key_metrics.${index}.properties.${idx}.key`}
                                                                    value={
                                                                      currentProperty?.key
                                                                    }
                                                                    placeholder={
                                                                      "Enter key"
                                                                    }
                                                                    onChange={(e) =>
                                                                      formik.setFieldValue(
                                                                        `key_metrics.${index}.properties.${idx}.key`,
                                                                        e.target.value
                                                                          ?.charAt(
                                                                            0
                                                                          )
                                                                          ?.toUpperCase() +
                                                                        e.target.value?.slice(
                                                                          1
                                                                        )
                                                                      )
                                                                    }
                                                                    fullWidth={true}
                                                                  />
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  container
                                                                  xs={12}
                                                                  sm={6}
                                                                  lg={6}
                                                                  display={"flex"}
                                                                  flexWrap={"nowrap"}
                                                                  height={
                                                                    "min-content"
                                                                  }
                                                                >
                                                                  <CustomInputField
                                                                    sx={{
                                                                      borderRadius:
                                                                        "4px",
                                                                      marginBottom: 0,
                                                                      width: idx != 0 ? '97%' : "100%"
                                                                    }}
                                                                    name={`key_metrics.${index}.properties.${idx}.value`}
                                                                    value={
                                                                      currentProperty?.value
                                                                    }
                                                                    placeholder={
                                                                      "Enter value"
                                                                    }
                                                                    onChange={(e) =>
                                                                      formik.setFieldValue(
                                                                        `key_metrics.${index}.properties.${idx}.value`,
                                                                        e.target.value
                                                                          ?.charAt(
                                                                            0
                                                                          )
                                                                          ?.toUpperCase() +
                                                                        e.target.value?.slice(
                                                                          1
                                                                        )
                                                                      )
                                                                    }
                                                                    fullWidth={true}
                                                                  />
                                                                  {/* <Grid
                                                                  item
                                                                  // xs={1}
                                                                  height={
                                                                    "min-content"
                                                                  }
                                                                > */}
                                                                  {/* <Button sx={{ padding: '14px 15px', background: "white", marginBlock: "8px 16px", border: `1px solid ${theme?.palette?.customColors?.g75}` }}><DeleteIcon /></Button> */}
                                                                  {idx !== 0 && (
                                                                    <BLUEBORDER
                                                                      sx={{
                                                                        padding:
                                                                          "14px 15px",
                                                                        background:
                                                                          "white",
                                                                        // marginBlock:
                                                                        //   "8px 4px",
                                                                        border: `1px solid ${theme?.palette?.customColors?.g75}`,
                                                                        borderRadius:
                                                                          "4px",
                                                                        maxHeight: '3rem'
                                                                      }}
                                                                      onClick={() =>
                                                                        helpers?.remove(
                                                                          idx
                                                                        )
                                                                      }
                                                                    >
                                                                      <DeleteIcon />
                                                                    </BLUEBORDER>
                                                                  )
                                                                  }
                                                                  {/* </Grid> */}
                                                                </Grid>
                                                              </Grid>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      height={"min-content"}
                                                    >
                                                      <WHITEBG
                                                        startIcon={<AddIcon />}
                                                        sx={{
                                                          borderRadius: "8px",
                                                          borderWidth: "1px",
                                                          padding:
                                                            "10px 20px 10px 20px",
                                                          marginTop: 1,
                                                          borderColor:
                                                            theme?.palette
                                                              ?.customColors?.g100,
                                                          color:
                                                            theme?.palette
                                                              ?.customColors?.g300,
                                                          fontWeight: 700,
                                                        }}
                                                        onClick={() => {
                                                          helpers.push({
                                                            key: "",
                                                            value: "",
                                                          });
                                                          scrollToBottom()
                                                        }
                                                        }
                                                      >
                                                        Add more
                                                      </WHITEBG>
                                                    </Grid>
                                                  </Grid>
                                                </>
                                              )}
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  {/* {key_metrics_length - 1 === index && ( */}
                                  <Grid item xs={12} sm={12} height={"min-content"}>
                                    <WHITEBG
                                      startIcon={<AddIcon />}
                                      sx={{
                                        borderRadius: "8px",
                                        borderWidth: "1px",
                                        padding: "10px 20px 10px 20px",
                                        marginBottom: 2,
                                        borderColor:
                                          theme?.palette?.customColors?.g100,
                                        color: theme?.palette?.customColors?.g300,
                                        fontWeight: 700,
                                      }}
                                      onClick={() => {
                                        arrayHelpers.push({
                                          heading: "",
                                          properties: [
                                            { key: "", value: "" },
                                            { key: "", value: "" },
                                            { key: "", value: "" },
                                          ],
                                        });
                                        scrollToBottom()
                                      }
                                      }
                                    >
                                      Add key metrics
                                    </WHITEBG>
                                  </Grid>
                                  {/* )} */}
                                </Grid>
                              )}
                            />
                          </>
                        )}
                        {currentIndex == 5 && (
                          <>
                            <Grid item container marginTop={2} gap={1} paddingBottom={3}>
                              <FieldArray
                                name="documents"
                                key={"documents"}
                                render={(arrayHelpers) => (
                                  <>
                                    {formik.values?.documents &&
                                      formik.values?.documents?.length > 0 &&
                                      formik.values?.documents?.map(
                                        (document, index) => {
                                          return (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                spacing={1}
                                                position={"relative"}
                                                key={index}
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  display={"flex"}
                                                  alignItems={"center"}
                                                  justifyContent={"space-between"}
                                                  sx={{
                                                    background:
                                                      theme?.palette.customColors
                                                        ?.whiteBG,
                                                    padding: "0.8rem 1rem",
                                                    borderRadius: "8px",
                                                    border: `1px solid ${theme.palette.customColors.g75}`,
                                                  }}
                                                >
                                                  <Grid
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    gap={1}
                                                  >
                                                    {document?.editable ? (
                                                      <CustomInputField
                                                        sx={{
                                                          margin: 0,
                                                          "& .MuiFilledInput-input": {
                                                            padding:
                                                              "8px 15px !important",
                                                          },
                                                        }}
                                                        name={`documents.${index}.title`}
                                                        value={document?.title}
                                                        onChange={(e) =>
                                                          formik.setFieldValue(
                                                            `documents.${index}.title`,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <Typography variant="body1">
                                                        {document?.title}
                                                      </Typography>
                                                    )}
                                                    {document?.editable ? (
                                                      <Done
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                          formik.setFieldValue(
                                                            `documents.${index}.editable`,
                                                            false
                                                          );
                                                        }}
                                                      />
                                                    ) : (
                                                      <EditIcon
                                                        style={{
                                                          cursor: "pointer",
                                                          width: 20,
                                                          height: 20,
                                                        }}
                                                        onClick={() => {
                                                          formik.setFieldValue(
                                                            `documents.${index}.editable`,
                                                            true
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </Grid>
                                                  {/* {console.log("ffffff", formik.values?.documents)} */}
                                                  <Grid
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: "1rem",

                                                    }}
                                                  >
                                                    {(document?.document || document?._id) && (
                                                      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                        <img
                                                          style={{ width: "36px", height: '36px' }}
                                                          src={getFileIcon(
                                                            document?.document?.name || document?.filename)}
                                                        />
                                                        {document?.document && <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} onClick={() => {
                                                          formik.setFieldValue(
                                                            `documents.${index}.document`,
                                                            null
                                                          );
                                                        }} />}
                                                      </Box>
                                                    )}
                                                    {/* {console.log(document?.document_type, "feferfefef")} */}
                                                    <WHITEBG
                                                      component="label"
                                                      role={undefined}
                                                      tabIndex={-1}
                                                      sx={{
                                                        minWidth: "0 !important",
                                                        border: `1px solid #D0D5DD !important`,
                                                      }}
                                                    >
                                                      <UploadDocumentsIcon
                                                        style={{
                                                          width: 20,
                                                          height: 20,
                                                          stroke: "#344054"
                                                        }}
                                                      />
                                                      <VisuallyHiddenInput
                                                        accept=".pdf,.png,.jpeg,.jpg,.mp4,.mov,.xlsx,.csv,.xls,.doc,.docx"
                                                        type="file"
                                                        name={`documents.${index}.document`}
                                                        id={`documents.${index}.document`}
                                                        onChange={(e) =>
                                                          handleFileChange(e, index)
                                                        }
                                                      />
                                                    </WHITEBG>
                                                  </Grid>
                                                </Grid>
                                                {formik.values?.documents?.length > 1 && (
                                                  matchesUpMd ?
                                                    <Grid item ml={1}>
                                                      {/* <Button sx={{ padding: '14px 15px', background: "white", marginBlock: "8px 16px", border: `1px solid ${theme?.palette?.customColors?.g75}` }}><DeleteIcon /></Button> */}
                                                      <BLUEBORDER
                                                        sx={{
                                                          padding: "18px 15px",
                                                          background: "white",
                                                          margin: 0,
                                                          border: `1px solid ${theme?.palette?.customColors?.g75}`,
                                                        }}
                                                        onClick={() => {
                                                          if (window.confirm("Are you sure you want to delete this document?") == true) {
                                                            if (document?._id) {
                                                              deleteRoundDocumentAsync({ document_id: document?._id }, {
                                                                onSuccess: (succ) => {
                                                                  // console.log(succ, 'succ')
                                                                  arrayHelpers?.remove(index);
                                                                  toast.success(
                                                                    <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                    {
                                                                      duration: 2000,
                                                                      position: "top-right",
                                                                    }
                                                                  );
                                                                  refetchRoundDetails();
                                                                },
                                                                onError: (err) => {
                                                                  toast.error(
                                                                    <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                    {
                                                                      duration: 2000,
                                                                      position: "top-right",
                                                                    }
                                                                  );
                                                                }
                                                              });
                                                            }
                                                            else {
                                                              arrayHelpers?.remove(index)
                                                            }
                                                          }
                                                          else {
                                                            return;
                                                          }

                                                        }
                                                        }
                                                      >
                                                        <DeleteIcon />
                                                      </BLUEBORDER>
                                                    </Grid>
                                                    : <img style={{ position: 'absolute', top: "-0.5rem", right: "-0.5rem", height: "1.2rem", width: "1.2rem", cursor: 'pointer' }} src={ClearIcon} onClick={() => {
                                                      if (window.confirm("Are you sure you want to delete this document?") == true) {
                                                        if (document?._id) {
                                                          deleteRoundDocumentAsync({ document_id: document?._id }, {
                                                            onSuccess: (succ) => {
                                                              // console.log(succ, 'succ')
                                                              arrayHelpers?.remove(index);
                                                              toast.success(
                                                                <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                {
                                                                  duration: 2000,
                                                                  position: "top-right",
                                                                }
                                                              );
                                                            },
                                                            onError: (err) => {
                                                              toast.error(
                                                                <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                {
                                                                  duration: 2000,
                                                                  position: "top-right",
                                                                }
                                                              );
                                                            }
                                                          });
                                                        }
                                                        else {
                                                          arrayHelpers?.remove(index)
                                                        }
                                                      }
                                                      else {
                                                        return;
                                                      }

                                                    }
                                                    } />
                                                )}
                                              </Grid>
                                              {
                                                formik.errors?.documents?.[index]?.document && formik.touched.documents?.[index]?.document && (
                                                  <CustomValidationText value={formik.errors?.documents[index]?.document} />
                                                )
                                              }
                                            </>
                                          );
                                        }
                                      )}
                                  </>
                                )}
                              />
                            </Grid>
                          </>
                        )}
                        {currentIndex == 6 && (
                          <>
                            {/* {console.log(formik.values.faq, "faq")} */}
                            <Grid item container paddingBottom={1}>
                              <FieldArray
                                name="faqs"
                                render={(arrayHelpers) => (
                                  <>
                                    {formik.values?.faqs && formik.values?.faqs?.length > 0 && formik.values?.faqs?.map(
                                      ({ question, answer }, index) => {
                                        const filled =
                                          formik.values?.faqs[index]?.question !=
                                          "" ||
                                          formik.values?.faqs[index]?.answer != "";
                                        return (
                                          <Grid xs={12}>
                                            <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              justifyContent={"space-between"}
                                            >
                                              <CustomLabel>
                                                QUESTION {index + 1}
                                              </CustomLabel>
                                              {!filled ? (
                                                index == index ? (
                                                  <span
                                                    onClick={() => {
                                                      arrayHelpers.remove(index);
                                                    }}
                                                    style={{
                                                      color: "red",
                                                      cursor: "pointer",
                                                      marginBottom: "0.5rem",
                                                    }}
                                                  >
                                                    Delete
                                                  </span>
                                                ) : (
                                                  <span
                                                    onClick={() => { }}
                                                    style={{
                                                      color: "red",
                                                      height: 20.8,
                                                      width: 38.74,
                                                    }}
                                                  ></span>
                                                )
                                              ) : (
                                                <span
                                                  onClick={() => {
                                                    formik.setFieldValue(
                                                      `faqs.${index}.question`,
                                                      ""
                                                    );
                                                    formik.setFieldValue(
                                                      `faqs.${index}.answer`,
                                                      ""
                                                    );
                                                  }}
                                                  style={{
                                                    color: "red",
                                                    cursor: "pointer",
                                                    marginBottom: "0.5rem",
                                                  }}
                                                >
                                                  Clear
                                                </span>
                                              )}
                                            </Box>
                                            <Grid
                                              xs={12}
                                              sx={{
                                                background: "#FFF",
                                                padding: "12px",
                                                borderRadius: "8px",
                                                border: `1px solid ${theme?.palette?.customColors?.g75}`,
                                                marginBottom: 2,
                                              }}
                                            >
                                              <CustomInputField
                                                sx={
                                                  {
                                                    // margin: 0,
                                                  }
                                                }
                                                name={`faqs.${index}.question`}
                                                placeholder={"Enter question"}
                                                label={"ENTER YOUR QUESTION"}
                                                value={question}
                                                onChange={(e) =>
                                                  formik.setFieldValue(
                                                    `faqs.${index}.question`,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              {/* <CustomInputField
                                                sx={{
                                                  margin: 0,
                                                }}
                                                name={`faqs.${index}.answer`}
                                                placeholder={"Enter answer"}
                                                label={"PROVIDE ANSWER"}
                                                value={answer}
                                                onChange={(e) =>
                                                  formik.setFieldValue(
                                                    `faqs.${index}.answer`,
                                                    e.target.value
                                                  )
                                                }
                                              /> */}
                                              <CustomRichTextEditor
                                                name={`faqs.${index}.answer`}
                                                label={
                                                  "PROVIDE ANSWER"
                                                }
                                                placeholder={"Enter answer"}
                                                value={answer}
                                                handleChange={(newValue) =>
                                                  formik.setFieldValue(
                                                    `faqs.${index}.answer`,
                                                    newValue
                                                  )
                                                }
                                              />
                                            </Grid>
                                            {index ==
                                              formik.values.faqs?.length - 1 && (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={12}
                                                  height={"min-content"}
                                                >
                                                  <WHITEBG
                                                    startIcon={<AddIcon />}
                                                    sx={{
                                                      borderRadius: "8px",
                                                      borderWidth: "1px",
                                                      padding: "10px 20px 10px 20px",
                                                      // marginBottom: 2,
                                                      borderColor:
                                                        theme?.palette?.customColors
                                                          ?.g100,
                                                      color:
                                                        theme?.palette?.customColors
                                                          ?.g300,
                                                      fontWeight: 700,
                                                    }}
                                                    onClick={() => {
                                                      arrayHelpers.insert(index + 1, {
                                                        question: "",
                                                        answer: "",
                                                      });
                                                      scrollToBottom()
                                                    }
                                                    }
                                                  >
                                                    Add question
                                                  </WHITEBG>
                                                </Grid>
                                              )}
                                          </Grid>
                                        );
                                      }
                                    )}
                                    {formik.values.faqs?.length === 0 && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        height={"min-content"}
                                      >
                                        <WHITEBG
                                          startIcon={<AddIcon />}
                                          sx={{
                                            borderRadius: "8px",
                                            borderWidth: "1px",
                                            padding: "10px 20px 10px 20px",
                                            // marginBottom: 2,
                                            borderColor:
                                              theme?.palette?.customColors
                                                ?.g100,
                                            color:
                                              theme?.palette?.customColors
                                                ?.g300,
                                            fontWeight: 700,
                                          }}
                                          onClick={() => {
                                            arrayHelpers.insert(0, {
                                              question: "",
                                              answer: "",
                                            });
                                            scrollToBottom()
                                          }
                                          }
                                        >
                                          Add question
                                        </WHITEBG>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              />
                            </Grid>
                          </>
                        )}
                        {currentIndex == 7 && (
                          <>
                          <Grid item xs={12} sm={12} mb={1} height={"min-content"}>

<CustomRichTextEditor
  name="exit_highlights"
  label={
    <Box display="flex" alignItems="center" gap='4px'>
      EXIT HIGHLIGHTS
      {/* <CustomInfoIcon defaultTitle="EXIT HIGHLIGHTS " /> */}
    </Box>
  }
  placeholder={"Enter exit highlights"}
  value={formik.values?.exit_highlights}
  handleChange={(newValue) =>
    formik.setFieldValue("exit_highlights", newValue)
  }
/>
{formik.errors.exit_highlights && formik.touched.exit_highlights && (
  <CustomValidationText value={formik.errors.exit_highlights} />
)}
</Grid>
<Grid item xs={12} lg={5.8} height={"min-content"}>

<CustomLabel>RUNWAY IN MONTHS</CustomLabel>
<Select
  size="small"
  name="runway_left"
  fullWidth
  value={formik.values.runway_left}
  onChange={(e) =>
    formik.setFieldValue(
      "runway_left",
      e.target.value
    )
  }
  sx={{
    background: theme.palette.customColors.whiteBG,
    paddingBlock: "6px",
  }}
>
  <MenuItem value={"select_runway_left"}>Select runway</MenuItem>
  <MenuItem value={1}>1 Month</MenuItem>
  {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, "12+"]?.map(item => {
    return <MenuItem value={item}>{item} Months</MenuItem>
  })}
</Select>
{formik.errors.runway_left && formik.touched.runway_left && (
  <CustomValidationText value={formik.errors.runway_left} />
)}
</Grid>
<Grid item xs={12} lg={5.8} sx={{ marginTop: { xs: '0.5rem', lg: 0 } }} height={"min-content"}>
<CustomInputField
  InputProps={{
    ...currencyInputProps,
    startAdornment: (
      <Box sx={{ padding: "0 0 0 1rem" }}>
        {formik.values.currency}
      </Box>
    ),
  }}
  name={`monthly_net_burn`}
  label={"MONTHLY NET BURN"}
  placeholder={"10,000"}
  value={formik.values?.monthly_net_burn}
  onChange={(e) =>
    formik.setFieldValue(
      `monthly_net_burn`,
      e.target.value
    )
  }
  fullWidth
/>
</Grid>
<Grid item xs={12} marginTop={1} marginBottom={1.5}>
<CustomLabel
  sx={{
    fontSize: "1rem !important",
    fontWeight: "500 !important",
  }}
>
  OTHER DETAILS
</CustomLabel>
</Grid>
<Grid item xs={12} lg={5.8}>
{/* {console.log(formik.values?.round_start_date, "formik.values?.round_start_date")} */}
{/* <CustomLabel>ROUND START DATE</CustomLabel> */}
<CustomDatePicker
  fullWidth={true}
  name="round_start_date"
  label="ROUND START DATE"
  value={moment(formik.values?.round_start_date)}
  maxDate={moment(formik.values?.round_closing_date)}
  placeholder="Select Date"
  sx={{
    background: "#fff",
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    margin: 0,
    width: "100%"
  }}
  format={"DD/MM/YYYY"}
/>
</Grid>
<Grid item xs={12} lg={5.8} sx={{ marginTop: { xs: '0.5rem', lg: 0 } }}>
{/* <CustomLabel>ROUND CLOSING DATE</CustomLabel> */}
<CustomDatePicker
  fullWidth
  name="round_closing_date"
  label="ROUND CLOSING DATE"
  minDate={moment(formik.values?.round_start_date)}
  placeholder="Select Date"
  sx={{
    background: "#fff",
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    margin: 0,
    width: "100%"
  }}
  value={moment(formik.values?.round_closing_date)}
  format={"DD/MM/YYYY"}
/>
</Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              height={"min-content"}
                              marginTop={2}
                            >
                              <CustomLabel>ROUND STATUS

                              </CustomLabel>
                              <Grid
                                display={"flex"}
                                justifyContent={"space-between"}
                                flexWrap={"wrap"}
                                item
                                container
                                xs={12}
                                gap={"0.5rem"}
                                marginBottom={{ xs: 4, md: 0 }}
                              >
                                {/* {console.log(round_statuses,"round_statuses")} */}
                                {round_statuses?.map((status, index) => {
                                  return (
                                    <Grid
                                      role={"button"}
                                      item
                                      width={{ xs: '100%', sm: '49%', md: '30%', lg: '22%' }}
                                      // xs={12}
                                      // sm={5.65}
                                      // md={3.5}
                                      onClick={(e) => {
                                        formik.setFieldValue(
                                          "round_status",
                                          status?.value
                                        );
                                        e.preventDefault()
                                      }}
                                      sx={{
                                        background:
                                          formik.values.round_status == status?.value
                                            ? theme?.palette?.customColors?.y50
                                            : theme?.palette?.customColors?.whiteBG,
                                        minWidth: 100,
                                        height: 55,
                                        borderRadius: "8px",
                                        display: "grid",
                                        placeItems: "center",
                                        border: `1px solid ${formik.values.round_status == status?.value
                                          ? theme?.palette?.customColors?.y300
                                          : theme?.palette?.customColors?.g75
                                          }`,
                                        boxShadow: "0px 1px 2px 0px #1018280D",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color={theme.palette.customColors.g200}
                                        fontSize="0.875rem"
                                        textAlign={"center"}
                                      >
                                        {status?.name}
                                      </Typography>
                                    </Grid>
                                  );
                                })}
                                {formik.errors.round_status && formik.touched.round_status && (
                                  <Grid item xs={12} mt={"-0.5rem"}>
                                    <CustomValidationText value={formik.errors.round_status} />
                                  </Grid>
                                )}
                              </Grid>

                            </Grid>
                          </>
                        )}
                      </Grid>
                    </PerfectScrollbar>
                  </Form>
                </FormikProvider>
                {/* Content for the selected item goes here */}
                <Grid
                  item
                  container
                  display={"flex"}
                  xs={12}
                  sm={12}
                  height={"min-content"}
                  gap={"1.5rem"}
                  justifyContent={"center"}
                  padding={"0 0rem 1rem 0rem"}
                >
                  {/* {currentIndex !== 1 &&  */}
                  {currentIndex !== 0 && <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                    <BLUEBORDER
                      sx={{
                        textTransform: "none",
                        padding: 2,
                        borderWidth: 1,
                        borderRadius: "8px",
                      }}
                      type="no usetr"
                      onClick={() => {
                        if (currentIndex === 0) {
                          // navigate(-1);
                        } else {
                          setCurrentIndex(currentIndex - 1);
                          handleChangeValue(currentIndex - 1);
                        }
                        window.document
                          .getElementById("scrollable-form")
                          .scrollTo(0, 0);
                        // window.scrollTo(0, 0);
                      }}
                      fullWidth
                    >
                      Back
                    </BLUEBORDER>
                  </Grid>}
                  {/* } */}
                  <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                    <CustomButton
                      sx={{ padding: 2, borderRadius: "8px" }}
                      onClick={(e) => {
                        // formik.handleSubmit();
                        handleFormSubmit(e, formik);
                      }}
                      // onClick={() => { setCurrentIndex(currentIndex + 1) }}
                      fullWidth
                    >
                      {(isCreatingRound || isUploadingDocuments) && !matchesUpMd ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : currentIndex == 7 ? "Submit" : "Next"}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </ThemeBGWrapper>
  );
};

export default CreateRound;
