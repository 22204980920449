import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";


export const FetchAllRounds = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-roundss-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/list/rounds-listing`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return data?.data?.data != undefined ? data?.data?.data : []
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchViewStartupDetails = (id) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchStartupDetails-FetchStartupDetails-FetchStartupDetails-fetch-view-view', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/startup-details-new/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data[0];
            // console.log(data.data.data[0], 'datatatatat')
            const savedValues = {
                // step 1
                first_name: savedData?.first_name ? String(savedData?.first_name) : '',
                last_name: savedData?.last_name ? String(savedData?.last_name) : '',
                mobile_number: savedData?.mobile_number ? String(savedData?.mobile_number) : '',
                phone_code: savedData?.phone_code ? String(savedData?.phone_code) : '91',
                country: savedData?.country ? savedData?.country : null,
                state: savedData?.state ? savedData?.state : null,
                city: savedData?.city ? savedData?.city : null,
                linkedin_url: savedData?.linkedin_url ? String(savedData?.linkedin_url)?.startsWith('https://') ? String(savedData?.linkedin_url)?.slice(8) : String(savedData?.linkedin_url) : '',
                // step 2
                // startup_logo: ("http://192.168.55.19:3001/assets/project_image/startup/" + savedData?.logo_url) || null,
                // startup_logo: savedData?.logo_url ? (savedData?.bucket_path + savedData?.logo_url) : null,
                startup_logo: (savedData?.startup_logo_path),
                startup_name: savedData?.startup_name ? savedData?.startup_name : "",
                entity_name: savedData?.entity_name ? savedData?.entity_name : "",
                formation_date: savedData?.formation_date ? savedData?.formation_date : null,
                formatted_formation_date: savedData?.formatted_formation_date ? savedData?.formatted_formation_date : null,
                sector_info: savedData?.sector_info ? savedData?.sector_info : "",
                raised_round: savedData?.raised_round ? savedData?.raised_round : null,
                stage_info: savedData?.stage_info ? savedData?.stage_info : "",
                category_info: savedData?.category_info ? savedData?.category_info : "",
                // sector_ids: savedData?.sector_ids ? savedData?.sector_ids : [],
                pitch_deck_path: savedData?.pitch_deck_path ? savedData?.pitch_deck_path : null,
                pitch_deck_thumbnail_path: savedData?.pitch_deck_thumbnail_path ? savedData?.pitch_deck_thumbnail_path : null,
                pitch_deck_url: savedData?.pitch_deck_url ? savedData?.pitch_deck_url : null,
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || "",
                description: savedData?.description || '',
                elevator_pitch_path: savedData?.elevator_pitch_path ? savedData?.elevator_pitch_path : null,
                problem_solving: savedData?.problem_solving || "",
                website_url: savedData?.website_url ? String(savedData?.website_url)?.startsWith('https://') ? String(savedData?.website_url)?.slice(8) : String(savedData?.website_url) : '',

                coFoundersArray:
                    savedData?.cofounder && savedData?.cofounder?.length > 0 ? savedData?.cofounder?.map(item => (
                        {
                            edit: true,
                            name: item?.name || '',
                            title: item?.title || '',
                            bio: item?.bio || '',
                            linkedin_url: item?.linkedin_url ? String(item?.linkedin_url) : '',
                            image: item?.cofounder_image_path ? item?.cofounder_image_path : null,
                            image_name: item?.cofounder_image ? item?.cofounder_image : null,
                        }
                    )) : [],
                referral_type: savedData?.referral_type || '',
                referral_name: savedData?.referral_name || '',
                founder: savedData?.founder && savedData?.founder?.length > 0 ? {
                    name: savedData?.founder[0]?.name || '',
                    bio: savedData?.founder[0]?.bio || '',
                    title: savedData?.founder[0]?.title || '',
                    linkedin_url: savedData?.founder[0]?.linkedin_url ? savedData?.founder[0]?.linkedin_url : '',
                    image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null,
                    founder_image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null
                } : null,
                step: savedData?.step || 1,
                _id: savedData?._id,
                document_base_url: savedData?.document_base_url,
                backrr_base_url: savedData?.backrr_base_url,
                round_id: savedData?.startup_rounds_info,
                raised_round: savedData?.round_info?.raised_round,
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        retryDelay: 0,
        cacheTime: 0,
        enabled: id != null && !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchEditStartupDetails = ({ startup_id }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchStartupDetails-FetchStartupDetails-FetchStartupDetails-fetch+uaehfuaeuifnuiaenfuneufnu9999eufnunun' + startup_id, startup_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/startup-details-new/${startup_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data[0];
            // console.log(data.data.data,'datatatatat')
            // console.log(savedData?.round_info?.raised_round)
            const savedValues = {
                // step 1
                first_name: savedData?.first_name ? String(savedData?.first_name) : '',
                last_name: savedData?.last_name ? String(savedData?.last_name) : '',
                mobile_number: savedData?.mobile_number ? String(savedData?.mobile_number) : '',
                phone_code: savedData?.phone_code ? String(savedData?.phone_code) : '91',
                country_id: savedData?.country_id ? savedData?.country_id : null,
                state_id: savedData?.state_id ? savedData?.state_id : null,
                city_id: savedData?.city_id ? savedData?.city_id : null,
                slug: savedData?.slug ? savedData?.slug : null,
                linkedin_url: savedData?.linkedin_url ? String(savedData?.linkedin_url)?.startsWith('https://') ? String(savedData?.linkedin_url)?.slice(8) : String(savedData?.linkedin_url) : '',
                // step 2
                // startup_logo: ("http://192.168.55.19:3001/assets/project_image/startup/" + savedData?.logo_url) || null,
                // startup_logo: savedData?.logo_url ? (savedData?.bucket_path + savedData?.logo_url) : null,
                startup_logo: (savedData?.startup_logo_path),
                startup_name: savedData?.startup_name ? savedData?.startup_name : "",
                entity_name: savedData?.entity_name ? savedData?.entity_name : "",
                formation_date: savedData?.formation_date ? savedData?.formation_date : null,
                stage_id: savedData?.stage_id ? savedData?.stage_id : "",
                raised_round: savedData?.round_info?.raised_round != null ? savedData?.round_info?.raised_round : null,
                sector_id: savedData?.sector_id ? String(savedData?.sector_id) : "",
                category_id: savedData?.category_id ? savedData?.category_id : "",
                business_model_id: savedData?.business_model_id ? savedData?.business_model_id : [],
                // sector_ids: savedData?.sector_ids ? savedData?.sector_ids : [],
                pitch_deck: savedData?.pitch_deck_path ? savedData?.pitch_deck_path : null,
                pitch_deck_url: savedData?.pitch_deck_url ? savedData?.pitch_deck_url : null,
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || "",
                description: savedData?.description || '',
                elevator_pitch: savedData?.elevator_pitch ? savedData?.elevator_pitch : null,
                problem_solving: savedData?.problem_solving || "",
                website_url: savedData?.website_url ? String(savedData?.website_url)?.startsWith('https://') ? String(savedData?.website_url)?.slice(8) : String(savedData?.website_url) : '',
                coFoundersArray:
                    (savedData?.cofounder
                        ?
                        savedData?.cofounder?.length > 0
                            ? savedData?.cofounder?.map(item => (
                                {
                                    edit: true,
                                    name: item?.name || '',
                                    title: item?.title || '',
                                    bio: item?.bio || '',
                                    linkedin_url: item?.linkedin_url ? String(item?.linkedin_url)?.startsWith('https://') ? String(item?.linkedin_url)?.slice(8) : String(item?.linkedin_url) : '',
                                    image: item?.cofounder_image_path ? item?.cofounder_image_path : null,
                                    image_name: item?.cofounder_image ? item?.cofounder_image : null,
                                }
                            )) : [
                                {
                                    name: "",
                                    title: "",
                                    bio: '',
                                    linkedin_url: "",
                                    image: null,
                                    edit: true,
                                },
                            ] : [
                            {
                                name: "",
                                title: "",
                                bio: '',
                                linkedin_url: "",
                                image: null,
                                edit: true,
                            },
                        ]),
                referral_type: savedData?.referral_type || '',
                referral_name: savedData?.referral_name || '',
                founder:
                    savedData?.founder
                        ? (
                            savedData?.founder?.length > 0
                                ?
                                {
                                    name: savedData?.founder[0]?.name || '',
                                    bio: savedData?.founder[0]?.bio || '',
                                    title: savedData?.founder[0]?.title || '',
                                    linkedin_url: savedData?.founder[0]?.linkedin_url ? String(savedData?.founder[0]?.linkedin_url)?.startsWith('https://') ? String(savedData?.founder[0]?.linkedin_url)?.slice(8) : String(savedData?.founder[0]?.linkedin_url) : '',
                                    image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null,
                                    founder_image: savedData?.founder_image ? savedData?.founder_image : null
                                } : {
                                    name: "",
                                    bio: "",
                                    title: "",
                                    linkedin_url: "",
                                    image: null,

                                }
                        ) : {
                            name: "",
                            bio: "",
                            title: "",
                            linkedin_url: "",
                            image: null,

                        },
                step: savedData?.step || 1,
                _id: savedData?._id,
                document_base_url: savedData?.document_base_url,
                backrr_base_url: savedData?.backrr_base_url,
                round_name: savedData?.round_info?.round_name || '',
                round_type: savedData?.round_info?.round_type || "",
                round_id: savedData?.round_info?._id || '',
                total_round_size: savedData?.round_info?.total_round_size || '',
                currency_id: savedData?.round_info?.currency_id || '',
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        retryDelay: 0,
        cacheTime: 0,
        enabled: !!startup_id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchRoundViewDetails = (id) => {
    return useQuery(
        ['master-listing-template-round--view-details-using-id-fetch', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/round-details-new/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data, 'values new')
            const savedData = data?.data?.data[0];
            const savedValues = {
                // step 0
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || '',
                security_type: savedData?.security_type || "",
                // step 1
                round_highlights: savedData?.round_highlights || "",
                total_round_size: savedData?.total_round_size || "",
                round_valuation: savedData?.round_valuation | "",
                minimum_cheque_size: savedData?.minimum_cheque_size || "",
                received_termsheet: savedData?.received_termsheet || false,
                committed_funding_amount: savedData?.committed_funding_amount || "",
                currency_id: savedData?.currency_id || "",
                currency: savedData?.currency_info || "",
                // step 2
                investors: savedData?.investors || [{ name: "", email: "" }],
                // step 3
                fund_utilization_highlights: savedData?.fund_utilization_highlights || "",
                fund_allocation_percentages: savedData?.fund_allocation_percentages || [
                    { name: "", percentage: 0 },
                ],
                // step 4
                traction_highlights: savedData?.traction_highlights || "",
                key_metrics: savedData?.key_metrics || [
                    {
                        heading: "",
                        properties: [
                            { key: "", value: "" },
                            { key: "", value: "" },
                            { key: "", value: "" },
                        ],
                    },
                ],
                // step 5
                documents: savedData?.documents || [

                ],
                // step 6
                faqs: savedData?.faqs || [
                    {
                        question: "",
                        answer: "",
                    },
                ],
                // step 7
                exit_highlights: savedData?.exit_highlights || "",
                runway_left: savedData?.runway_left || 'select_runway_left',
                monthly_net_burn: savedData?.monthly_net_burn || "",
                round_start_date: savedData?.round_start_date || null,
                round_closing_date: savedData?.round_closing_date || null,
                round_status: savedData?.round_status || "",
                ...savedData
            }
            return savedValues
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchRoundDocuments = ({ round_id }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-roundss-fetch', round_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/round-documents/${round_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return data?.data?.data != undefined ? data?.data?.data?.documents : null
        },
        refetchOnWindowFocus: false,
        cacheTime: 0,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const MoveStartupToOnboarded = ({ investor_id }) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/update-onboarding-flag`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
}

export const UpdateStartupStatus = ({ investor_id }) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/startups_status/${investor_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
}

export const FetchAllStartupsListing = ({ status = "", page = 1, limit = 20, sort = -1, filter, queryParams, searchText = "" }) => {
    let profileStatus;

    switch (status) {
        case "all":
            profileStatus = "";
            break;
        case "in_progress":
            profileStatus = "&is_onboarded=false&has_startup_name=true";
            break;
        case "on_boarded":
            profileStatus = "&is_onboarded=true";
            break;
        case "in_complete":
            profileStatus = "&is_onboarded=false&has_startup_name=false";
            break;
        default:
            profileStatus = ""; // Fallback in case none of the cases match
    }

    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-sub-sub-sub-admin-FetchAllStartupsListing-fetch-all-admin' + status, profileStatus, page, limit, sort],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-all-startup-details?search=${searchText}&page=${page}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ''}${profileStatus}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
                params: queryParams
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data, "dddd")
            return { data: data.data.data?.data, page: data.data?.data.page, sort: data.data?.data.sort, limit: data.data?.data.limit, count: data.data?.data.count }
        },
        refetchOnWindowFocus: false,
        // enabled: !!investor,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const InviteStartupPOST = () => {
    return useMutation(
        ({ payload, _id }) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/invite-startup/${_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveFeedback = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/submit-feedback`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveInterest = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/show_interested`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchInterestFeedbackStatus = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-investor-info`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveAdminNotes = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/notes`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchAllMatchedInvestorList = () => {
    return useMutation(
        ({ payload, startup_id }) => {
            // console.log("Payload:", payload);
            // console.log("Startup ID:", startup_id);
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get_matched_investors/${startup_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            })
        }, {
        // onSuccess: (data)=>console.log("API successfull"),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllAdminNotes = () => {
    return useMutation(
        ({ payload }) => {
            // console.log("Payload:", payload);
            // console.log("Startup ID:", startup_id);
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get_notes`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            })
        }, {
        // onSuccess: (data)=>console.log("API successfull"),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const DeleteAdminNotes = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/investors/delete_notes/${payload?.note_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};