import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as NameTypeIcon } from "../../assets/rounds_icons/name_type_round_icon.svg";
import { ReactComponent as FundUtilizationIcon } from "../../assets/rounds_icons/fund_util_round_icon.svg";
import { ReactComponent as CurrentTractionIcon } from "../../assets/rounds_icons/current_traction_round_icon.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/rounds_icons/documents_round_icon.svg";
import { ReactComponent as SavingIcon } from "../../assets/saving_icon.svg";
import marketplaceIcon from '../../assets/marketplace.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'
import { ReactComponent as UploadDocumentsIcon } from "../../assets/rounds_icons/upload_documents_icon.svg";
import ClearIcon from '../../assets/doc_icons/close_icon.svg'
import { ReactComponent as RoundSizeIcon } from "../../assets/rounds_icons/round_size_val_round_icon.svg";
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
    IconButton,
    Card,
    TableHead,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    MenuItem,
    Menu,
    Skeleton,
} from "@mui/material";
import {
    BLUEBORDER,
    SECONDARY,
    B300 as CustomButton,
    WHITEBG,
} from "../../ui-components/CustomButton";
import { useTheme } from "@emotion/react";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../ui-components/formik/CustomInputField";
import CustomLabel from "../../ui-components/CustomLabel";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    UploadRoundDocuments,
    FetchAllStages,
    FetchAllSecurityTypes,
    FetchAllRoundStatus,
    FetchProfileDetails,
    SavePersonalInfo,
    SaveProfileImage,
    SaveStartupInfo,
    SendWhatsappOTP,
    FetchAllInvestmentSize,
    VerifyWhatsAppOTP,
    FetchAllCategories,
    FetchAllCities,
    FetchAllCountries,
    FetchAllCountriesWithPhoneCodes,
    FetchAllStates,
    FetchAllSubCategories,
    FetchAllBusinessModel,
    AddPortfolio as AddPortfolioDetails,
    EditPortfolio as EditPortfolioDetails,
    FetchPortfolioDetails,
    AddPortfolioRound,
    DeletePortfolioRound,
    DeletePortfolioRoundDocument
} from "../../api";


import * as Yup from "yup";
import ThemeBGWrapper from "../../ui-components/ThemeBGWrapper";
import toast from "react-hot-toast";
import moment from "moment";
import CustomToast from "../../ui-components/CustomToast";
import { containerRef, getFileIcon, getMarks, handleFormSubmit, isRequired, linkedInTestURL, notEmpty, websiteTestURL } from "../../utils";
import { ReactComponent as InfoIcon } from '../../assets/help-circle.svg';
import CustomValidationText from "../../ui-components/CustomValidationText";
import BreadcrumbHeader from "../../layout/BreadcrumbsHeader";
import CustomAutoComplete from "../../ui-components/CustomAutoComplete";
import { ReactComponent as gpsIcon } from "../../assets/gps.svg"
// import placeHolderImage from "../../assets/placeholder_image.png"
import placeHolderImage from "assets/no_data_images/placeholder_startup.png"
import CustomLogoButton from '../../ui-components/formik/CustomLogoButton'
import CustomUrlField from "../../ui-components/CustomUrlField";
import VerticalScrollableTabs from "../../ui-components/VerticalScrollableTabs";
import { useConfig } from "../../dynamic-configuration/configContext";
import CustomDatePicker from "ui-components/formik/CustomDatePicker";
import { ReactComponent as DeleteIcon } from "../../assets/rounds_icons/delete_icon.svg";
import { ReactComponent as EditIcon } from "../../assets/rounds_icons/edit_icon.svg";
import { Done, KeyboardArrowDownRounded, More, MoreVertOutlined } from "@mui/icons-material";
import styled from "@emotion/styled";
import CustomMenu from "ui-components/CustomMenu";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import NoData from "ui-components/NoData";
import DeleteConfirmationDialog from "ui-components/popups/DeleteConfirmationPopup";
import { reload } from "firebase/auth";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const CardHeader = ({ title, index = 0, item, handleSelected = () => { } }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, index) => {
        setAnchorEl(event.currentTarget);
        // setCurrentIndex(index)
        // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
        // console.log(event,"event")
        setAnchorEl(null);
        // setCurrentIndex(null);
    };
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
        <IconButton
            sx={{ borderRadius: "4px" }}
            id={`more-details-button-${index}`}
            size="small"
            aria-controls={openMenu ? 'more-details-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={(e) => handleClickMenu(e)}
            centerRipple={false}
        >
            <MoreVertOutlined />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : 'false'}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    padding: "12px",
                    borderRadius: "8px",
                    "& > ul.MuiList-root": { padding: "0 !important" },
                    bgcolor: theme.palette.customColors.g400,
                    color: theme.palette.customColors.whiteBG,
                    // '&::before': {
                    //     content: '""',
                    //     display: 'block',
                    //     position: 'absolute',
                    //     top: 1,
                    //     right: 5,
                    //     width: 10,
                    //     height: 10,
                    //     bgcolor: theme.palette.customColors.g900,
                    //     transform: 'translateY(-50%) rotate(45deg)',
                    //     zIndex: 0,
                    // },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        >
            <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => {
                handleSelected(item, "edit")
                // console.log(item,"selectedItemForEdit")
            }}>
                Edit
            </MenuItem>
            <MenuItem disableRipple sx={{ borderBottom: `0px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => {
                handleSelected(item, "delete")
                // console.log(item,"selectedItemForDelete")
            }}>
                Delete
            </MenuItem>
        </Menu>
    </Box>
}


const AddPortfolio = () => {
    const theme = useTheme();
    const portfolio_id = Cookies.get("portfolio_id");
    const bottomRef = useRef(null);

    const scrollToTop = () => {
        if (bottomRef.current) {
            // Access the Scrollbar's DOM node and scroll
            bottomRef.current.scrollTop = 0;
        }
    };

    const { config } = useConfig()
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const userDetails = useSelector((state) => state?.investorDetails?.data);
    const [openForm, setOpenForm] = React.useState(true);
    // console.log(userDetails,"investorrrrrrrrrrrrrr")
    const navigate = useNavigate();

    let stepIndex = new URLSearchParams(window.location.search).get("page");
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const [searchParams, setSearchParams] = useSearchParams()
    const [currentStep, setCurrentStep] = useState(Number(stepIndex) || 0);
    const [amountRanges, setAmountRanges] = useState([])
    const [currency, setCurrency] = useState(null);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedRange, setSelectedRange] = useState(null); // Track selected range
    const [allAmountRanges, setAllAmountRanges] = useState([])
    const [selectedStages, setSelectedStages] = useState([])
    const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);
    const [isPublicEnabled, setIsPublicEnabled] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [otpValues, setOtpValues] = useState(Array(4).fill(''));
    const [error, setError] = useState(null)
    const [countDown, setCountDown] = useState(30);
    const [search, setSearch] = useState("")
    const [searchState, setSearchState] = useState("")
    const [searchCountry, setSearchCountry] = useState("")
    const [currentSubCategory, setCurrentSubCategory] = useState('');
    const [searchSubCategory, setSearchSubCategory] = useState('');
    const [currentStages, setCurrentStages] = useState('');
    const [searchStages, setSearchStages] = useState('');
    const [currentSecurityType, setCurrentSecurityType] = useState('');
    const [searchSecurityType, setSearchSecurityType] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [currentBusinessModel, setCurrentBusinessModel] = React.useState('');
    const [searchBusinessModel, setSearchBusinessModel] = React.useState('');

    const { data: security_types, isRefetching: isRefetchingSecurityTypes, isLoading: isLoadingSecurityTypes, refetch: refetchSecurityTypes } = FetchAllSecurityTypes()
    const { data: portfolioDetailsData, isRefetching: isPortfolioDetailsRefetching, isLoading: isPortfolioDetailsLoading, refetch: refetchPortfolioDetails } = FetchPortfolioDetails({ id: portfolio_id })
    const { data: investmentSizeList, isRefetching: isRefetchingInvestmentSizeList, isLoading: isLoadingInvestmentSizeList, refetch: refetchInvestmentSizeList } = FetchAllInvestmentSize()
    const { mutateAsync: verifyWhatsAppOTPAsync, isLoading: isOTPLoading, data: verifyWhatsAppOTPResponse } = VerifyWhatsAppOTP();
    const { data: startup_stages, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllStages();
    const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
    const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
    const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })
    const { data: startup_business_model, isRefetching: isRefetchingBusinessModel, isLoading: isLoadingBusinessModel, refetch: refetchBusinessModel } = FetchAllBusinessModel({})
    const [isSectorAgnostic, setIsSectorAgnostic] = useState(false);
    const [isBusinessModelSelectAll, setIsBusinessModelSelectAll] = useState(false);
    const {
        mutateAsync: addPortfolioAsync,
        isLoading: isAddingPortfolio,
        status: addPortfolioStatus,
        error: addPortfolioError,
        data: addPortfolioResponse
    } = AddPortfolioDetails();
    const {
        mutateAsync: AddPortfolioRoundAsync,
        isLoading: isAddingPortfolioRound,
        status: AddPortfolioRoundStatus,
        error: AddPortfolioRoundError,
        data: AddPortfolioRoundResponse
    } = AddPortfolioRound();
    const {
        mutateAsync: editPortfolioAsync,
        isLoading: isUpdatingPortfolio,
        status: editPortfolioStatus,
        error: editPortfolioError,
        data: editPortfolioResponse
    } = EditPortfolioDetails();

    const {
        mutateAsync: deletePortfolioRoundAsync,
        isLoading: isdeletingRoundfromPortfolio,
        status: deletePortfolioRoundStatus,
        error: deletePortfolioRoundError,
        data: deletePortfolioRoundResponse
    } = DeletePortfolioRound();

    const {
        mutateAsync: deletePortfolioRoundDocumentAsync,
        isLoading: isdeletingRoundfromPortfolioDocument,
        status: deletePortfolioRoundDocumentStatus,
        error: deletePortfolioRoundDocumentError,
        data: deletePortfolioRoundDocumentResponse
    } = DeletePortfolioRoundDocument();

    //   console.log(userDetails?._id,"hjjbjbjkbk")
    const bottomElement = React.useRef(null);
    const scrollToBottom = () => {

        // Find the first visible validation message
        setTimeout(() => {
            if (bottomElement && bottomElement.current && bottomElement.current.offsetParent !== null) {
                bottomElement.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 10)
    }

    const validationObj = currentStep == 0 ? {
        startup_website: Yup.string("Startup website is required").required("Startup website is required").trim().matches(websiteTestURL, "Please enter a valid website URL"),
        startup_name: Yup.string().required("Startup name is required"),
        startup_logo: Yup.mixed()
            .test('file-or-url', "Startup logo is required", function (value) {
                // If value is a file, check its type and size
                if (value && value instanceof File) {
                    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                    return validFileTypes.includes(value.type) && value.size <= 10000000; // File type & size check
                }
                // If value is a string, validate it as a URL
                if (typeof value === 'string') {
                    return Yup.string().url().isValidSync(value); // URL validation
                }
                return true; // If no value, it's valid (nullable)
            }).required("Startup logo is required")
    } : {
        round_name: Yup.string().required("Round name is required"),
        investment_date: Yup.string().required("Investment date is required"),
        no_of_units: Yup.string().required("No. of shares/units is required"),
        per_share_unit: Yup.string().required("Value per share/unit is required"),
        // investment_amount: Yup.string().required("Investment amount is required"),
        current_value_per_unit: Yup.string().required("Current value per share/unit is required"),
        // current_investment_value: Yup.string().required("Current investment value is required"),
    }

    const validationSchema = Yup.object({ ...validationObj });

    const [selectedIndex, setSelectedIndex] = React.useState(null)

    // console.log("eeee", portfolioDetailsData)

    const isEditMode = !!portfolio_id;

    const formik = useFormik({
        initialValues:
            isEditMode && !isPortfolioDetailsLoading && portfolioDetailsData ? portfolioDetailsData?.data :
                {
                    // step 0
                    startup_website: '',
                    startup_name: '',
                    startup_logo: null,
                    sectors: null,
                    founder_email: '',
                    stages: null,
                    custom_image_url: null,
                    business_model: [],
                    category: null,
                    investor_id: userDetails?._id,
                    // step 1
                    round_name: '',
                    investment_date: '',
                    syndicate_name: '',
                    no_of_units: '',
                    per_share_unit: '',
                    investment_amount: '',
                    current_value_per_unit: '',
                    current_investment_value: '',
                    security_type: '',
                    documents: [
                        {
                            title: "Round Document",
                            document: null,
                            editable: false,
                            type: "",
                            // document_type: null,
                        },
                    ],
                    step: 1,
                    current_index: 0
                },
        validationSchema: validationSchema,
        // validate: (values) => { console.log(values, "valueseferfe"); console.log(formik.errors, "err") },
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        enableReinitialize: true,
        onSubmit: async (values) => {
            // console.log(values, "values")
            const formData = new FormData();
            let request = {}
            //  let currentDocuments = documents?.filter(doc => doc?.document?.size != undefined);
            switch (currentStep) {
                case 0:
                    formData.append("startup_website", values?.startup_website)
                    formData.append("investor_id", values?.investor_id)
                    formData.append("startup_name", values?.startup_name)
                    notEmpty(values.founder_email) && formData.append("founder_email", values?.founder_email)
                    values?.stages != null && formData.append("stages", values?.stages)
                    if (values?.business_model?.length > 0) {
                        values?.business_model?.forEach((element, index) => {
                            formData.append(`business_model[${index}]`, element)
                        })
                    }
                    values?.sectors != null && values?.sectors != 'null' && (String(values?.sectors)?.length > 0) && formData.append("sectors[0]", values?.sectors)
                    // console.log("sectors[0]", values?.sectors)
                    values?.category != null && formData.append("category", values?.category)
                    if (values.startup_logo != null) {
                        if (typeof values.startup_logo != 'string') {
                            formData.append("startup_logo", values.startup_logo);
                        }
                        else {
                            formData.append("startup_logo_flag", true);
                        }
                    } else {

                    }
                    break;
                case 1:
                    formData.append("investor_id", values?.investor_id)
                    formData.append(`portfolio_id`, portfolio_id)
                    // const rounds_length = values?.rounds && Array.isArray(values?.rounds) ? values?.rounds?.length : 0;
                    notEmpty(values?.round_name) && formData.append(`round_name`, values.round_name)
                    notEmpty(values?.investment_date) && values?.investment_date != null && values?.investment_date != 'null' && formData.append(`investment_date`, moment(values.investment_date)?.toISOString())
                    notEmpty(values?.syndicate_name) && formData.append(`syndicate_name`, values.syndicate_name)
                    notEmpty(values?.no_of_units) && formData.append(`no_of_units`, values.no_of_units)
                    notEmpty(values?.per_share_unit) && formData.append(`per_share_unit`, values.per_share_unit)
                    formData.append(`investment_amount`, (formik.values?.no_of_units * formik.values?.per_share_unit) || 0)
                    formData.append(`current_value_per_unit`, formik.values?.current_value_per_unit || 0)
                    formData.append(`current_investment_value`, (formik.values?.no_of_units * formik?.values?.current_value_per_unit) || 0)
                    notEmpty(values?.security_type) && formData.append(`security_type`, values.security_type)

                    if (values?.documents?.length > 0) {
                        // console.log(values?.documents, "values?.documents")
                        values?.documents?.filter(item => item?.document != null)?.filter(item => !item?._id).forEach((doc, index) => {
                            //   if (doc?.document?.size) {
                            // doc?._id && formData.append(`id[${index}]`, doc?._id);
                            // notEmpty(values.doc?.title) && 

                            formData.append(`title[${index}]`, doc?.title);
                            // if (typeof doc?.document === "string") {
                            //     formData.append(`flag[${index}]`, true);
                            // }
                            // else {
                            formData.append('file', doc?.document);
                            // }
                            // doc?.document && formData.append(`document_type[${index}]`, doc?.document_type);
                            //   }
                            // else {
                            //   formData.append(`title[${index}]`, doc?.title);
                            //   doc?._id && formData.append(`id[${index}]`, doc?._id);
                            //   // doc?._id && formData.append(`document_type[${index}]`, doc?.document_type);
                            // }
                        });
                    }

            }

            if (isEditMode) {


                switch (currentStep) {
                    case 0:
                        await editPortfolioAsync({ payload: formData, portfolio_id: portfolio_id }).catch(err => {
                            // console.log(err?.response?.data?.message, "response herer")

                            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                                duration: 4000,
                                position: "top-right",
                                style: {
                                    padding: "15px 30px",
                                },
                            });
                        });
                        break;
                    case 1:
                        await selectedItem?._id && formData.append("round_id", selectedItem?._id)
                        await AddPortfolioRoundAsync({ payload: formData }).then(() => {
                            // formik.setValues({
                            //     ...formik.values,
                            //     round_name: '',
                            //     investment_date: '',
                            //     syndicate_name: '',
                            //     no_of_units: '',
                            //     per_share_unit: '',
                            //     investment_amount: '',
                            //     current_value_per_unit: '',
                            //     current_investment_value: '',
                            //     security_type: '',
                            //     documents: [
                            //         {
                            //             title: "Round Document",
                            //             document: null,
                            //             editable: false,
                            //             type: "",
                            //             // document_type: null,
                            //         },
                            //     ],
                            // });
                            setOpenForm(false)
                            window.location.reload();
                            // refetchPortfolioDetails();
                        }).catch(err => {
                            // console.log(err?.response?.data?.message, "response herer")

                            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                                duration: 4000,
                                position: "top-right",
                                style: {
                                    padding: "15px 30px",
                                },
                            });
                        });
                        break;

                    default:
                        break;
                }


            } else {

                switch (currentStep) {
                    case 0:
                        await addPortfolioAsync(formData).then((succ) => {
                            Cookies.set("portfolio_id", succ?.data?.data?.id)
                        }).catch(err => {
                            // console.log(err?.response?.data?.message, "response herer")

                            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                                duration: 4000,
                                position: "top-right",
                                style: {
                                    padding: "15px 30px",
                                },
                            });
                        });
                        break;
                    case 1:
                        await selectedItem?._id && formData.append("round_id", selectedItem?._id);
                        await AddPortfolioRoundAsync({ payload: formData }).then(() => {
                            // formik.setValues({
                            //     ...formik.values,
                            //     round_name: '',
                            //     investment_date: '',
                            //     syndicate_name: '',
                            //     no_of_units: '',
                            //     per_share_unit: '',
                            //     investment_amount: '',
                            //     current_value_per_unit: '',
                            //     current_investment_value: '',
                            //     security_type: '',
                            //     documents: [
                            //         {
                            //             title: "Round Document",
                            //             document: null,
                            //             editable: false,
                            //             type: "",
                            //             // document_type: null,
                            //         },
                            //     ],
                            // });
                            setOpenForm(false)
                            // refetchPortfolioDetails();
                            window.location.reload();
                        }).catch(err => {
                            // console.log(err?.response?.data?.message, "response herer")

                            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                                duration: 4000,
                                position: "top-right",
                                style: {
                                    padding: "15px 30px",
                                },
                            });
                        });
                        break;

                    default:
                        break;
                }
            }
        },
    });

    // console.log(formik.values,"formik")

    React.useEffect(() => {
        if (!isEditMode) {
            setOpenForm(true)
        }
        else if (searchParams?.get("add") === "true") {
            setOpenForm(true)
        }
        else {
            setOpenForm(false)
        }
    }, [isEditMode])



    // console.log(portfolioDetailsData,"portfolioDetailsData")

    React.useEffect(() => {
        if (editPortfolioResponse && editPortfolioResponse?.data?.statusCode == 200) {
            scrollToTop();

            if (currentStep == 1) {
                // toast.success(
                //   <CustomToast message={"Round Details Saved!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />
                //   , 3000)
                // setTimeout(() => {
                //     navigate("/portfolio");
                // }, 3000)
                // formik.setValues({
                //     ...formik.values,
                //     round_name: '',
                //     investment_date: '',
                //     syndicate_name: '',
                //     no_of_units: '',
                //     per_share_unit: '',
                //     investment_amount: '',
                //     current_value_per_unit: '',
                //     current_investment_value: '',
                //     security_type: '',
                //     documents: [
                //         {
                //             title: "Round Document",
                //             document: null,
                //             editable: false,
                //             type: "",
                //             // document_type: null,
                //         },
                //     ],
                // })
                // setOpenForm(false)
            }
            else {
                setCurrentStep(currentStep + 1);
                setSearchParams({ page: currentStep + 1 });
                refetchPortfolioDetails();
                // console.log(addPortfolioResponse?.data?.data?._id,"addPortfolioResponse?.data?.data?._id")
                editPortfolioResponse?.data?.data?._id && navigate(`/porfolio/${window.location.pathname?.includes("create_portfolio") ? "create_portfolio" : 'edit_portfolio'}/${addPortfolioResponse?.data?.data?._id}`)
                // currentStep == formik.values?.currentStep && formik.setFieldValue("currentStep", currentStep + 1);
            }
        }
        else if (editPortfolioResponse && editPortfolioResponse?.data?.statusCode != 200) {
            // toast.error(
            //   <CustomToast message={createRoundError?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
            //   {
            //     duration: 2000,
            //     position: "top-right",
            //   }
            // );
            // toast.error("Internal Server Error")
            // console.log("error", createRoundError)
        }
    }, [editPortfolioResponse?.data?.statusCode]);

    React.useEffect(() => {
        if (addPortfolioResponse && addPortfolioResponse?.data?.statusCode == 200) {
            scrollToTop();

            if (currentStep == 1) {
                // formik.setValues({
                //     ...formik.values,
                //     round_name: '',
                //     investment_date: '',
                //     syndicate_name: '',
                //     no_of_units: '',
                //     per_share_unit: '',
                //     investment_amount: '',
                //     current_value_per_unit: '',
                //     current_investment_value: '',
                //     security_type: '',
                //     documents: [
                //         {
                //             title: "Round Document",
                //             document: null,
                //             editable: false,
                //             type: "",
                //             // document_type: null,
                //         },
                //     ],
                // })
                // setOpenForm(false)
                // toast.success(
                //   <CustomToast message={"Round Details Saved!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />
                //   , 3000)
                // setTimeout(() => {
                //     navigate("/portfolio");
                // }, 3000)
            }
            else {
                setCurrentStep(currentStep + 1);
                setSearchParams({ page: currentStep + 1 });
                // console.log(addPortfolioResponse?.data?.data?._id,"addPortfolioResponse?.data?.data?._id")
                addPortfolioResponse?.data?.data?._id && navigate(`/porfolio/${window.location.pathname?.includes("create_portfolio") ? "create_portfolio" : 'edit_portfolio'}/${addPortfolioResponse?.data?.data?._id}`)
                // currentStep == formik.values?.currentStep && formik.setFieldValue("currentStep", currentStep + 1);
            }
        }
        else if (addPortfolioResponse && addPortfolioResponse?.data?.statusCode != 200) {
            // toast.error(
            //   <CustomToast message={createRoundError?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
            //   {
            //     duration: 2000,
            //     position: "top-right",
            //   }
            // );
            // toast.error("Internal Server Error")
            // console.log("error", createRoundError)
        }
    }, [addPortfolioResponse?.data?.statusCode]);

    React.useEffect(() => {
        if (AddPortfolioRoundResponse && AddPortfolioRoundResponse?.data?.statusCode == 200) {
            scrollToTop();

            // if (currentStep == 1) {

            // toast.success(
            //   <CustomToast message={"Round Details Saved!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />
            //   , 3000)
            // setTimeout(() => {
            //     navigate("/portfolio");
            // }, 3000)
            // }
            // else {
            //     setCurrentStep(currentStep + 1);
            //     setSearchParams({ page: currentStep + 1 });
            //     // console.log(    AddPortfolioRoundResponse?.data?.data?._id,"    AddPortfolioRoundResponse?.data?.data?._id")
            //     // AddPortfolioRoundResponse?.data?.data?._id && navigate(`/porfolio/${window.location.pathname?.includes("create_portfolio") ? "create_portfolio" : 'edit_portfolio'}/${AddPortfolioRoundResponse?.data?.data?._id}`)
            //     // currentStep == formik.values?.currentStep && formik.setFieldValue("currentStep", currentStep + 1);
            // }
        }
        else if (AddPortfolioRoundResponse && AddPortfolioRoundResponse?.data?.statusCode != 200) {
            // toast.error(
            //   <CustomToast message={createRoundError?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
            //   {
            //     duration: 2000,
            //     position: "top-right",
            //   }
            // );
            // toast.error("Internal Server Error")
            // console.log("error", createRoundError)
        }
    }, [AddPortfolioRoundResponse?.data?.statusCode]);

    useEffect(() => {
        if (countDown > 0) {
            const timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countDown]);



    const portfolio_steps = [
        {
            name: "STARTUP INFORMATION",
            sub_title: "Provide name, logo, stage and sector",
            image_url: CurrentTractionIcon,
        },
        {
            name: "ROUND INFORMATION",
            sub_title: "Provide round information",
            image_url: RoundSizeIcon,
        },
    ];



    const Actions = () => {
        return (
            <>
                {((isAddingPortfolioRound || isAddingPortfolio || isUpdatingPortfolio)) && matchesUpMd && <Typography sx={{ marginRight: "1rem" }} display={"flex"} alignItems={'center'} gap={1} fontSize={"14px"} fontWeight={600} color={theme.palette.customColors.g200}>
                    <SavingIcon width={24} height={24} className="saving-icon" />
                    <span>Saving...</span>
                </Typography>}
                {/* <SECONDARY
                    sx={{
                        background: theme?.palette?.customColors.indigo50,
                        color: theme?.palette?.customColors?.indigo700,
                        fontWeight: 600,
                        fontSize: "14px",
                        padding: "10px 16px",
                        display: { xs: "none", md: 'inline-block' }
                    }}
                >
                    Need help?
                </SECONDARY> */}
                <IconButton sx={{ display: { xs: "inline-block", md: 'none' }, padding: "5px" }}><InfoIcon style={{ width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', gap: "4px", marginBlock: 'auto', stroke: '#444CE7' }} /></IconButton>
            </>
        );
    };

    const RoundActions = ({ fullWidth = false }) => {
        return (
            <CustomButton
                sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' }, minWidth: fullWidth ? "auto" : 200 }}
                onClick={(e) => {
                    if (currentStep === 0) {
                        handleFormSubmit(e, formik);
                    }
                    else if (openForm && currentStep === 1) {
                        handleFormSubmit(e, formik);
                    }
                    else {
                        handleDeselected();
                        // handleFormSubmit(e, formik);
                        setOpenForm(!openForm);
                        // if (openForm) {
                        formik.setValues({
                            ...formik.values,
                            round_name: '',
                            investment_date: '',
                            syndicate_name: '',
                            no_of_units: '',
                            per_share_unit: '',
                            investment_amount: '',
                            current_value_per_unit: '',
                            current_investment_value: '',
                            security_type: '',
                            documents: [
                                {
                                    title: "Round Document",
                                    document: null,
                                    editable: false,
                                    type: "",
                                    // document_type: null,
                                },
                            ],
                        })
                        // }
                        scrollToTop();
                    }
                }}
                // disabled={currentStep === 5 && formik.values?.documents?.filter(item => item?.t}
                // onClick={() => { setCurrentStep(currentStep + 1) }}
                fullWidth={fullWidth}
            >
                {(currentStep === 0 ? (isAddingPortfolio || isUpdatingPortfolio ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : "Save & Next") : (openForm ? (isAddingPortfolioRound ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : "Submit") : "Add Round"))}
            </CustomButton>
        )
    }

    const isLoading = (isPortfolioDetailsLoading && isEditMode) || isLoadingRoundTypes || isLoadingSubCategories || isLoadingBusinessModel || isLoadingCategories || isLoadingSecurityTypes;
    // Effect to set selected category based on formik values
    useEffect(() => {
        if (!isPortfolioDetailsLoading && !isLoadingCategories && portfolioDetailsData?.data && Array.isArray(startup_categories)) {
            const category = startup_categories?.filter(item => formik?.values?.category?.includes(item?._id));

            if (category.length) {
                setSelectedCategory(category.map(item => item?.name).join(","));  // Or whichever property you want to use
            }
        }
    }, [isPortfolioDetailsLoading, isLoadingCategories, portfolioDetailsData?.data, startup_categories, formik?.values?.category]);

    // Effect to refetch subcategories when selectedCategory changes
    useEffect(() => {
        if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
            refetchSubCategories();
        }
    }, [selectedCategory, isRefetchingCategories || isLoadingCategories,]);

    React.useEffect(() => {
        // console.log(formik.values.sectors,'formik.values.sectors')
        if (!isLoading && formik.values.sectors && startup_sub_categories) {
            const matchedSubCategory = startup_sub_categories.find(subCat => subCat._id === formik.values.sectors);
            if (matchedSubCategory) {
                setCurrentSubCategory({ label: matchedSubCategory?.sub_category, value: matchedSubCategory?._id, description: matchedSubCategory?.description });
            }
        }
    }, [formik.values.sectors, startup_sub_categories, isLoading]);

    // console.log(formik,"ffff")

    const handleSearchSubCategoryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSubCategory(value);
        // refetchCities();
    };
    const handleSelectionSubCategoryChange = (event, sector) => {
        // console.log(value, "value")
        formik.setFieldValue("sectors", sector ? sector?.value : '');
        setCurrentSubCategory(sector)
        event.preventDefault();
    };
    const handleSearchUpdateSubCategory = () => {
        refetchSubCategories();
    }
    React.useEffect(() => {
        if (formik.values.stages && startup_stages) {
            const matchedSubCategory = startup_stages.find(stage => stage._id === formik.values.stages);
            if (matchedSubCategory) {
                setCurrentStages({ label: matchedSubCategory?.name, value: matchedSubCategory?._id });
            }
        }
    }, [formik.values.stages, startup_stages]);

    const handleSearchStagesChanges = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchStages(value);
        // refetchCities();
    };
    const handleSelectionStageChange = (event, stage) => {
        // console.log(stage, "value")
        formik.setFieldValue("stages", stage ? stage?.value : '');
        setCurrentStages(stage)
        event.preventDefault();
    };
    const handleSearchUpdateStages = () => {
        refetchRoundTypes();
    }
    React.useEffect(() => {
        if (formik.values.security_type && security_types) {
            const matchedSubCategory = security_types.find(security => security._id === formik.values.security_type);
            if (matchedSubCategory) {
                setCurrentSecurityType({ label: matchedSubCategory?.name, value: matchedSubCategory?._id });
            }
        }
    }, [formik.values.security_type, security_types]);

    const handleSearchSecurityTypeChanges = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSecurityType(value);
        // refetchCities();
    };
    const handleSelectionSecurityTypeChange = (event, security) => {
        // console.log(security, "value")
        formik.setFieldValue("security_type", security ? security?.value : '');
        setCurrentSecurityType(security)
        event.preventDefault();
    };
    const handleSearchUpdateSecurityType = () => {
        refetchSecurityTypes();
    }


    React.useEffect(() => {
        if (!isLoading && !isLoadingBusinessModel && formik?.values && Array.isArray(startup_business_model) && Array.isArray(formik?.values.business_model)) {
            const businessModel = startup_business_model?.filter(item => formik?.values?.business_model?.includes(item?._id));
            if (businessModel.length) {
                setCurrentBusinessModel(
                    businessModel.map(item => ({
                        label: item?.name,
                        value: item?._id,
                        description: item?.description,
                    }))
                );
            }
        }
    }, [isLoading, isLoadingBusinessModel, formik?.values, startup_business_model, formik?.values?.business_model]);

    const handleSearchBusinessModelChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchBusinessModel(value);
        // refetchCities();
    };
    const handleSelectionBusinessModelChange = (event, businessModel) => {
        formik.setFieldValue("business_model", businessModel ? businessModel?.map(item => item?.value) : []);
        setCurrentBusinessModel(businessModel)
        event.stopPropagation();
        // console.log(businessModel ,"businessModelbusinessModelbusinessModel")
    };
    const handleSearchUpdateBusinessModel = () => {
        refetchBusinessModel();
    }
    const availableOptionsBusinessModel = startup_business_model?.filter(
        item =>
            !(currentBusinessModel || []).some(selected => selected.value === item._id)
    ) || [];

    const handleFileChange = (e, index) => {
        const file = e.target.files[0];
        formik.setFieldValue(`documents.${index}.document`, file);
    };

    React.useEffect(() => {
        !isLoading && portfolioDetailsData?.data?.sector_type === "All" && setIsSectorAgnostic(true) && portfolioDetailsData?.data?.business_model_type === "All" && setIsBusinessModelSelectAll(true)
    }, [isLoading])
    const handleAddDocument = () => {
        // console.log(formik.values,"m")

        const defaultDocument = {
            title: "Document Name",
            document: null,
            editable: false,
            type: "",
        };

        formik.setFieldValue("documents", [
            ...formik.values?.documents,
            defaultDocument,
        ]);

        scrollToBottom();
    };

    React.useEffect(() => {
        if (!isLoading) {
            formik.setFieldValue('current_index', portfolioDetailsData?.data?.rounds?.length);
        }
    }, [isLoading])

    const [selectedItem, setSelectedItem] = React.useState(null)
    const [openDeletePopup, setOpenDeletePopup] = React.useState(null)

    const handleOpenDeletePopup = (item) => {
        setSelectedItem(item);
        setOpenDeletePopup(true)
    }

    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false);
        setSelectedItem(null);
    }

    const handleDeletion = async () => {
        // console.log(selectedItem,"item")
        const payload = {
            "round_id": selectedItem?._id,
            "portfolio_id": portfolio_id,
            // "_id": currentRow?._id,
        }
        // console.log(payload ,"paylodrowvalues")
        // console.log(currentRow ,"rowvalues")
        await deletePortfolioRoundAsync((payload), {
            onSuccess: (succ) => {
                // console.log(succ, 'succ')
                toast.success(
                    <CustomToast message={"Round Details Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                    {
                        duration: 2000,
                        position: "top-right",
                    }
                );
                refetchPortfolioDetails();

                // window.document
                //     .getElementById("scrollable-form")
                //     .scrollTo(0, 0);
                scrollToTop();
            },
            onError: (err) => {
                toast.error(
                    <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                    {
                        duration: 2000,
                        position: "top-right",
                    }
                );
            }
        });
        handleCloseDeletePopup();
    }

    const handleSelected = async (item, method) => {
        switch (method) {
            case "edit":
                setSelectedItem(item);
                // console.log(item, "item")
                // console.log(formik.values, "formik.values just before setting")
                if (item) {
                    await formik.setValues({
                        ...formik.values,
                        round_name: item?.round_name || "",
                        investment_date: item?.investment_date || "",
                        syndicate_name: item?.syndicate_name || "",
                        no_of_units: item?.no_of_units || "",
                        per_share_unit: item?.per_share_unit || "",
                        investment_amount: item?.investment_amount || "",
                        current_value_per_unit: item?.current_value_per_unit || "",
                        current_investment_value: item?.current_investment_value || "",
                        security_type: item?.security_type || "",
                        documents: item?.documents
                            ? item.documents.map(doc => ({
                                ...doc,
                                document: doc?.file_path || ""
                            }))
                            : []
                    });
                    // console.log("Formik values successfully updated");
                } else {
                    console.warn("Item data not available for setting Formik values");
                }
                // console.log(formik.values, "formik.values just after setting")
                setOpenForm(true);
                // window.document
                //     .getElementById("scrollable-form")
                //     .scrollTo(0, 0);
                scrollToTop();
                // console.log(formik.values, "formik.values just after")
                break;
            case "delete":
                handleOpenDeletePopup(item);
                break;

            default:
                break;
        }
    }

    const handleDeselected = (item) => {
        setSelectedItem(null)
    }


    return (
        <ThemeBGWrapper>
            <BreadcrumbHeader
                title={isEditMode && isPortfolioDetailsLoading ? "Loading..." : (isEditMode ? 'Edit' : `Add`)}
                breadcrumbTitle="Add"
                Actions={Actions}
            />
            <>
                {/* Modals */}
                <DeleteConfirmationDialog open={openDeletePopup} handleClose={handleCloseDeletePopup} handleSubmission={handleDeletion} subheading={"Are you sure you want to delete this portfolio?"} />
            </>
            {isEditMode && isLoading ?
                <Grid container xs={12} display={"flex"} height={"100%"} maxHeight={"85vh"} sx={{ padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" }, width: '100%' }}>
                    <Grid item xs={12} className="skeleton" height={"100%"} width={"100%"} borderRadius={"24px"}>

                    </Grid>
                </Grid> :
                <>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            // height: "calc(100vh - 118px)",
                            height: '100%',
                            maxHeight: { xs: "90%", sm: "93%", md: '85vh' },
                            overflow: "hidden",
                            padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" },
                        }}
                    >
                        <Grid
                            xs={12}
                            item
                            container
                            sx={{
                                backgroundColor: "#FFFAF5",
                                borderRadius: { xs: "24px", sm: "24px" },
                                height: "100%",
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    position: "relative",
                                    backgroundColor: "#ADA2ED",
                                    borderRadius: `24px ${currentStep === 0 ? "0" : "24px"} 24px 24px`,
                                    overflow: "hidden",
                                    height: "100%",
                                    width: { xs: '0%', md: '40%', lg: '40%', xl: '37.9%' },
                                }}
                            >
                                <VerticalScrollableTabs data={portfolio_steps} currentIndex={currentStep} setCurrentIndex={setCurrentStep} setSearchParams={setSearchParams} scrollToTop={scrollToTop} />
                            </Grid>
                            <Grid
                                item
                                container
                                sx={{
                                    width: { xs: "100%", md: '60%', lg: '60%', xl: '62.1%' },
                                    // width: "100%",
                                    padding: { xs: '1rem', md: '2rem', lg: "2.5rem" }
                                }}
                                gap={3}
                                marginTop={0}
                                display={"flex"}
                                alignItems={"flex-start"}
                                alignContent={"space-around"}
                                justifyContent={"space-between"}
                                // padding={"2.5rem"}
                                paddingBottom={0}
                            >
                                <FormikProvider value={formik}>
                                    <Form
                                        onSubmit={(event) => event.preventDefault()}
                                        style={{ width: "100%", height: "90%", maxHeight: matchUpXl ? "calc(100vh - 295px)" : matchUpLg ? "calc(100vh - 290px)" : matchesUpMd ? "calc(100vh - 285px)" : "calc(-220px + 100vh)", overflow: "hidden", paddingBottom: '1rem' }}
                                    >
                                        {!matchesUpMd &&
                                            <Box width={"100%"}>
                                                {portfolio_steps &&
                                                    portfolio_steps?.length > 0 ?
                                                    <>
                                                        <Typography marginInline={"auto"} flexWrap={"wrap"} variant="h6"
                                                            fontSize="1rem"
                                                            color={theme.palette.customColors.g300}
                                                            fontWeight={700} textAlign={"center"} marginBottom={1}>
                                                            <span>{portfolio_steps[currentStep]?.name}
                                                                {/* ({`${currentStep + 1}/${portfolio_steps?.length}`}) */}
                                                            </span>
                                                            <Divider sx={{ marginTop: 1, marginBottom: 2, marginInline: 'auto', maxWidth: '50%' }} />
                                                        </Typography>
                                                    </>
                                                    : ''}
                                            </Box>
                                        }
                                        <PerfectScrollbar
                                            ref={bottomRef}
                                            containerRef={containerRef}
                                            style={{
                                                minHeight: currentStep == 5 ? "56vh" : "60vh",
                                                height: "100%",
                                                width: "98%",
                                                maxHeight: currentStep == 5 ? "calc(-330px + 100vh)" : "calc(100vh - 285px)",
                                                paddingRight: "1rem",
                                                marginTop: 0,
                                                paddingBottom: "1rem",
                                                // paddingLeft: "1rem",
                                                overflow: 'hidden',
                                                marginInline: 'auto'
                                            }}
                                            className="scrollbar-container"
                                            id="scrollable-form"
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                paddingBottom={{ xs: 1, md: currentStep == 0 || currentStep == 7 ? 2 : 0 }}
                                                // sx={{ width: "100%" }}
                                                justifyContent={"space-between"}
                                                columnSpacing={"0.5rem"}
                                            >
                                                {currentStep == 0 && (
                                                    <Grid item container xs={12} spacing={{ xs: 0, md: 1 }}>
                                                        <Grid item container xs={12} md={12} gap={1} sx={{ overflowX: "hidden", marginBottom: "0rem" }}>
                                                            {/* {console.log(formik.errors, "rerrr")} */}
                                                            <Grid height={"min-content"} item xs={12}>
                                                                <CustomInputField name={`startup_name`} label={"STARTUP NAME"} placeholder={"Enter startup name"} value={formik.values?.startup_name} onChange={(e) => formik.setFieldValue(`startup_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                            </Grid>
                                                            <Grid item xs={12} mb={1}>
                                                                <CustomLogoButton label={"Logo"} handleUpdateFile={(file) => { formik.setFieldValue("startup_logo", file) }} name={"startup_logo"} value={formik.values.startup_logo} placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
                                                            </Grid>
                                                            <Grid item container xs={12} spacing={1}>
                                                                <Grid item xs={12} sm={6} gap={0} position="relative">
                                                                    <CustomUrlField
                                                                        label={"STARTUP WEBSITE URL"}
                                                                        name={'startup_website'}
                                                                        // value={formik?.values?.startup_website}
                                                                        placeholder={"Startup Website url"}
                                                                        value={
                                                                            String(formik?.values?.startup_website)?.startsWith("https://")
                                                                                ? String(formik?.values?.startup_website)?.replace("https://", "")
                                                                                : formik?.values?.startup_website

                                                                        }
                                                                        onChange={(evt) => {
                                                                            formik.setFieldValue(`startup_website`, String(evt.target.value)?.startsWith("https://")
                                                                                ? String(evt.target.value)?.replace("https://", "")
                                                                                : evt.target.value)
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomInputField name={`founder_email`} label={<>Founder Email <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>} placeholder={"Enter founder email"} value={formik.values?.founder_email} onChange={(e) => formik.setFieldValue(`founder_email`, e.target.value)} fullWidth />
                                                                </Grid>
                                                                {/* {(!isMobile || !matchesUpMd) && <Grid item xs={12} gap={0} position="relative">
                                                                    <CustomInputField
                                                                        label={"STARTUP WEBSITE URL"}
                                                                        name={'startup_website'}
                                                                        value={formik?.values?.startup_website}
                                                                        placeholder={"Startup Website url"}
                                                                    />
                                                                </Grid>} */}
                                                            </Grid>

                                                            <Grid height={"min-content"} item xs={12}>
                                                                <CustomAutoComplete
                                                                    options={!isLoadingRoundTypes && !isRefetchingRoundTypes && Array.isArray(startup_stages) ? startup_stages?.map(item => ({ label: item?.name, value: item?._id })) : []}
                                                                    label={<>Select Stage <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                                                                    name={"stages"}
                                                                    noOptionsText={!isLoadingRoundTypes && !isRefetchingRoundTypes ? "No Options" : "Searching..."}
                                                                    placeholder="Type a stage name"
                                                                    icon={null}
                                                                    handleSearchChange={handleSearchStagesChanges}
                                                                    handleSelectionChange={handleSelectionStageChange}
                                                                    search={searchStages}
                                                                    renderOption={(props, option) => (
                                                                        <li {...props} style={{ display: 'grid' }}>
                                                                            <span style={{ fontWeight: 500 }}>{option?.label}</span>
                                                                            {/* <p style={{ fontSize: '0.8rem' }}>{option?.description}</p> */}
                                                                            {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                        </li>
                                                                    )}
                                                                    value={currentStages}
                                                                    defaultValue={startup_stages?.find(item => item?._id == formik?.values?.stages)}
                                                                    loading={isRefetchingRoundTypes}
                                                                    handleSearchUpdate={handleSearchUpdateStages}
                                                                />
                                                            </Grid>

                                                        </Grid>

                                                        <Grid item container xs={12} md={12} sx={{ overflowX: "hidden", marginBottom: "0rem", display: 'flex', alignContent: 'flex-start', marginTop: 'auto', paddingTop: matchesUpMd ? "8px" : "0px" }}>
                                                            <Grid item xs={12} paddingBottom={0} paddingRight={1} marginTop={'0rem'} >
                                                                <CustomLabel sx={{ marginTop: "0rem" }}>Select Category <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span> {formik.errors?.category && (
                                                                    <CustomValidationText value={formik.errors?.category} style={{ textTransform: 'none' }} />
                                                                )}</CustomLabel>
                                                            </Grid>
                                                            <FieldArray
                                                                name="category"
                                                                render={arrayHelpers => (
                                                                    <>
                                                                        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 1, justifyContent: 'space-between' }} gap={1}>
                                                                            {Array.isArray(startup_categories) && startup_categories?.map((category, index) => {
                                                                                const isSelected = formik?.values?.category?.includes(category?._id);
                                                                                return (
                                                                                    <Grid
                                                                                        item
                                                                                        xs={5.82}
                                                                                        sm={5.87}
                                                                                        md={5.88}
                                                                                        lg={5.9}
                                                                                        // sm={2.85}
                                                                                        // md={2.5}
                                                                                        // lg={2.95}
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            gap: 0,
                                                                                            background: isSelected ? "#FFFAE6" : "#FFF",
                                                                                            fontSize: "0.875rem",
                                                                                            border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                                                                            borderRadius: "8px",
                                                                                            paddingRight: "1rem",
                                                                                            paddingLeft: "1rem",
                                                                                            paddingBlock: '1.5rem',
                                                                                            // height: matchesUpMd ? "5.5rem" : "2.5rem",
                                                                                            height: "2.5rem",
                                                                                            cursor: "pointer",
                                                                                            textTransform: 'none',
                                                                                            color: 'inherit',
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            formik.setFieldValue("category", category?._id)
                                                                                            setSelectedCategory(category?.name);
                                                                                            formik.setFieldValue("sectors", '');
                                                                                            setCurrentSubCategory('')

                                                                                        }}
                                                                                        key={category?._id}
                                                                                    >


                                                                                        {index === 0 && (
                                                                                            <img
                                                                                                src={laptopScreenIcon}
                                                                                                style={
                                                                                                    // matchesUpMd ? 
                                                                                                    // { height: '2.6rem', width: '2.6rem', marginRight: '0.5rem' } :
                                                                                                    { height: '1.6rem', width: '1.6rem', marginRight: '0.5rem' }
                                                                                                }
                                                                                                alt="icon"
                                                                                            />
                                                                                        )}
                                                                                        {index === 1 && (
                                                                                            <img
                                                                                                src={mobileShoppingIcon}
                                                                                                style={
                                                                                                    // matchesUpMd ? 
                                                                                                    // { height: '2.4rem', width: '2.4rem', marginRight: '0.5rem' } : 
                                                                                                    { height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }
                                                                                                }
                                                                                                alt="icon"
                                                                                            />
                                                                                        )}
                                                                                        {index === 2 && (
                                                                                            <img
                                                                                                src={marketplaceIcon}
                                                                                                style={
                                                                                                    // matchesUpMd ? 
                                                                                                    // { height: '2.4rem', width: '2.4rem', marginRight: '0.5rem' } :
                                                                                                    { height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }
                                                                                                }
                                                                                                alt="icon"
                                                                                            />
                                                                                        )}
                                                                                        {index === 3 && (
                                                                                            <img
                                                                                                src={digitalServiceIcon}
                                                                                                style={
                                                                                                    //     matchesUpMd ? {
                                                                                                    //     height: '2.4rem',
                                                                                                    //     width: '2.4rem',
                                                                                                    //     marginRight: '0.5rem',
                                                                                                    //     transform: 'rotateY(180deg)',
                                                                                                    // } :
                                                                                                    {
                                                                                                        height: '1.4rem',
                                                                                                        width: '1.4rem',
                                                                                                        marginRight: '0.5rem',
                                                                                                        transform: 'rotateY(180deg)',
                                                                                                    }
                                                                                                }
                                                                                                alt="icon"
                                                                                            />
                                                                                        )}

                                                                                        <span> {category?.name} </span>
                                                                                    </Grid>
                                                                                );
                                                                            })}
                                                                        </Grid>
                                                                        {formik.errors?.category && (
                                                                            <CustomValidationText value={formik.errors?.category} style={{ textTransform: 'none' }} />
                                                                        )}
                                                                    </>
                                                                )}
                                                            />

                                                            <Grid item xs={12} mt={matchesUpMd ? '1rem' : "1rem"}>
                                                                <CustomAutoComplete
                                                                    options={!isLoadingSubCategories && !isRefetchingSubCategories && Array.isArray(startup_sub_categories) ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?._id, description: item?.description })) : []}
                                                                    label={<>Select Sector <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                                                                    name={"sectors"}
                                                                    noOptionsText={!isLoadingSubCategories && !isRefetchingSubCategories ? "No Options" : "Searching..."}
                                                                    placeholder="Type a sector name"
                                                                    icon={null}
                                                                    handleSearchChange={handleSearchSubCategoryChange}
                                                                    handleSelectionChange={handleSelectionSubCategoryChange}
                                                                    search={searchSubCategory}
                                                                    renderOption={(props, option) => (
                                                                        <li {...props} style={{ display: 'grid' }}>
                                                                            <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                                            <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                                                            {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                        </li>
                                                                    )}
                                                                    value={currentSubCategory}
                                                                    defaultValue={startup_sub_categories?.find(item => item?._id == formik?.values?.sectors)}
                                                                    loading={isRefetchingSubCategories}
                                                                    handleSearchUpdate={handleSearchUpdateSubCategory}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <CustomAutoComplete
                                                                    options={!isLoadingBusinessModel && !isRefetchingBusinessModel && Array.isArray(availableOptionsBusinessModel) ? availableOptionsBusinessModel?.map(item => ({ label: item?.name, value: item?._id, description: item?.description })) : []}
                                                                    label={<>SELECT BUSINESS MODEL (MULTI SELECT) <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                                                                    labelStyle={{ whiteSpace: 'nowrap' }}
                                                                    name={"business_model"}
                                                                    multiple={true}
                                                                    noOptionsText={!isLoadingBusinessModel && !isRefetchingBusinessModel ? "No Options" : "Searching..."}
                                                                    placeholder="Business model"
                                                                    icon={null}
                                                                    handleSearchChange={handleSearchBusinessModelChange}
                                                                    handleSelectionChange={handleSelectionBusinessModelChange}
                                                                    search={searchBusinessModel}
                                                                    renderOption={(props, option) => (
                                                                        <li {...props} style={{ display: 'grid' }}>
                                                                            <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                                            <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                                                            {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                        </li>
                                                                    )}
                                                                    value={currentBusinessModel || []}
                                                                    defaultValue={startup_business_model?.filter(item => item?._id == formik?.values?.business_model)}
                                                                    loading={isRefetchingBusinessModel}
                                                                    handleSearchUpdate={handleSearchUpdateBusinessModel}
                                                                />
                                                            </Grid>
                                                            {/* {(isMobile || matchesUpMd) && */}

                                                            {/* } */}
                                                        </Grid>


                                                    </Grid>
                                                )}
                                                {currentStep == 1 && (
                                                    <>
                                                        <Grid item xs={12} marginTop={0} marginBottom={1.5}>
                                                            <CustomLabel
                                                                sx={{
                                                                    fontSize: "1rem !important",
                                                                    fontWeight: "500 !important",
                                                                }}
                                                            >
                                                                {openForm ? (selectedItem ? "EDIT ROUND DETAILS" : "ADD ROUND DETAILS") : "ROUND DETAILS"}
                                                            </CustomLabel>
                                                        </Grid>
                                                        {!openForm ? <>
                                                            <Grid item xs={12} mb={1}>
                                                                {isPortfolioDetailsRefetching ? <div className="skeleton" style={{ height: 300, width: "100%" }} /> : (formik?.values?.rounds && Array.isArray(formik?.values?.rounds) && formik?.values?.rounds?.length > 0 ? formik?.values?.rounds?.sort((a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0))?.map(((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginBottom: 2 }}>
                                                                                <CardHeader title={item?.round_name ? item?.round_name : "Round 1"} index={index} item={item} handleSelected={handleSelected} />
                                                                                {/* {console.log("info", item)} */}

                                                                                <Grid item container display={"flex"} alignItems={"center"} justifyContent={"space-between"} xs={12} gap={1}>
                                                                                    <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}> <Typography fontSize={'1.125rem'} fontWeight={700} color={theme.palette.customColors.g300}>
                                                                                        {item?.formatted_investment_date ? item?.formatted_investment_date : "-"}
                                                                                    </Typography>
                                                                                        <Typography fontSize={"0.75rem"}>
                                                                                            Investment Date
                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    {/* <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}>
                                                                                        <Typography fontSize={'1.125rem'} fontWeight={700} color={theme.palette.customColors.g300}>
                                                                                            {item?.security_type && item?.security_info[0]?.name ? item?.security_info[0]?.name : "-"}
                                                                                        </Typography>
                                                                                        <Typography fontSize={"0.75rem"}>
                                                                                            Security Type
                                                                                        </Typography>
                                                                                    </Grid> */}

                                                                                    {/* <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}>
                                                                                        <Typography fontSize={'1.125rem'} fontWeight={700} color={theme.palette.customColors.g300}>
                                                                                            {item?.syndicate_name ? item?.syndicate_name : '-'}
                                                                                        </Typography>
                                                                                        <Typography fontSize={"0.75rem"}>
                                                                                            Platform/Syndicate Name
                                                                                        </Typography>
                                                                                    </Grid> */}

                                                                                    <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}>
                                                                                        <Typography fontSize={'1.125rem'} fontWeight={700} color={theme.palette.customColors.g300}>
                                                                                            {item?.investment_amount_ref ? item?.investment_amount_ref : "-"}
                                                                                        </Typography>
                                                                                        <Typography fontSize={"0.75rem"}>
                                                                                            Investment Amount
                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}>
                                                                                        <Typography fontSize={'1.125rem'} fontWeight={700} color={theme.palette.customColors.g300}>
                                                                                            {item?.per_share_unit_ref ? item?.per_share_unit_ref : '-'}
                                                                                        </Typography>
                                                                                        <Typography fontSize={"0.75rem"}>
                                                                                            Value Per Share/Unit
                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}>
                                                                                        <Typography fontSize={'1.125rem'} fontWeight={700} color={theme.palette.customColors.g300}>
                                                                                            {item?.current_value_per_unit_ref ? item?.current_value_per_unit_ref : '-'}
                                                                                        </Typography>
                                                                                        <Typography fontSize={"0.75rem"}>
                                                                                            Current Value Per Share/Unit
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}>
                                                                                        <Typography fontSize={'1.125rem'} fontWeight={700} color={theme.palette.customColors.g300}>
                                                                                            {item?.no_of_units ? item?.no_of_units : "-"}
                                                                                        </Typography>
                                                                                        <Typography fontSize={"0.75rem"}>
                                                                                            No. of Shares/Units
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Card>
                                                                        </>
                                                                    )
                                                                })) : <>
                                                                    <NoData title={"No Round Available"} subTitle={"It seems like there are no round data available at the moment."} Actions={RoundActions} />
                                                                </>)}
                                                            </Grid>
                                                        </> :
                                                            <>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomInputField name={`round_name`} label={"Round NAME"} placeholder={"Enter round name"} value={formik.values?.round_name} onChange={(e) => formik.setFieldValue(`round_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                                </Grid>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomDatePicker
                                                                        fullWidth={true}
                                                                        name="investment_date"
                                                                        label="Investment Date"
                                                                        value={moment(formik.values?.investment_date)}
                                                                        placeholder="Select Date"
                                                                        sx={{
                                                                            background: "#fff",
                                                                            borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                            borderRadius: '4px',
                                                                            margin: 0,
                                                                            width: "100%",
                                                                            padding: "14px 14px",
                                                                        }}
                                                                        format={"DD/MM/YYYY"}
                                                                    />
                                                                </Grid>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomInputField name={`syndicate_name`} label={<> Platform/Syndicate Name <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span> </>} placeholder={"Enter syndicate name"} value={formik.values?.syndicate_name} onChange={(e) => formik.setFieldValue(`syndicate_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                                </Grid>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomAutoComplete
                                                                        options={!isLoadingSecurityTypes && !isRefetchingSecurityTypes && Array.isArray(security_types) ? security_types?.map(item => ({ label: item?.name, value: item?._id })) : []}
                                                                        label={<>Select Security Type <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                                                                        name={"security_type"}
                                                                        noOptionsText={!isLoadingSecurityTypes && !isRefetchingSecurityTypes ? "No Options" : "Searching..."}
                                                                        placeholder="Type a security"
                                                                        icon={null}
                                                                        handleSearchChange={handleSearchSecurityTypeChanges}
                                                                        handleSelectionChange={handleSelectionSecurityTypeChange}
                                                                        search={searchSecurityType}
                                                                        renderOption={(props, option) => (
                                                                            <li {...props} style={{ display: 'grid' }}>
                                                                                <span style={{ fontWeight: 500 }}>{option?.label}</span>
                                                                                {/* <p style={{ fontSize: '0.8rem' }}>{option?.description}</p> */}
                                                                                {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                            </li>
                                                                        )}
                                                                        value={currentSecurityType}
                                                                        defaultValue={security_types?.find(item => item?._id == formik?.values?.security_type)}
                                                                        loading={isRefetchingSecurityTypes}
                                                                        handleSearchUpdate={handleSearchUpdateSecurityType}

                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} marginTop={1.5} marginBottom={1.5}>
                                                                    <CustomLabel
                                                                        sx={{
                                                                            fontSize: "1rem !important",
                                                                            fontWeight: "500 !important",
                                                                        }}
                                                                    >
                                                                        Investment Details
                                                                    </CustomLabel>
                                                                </Grid>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomInputField name={`no_of_units`} label={"No. of Shares/Units"} placeholder={"Enter units"} value={formik.values?.no_of_units} onChange={(e) => formik.setFieldValue(`no_of_units`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                                </Grid>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomInputField name={`per_share_unit`} label={"Value Per Share/Unit"} placeholder={"Enter value per units"} value={formik.values?.per_share_unit} onChange={(e) => formik.setFieldValue(`per_share_unit`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                                </Grid>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomInputField name={`investment_amount`} disabled label={"Investment Amount"} placeholder={"Enter investment amount"}
                                                                        value={formik.values?.no_of_units * formik.values?.per_share_unit || 0}
                                                                        // value={formik.values?.investment_amount}
                                                                        onChange={(e) => formik.setFieldValue(`investment_amount`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                                </Grid>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomInputField name={`current_value_per_unit`} label={"Current Value Per Share/Unit"} placeholder={"Enter current value per units"} value={formik.values?.current_value_per_unit} onChange={(e) => formik.setFieldValue(`current_value_per_unit`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                                </Grid>
                                                                <Grid height={"min-content"} item xs={12} sm={6}>
                                                                    <CustomInputField name={`current_investment_value`} disabled label={"Current Investment Value"} placeholder={"Enter current investment value"}
                                                                        //  value={formik.values?.current_investment_value} 
                                                                        value={formik.values?.no_of_units * formik?.values?.current_value_per_unit || 0}
                                                                        onChange={(e) => formik.setFieldValue(`current_investment_value`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                                </Grid>
                                                                <Grid item xs={12} marginTop={1.5} marginBottom={0} display={"flex"}
                                                                    justifyContent={"space-between"}
                                                                    alignItems={"center"}
                                                                // rowGap={1}
                                                                >
                                                                    <CustomLabel
                                                                        sx={{
                                                                            fontSize: "1rem !important",
                                                                            fontWeight: "500 !important",
                                                                        }}
                                                                    >
                                                                        Documents
                                                                    </CustomLabel>
                                                                    <WHITEBG
                                                                        sx={{
                                                                            border: `1px solid ${theme.palette.customColors.g100}`,
                                                                            borderRadius: "8px",
                                                                        }}
                                                                        onClick={handleAddDocument}
                                                                    >
                                                                        Add Document
                                                                    </WHITEBG>
                                                                </Grid>
                                                                <Grid item container gap={1} marginTop={2} paddingBottom={3}>
                                                                    <FieldArray
                                                                        name="documents"
                                                                        key={"documents"}
                                                                        render={(arrayHelpers) => (
                                                                            <>
                                                                                {formik.values?.documents &&
                                                                                    formik.values?.documents?.length > 0 &&
                                                                                    formik.values?.documents?.map(
                                                                                        (document, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <Grid
                                                                                                        item
                                                                                                        xs={12}
                                                                                                        display={"flex"}
                                                                                                        alignItems={"center"}
                                                                                                        justifyContent={"space-between"}
                                                                                                        spacing={1}
                                                                                                        position={"relative"}
                                                                                                        key={index}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            item
                                                                                                            xs={12}
                                                                                                            display={"flex"}
                                                                                                            alignItems={"center"}
                                                                                                            justifyContent={"space-between"}
                                                                                                            sx={{
                                                                                                                background:
                                                                                                                    theme?.palette.customColors
                                                                                                                        ?.whiteBG,
                                                                                                                padding: "0.8rem 1rem",
                                                                                                                borderRadius: "8px",
                                                                                                                border: `1px solid ${theme.palette.customColors.g75}`,
                                                                                                            }}
                                                                                                        >
                                                                                                            <Grid
                                                                                                                display={"flex"}
                                                                                                                alignItems={"center"}
                                                                                                                gap={1}
                                                                                                            >
                                                                                                                {document?.editable ? (
                                                                                                                    <CustomInputField
                                                                                                                        sx={{
                                                                                                                            margin: 0,
                                                                                                                            "& .MuiFilledInput-input": {
                                                                                                                                padding:
                                                                                                                                    "8px 15px !important",
                                                                                                                            },
                                                                                                                        }}
                                                                                                                        name={`documents.${index}.title`}
                                                                                                                        value={document?.title}
                                                                                                                        onChange={(e) =>
                                                                                                                            formik.setFieldValue(
                                                                                                                                `documents.${index}.title`,
                                                                                                                                e.target.value
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />
                                                                                                                    // !formik.values?.documents?.find(item => item?.title == "Pitch Deck Round")
                                                                                                                ) :
                                                                                                                    (
                                                                                                                        <Typography variant="body1">
                                                                                                                            {document?.title}
                                                                                                                        </Typography>
                                                                                                                    )
                                                                                                                    //  :(
                                                                                                                    //   <CustomValidationText value="Pitch Deck Round is already exists"/>
                                                                                                                    //  )) 
                                                                                                                }
                                                                                                                {document?.editable ? (
                                                                                                                    <>
                                                                                                                        {<Done
                                                                                                                            sx={{ cursor: "pointer" }}
                                                                                                                            onClick={async () => {
                                                                                                                                // {
                                                                                                                                //   document?._id && await updateRoundDocumentTitleAsync({
                                                                                                                                //     "document_id": document?._id,
                                                                                                                                //     "title": document?.title
                                                                                                                                //   })
                                                                                                                                // }
                                                                                                                                formik.setFieldValue(
                                                                                                                                    `documents.${index}.editable`,
                                                                                                                                    false
                                                                                                                                );
                                                                                                                            }}
                                                                                                                        />}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <EditIcon
                                                                                                                        style={{
                                                                                                                            cursor: "pointer",
                                                                                                                            width: 20,
                                                                                                                            height: 20,
                                                                                                                        }}
                                                                                                                        onClick={() => {
                                                                                                                            formik.setFieldValue(
                                                                                                                                `documents.${index}.editable`,
                                                                                                                                true
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )}
                                                                                                            </Grid>

                                                                                                            {/* {console.log("ffffff", formik.values?.documents)} */}
                                                                                                            <Grid
                                                                                                                sx={{
                                                                                                                    display: "flex",
                                                                                                                    alignItems: "center",
                                                                                                                    gap: "1rem",

                                                                                                                }}
                                                                                                            >
                                                                                                                {(document?.document || document?._id) && (
                                                                                                                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                                                                                        <img
                                                                                                                            style={{ width: "36px", height: '36px' }}
                                                                                                                            src={getFileIcon(
                                                                                                                                document?.document?.name || document?.filename)}
                                                                                                                        />
                                                                                                                        {document?.document && <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} onClick={() => {
                                                                                                                            formik.setFieldValue(
                                                                                                                                `documents.${index}.document`,
                                                                                                                                null
                                                                                                                            );
                                                                                                                        }} />}
                                                                                                                    </Box>
                                                                                                                )}
                                                                                                                {/* {console.log(document?.document_type, "feferfefef")} */}
                                                                                                                {(!document?._id) &&
                                                                                                                    <WHITEBG
                                                                                                                        component="label"
                                                                                                                        role={undefined}
                                                                                                                        tabIndex={-1}
                                                                                                                        sx={{
                                                                                                                            minWidth: "0 !important",
                                                                                                                            border: `1px solid #D0D5DD !important`,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <UploadDocumentsIcon
                                                                                                                            style={{
                                                                                                                                width: 20,
                                                                                                                                height: 20,
                                                                                                                                stroke: "#344054"
                                                                                                                            }}
                                                                                                                        />

                                                                                                                        <VisuallyHiddenInput
                                                                                                                            accept=".pdf,.png,.jpeg,.jpg,.mp4,.mov,.xlsx,.csv,.xls,.doc,.docx"
                                                                                                                            type="file"
                                                                                                                            name={`documents.${index}.document`}
                                                                                                                            id={`documents.${index}.document`}
                                                                                                                            onChange={(e) =>
                                                                                                                                handleFileChange(e, index)
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </WHITEBG>
                                                                                                                }

                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        {/* formik.values?.documents?.length > 1 && ( */}
                                                                                                        {
                                                                                                            matchesUpMd ?
                                                                                                                <Grid item ml={1}>
                                                                                                                    {/* <Button sx={{ padding: '14px 15px', background: "white", marginBlock: "8px 16px", border: `1px solid ${theme?.palette?.customColors?.g75}` }}><DeleteIcon /></Button> */}
                                                                                                                    <BLUEBORDER
                                                                                                                        sx={{
                                                                                                                            padding: "18px 15px",
                                                                                                                            background: "white",
                                                                                                                            margin: 0,
                                                                                                                            border: `1px solid ${theme?.palette?.customColors?.g75}`,
                                                                                                                        }}
                                                                                                                        onClick={() => {
                                                                                                                            if (window.confirm("Are you sure you want to delete this document?") == true) {
                                                                                                                                if (document?._id) {
                                                                                                                                    deletePortfolioRoundDocumentAsync({ document_id: document?._id, portfolio_id, round_id: selectedItem?._id }, {
                                                                                                                                        onSuccess: (succ) => {

                                                                                                                                            arrayHelpers?.remove(index);
                                                                                                                                            toast.success(
                                                                                                                                                <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                                                                                                {
                                                                                                                                                    duration: 2000,
                                                                                                                                                    position: "top-right",
                                                                                                                                                }
                                                                                                                                            );
                                                                                                                                            // refetchRoundDetails();
                                                                                                                                        },
                                                                                                                                        onError: (err) => {
                                                                                                                                            toast.error(
                                                                                                                                                <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                                                                                                {
                                                                                                                                                    duration: 2000,
                                                                                                                                                    position: "top-right",
                                                                                                                                                }
                                                                                                                                            );
                                                                                                                                        }
                                                                                                                                    });
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    arrayHelpers?.remove(index)
                                                                                                                                    // console.log("removed")
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                return;
                                                                                                                            }

                                                                                                                        }
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <DeleteIcon />
                                                                                                                    </BLUEBORDER>
                                                                                                                </Grid>
                                                                                                                : <img style={{ position: 'absolute', top: "-0.5rem", right: "-0.5rem", height: "1.2rem", width: "1.2rem", cursor: 'pointer' }} src={ClearIcon} onClick={() => {
                                                                                                                    if (window.confirm("Are you sure you want to delete this document?") == true) {
                                                                                                                        if (document?._id) {
                                                                                                                            deletePortfolioRoundDocumentAsync({ document_id: document?._id, portfolio_id, round_id: selectedItem?._id }, {
                                                                                                                                onSuccess: (succ) => {

                                                                                                                                    arrayHelpers?.remove(index);
                                                                                                                                    toast.success(
                                                                                                                                        <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                                                                                        {
                                                                                                                                            duration: 2000,
                                                                                                                                            position: "top-right",
                                                                                                                                        }
                                                                                                                                    );
                                                                                                                                    // refetchRoundDetails();
                                                                                                                                },
                                                                                                                                onError: (err) => {
                                                                                                                                    toast.error(
                                                                                                                                        <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                                                                                        {
                                                                                                                                            duration: 2000,
                                                                                                                                            position: "top-right",
                                                                                                                                        }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            });
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            arrayHelpers?.remove(index)
                                                                                                                            // console.log("removed")
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        return;
                                                                                                                    }

                                                                                                                }
                                                                                                                } />
                                                                                                        }
                                                                                                        {/* ) */}
                                                                                                    </Grid>
                                                                                                    {
                                                                                                        formik.errors?.documents?.[index]?.document && formik.touched.documents?.[index]?.document && (
                                                                                                            <CustomValidationText value={formik.errors?.documents[index]?.document} />
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                    />
                                                                    {/* {
                                                                        formik.values?.documents?.filter(item => item?.title == "Pitch Deck Round")?.length > 1 && (
                                                                            <CustomValidationText value="Pitch Deck Round is already exists" />
                                                                        )

                                                                    } */}
                                                                </Grid>
                                                            </>}

                                                    </>
                                                )}

                                            </Grid>
                                        </PerfectScrollbar>
                                    </Form>
                                </FormikProvider>
                                <Grid
                                    item
                                    container
                                    display={"flex"}
                                    xs={12}
                                    sm={12}
                                    height={"min-content"}
                                    gap={"1.5rem"}
                                    justifyContent={"center"}
                                    padding={"0 0rem 1rem 0rem"}
                                >
                                    {/* {currentStep !== 0 && <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                                        <BLUEBORDER
                                            sx={{
                                                textTransform: "none",
                                                padding: 2,
                                                borderWidth: 1,
                                                borderRadius: "8px",
                                            }}
                                            type="no usetr"
                                            onClick={() => {
                                                if (currentStep === 0) {
                                                    // navigate(-1);
                                                } else {
                                                    setCurrentStep(currentStep - 1);
                                                }
                                                window.document
                                                    .getElementById("scrollable-form")
                                                    .scrollTo(0, 0);
                                                // window.scrollTo(0, 0);
                                            }}
                                            fullWidth
                                        >
                                            Back
                                        </BLUEBORDER>
                                    </Grid>} */}
                                    {/* {console.log(handleFormSubmit,"handleFormSubmithandleFormSubmit")} */}



                                    {<Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                                        <BLUEBORDER
                                            sx={{
                                                textTransform: "none",
                                                padding: 2,
                                                borderWidth: 1,
                                                borderRadius: "8px",
                                            }}
                                            onClick={(e) => {
                                                if (openForm) {
                                                    setOpenForm(false);
                                                }
                                                else {
                                                    navigate("/portfolio");
                                                }
                                            }}
                                            // disabled={currentStep === 5 && formik.values?.documents?.filter(item => item?.t}
                                            // onClick={() => { setCurrentStep(currentStep + 1) }}
                                            fullWidth
                                        >
                                            Close
                                        </BLUEBORDER>
                                    </Grid>}
                                    {<Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                                        <RoundActions fullWidth />
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </ThemeBGWrapper>
    );
};

export default AddPortfolio;