import React from 'react'
import AsyncTableListing from '../../ui-components/AsyncTableListing'
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader'
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper'
import { useTheme } from '@emotion/react'
import { ReactComponent as RoundDetailsIcon } from "assets/rounds_icons/round_details_icon.svg"
import { Autocomplete, Box, capitalize, Chip, Dialog, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import CustomTabs from '../../ui-components/CustomTabs'
import { TabContext } from '@mui/lab'
import { Add, CancelRounded, Description, DoneRounded, FiberNew, FiberNewRounded, FilterAltOutlined, FilterAltRounded, LanguageOutlined, LinkedIn, More, NewReleasesOutlined, Notes, PublishedWithChanges, RateReview, RocketLaunch, Search, ThumbDown, ThumbUp } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { amountInWords, capitalizedSentence, convertCurrencyAmountRange, getFileIcon } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
import CustomInputField from 'ui-components/CustomInputField'
import { B300, B400 } from 'ui-components/CustomButton'
import { ReactComponent as MoreOptionsIcon } from "assets/rounds_icons/more_solid_icon.svg";
import Cookies from 'js-cookie'
import { FetchAllCategories, FetchAllStages, FetchAllSubCategories } from 'api/utils'
import { UpdateStartupStatus } from 'api/backrr_startups'
import { useSelector } from 'react-redux'
import { FetchAllStartupsListing, FetchInterestFeedbackStatus, InviteStartupPOST, SaveFeedback, SaveInterest } from 'api/backrr_startups'
import { FetchAllInvestorList, UpdateInvestorStatus } from 'api/backrr_investor'
import ModalComponent from 'ui-components/ModalComponent'
import ImportInvestorPopup from './popups/ImportInvestorPopup'
import UpdateStatus from './popups/UpdateStatus'
import toast from 'react-hot-toast'
const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    "&": {
      padding: "3rem"
    }

  }

}));

const AllInvestor = ({ setCount, count, limit, setLimit, page, setPage, search, category, searchStatus, sector, reset, stage, queryParams, openImportInvestorModal, setOpenImportInvestorModal }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  // console.log(queryParams,"queryParams")
  const parsedCookieForQueryParams = Cookies.get("backrr_investors_query") ? JSON.parse(Cookies.get("backrr_investors_query")) : {}
  // const [queryParams, setQueryParams] = React.useState(parsedCookieForQueryParams);
  const parsedCookieForStatus = Cookies.get("backrr_investors_status") || "all"
  const [value, setValue] = React.useState(parsedCookieForStatus || "all")
  // const [search, setSearch] = React.useState("" || Cookies.get("search_backrr_investors"));
  // const [reset, setReset] = React.useState(false);
  const handleImportInvestorClose = () => {
    setOpenImportInvestorModal(false)
  }
  const handleImportInvestorSubmit = () => {
    setOpenImportInvestorModal(false)
  }
  const [allInvestorData, setAllInvestorData] = React.useState(null);



  const { mutateAsync: fetchAllInvestor, isLoading: isLoadingfetchAllInvestor, status: fetchAllInvestorStatus, error: fetchAllInvestorError } = FetchAllInvestorList();
  const { mutateAsync: fetchStatusInvestor } = UpdateInvestorStatus();
  const { data: startup_stages, isRefetching: isRefetchingAllStages, isLoading: isLoadingAllStages, refetch: refetchAllStages } = FetchAllStages();
  const { data: startup_categories, isRefetching: isRefetchingAllCategories, isLoading: isLoadingAllCategories, refetch: refetchAllCategories } = FetchAllCategories();
  const { data: startup_sub_categories, isRefetching: isRefetchingAllSubcategories, isLoading: isLoadingAllSubcategories, refetch: refetchAllSubcategories } = FetchAllSubCategories({ category: queryParams?.category });
  const refetchListing = () => {
    // Get the _id for each based on the selected value
    const selectedStageId = queryParams?.stage
      ? startup_stages?.find((item) => item?.name === queryParams?.stage)?._id
      : null;
    const selectedCategoryId = category?.value
      ? startup_categories?.find((item) => item?.name === category?.value)?._id
      : null;
    const selectedSectorId = sector?.value
      ? startup_sub_categories?.find((item) => item?.sub_category === sector?.value)?._id
      : null;
    // console.log(selectedSectorId, "sector")
    // console.log(selectedCategoryId, "sector")
    // console.log(selectedStageId, "sector")

    // Construct the payload
    const payload = {
      ...(selectedStageId && { "select_stages": [selectedStageId] }),
      ...(selectedCategoryId && { "category": [selectedCategoryId] }),
      ...(selectedSectorId && { "sub_sector": [selectedSectorId] }),
    };

    // Call the API with the constructed payload
    fetchAllInvestor(
      {
        page,
        limit,
        name: search,
        ...payload,
      },
      {
        onSuccess: (res) => {
          // setLoadingData(false)
          setAllInvestorData(res.data.data)
          setCount(res?.data?.totalCount)
        },
        onError: (error) => {
          // setLoadingData(false)
          console.error("API Error:", error);
        },
      }
    )
  };

  // console.log(allInvestorData, "allInvestorData")

  React.useEffect(() => {
    if (searchStatus)
      refetchListing()

  }, [searchStatus])

  React.useEffect(() => {
    // let payload = {
    //     "select_stages": ["Early Stage"],
    //     "category": ["Services"],
    //     "sub_sector": ["3D Tech"]
    // };

    // fetchAllInvestor({
    //   "page": page,
    //   "limit": limit
    // }, {
    //     onSuccess: (res) => {
    //       // setLoadingData(false);
    //       setAllInvestorData(res.data.data)
    //       setCount(res?.data?.totalCount);
    //     },
    //     onError: () => {
    //       // setLoadingData(false);
    //     }
    //   })

    refetchListing();

  }, [page, limit])
  const AllColumns = [
    { Header: 'Investor Name', accessor: 'name', minWidth: 200, align: 'start', fixed: true },
    { Header: 'Type', accessor: 'formatted_investor_type', minWidth: 100, align: 'center' },
    { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
    { Header: 'Stage', accessor: 'focused_stage', minWidth: 150, align: 'center' },
    { Header: 'Categories', accessor: 'focused_categories', minWidth: 150, align: 'center', },
    { Header: 'Sectors', accessor: 'focused_sectors', minWidth: 150, align: 'center', },
    { Header: 'Business Models', accessor: 'focused_business_models', minWidth: 200, align: 'center', },
    { Header: 'Created', accessor: 'createdAt', minWidth: 100, align: 'center', },
    { Header: 'Cheque Size', accessor: 'cheque_size', minWidth: 150, align: 'center' },
    { Header: 'Status', accessor: 'investor_status', minWidth: 100, align: 'center' },
    { Header: 'Actions', accessor: 'actions', minWidth: 100, align: 'center' },

  ];


  const StatusColumn = ({ row }) => {
    const investor_id = row?.original?._id;
    const adminProfileView = row?.original?.admin_profile_view;
    const [openStatus, setOpenStatus] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState(false);

    const handleOpenStatusModal = ({ row }) => {
      // console.log(row,"row")
      setCurrentRow(row?.original);
      setOpenStatus(true);
    };

    const handleCloseStatusModal = () => {
      setOpenStatus(false);
    };

    const handleUpdateStatus = ({ payload = {} }) => {
      fetchStatusInvestor(
        {
          payload: {
            ...payload, // Adjust payload if needed
          },
          investor_id: investor_id,
        },
        {
          onSuccess: (res) => {
            toast.success(res?.data?.message);
            setOpenStatus(false);
            refetchListing();
          },
          onError: (err) => {
            console.error("Error moving to shortlisted:", err);
          },
        }
      );
    };

    return (
      <>
        <Dialog
          open={Boolean(openStatus)}
          onClose={handleCloseStatusModal}
          aria-labelledby="update-status-dialog"
          sx={{
            "& > .MuiDialog-container > .MuiPaper-root": { minWidth: 400 },
          }}
        >
          <UpdateStatus
            currentRow={currentRow}
            handleClose={handleCloseStatusModal}
            handleSubmit={handleUpdateStatus}
            isLoading={false}
            open={Boolean(openStatus)}
          />
        </Dialog>
        <Box>
          <Typography
            sx={{
              fontSize: "0.8rem",
              bgcolor: adminProfileView ? "#D2F2E7" : "#FDECEA",
              color: adminProfileView ? "#1B8662" : "#B00020",
              fontWeight: 700,
              padding: "6px 8px",
              borderRadius: "4px",
              textTransform: "uppercase",
              lineHeight: "10px",
              ":hover": { bgcolor: adminProfileView ? "#C8EEDC" : "#FCD3D3" },
              cursor: "pointer",
            }}
            onClick={() => handleOpenStatusModal({ row })}
          >
            {adminProfileView ? "Active" : "Inactive"}
          </Typography>
        </Box>
      </>
    );
  };

  const RowActions = ({ row, iconsColor = theme.palette.customColors.indigo700, index }) => {
    const investor_id = row?.original?._id;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, index) => {
      setAnchorEl(event.currentTarget);
      // setCurrentIndex(index)
      // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
      // console.log(event,"event")
      setAnchorEl(null);
      // setCurrentIndex(null);
    };

    return (
      <>
        <IconButton
          sx={{ borderRadius: "4px" }}
          id={`more-details-button-${index}`}
          size="small"
          aria-controls={openMenu ? 'more-details-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={(e) => handleClickMenu(e)}
          centerRipple={false}
        >
          <MoreOptionsIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openMenu}
          aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : 'false'}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              padding: "12px",
              borderRadius: "8px",
              "& > ul.MuiList-root": { padding: "0 !important" },
              bgcolor: theme.palette.customColors.g400,
              color: theme.palette.customColors.whiteBG,
              // '&::before': {
              //     content: '""',
              //     display: 'block',
              //     position: 'absolute',
              //     top: 1,
              //     right: 5,
              //     width: 10,
              //     height: 10,
              //     bgcolor: theme.palette.customColors.g900,
              //     transform: 'translateY(-50%) rotate(45deg)',
              //     zIndex: 0,
              // },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        >
          {row?.original?.backrr_base_url && row?.original?.backrr_base_url != null && (String(row?.original?.backrr_base_url)?.length > 0) && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => window.open(row?.original?.backrr_base_url)}>
            View Public Profile
          </MenuItem>}
          <MenuItem disableRipple sx={{ borderBottom: `0px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => {
            if (row?.original?._id) {
              Cookies.set("backrr_investor_id", row?.original?._id);

              navigate(`/backrr_investors/edit`)
            }
          }}>
            Edit Profile
          </MenuItem>
        </Menu>
      </>
    );
  };


  const useFormattedData = (transformedData) => {
    return React.useMemo(() => {
      return transformedData && transformedData?.length > 0
        ? transformedData?.map((item) => {
          const isAngelInvestor = ["angel", "super angel"].includes(
            String(item?.investor_type).replaceAll("_", " ").toLowerCase()
          )? true : false;
          return ({
            ...item,
            investor_status: <StatusColumn row={{ original: item }} />,
            formatted_investor_type: item.investor_type
              ? String(item.investor_type)
                .replaceAll("_", " ")
                .toLowerCase()
                .split(" ")
                .map((word) => {
                  // Capitalize "VC" and "Micro VC" correctly
                  if (word.toLowerCase() === "vc") return "VC";
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ")
              : '-',
            image: isAngelInvestor ? item?.custom_profile_picture_url : item?.website_upload_url,
            name: isAngelInvestor
              ? `${item?.personal_information?.first_name || ""} ${item?.personal_information?.last_name || ""}`.trim()
              : item?.personal_information?.organization_name,

            location: item?.personal_information?.city_id?.name && item?.personal_information?.country_id?.name ? `${item?.personal_information?.city_id?.name}, ${item?.personal_information?.country_id?.name}` : "-",
            focused_stage: item?.preferences?.startup_stages && item?.preferences?.startup_stages?.length > 0
              ? item?.preferences?.startup_stages?.length > 1
                ?
                <Tooltip title={item?.preferences?.startup_stages?.map((item) => <span> {item?.name} <br /></span>)}>
                  <Chip style={{ borderRadius: '4px' }} label={item?.preferences?.startup_stages[0]?.name + " + " + String(item?.preferences?.startup_stages?.length - 1)} />
                </Tooltip>
                : item?.preferences?.startup_stages[0]?.name : '-',
            focused_categories: item?.preferences?.category_id && item?.preferences?.category_id?.length > 0
              ? item?.preferences?.category_id?.length > 1
                ?
                <Tooltip title={item?.preferences?.category_id?.map((item) => <span> {item?.name} <br /></span>)}>
                  <Chip style={{ borderRadius: '4px' }} label={item?.preferences?.category_id[0]?.name + " + " + String(item?.preferences?.category_id?.length - 1)} />
                </Tooltip>
                : item?.preferences?.category_id[0]?.name : '-',
            focused_sectors: item?.preferences?.sector_type === "All" ? "Sector Agnostic" : item?.preferences?.preferred_sectors && item?.preferences?.preferred_sectors?.length > 0
              ? item?.preferences?.preferred_sectors?.length > 1
                ?
                <Tooltip title={item?.preferences?.preferred_sectors?.map((item) => <span> {item?.name} <br /></span>)}>
                  <Chip style={{ borderRadius: '4px' }} label={item?.preferences?.preferred_sectors[0]?.name + " + " + String(item?.preferences?.preferred_sectors?.length - 1)} />
                </Tooltip>
                : item?.preferences?.preferred_sectors[0]?.name : '-',
            focused_business_models: item?.preferences?.business_model_type === "All" ? "All Selected" : item?.preferences?.business_model_id && item?.preferences?.business_model_id?.length > 0
              ? item?.preferences?.business_model_id?.length > 1
                ?
                <Tooltip title={item?.preferences?.business_model_id?.map((item) => <span> {item?.name} <br /></span>)}>
                  <Chip style={{ borderRadius: '4px' }} label={item?.preferences?.business_model_id[0]?.name + " + " + String(item?.preferences?.business_model_id?.length - 1)} />
                </Tooltip>
                : item?.preferences?.business_model_id[0]?.name : '-',
            // cheque_size: 
            // `${ typeof item?.investment_size_preference?.min_amount === "number" ?
            //   (item?.investment_size_preference?.currency === "INR"
            //    ? "₹" :
            //     item?.investment_size_preference?.currency === "USD" 
            //     ? "$" : 
            //     "") : ""}${convertCurrencyAmountRange(item?.investment_size_preference?.min_amount,item?.investment_size_preference?.currency, 0 )} - 
            //     ${typeof item?.investment_size_preference?.max_amount === "number" ? (item?.investment_size_preference?.currency === "INR" ? "₹" : item?.investment_size_preference?.currency === "USD" ? "$" : "") : ""}${convertCurrencyAmountRange(item?.investment_size_preference?.max_amount,item?.investment_size_preference?.currency, 0 )}`.trim(),
            cheque_size:
              item?.investment_size_preference?.min_amount && item?.investment_size_preference?.max_amount ? (`${item?.investment_size_preference?.currency === "INR"
                ? "₹" :
                item?.investment_size_preference?.currency === "USD"
                  ? "$" :
                  ""}${convertCurrencyAmountRange(item?.investment_size_preference?.min_amount, item?.investment_size_preference?.currency, 0)} - 
                    ${item?.investment_size_preference?.currency === "INR" ? "₹" : item?.investment_size_preference?.currency === "USD" ? "$" : ""}${convertCurrencyAmountRange(item?.investment_size_preference?.max_amount, item?.investment_size_preference?.currency, 0)}`.trim()) : "-",
          })
        })
        : [];
    }, [transformedData]);
  };
  React.useEffect(() => {
    // if(reset){
    refetchListing();
    // }
  }, [reset]);
  const FormattedData = useFormattedData(allInvestorData);
  // console.log(FormattedData,"FormattedData")

  return (
    <>
      <ModalComponent open={openImportInvestorModal} onClose={handleImportInvestorClose} style={{ maxWidth: 640, width: "100%" }}>
        <ImportInvestorPopup value={value} handleClose={handleImportInvestorClose} handleSubmit={handleImportInvestorSubmit} refetchListing={refetchListing} />
      </ModalComponent>

      {!allInvestorData ?
        <Grid item xs={12} height={"100vh"} sx={{ width: '100%', bgColor: theme.palette.customColors.yellow_bg }} >
          <Box className='skeleton' minHeight={"60vh"} sx={{ borderRadius: "0 0 8px 8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

          </Box>
        </Grid>
        :
        (<AsyncTableListing
          columns={AllColumns}
          tabValue={value}
          data={FormattedData}
          page={page}
          setPage={setPage}
          limit={limit}
          count={count}
          RowActions={RowActions}
          setLimit={setLimit}
          sx={{ borderRadius: '0 0 8px 8px', border: `1px solid ${theme.palette.customColors.b50}` }}
          refetchListing={refetchListing}
        />
        )}

    </>
  )
}

export default AllInvestor