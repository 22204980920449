import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  Tooltip,
  Menu,
  MenuItem,
  useMediaQuery,
  capitalize,
  Grid,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Cookies from 'js-cookie';

import SideBarLogo from '../../assets/Backrr_logo_square_white.png';
import NavItem from './MenuList/NavItem';

// import { ReactComponent as DashboardIcon } from '../../assets/nav_icons/home_nav_icon.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/nav_icons/rocket-svgrepo-com.svg';
// import { ReactComponent as LayersIcon } from '../../assets/nav_icons/layers_nav_icon.svg';
// import { ReactComponent as NoteIcon } from '../../assets/nav_icons/notes_nav_icon2.svg';
// import { ReactComponent as FlagIcon } from '../../assets/nav_icons/flag_nav_icon2.svg';
import { ReactComponent as UserIcon } from '../../assets/nav_icons/person-male-svgrepo-com.svg';
//import { ReactComponent as ReportsIcon } from '../../assets/nav_icons/analytics-hands-report-svgrepo-com.svg';
//import { ReactComponent as UserIcon } from '../../assets/nav_icons/users_nav_icon2.svg';
//import { ReactComponent as LifeBoyIcon } from '../../assets/nav_icons/report-svgrepo-com.svg';
import { ReactComponent as ReportsIcon } from '../../assets/nav_icons/reports.svg';
// import { ReactComponent as SettingsIcon } from '../../assets/nav_icons/settings_nav_icon2.svg';
import { ReactComponent as LogoutIcon } from '../../assets/nav_icons/logout.svg';
import { ReactComponent as SearchInvestorsIcon } from '../../assets/nav_icons/search-find-user-svgrepo-com.svg';
import { ReactComponent as PortfolioIcon } from '../../assets/nav_icons/chart-diagram-pie-svgrepo-com.svg';
import { ReactComponent as SettingsIcon } from '../../assets/nav_icons/settings_nav_icon2.svg';
// import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
// import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import { FetchProfileDetails, FetchStartupDetails } from '../../api';
import { SET_MENU, SET_INVESTOR_DETAILS, RESET_STATE } from '../../store/actions';
import { containerRef, hasPermission } from '../../utils';
import { FetchUserAssociatedInvestorAccounts } from 'api/permission';

// const checkImage = (url) => {
//   let image = new Image();
//   image.onload = function () {
//     return true
//   }
//   image.onerror = function () {
//     return false
//   }
//   image.src = url;
//   return image
// }


const Sidebar = () => {
  let [investor_id, setInvestorID] = React.useState(Cookies.get("X-INV-ID") || null);
  const { data: userAssociatedInvestorAccounts, isLoading: isLoadingUserAssociatedInvestorAccounts, refetch: refetchUserAssociatedInvestorAccounts } = FetchUserAssociatedInvestorAccounts();
  const userAccess = useSelector((state) => state?.permissions);
  const backrrPermissionCookie = Cookies.get("permissions") || null;

  const backrrStartupsArray = backrrPermissionCookie && JSON.parse(backrrPermissionCookie)?.backrr_startups ? [{ title: 'Backrr Startups', icon: AnalyticsIcon, path: '/backrr_startups', useFill: true, permission: JSON.parse(backrrPermissionCookie)?.backrr_startups }] : []
  const backrrInvestorsArray = backrrPermissionCookie && JSON.parse(backrrPermissionCookie)?.backrr_investors ? [
    { title: 'Backrr Investors', icon: SearchInvestorsIcon, useStroke: true, path: '/backrr_investors', permission: JSON.parse(backrrPermissionCookie)?.backrr_investors },
  ] : []

  const NavItemsTop = [
    // { title: 'Overview', icon: DashboardIcon, path: '/' },
    { title: 'Startups', icon: AnalyticsIcon, path: '/', useFill: true, permission: hasPermission("startups", "view", userAccess?.userPermissions) },
    { title: 'Reports', icon: ReportsIcon, path: '/reports', useFill: true, permission: hasPermission("reports", "view", userAccess?.userPermissions) },
    { title: 'Portfolio', icon: PortfolioIcon, path: '/portfolio', useFill: true, permission: hasPermission("portfolio", "view", userAccess?.userPermissions) },
    // { title: 'Dataroom', icon: NoteIcon, path: '/dataroom' },
    // { title: 'CepTable', icon: FlagIcon, path: '/ceptable' },
  ];

  const NavItemsBottom = Cookies.get("permissions") ? [
    { title: 'Profile', icon: UserIcon, path: '/profile', useStroke: true, permission: hasPermission("profile", "view", userAccess?.userPermissions) },
    ...backrrStartupsArray,
    ...backrrInvestorsArray,
    { title: 'Settings', icon: SettingsIcon, path: '/settings', disabled: false, useStroke: true, permission: true, permission: hasPermission("settings", "manage_users", userAccess?.userPermissions) },
  ] : [
    { title: 'Profile', icon: UserIcon, path: '/profile', useStroke: true, permission: hasPermission("profile", "view", userAccess?.userPermissions) },
    { title: 'Settings', icon: SettingsIcon, path: '/settings', disabled: false, useStroke: true, permission: hasPermission("settings", "manage_users", userAccess?.userPermissions) }
  ]
  const theme = useTheme();
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.customization.opened);
  const details = useSelector((state) => state?.investorDetails?.data);
  // console.log(details, 'details')

  // const NavItemsBottom = Cookies.get("ur") === "admin" ? [
  // ] : [];




  const toggleDrawer = () => {
    dispatch({ type: SET_MENU, opened: !open });
  };

  const path = location.pathname;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = ({ _id }) => {
    // console.log(investor_id, _id, "startup_id")
    // Cookies.remove("X-ST-ID")
    if (_id && investor_id !== _id) {
      Cookies.set("X-INV-ID", _id);
      setInvestorID(_id);
    }
    setAnchorEl(null);
    if (_id && investor_id !== _id) {
      window.location.reload();
    }
  };

  const handleLogOut = () => {
    // dispatch({ type: SET_MENU, opened: false });
    dispatch({ type: RESET_STATE });
    Cookies.remove('jwt', { path: '/' });
    Cookies.remove('name', { path: '/' });
    Cookies.remove('user_id', { path: '/' });
    Cookies.remove('startup_id', { path: '/' });
    Cookies.remove('email', { path: '/' });
    Cookies.remove('is_profile_complete', { path: '/' });
    Cookies.remove('startup_id', { path: '/' });
    Cookies.remove('step', { path: '/' });
    Cookies.remove('permissions', { path: '/' });
    Cookies.remove('ur', { path: '/' });
    Cookies.remove('search_startups', { path: '/' });
    Cookies.remove('search_backrr_startups', { path: '/' });
    Cookies.remove('backrr_startups_query', { path: '/' });
    Cookies.remove('backrr_startups_status', { path: '/' });
    const cookies = document.cookie.split(";");
    cookies.forEach(cookie => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });
    navigate('/login');
  };

  const getAvatarProps = (name) => {
    const initials = name
      ? name
        .split(' ')
        .map((n) => n[0])
        .join('')
        .toUpperCase()
      : '';
    return {
      sx: {
        bgcolor: '#F9F5FF',
        color: '#3538CD',
        width: 48,
        height: 48,
        fontSize: 16,
        fontWeight: 500,
        borderRadius: '4px',
      },
      children: initials,
    };
  };

  function ProfileImage({ src, alt, width, height, style }) {
    const [imageError, setImageError] = React.useState(false);

    const handleImageError = () => {
      setImageError(true);
    };

    // If image is broken, render Avatar
    if (imageError) {
      return (
        <Avatar
          {...getAvatarProps(alt)}
        />
      );
    }

    // Render Image with error handling
    return (
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        style={style}
        onError={handleImageError}
        // Optional: Add error placeholder
        placeholder={imageError ? 'blur' : 'empty'}
        blurDataURL="/path/to/placeholder.jpg"
      />
    );
  }

  const SwitchAccountsMenu = () => {
    return <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{ "& .MuiPaper-root": { minWidth: 300, borderRadius: 3, boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' } }}
    >
      <MenuItem disabled sx={{ fontWeight: 'bold', textAlign: 'center', justifyContent: 'center', padding: '12px', opacity: "1 !important" }}>
        Switch Account
      </MenuItem>
      <Divider />
      {userAssociatedInvestorAccounts?.map((investor) => {
        return (
          <MenuItem item key={investor?.investor_id} onClick={() => {
            handleMenuClose({ _id: investor?.investor_id });
          }} sx={{ padding: '12px 16px', gap: 2, background: investor?.investor_id === investor_id ? "#eae8fb" : 'inherit' }}>
            <Grid container alignItems="center" wrap="nowrap" spacing={2}>
              <Grid item>
                <ProfileImage
                  height={50}
                  width={50}
                  style={{ borderRadius: '8px', mixBlendMode: 'multiply', maxWidth: '50px' }}
                  src={investor?.profile_picture}
                  alt={`${investor?.investor_name}`}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1" sx={{}}>
                  <span style={{ fontWeight: 700 }}>
                    {investor?.investor_name}
                  </span> • <span style={{ color: theme.palette.customColors.g200 }}>
                    {capitalize(investor?.user_role)}
                  </span>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {investor?.email}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        )
      })}
      <Divider />
      <MenuItem onClick={handleLogOut} sx={{ paddingBlock: "1rem", display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ ml: 1, color: "#3c3c3c", fontWeight: 'bold', }}>Logout</Box>
        <LogoutIcon stroke="#3c3c3c" />
      </MenuItem>
    </Menu>
  }

  return (
    <Box component="nav" aria-label="sidebar navigation">
      <Drawer
        variant={matchesUpMd ? 'permanent' : 'temporary'}
        open={matchesUpMd ? false : open}
        onClose={toggleDrawer}
        // ModalProps={{ keepMounted: true }}
        sx={{
          '& .MuiDrawer-paper': {
            width: matchesUpMd ? '5.125rem' : 280,
            backgroundColor: '#3538CD',
            color: '#FFFFFF',
            borderRight: 'none',
            boxSizing: 'border-box',
            paddingTop: matchesUpMd ? '2rem' : 0
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: matchesUpMd ? 'center' : 'space-between',
            p: 2,
          }}
        >
          <img src={SideBarLogo} alt="Logo" width={32} height={32} />
          {!matchesUpMd && (
            <IconButton onClick={toggleDrawer} sx={{ color: '#FFFFFF' }}>
              ✕
            </IconButton>
          )}
        </Box>

        <PerfectScrollbar containerRef={containerRef} component="div" style={{ display: "grid" }}>
          <List>
            {NavItemsTop.map((item) => (
              <NavItem
                key={item.title}
                item={item}
                drawerToggle={toggleDrawer}
                isActive={item?.title == "Startups" ? path == item?.path || path?.includes("/startups") : path?.includes(item?.path)} open={!matchesUpMd ? (open) : false}
              />
            ))}
          </List>
          <List sx={{ marginTop: 'auto' }}>
            {NavItemsBottom.map((item) => (
              <NavItem
                key={item.title}
                item={item}
                isActive={item?.title == "Startups" ? path == item?.path || path?.includes("/startups") : path?.includes(item?.path)} open={!matchesUpMd ? (open) : false}
                drawerToggle={toggleDrawer}
              />
            ))}
          </List>
        </PerfectScrollbar>
        <Divider sx={{ mx: "auto", borderColor: '#444CE7', width: matchesUpMd ? '62.5%' : "80%" }} />
        <Box sx={{ pt: 1 }}>

          {!matchesUpMd ? <>
            {/* <Divider sx={{ marginInline: '1rem', borderWidth: 1, color: '#444CE7' }} /> */}
            <Box paddingBlock={"1rem"} display={"flex"} justifyContent={open ? "space-between" : 'center'} px={open ? 2 : 0}>
              <Tooltip title={userAccess?.userName ? (`${userAccess?.userName}`) : ""} arrow placement='right'>
                <IconButton
                  onClick={handleAvatarClick}
                  size="small"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Avatar
                    {...getAvatarProps(userAccess?.userName ? (`${userAccess?.userName}`) : "")}
                  />
                </IconButton>
              </Tooltip>
              <SwitchAccountsMenu />
              {open ? <Tooltip title="">
                <IconButton
                  onClick={handleLogOut}
                  size="small"
                >
                  <LogoutIcon stroke='white' fill='transparent' />
                </IconButton>
              </Tooltip> : <></>}
            </Box>
          </> : <Box paddingBlock={"0.5rem 0.5rem"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Tooltip title={userAccess?.userName ? (`${userAccess?.userName}`) : ""} arrow placement='right'>
              <IconButton
                onClick={handleAvatarClick}
                size="small"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Avatar
                  {...getAvatarProps(userAccess?.userName ? (`${userAccess?.userName}`) : "")}
                />
              </IconButton>
            </Tooltip>
            <SwitchAccountsMenu />
          </Box>
          }

        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;

/////
// import React from 'react'
// import SideBarLogo from "../../assets/Backrr_logo_square_white.png"
// import NavItem from './MenuList/NavItem'
// import { ReactComponent as DashboardIcon } from "../../assets/nav_icons/home_nav_icon.svg"
// import { ReactComponent as AnalyticsIcon } from "../../assets/nav_icons/analytics_nav_icon.svg"
// import { ReactComponent as LayersIcon } from "../../assets/nav_icons/layers_nav_icon.svg"
// import { ReactComponent as NoteIcon } from "../../assets/nav_icons/notes_nav_icon2.svg"
// import { ReactComponent as FlagIcon } from "../../assets/nav_icons/flag_nav_icon2.svg"
// import { ReactComponent as UserIcon } from "../../assets/nav_icons/users_nav_icon2.svg"
// import { ReactComponent as LifeBoyIcon } from "../../assets/nav_icons/life-buoy_nav_icon2.svg"
// import { ReactComponent as SettingsIcon } from "../../assets/nav_icons/settings_nav_icon2.svg"
// import { Avatar, Box, Divider, Drawer, IconButton, List, Tooltip, Menu, MenuItem, useMediaQuery } from '@mui/material'
// import { useLocation, useNavigate } from 'react-router-dom'
// import { ReactComponent as LogoutIcon } from "../../assets/nav_icons/logout.svg"
// import Cookies from "js-cookie"
// import { FetchStartupDetails } from '../../api'
// import PerfectScrollbar from "react-perfect-scrollbar"
// import theme from '../../themes/theme'
// import { useDispatch, useSelector } from 'react-redux'
// import { SET_MENU } from '../../store/actions'
// const Sidebar = () => {
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
//   const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
//   const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
//   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
//   const location = useLocation();
//   const navigate = useNavigate();
//   // const [open, setOpen] = React.useState(false);
//   const open = useSelector((state) => state.customization.opened);
//   const dispatch = useDispatch();
//   const drawerToggle = () => {
//     dispatch({ type: SET_MENU, opened: !open });
//   };
//   const path = location.pathname;
//   const NavItemsTop = [{
//     title: "Overview",
//     icon: DashboardIcon,
//     path: "/"
//   },
//   {
//     title: "Funding Rounds",
//     icon: AnalyticsIcon,
//     path: "/rounds"
//   },
//   {
//     title: "Investors",
//     icon: LayersIcon,
//     path: "/investors"
//   },
//   {
//     title: "Dataroom",
//     icon: NoteIcon,
//     path: "/dataroom"
//   },
//   {
//     title: "CepTable",
//     icon: FlagIcon,
//     path: "/ceptable"
//   },
//   {
//     title: "ESOPs",
//     icon: UserIcon,
//     path: "/esops"
//   },
//   ]

//   const NavItemsBottom = [{
//     title: "Reports",
//     icon: LifeBoyIcon,
//     path: "/reports"
//   },
//   {
//     title: "Settings",
//     icon: SettingsIcon,
//     path: "/settings"
//   },
//   ]

//   function stringAvatar(name) {
//     if (name && name?.includes(" ")) {
//       return {
//         sx: {
//           bgcolor: "#F9F5FF",
//           color: "#3538CD",
//           width: "48px",
//           height: "48px"
//         },
//         children: `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`,
//       }
//     }
//     else if (name && !(name?.includes(" "))) {
//       return {
//         sx: {
//           bgcolor: "#F9F5FF",
//           color: "#3538CD",
//           width: "48px",
//           height: "48px"
//         },
//         children: name?.split(' ')[0][0],
//       }
//     }
//     else {
//       return {
//         sx: {
//           bgcolor: "#F9F5FF",
//           color: "#3538CD",
//           width: "48px",
//           height: "48px"
//         },
//         children: '',
//       }
//     }
//   }

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const openAvatar = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   const handleLogOut = () => {
//     drawerToggle()
//     Cookies.remove("jwt", { path: "/" });
//     Cookies.remove("is_profile_complete", { path: "/" });
//     Cookies.remove("email", { path: "/" });
//     navigate("/login")
//   }

//   const { data: userData, isLoading: isUserDataLoading } = FetchStartupDetails();
//   // console.log(userData, "userData")

//   return (
//     <Drawer
//       // onMouseEnter={() => setOpen(true)}
//       // onMouseLeave={() => setOpen(false)}
//       onClose={drawerToggle}
//       variant={isNotLargeScreen ? "temporary" : "permanent"}
//       open={isNotLargeScreen ? (open) : false}
//       sx={{
//         minWidth: '5.125rem',
//         position: 'relative',
//         "& > .MuiPaper-root": {
//           background: "#3538CD",
//           padding: "0 0px",
//           width: isNotLargeScreen ? (open ? 300 : "auto")
//             : '5.125rem',
//           transition: '0.3s ease 0.1s',
//           borderRight: "none",
//           display: 'flex',
//           justifyContent: "flex-start",
//           height: '100vh',
//           // maxHeight: "calc(100% - 32px)",
//           overflow: 'hidden'
//         }
//       }}>
//       <Box>
//         <img style={{ padding: "1.5rem 1.6rem 1rem 1.6rem" }} src={SideBarLogo} width={32} height={32} />
//       </Box>
//       <PerfectScrollbar id="sidebar-scroller" style={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: "space-between",
//         height: 'calc(100% - 100px)'
//       }}>
//         <Box display={"grid"} gap={0}>
//           {NavItemsTop?.map((item) => <NavItem drawerToggle={drawerToggle} key={item?.title} item={item} isActive={item?.title == "Overview" ? path == item?.path : path?.includes(item?.path)} open={isNotLargeScreen ? (open) : false} />)}
//           {isNotLargeScreen && NavItemsBottom?.map(item => <NavItem drawerToggle={drawerToggle} key={item?.title} item={item} isActive={path?.includes(item?.path)} open={isNotLargeScreen ? (open) : false} />)}
//         </Box>

//       </PerfectScrollbar>
//       <Box position={"absolute"} bottom={0} width={"100%"}>
//         {!isNotLargeScreen && <Box display={"grid"} gap={0}>
//           {NavItemsBottom?.map(item => <NavItem drawerToggle={drawerToggle} key={item?.title} item={item} isActive={path?.includes(item?.path)} open={isNotLargeScreen ? (open) : false} />)}
//         </Box>}

// {isNotLargeScreen ? <>
//   {/* <Divider sx={{ marginInline: '1rem', borderWidth: 1, color: '#444CE7' }} /> */}
//   <Box paddingBlock={"1rem"} display={"flex"} justifyContent={open ? "space-between" : 'center'} px={open ? 2 : 0}>
//     <Tooltip title="">
//       <IconButton
//         onClick={handleClick}
//         size="small"
//         // sx={{ ml: 2 }}
//         aria-controls={openAvatar ? 'account-menu' : undefined}
//         aria-haspopup="true"
//         aria-expanded={openAvatar ? 'true' : undefined}
//       >
//         {console.log(userData?.data?.startup_name, "userData?.data?.startup_name")}
//         {userData?.data?.startup_name ? <Avatar {...stringAvatar(userData?.data?.startup_name)} /> : <Avatar {...stringAvatar('')} />}
//         {/* <Avatar {...stringAvatar(userData?.data?.first_name + " " + userData?.data?.last_name)} /> */}
//       </IconButton>
//     </Tooltip>
//     {open ? <Tooltip title="">
//       <IconButton
//         onClick={handleLogOut}
//         size="small"
//         // sx={{ ml: 2 }}
//         aria-controls={openAvatar ? 'account-menu' : undefined}
//         aria-haspopup="true"
//         aria-expanded={openAvatar ? 'true' : undefined}
//       >
//         <LogoutIcon stroke='white' fill='transparent' />
//         {/* Logout */}
//       </IconButton>
//     </Tooltip> : <></>}
//   </Box>
// </> : <>
//   <Divider sx={{ marginInline: '1rem', borderWidth: 1, color: '#444CE7' }} />
//   <Box paddingBlock={"1rem"} display={"flex"} justifyContent={"center"}>
//     <Tooltip title="">
//       <IconButton
//         onClick={handleClick}
//         size="small"
//         // sx={{ ml: 2 }}
//         aria-controls={openAvatar ? 'account-menu' : undefined}
//         aria-haspopup="true"
//         aria-expanded={openAvatar ? 'true' : undefined}
//       >
//         {console.log(userData?.data?.startup_name, "userData?.data?.startup_name")}
//         {userData?.data?.startup_name ? <Avatar {...stringAvatar(userData?.data?.startup_name)} /> : <Avatar {...stringAvatar('')} />}
//         {/* <Avatar {...stringAvatar(userData?.data?.first_name + " " + userData?.data?.last_name)} /> */}
//       </IconButton>
//     </Tooltip>
//     <Menu
//       anchorEl={anchorEl}
//       id="account-menu"
//       open={openAvatar}
//       onClose={handleClose}
//       onClick={handleClose}
//       transformOrigin={{ horizontal: 'left', vertical: 'top' }}
//       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
//     >
//       <MenuItem sx={{ display: "flex", gap: '1rem' }} onClick={() => {
//         handleLogOut()
//       }}>
//         <LogoutIcon stroke='red' fill='white' /> Logout
//       </MenuItem>
//     </Menu>
//   </Box>
// </>
//  }
//       </Box>

//     </Drawer>
//   )
// }

// export default Sidebar