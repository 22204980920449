import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PerfectScrollbar from "react-perfect-scrollbar"
import BreadcrumbHeader from '../../../layout/BreadcrumbsHeader'
import { SECONDARY, WHITEBG } from '../../../ui-components/CustomButton'
import { useTheme } from '@emotion/react'
import ThemeBGWrapper from '../../../ui-components/ThemeBGWrapper'
// import { FetchRoundViewDetails, FetchStartupDetails, FetchViewStartupDetails } from '../../../api'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, CircularProgress, Grid, Tab, Tabs, Typography, useMediaQuery, IconButton, Tooltip } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { ReactComponent as RoundDetailsIcon } from "../../../assets/rounds_icons/round_details_icon.svg"
import { ReactComponent as RoundInvestorsIcon } from "../../../assets/rounds_icons/round_investor_icon.svg"
import { ReactComponent as RoundDocumentsIcon } from "../../../assets/rounds_icons/round_documents.svg"
import RoundInformation from './RoundInformation'
// import RoundDocuments from './RoundDocuments'

import { ReactComponent as InfoIcon } from '../../../assets/help-circle.svg';
import CustomTabs from '../../../ui-components/CustomTabs'
import { containerRef, handleCopyClick } from '../../../utils'
import { ContentCopyOutlined, FileCopy } from '@mui/icons-material'
import NeedHelpButton from 'ui-components/NeedHelpButton'
import RoundDocuments from '../../startups/viewDetails/RoundDocuments'
import StartupInformation from './StartupInformation'
import { FetchRoundViewDetails, FetchViewStartupDetails } from 'api/startups'
import Cookies from 'js-cookie'
import ManageNotes from '../../startups/viewDetails/ManageNotes'
import SubmitFeedback from '../../startups/viewDetails/SubmitFeedback'
import Fundings from '../../startups/viewDetails/Fundings'
import Reports from './Reports'
import Performance from '../../startups/viewDetails/Performance'
import UpdateProfile from '../updateProfile'
import CreateRound from 'views/rounds/CreateRound'
import MatchedInvestors from './MatchedInvestors'
import AdminNotes from './AdminNotes'
// import ViewProfile from './ViewProfile'
// import ViewFeedback from './ViewFeedback'
// import RoundInvestorsNew from './RoundInvestorsNew'

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        '& > .Mui-disabled.MuiTabs-scrollButtons': {
            display: 'none',
            transition: 'all 1s ease'
        }
    }
}));

const ViewDetails = () => {
    // useEffect(() => {
    //     console.log(window.history, 'history')
    //   },[window.history.length])
    const {tab : tabValue} = useParams();
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles();
    const [count, setCount] = React.useState(0);
    const Actions = () => {
        return (
            <>
                <NeedHelpButton />
            </>
        );
    };

    const [searchParams, setSearchParams] = useSearchParams();
    // let tabValue = new URLSearchParams(window.location.search).get("page");
    // console.log("tabl",tabValue)

    
    const [value, setValue] = React.useState(tabValue || "view-profile");
    // console.log(params,"value", value)


    const handleChange = (event, newValue) => {
        
        navigate("/backrr_startups/"+ newValue, {replace: true});
        setValue(newValue);

        // setSearchParams({ page: newValue })

        // navigate(`/startups/view?page=${}`)
    };

    let roundTabs

    const [copied, setCopied] = React.useState(false)

    // const [searchParams, setSearchParams] = useSearchParams();
    let startup_id = searchParams.get("startup_id");
    let cookieStartupId = Cookies.get("startup_id")?.trim();
    // console.log(cookieStartupId, "frgt")
    React.useEffect(() => {
        if (cookieStartupId) {
            // searchParams.delete("startup_id");
            // setSearchParams(searchParams); // Update the URL without startup_id
        } else if (startup_id?.trim()?.length > 0 && startup_id !== null) {
            Cookies.set("startup_id", startup_id);
            // searchParams.delete("startup_id");
            // setSearchParams(searchParams); // Update the URL without startup_id
        } else {
            navigate(-1); // Navigate back if no valid startup_id
        }
    }, [cookieStartupId, startup_id, setSearchParams, navigate, searchParams]);
      
    // const startup_id = Cookies.get("startup_id");
    const { data: startupDetails, isLoading: isLoadingStartupDetails, refetch: refetchStartupDetails } = FetchViewStartupDetails(cookieStartupId)
    // console.log(startupDetails, "startupDetails");

    const manage_notes = window.location?.pathname?.includes("backrr_startups")  ?
   [        {
        label: "Matched Investors",
        // icon: RoundInvestorsIcon,
        value: "matched-investors",
    },
    {
        label: "Admin Notes",
        // icon: RoundDocumentsIcon,
        value: "admin-notes"
    }]:   [{
        label: "Notes",
        value: "view-notes",
    }] 

    const round_details_visible = startupDetails?.data?.round_id ? [
        {
            label: "Round Details",
            // icon: RoundInvestorsIcon,
            value: "view-round",
        },
        {
            label: "Documents",
            // icon: RoundDocumentsIcon,
            value: "view-documents"
        },
    ] : []
    roundTabs = [
        {
            label: "Profile",
            // icon: RoundDetailsIcon,
            value: "view-profile",
        },
        ...round_details_visible,
        // {
        //     label: "Investors",
        //     icon: RoundInvestorsIcon,
        //     value: "investors",
        // },
        {
            label: "Funding",
            // icon: RoundDocumentsIcon,
            value: "view-funding"
        },
        {
            label: "Reports",
            // icon: RoundDocumentsIcon,
            value: 'view-reports'
        },
        {
            label: "Performance",
            // icon: RoundDocumentsIcon,
            value: "view-performance"
        },
        {
            label: "Feedback",
            // icon: RoundDocumentsIcon,
            value: "view-feedback"
        },
        ...manage_notes
        // ,
        // {
        //     label: "Investors Documents",
        //     icon: RoundDetailsIcon,
        //     value: "investors_documents"
        // }
    ]


    return (
        tabValue === "edit" ? <UpdateProfile /> : (tabValue === "edit-round" ? <CreateRound /> :
        <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
            <BreadcrumbHeader title={() => <>{isLoadingStartupDetails ? "Loading..." : isSmallScreen ? "View Details" : startupDetails?.data?.startup_name ? startupDetails?.data?.startup_name : "View Details"} </>} Actions={Actions} sx={{ paddingBottom: 0 }} />
            {isLoadingStartupDetails ?
                <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingTop: "1rem", bgColor: theme.palette.customColors.yellow_bg }}>
                    {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
                    <Box className='skeleton' height={80}>

                    </Box>
                    <Box className='skeleton' height={"calc(100% - 80px)"} marginTop={2}>

                    </Box>
                </Grid>
                : <>


                    <Grid xs={12} container bgColor={theme.palette.customColors.yellow_bg} height={"100%"}>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box px={isSmallScreen ? "1rem" : "2rem"} bgColor={theme.palette.customColors.yellow_bg}>
                                    <CustomTabs 
                                    // data={roundTabs} 
                                    data={
                                        [...roundTabs?.map(item => {
                                          return ({ ...item, label: `${item?.label} ${item?.value === value && value === "matched-investors"  && count && count !== null ? `(${count})` : ""}` })
                                        })]
                                      }
                                    value={value} isSmallScreen={isSmallScreen} className={classes} titleStyle={{ fontSize: '1.10rem' }} handleChange={handleChange} key={"Tabs Tabs"} sx={{ paddingTop: '1rem' }} bgColor={theme.palette.customColors.yellow_bg} />
                                </Box>
                                <TabPanel value="view-profile" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <StartupInformation data={startupDetails?.data} round_id={"wefrtg"} />
                                    </PerfectScrollbar>
                                </TabPanel>
                                {startupDetails?.data?.round_id && <TabPanel value="view-round" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <RoundInformation data={{}} round_id={startupDetails?.data?.round_id} />
                                    </PerfectScrollbar>
                                </TabPanel>}
                                {startupDetails?.data?.round_id && <TabPanel value="view-documents" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <RoundDocuments round_id={startupDetails?.data?.round_id} startup_name={startupDetails?.data?.startup_name} />
                                    </PerfectScrollbar>
                                </TabPanel>}
                                {/* <TabPanel value="view-notes" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <ManageNotes data={startupDetails?.data} startup_id={cookieStartupId} />
                                    </PerfectScrollbar>
                                </TabPanel> */}
                                <TabPanel value="view-feedback" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <SubmitFeedback />
                                    </PerfectScrollbar>
                                </TabPanel>
                                <TabPanel value="view-funding" sx={{ paddingInline: 0, height: '100%' }}>
                                    {/* <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}> */}
                                    <Fundings isAdmin={window.location?.pathname?.includes("backrr_startups")} />
                                    {/* </PerfectScrollbar> */}
                                </TabPanel>
                                <TabPanel value="view-reports" sx={{ paddingInline: 0, height: '100%' }}>
                                    {/* <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}> */}
                                    <Reports startup_details_id={cookieStartupId} />
                                    {/* </PerfectScrollbar> */}
                                </TabPanel>
                                <TabPanel value="view-performance" sx={{ paddingInline: 0, height: '100%' }}>
                                    {/* <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}> */}
                                    <Performance startup_id={cookieStartupId}/>
                                    {/* </PerfectScrollbar> */}
                                </TabPanel>
                                <TabPanel value="matched-investors" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                    <MatchedInvestors startup_id={cookieStartupId} setCount={setCount} count={count}/>
                                    </PerfectScrollbar>
                                </TabPanel>
                                <TabPanel value="admin-notes" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <AdminNotes data={startupDetails?.data} startup_id={cookieStartupId} />
                                    </PerfectScrollbar>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Grid>
                </>
            }
        </ThemeBGWrapper>)
    )
}

export default ViewDetails