import { useTheme } from '@emotion/react'
import { Add, DeleteOutlineRounded, Description, Edit, EditOutlined, Search } from '@mui/icons-material'
import { Box, Button, capitalize, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material'
// import { FetchAllStartupFeedbacks } from 'api/profile'
import { FetchAllRounds } from 'api/round'
import Cookies from 'js-cookie'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AsyncTableListing from 'ui-components/AsyncTableListing'
import { handleDateValues, hasPermission } from 'utils/index'
import DeleteConfirmationDialog from 'ui-components/popups/ConfirmationPopup'
// import placeHolderImage from "assets/no_data_images/no_data_rounds.svg"
import { B300 } from 'ui-components/CustomButton'
import CustomInputField from 'ui-components/CustomInputField'
import { DeleteUserDataByID, FetchAllUsersData } from 'api/settings'
import toast from 'react-hot-toast'
import toast_message from "utils/toast_message.json"


const Users = ({ refetchListing, searchStatus, setSearchStatus, reset, search, setSearch, setReset, queryParams, setQueryParams, }) => {
  const theme = useTheme();
  let [investor_id, setStartupID] = React.useState(Cookies.get("X-INV-ID") || null);
  const userPermissions = useSelector((state) => state?.permissions?.userPermissions);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate()
  const columns = [
    { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start', fixed: true, hideEmail: true },
    { Header: 'Email', accessor: 'email', minWidth: 150, align: 'center' },
    { Header: 'Role', accessor: 'user_role_type', minWidth: 150, align: 'center' },
    { Header: 'Registered On', accessor: 'formatted_date', minWidth: 150, align: 'center' },
    { Header: 'Actions', accessor: 'actions', minWidth: 100, align: 'end' },
  ];

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [sort, setSort] = React.useState(-1);
  // const [search, setSearch] = React.useState(-1);
  const { data: userData, isLoading: isLoadingUserData, refetch: refetchUsersData } = FetchAllUsersData({ investor_id, limit, page, searchText: search, enabled: hasPermission("settings", "manage_users", userPermissions) })
  const {
    mutateAsync: deleteUserDataAsync,
    isLoading: isDeletingUserData,
    status: deleteUserDataStatus,
    error: deleteUserDataError,
    data: deleteUserDataResponse
  } = DeleteUserDataByID();

  const formattedUsersData = React.useMemo(() => {
    const dataArray = userData?.data || [];
    return dataArray.map(user => {
      return ({
        ...user,
        name: user?.user_details?.first_name + " " + user?.user_details?.last_name,
        email: user.user_details?.email,
        user_role_type: capitalize(user?.user_role_type)
      })
    })

  })

  const [currentRow, setCurrentRow] = React.useState(null);
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);

  const handleOpenDeletePopup = () => {
    setShowDeletePopup(true);
  }

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
  }

  const handleDeleteUser = () => {
    // console.log(currentRow?._id,"ddd")
    deleteUserDataAsync({ user_id: currentRow?._id }, {
      onSuccess: () => {
        refetchUsersData();
        handleCloseDeletePopup();
        toast.success(toast_message?.users?.delete)
      },
      onError: () => {
      }
    })
  }
  React.useEffect(() => {
    refetchUsersData();
  }, [reset]);

  const RowActions = ({ row }) => {
    const currentLocation = window.location;
    // console.log(row?.original,"row")
    return (!row?.original?.is_owner ? <div style={{ display: "flex", marginLeft: 'auto' }}>
      <Tooltip title="Edit Permissions">
        <Button sx={{ minWidth: "1rem" }} onClick={() => {
          Cookies.set("settings_user_id", row?.original?._id)
          if (currentLocation.pathname?.includes("/settings")) {
            navigate("add_user")
          }
          else {
            navigate("/settings/add_user")
          }
        }}>
          <EditOutlined
            sx={{ color: theme.palette.customColors.indigo700 }}

          />
        </Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          sx={{ minWidth: "1rem" }}
          onClick={() => { setCurrentRow(row?.original); handleOpenDeletePopup() }}
        >
          <DeleteOutlineRounded
            sx={{ color: theme.palette.customColors.indigo700 }} />
        </Button>
      </Tooltip>
    </div> : "")
  }

  return (
    <>
      <DeleteConfirmationDialog open={showDeletePopup} handleClose={handleCloseDeletePopup} handleSubmission={handleDeleteUser} heading={"Delete User"} ActionText={"Confirm"} subheading={"Are you sure you want to delete this user?"} />
      <Grid container xs={12} pt={1.1} pb={0} spacing={2} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
        {isLoadingUserData ?

          <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
            <Box className='skeleton' minHeight={"70vh"} sx={{ borderRadius: "8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

            </Box>
          </Grid>
          :
          <Box width={"100%"} paddingLeft={"2rem"} maxWidth={"100%"} overflow={'hidden'} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
            <Grid container padding={"0.5rem"} sx={{
              border: `1px solid ${theme.palette.customColors.b50} !important`,
              borderRadius: '8px 8px 0px 0px',
              background: theme?.palette.customColors.whiteBG,
            }}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} style={{ display: 'flex', height: 'min-content', alignItems: 'center', gap: "0.5rem" }}>
                <CustomInputField
                  autoComplete="off"
                  sx={{
                    marginBottom: 0,
                    "& > .MuiFilledInput-root": {
                      borderColor: `${theme.palette.customColors.b50} !important`,
                      borderRadius: '8px'
                    }
                  }}
                  fullWidth
                  value={search}
                  name={`search_${Math.random().toString(36).substring(7)}`}  // Set a random name attribute
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      // alert(e.target.value);
                      refetchUsersData()
                      if (search) {
                        setSearchStatus(true)
                      }
                      setPage(1);
                    }
                  }}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    Cookies.set("search_startup_settings", event.target.value);
                    setSearchStatus(false)
                  }}
                  size="small"
                  startAdornment={<>
                    <Search sx={{ padding: "0rem 0rem 0rem 0.5rem" }} />
                  </>}
                  placeholder={'Search by name'}
                />
                <B300 onClick={() => {
                  if (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) {
                    setSearch('')
                    Cookies.set("search_startup_settings", "");
                    // setQueryParams({})
                    // Cookies.set("startup_settings_query", JSON.stringify({}));
                    // setStage(null)
                    // setCategory(null)
                    // setSector(null);
                    // setStatus('')
                    setReset(!reset)
                  } else {
                    // Cookies.set("startup_settings_query", JSON.stringify({ ...queryParams }));
                    refetchUsersData()
                  }
                  setSearchStatus((oldVal) => !oldVal)
                  setPage(1);
                }} sx={{ padding: ".7rem 1.25rem", bgcolor: searchStatus && (search !== '') ? "#e9f5f8" : theme.palette.customColors.b300, ":hover": { bgcolor: searchStatus && (search !== '') ? "#e9f5f8" : theme.palette.customColors.b400 }, border: `0px solid ${searchStatus && (search !== '') ? "black" : "inherit"}`, color: searchStatus && (search !== '') ? theme.palette.customColors.b300 : "white" }}  >{(searchStatus && (search !== '')) ? "Reset" : "Search"}</B300>


              </Grid>
              <Grid item width="25%" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginLeft: "auto" }}>
                <B300
                  onClick={() => {
                    Cookies.remove("settings_user_id");
                    navigate("/settings/add_user");
                  }}
                  sx={{
                    // bgcolor: theme.palette.customColors.indigo600,
                    // border: `1px solid ${theme.palette.customColors.indigo600} `,
                    padding: '10px 16px',
                    fontWeight: 600,
                    minWidth: isSmallScreen ? "0" : '64px',
                    '& > .MuiButton-icon.MuiButton-startIcon': {
                      marginRight: isSmallScreen ? 0 : '8px',
                      marginLeft: isSmallScreen ? 0 : '-4px'
                    }
                  }}
                  startIcon={<Add fontSize='1.25rem' />}
                >
                  {!isSmallScreen ? "Add User" : "Add"}
                </B300>
              </Grid>
            </Grid>

            <Grid id="users-settings-table">
              <AsyncTableListing
                actions={() => <B300
                  // onClick={() => { navigate("/add_user") }}
                  onClick={() => {
                    Cookies.remove("settings_user_id");
                    navigate("/settings/add_user");
                  }}
                  sx={{
                    bgcolor: theme.palette.customColors.indigo600,
                    border: `1px solid ${theme.palette.customColors.indigo600} `,
                    padding: '10px 16px',
                    fontWeight: 600,
                    minWidth: isSmallScreen ? "0" : '64px',
                    '& > .MuiButton-icon.MuiButton-startIcon': {
                      marginRight: isSmallScreen ? 0 : '8px',
                      marginLeft: isSmallScreen ? 0 : '-4px'
                    }
                  }}
                  startIcon={<Add fontSize='1.25rem' />}
                >
                  {!isSmallScreen ? "Add User" : "Add"}
                </B300>}
                // isLoading={isLoadingFeedbacks}
                columns={columns}
                data={formattedUsersData}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                count={10}
                RowActions={RowActions}
                // tabValue={value}
                pagination={true}
                sx={{
                  borderRadius: '0px 0px 8px 8px',
                  border: `1px solid ${theme.palette.customColors.b50}`,
                  bgcolor: "white"
                }}
              />
            </Grid>
          </Box>
        }

      </Grid>
    </>
  )
}

export default Users
