import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";


export const FetchAllRounds = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-roundss-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/list/rounds-listing`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return data?.data?.data != undefined ? data?.data?.data : []
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchViewStartupDetails = (id) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchStartupDetails-FetchStartupDetails-FetchStartupDetails-fetch-view-view', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/startup-details-new/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data[0];
            // console.log(data.data.data[0], 'datatatatat')
            const savedValues = {
                // step 1
                first_name: savedData?.first_name ? String(savedData?.first_name) : '',
                last_name: savedData?.last_name ? String(savedData?.last_name) : '',
                mobile_number: savedData?.mobile_number ? String(savedData?.mobile_number) : '',
                phone_code: savedData?.phone_code ? String(savedData?.phone_code) : '91',
                country: savedData?.country ? savedData?.country : null,
                state: savedData?.state ? savedData?.state : null,
                city: savedData?.city ? savedData?.city : null,
                email: savedData?.email ? savedData?.email : null,
                linkedin_url: savedData?.linkedin_url ? String(savedData?.linkedin_url)?.startsWith('https://') ? String(savedData?.linkedin_url)?.slice(8) : String(savedData?.linkedin_url) : '',
                // step 2
                // startup_logo: ("http://192.168.55.19:3001/assets/project_image/startup/" + savedData?.logo_url) || null,
                // startup_logo: savedData?.logo_url ? (savedData?.bucket_path + savedData?.logo_url) : null,
                startup_logo: (savedData?.startup_logo_path),
                startup_name: savedData?.startup_name ? savedData?.startup_name : "",
                entity_name: savedData?.entity_name ? savedData?.entity_name : "",
                formation_date: savedData?.formation_date ? savedData?.formation_date : null,
                formatted_formation_date: savedData?.formatted_formation_date ? savedData?.formatted_formation_date : null,
                sector_info: savedData?.sector_info ? savedData?.sector_info : "",
                business_model_info: savedData?.business_model_info ? savedData?.business_model_info : "",
                raised_round: savedData?.raised_round ? savedData?.raised_round : null,
                stage_info: savedData?.stage_info ? savedData?.stage_info : "",
                category_info: savedData?.category_info ? savedData?.category_info : "",
                // sector_ids: savedData?.sector_ids ? savedData?.sector_ids : [],
                pitch_deck_path: savedData?.pitch_deck_path ? savedData?.pitch_deck_path : null,
                pitch_deck_thumbnail_path: savedData?.pitch_deck_thumbnail_path ? savedData?.pitch_deck_thumbnail_path : null,
                pitch_deck_url: savedData?.pitch_deck_url ? savedData?.pitch_deck_url : null,
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || "",
                description: savedData?.description || '',
                elevator_pitch_path: savedData?.elevator_pitch_path ? savedData?.elevator_pitch_path : null,
                problem_solving: savedData?.problem_solving || "",
                website_url: savedData?.website_url ? String(savedData?.website_url)?.startsWith('https://') ? String(savedData?.website_url)?.slice(8) : String(savedData?.website_url) : '',

                coFoundersArray:
                    savedData?.cofounder && savedData?.cofounder?.length > 0 ? savedData?.cofounder?.map(item => (
                        {
                            edit: true,
                            name: item?.name || '',
                            title: item?.title || '',
                            bio: item?.bio || '',
                            linkedin_url: item?.linkedin_url ? String(item?.linkedin_url) : '',
                            image: item?.cofounder_image_path ? item?.cofounder_image_path : null,
                            image_name: item?.cofounder_image ? item?.cofounder_image : null,
                        }
                    )) : [],
                referral_type: savedData?.referral_type || '',
                referral_name: savedData?.referral_name || '',
                founder: savedData?.founder && savedData?.founder?.length > 0 ? {
                    name: savedData?.founder[0]?.name || '',
                    bio: savedData?.founder[0]?.bio || '',
                    title: savedData?.founder[0]?.title || '',
                    linkedin_url: savedData?.founder[0]?.linkedin_url ? savedData?.founder[0]?.linkedin_url : '',
                    image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null,
                    founder_image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null
                } : null,
                step: savedData?.step || 1,
                _id: savedData?._id,
                document_base_url: savedData?.document_base_url,
                backrr_base_url: savedData?.backrr_base_url,
                round_id: savedData?.startup_rounds_info,
                raised_round: savedData?.round_info?.raised_round,
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        retryDelay: 0,
        cacheTime: 0,
        enabled: id != null && !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchRoundViewDetails = (id) => {
    return useQuery(
        ['master-listing-template-round--view-details-using-id-fetch', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/round-details-new/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data, 'values new')
            const savedData = data?.data?.data[0];
            const savedValues = {
                // step 0
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || '',
                security_type: savedData?.security_type || "",
                // step 1
                round_highlights: savedData?.round_highlights || "",
                total_round_size: savedData?.total_round_size || "",
                round_valuation: savedData?.round_valuation | "",
                minimum_cheque_size: savedData?.minimum_cheque_size || "",
                received_termsheet: savedData?.received_termsheet || false,
                committed_funding_amount: savedData?.committed_funding_amount || "",
                currency_id: savedData?.currency_id || "",
                currency: savedData?.currency_info || "",
                // step 2
                investors: savedData?.investors || [{ name: "", email: "" }],
                // step 3
                fund_utilization_highlights: savedData?.fund_utilization_highlights || "",
                fund_allocation_percentages: savedData?.fund_allocation_percentages || [
                    { name: "", percentage: 0 },
                ],
                // step 4
                traction_highlights: savedData?.traction_highlights || "",
                key_metrics: savedData?.key_metrics || [
                    {
                        heading: "",
                        properties: [
                            { key: "", value: "" },
                            { key: "", value: "" },
                            { key: "", value: "" },
                        ],
                    },
                ],
                // step 5
                documents: savedData?.documents || [

                ],
                // step 6
                faqs: savedData?.faqs || [
                    {
                        question: "",
                        answer: "",
                    },
                ],
                // step 7
                exit_highlights: savedData?.exit_highlights || "",
                runway_left: savedData?.runway_left || 'select_runway_left',
                monthly_net_burn: savedData?.monthly_net_burn || "",
                round_start_date: savedData?.round_start_date || null,
                round_closing_date: savedData?.round_closing_date || null,
                round_status: savedData?.round_status || "",
                ...savedData
            }
            return savedValues
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchRoundDocuments = ({ round_id }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-roundss-fetch', round_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/round-documents/${round_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return data?.data?.data != undefined ? data?.data?.data?.documents : null
        },
        refetchOnWindowFocus: false,
        cacheTime: 0,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const UpdateStartupStatus = ({ investor_id }) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/startups_status/${investor_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
}

export const FetchAllStartupsListing = ({ status = "new", investor_id, page = 1, limit = 20, sort = -1, filter, queryParams, searchText = "" }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-sub-sub-sub-FetchAllStartupsListing-fetch-all' + status, status, investor_id, page, limit, sort],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-startup-list/${investor_id}?search=${searchText}&page=${page}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ''}&status=${status}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
                params: queryParams != null ? queryParams : {}
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data, "dddd")
            return { data: data.data.data?.data, page: data.data?.data.page, sort: data.data?.data.sort, limit: data.data?.data.limit, count: data.data?.data.count }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const InviteStartupPOST = () => {
    return useMutation(
        ({ payload, _id }) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/invite-startup/${_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveFeedback = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/submit-feedback`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveInterest = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/show_interested`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchInterestFeedbackStatus = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-investor-info`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};
export const SaveManageNotes = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/add-internal-notes`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const DeleteManageNotes = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/investors/delete-internal-notes`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchManageNotes = ({ investor_id, startup_id }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-managenotes-fetch', investor_id, startup_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-internal-notes/${investor_id}?startup_id=${startup_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data,'values new')
            return data?.data?.data != undefined ? data?.data?.data?.internal_notes && data?.data?.data?.internal_notes?.length > 0 ? data?.data?.data?.internal_notes : [] : []
        },
        refetchOnWindowFocus: false,
        cacheTime: 0,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllStartupFeedbacks = ({ id: startup_id, value, page = 1, limit = 10, sort = -1 }) => {
    // console.log(id,"id")
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-for-all-feedbacks-roundss-fffffff' + startup_id + value, startup_id, value, page, limit, sort],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/feedbacks/${startup_id}?page=${page}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ''}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return { data: data?.data?.data, count: data?.data?.count, limit: Number(data?.data?.limit) }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllStartupFeedbacksByInvestorID = () => {
    return useMutation(
        ({ payload, params }) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/feedbacks/${params?.startup_id}?page=${params?.page}&limit=${params?.limit}${params?.sort != 0 ? `&sort=${params?.sort}` : ''}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchFunding = ({ id: startup_id }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-fundings-fetch' + startup_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/list/rounds-listing/${startup_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data,'values new')
            return { data: data?.data?.data }

        },
        refetchOnWindowFocus: false,
        cacheTime: 0,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}