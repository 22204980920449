import React from 'react'
import AsyncTableListing from '../../ui-components/AsyncTableListing'
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader'
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper'
import { useTheme } from '@emotion/react'
import { ReactComponent as RoundDetailsIcon } from "assets/rounds_icons/round_details_icon.svg"
import { Autocomplete, Box, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, useMediaQuery } from '@mui/material'
import CustomTabs from '../../ui-components/CustomTabs'
import { TabContext } from '@mui/lab'
import { Add, CancelRounded, Description, DoneRounded, FiberNew, FiberNewRounded, FilterAltOutlined, FilterAltRounded, LanguageOutlined, LinkedIn, More, NewReleasesOutlined, Notes, PublishedWithChanges, RateReview, RocketLaunch, Search, ThumbDown, ThumbUp, UploadFile } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { amountInWords, getFileIcon } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
import CustomInputField from 'ui-components/CustomInputField'
import { B300, B400 } from 'ui-components/CustomButton'
import { ReactComponent as MoreOptionsIcon } from "assets/rounds_icons/more_solid_icon.svg";
import NeedHelpButton from 'ui-components/NeedHelpButton'
import ShortlistConfirmationPopup from "ui-components/popups/ConfirmationPopup"
import RejectedConfirmationPopup from "ui-components/popups/ConfirmationPopup"
import RequestDocumentsPopup from "ui-components/popups/ConfirmationPopup"
import Cookies from 'js-cookie'
import { FetchAllCategories, FetchAllStages, FetchAllSubCategories } from 'api/utils'
import { UpdateStartupStatus } from 'api/backrr_startups'
import { useSelector } from 'react-redux'
import { FetchAllStartupsListing, FetchInterestFeedbackStatus, InviteStartupPOST, SaveFeedback, SaveInterest } from 'api/backrr_startups'
import CustomToast from 'ui-components/CustomToast'
import AlertDialog from 'ui-components/popups/AlertPopup'
import toast from 'react-hot-toast'
import { ReactComponent as AnalyticsIcon } from '../../assets/nav_icons/rocket_1.svg';
import AllInvestor from './AllInvestor'
import BackrrInvestor from './BackrrInvestor'
import RegisteredInvestor from './RegisteredInvestor'
import DuplicateInvestor from './DuplicateInvestor'
import { FetchAllInvestorList } from 'api/backrr_investor'
import ImportInvestorPopup from './popups/ImportInvestorPopup'
import ModalComponent from 'ui-components/ModalComponent'
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    "&": {
      padding: "3rem"
    }
  }
}));

const StartupsListing = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.investorDetails?.data);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const startupTabs = [
    { value: "all", label: 'All', count: 10, read: false, icon: RocketLaunch },
    { value: "backrr", label: 'Backrr', count: 10, read: false, icon: CloudUploadOutlinedIcon },
    { value: "registered", label: 'Registered', count: 10, read: false, icon: HowToRegOutlinedIcon },
    { value: "duplicate", label: 'Duplicate', count: 10, read: false, icon: ContentCopyOutlinedIcon },
  ]
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [sort, setSort] = React.useState(-1);
  // const [nameSerch, setNameSerch] = React.useState("");
  const [count, setCount] = React.useState(0);
  const parsedCookieForQueryParams = Cookies.get("backrr_investors_query") ? JSON.parse(Cookies.get("backrr_investors_query")) : {}
  const [queryParams, setQueryParams] = React.useState(parsedCookieForQueryParams);
  // console.log(queryParams,"queryParams")
  const [stage, setStage] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [sector, setSector] = React.useState(null);
  const parsedCookieForStatus = Cookies.get("backrr_investors_status") || "all"
  const [value, setValue] = React.useState(parsedCookieForStatus || "all")
  const [search, setSearch] = React.useState("" || Cookies.get("search_backrr_investors"));
  const [reset, setReset] = React.useState(false);
  const [searchStatus, setSearchStatus] = React.useState(Object.keys(parsedCookieForQueryParams)?.length > 0 || search?.length > 0 || false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const refetchListing = () => {

  }
  const { data: startup_stages, isRefetching: isRefetchingAllStages, isLoading: isLoadingAllStages, refetch: refetchAllStages } = FetchAllStages();
  const { data: startup_categories, isRefetching: isRefetchingAllCategories, isLoading: isLoadingAllCategories, refetch: refetchAllCategories } = FetchAllCategories();
  const { data: startup_sub_categories, isRefetching: isRefetchingAllSubcategories, isLoading: isLoadingAllSubcategories, refetch: refetchAllSubcategories } = FetchAllSubCategories({ category: queryParams?.category });
  const handleTabChange = (event, newValue) => {
    // console.log("value", newValue)
    setValue(newValue);
    setCount(null)
    Cookies.set("backrr_investors_status", newValue);
    setPage(1);
  };
  const [currentRow, setCurrentRow] = React.useState(null);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [openImportInvestorModal, setOpenImportInvestorModal] = React.useState(false);
  const Actions = () => {
    return <>
      <B400 sx={{ display: 'flex', marginLeft: 'auto', background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }} startIcon={<UploadFile sx={{ width: 20, height: 20 }} />} onClick={() => setOpenImportInvestorModal(true)}>{isMediumScreen ? "" : "Import"}</B400>
    </>
  }
  React.useEffect(() => {
    refetchListing();
  }, [reset]);
  const stagesListing = startup_stages && startup_stages?.length > 0 ? startup_stages?.map(item => ({ label: item?.name, value: item?.name })) : []
  const categoriesListing = startup_categories && startup_categories?.length > 0 ? startup_categories?.map(item => ({ label: item?.name, value: item?.name })) : []
  const subCategoriesListing = startup_sub_categories && startup_sub_categories?.length > 0 ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?.sub_category })) : []

  const updateSector = (params) => {
    let removedSectorParamFromQuery = params;
    removedSectorParamFromQuery.sector && delete removedSectorParamFromQuery.sector;
    // Cookies.set("backrr_startups_query", JSON.stringify({ ...removedSectorParamFromQuery }));
    setQueryParams({ ...removedSectorParamFromQuery });
    setSector(null);
    setSearchStatus(false);
  }

  React.useEffect(() => {
    Cookies.remove("navigationState");
  }, [])


  return (
    <>
      {/* <ModalComponent open={openImportInvestorModal} onClose={handleImportInvestorClose} style={{ maxWidth: 640, width: "100%" }}>
                <ImportInvestorPopup  value={value} handleClose={handleImportInvestorClose} handleSubmit={handleImportInvestorSubmit} />
            </ModalComponent> */}
      <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
        <BreadcrumbHeader title={"Startups"} sx={{ paddingBottom: 0 }} Actions={() => <NeedHelpButton />} />
        <Grid container xs={12} padding={{ xs: "0 0rem 1rem 0rem", md: "0 0rem 2rem 0rem" }} justifyContent={"space-between"}>
          <TabContext value={value}>
            <Grid container xs={12} pt={1} pb={0} spacing={0} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
              {/* <Grid item xs={10} lg={10}> */}
              <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{ gap: "0.5rem" }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }} marginRight={"0rem"} mb={1}>
                <Grid item xs={12} sm={12} md={4} lg={3} style={{ display: 'flex', height: 'min-content', alignItems: 'center', gap: "0.5rem" }}>
                  <CustomInputField
                    autoComplete="off"
                    sx={{
                      marginBottom: 0,
                      "& > .MuiFilledInput-root": {
                        borderColor: `${theme.palette.customColors.b50} !important`,
                        borderRadius: '8px'
                      }
                    }}
                    fullWidth
                    value={search}
                    name={`search_${Math.random().toString(36).substring(7)}`}  // Set a random name attribute
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        // alert(e.target.value);
                        refetchListing()
                        if (search) {
                          setSearchStatus(true)
                        }
                        setPage(1);
                      }
                    }}
                    onChange={(event) => {
                      setSearch(event.target.value);
                      Cookies.set("search_backrr_investors", event.target.value);
                      setSearchStatus(false)
                    }}
                    size="small"
                    startAdornment={<>
                      <Search sx={{ padding: "0rem 0rem 0rem 0.5rem" }} />
                    </>}
                    placeholder={'Search by name'}
                  />
                  <B300 onClick={() => {
                    if (searchStatus && (search !== '')) {
                      setSearch('')
                      Cookies.set("search_backrr_investors", '');
                      setReset(!reset)
                    } else {
                      refetchListing()
                    }
                    setSearchStatus((oldVal) => !oldVal)
                    setPage(1);
                  }} sx={{ display: { xs: "block", md: 'none' }, padding: ".7rem 1.25rem", bgcolor: searchStatus && (search !== '') ? "#e9f5f8" : theme.palette.customColors.b300, ":hover": { bgcolor: searchStatus && (search !== '') ? "#e9f5f8" : theme.palette.customColors.b400 }, border: `0px solid ${searchStatus && (search !== '') ? "black" : "inherit"}`, color: searchStatus && (search !== '') ? theme.palette.customColors.b300 : "white" }}  >{(searchStatus && (search !== '')) ? "Reset" : "Search"}</B300>
                  <IconButton sx={{ marginLeft: "auto", display: { xs: 'flex', md: "none" }, marginBlock: 'auto' }} >
                    {Object.keys(queryParams)?.length > 0 ? <FilterAltRounded /> : <FilterAltOutlined />}
                  </IconButton>
                </Grid>
                <Grid item xs={0} sm={0} md={8} lg={9} display={{ xs: "none", md: "flex" }} container spacing={1} alignItems={"center"}>
                  <Grid item display={{ xs: "none", md: "block" }} width="25%">
                    <Autocomplete
                      disablePortal
                      options={stagesListing}
                      // disableClearable
                      fullWidth
                      // value={queryParams?.stage}
                      value={Object.keys(queryParams)?.length && queryParams?.stage ? { label: queryParams?.stage, value: queryParams?.stage } : null}
                      autoHighlight
                      onChange={(e, newValue) => {
                        // Cookies.set("backrr_startups_query", JSON.stringify({ ...queryParams, stage: newValue?.label }));
                        setQueryParams({ ...queryParams, stage: newValue?.label }); setStage(newValue); setSearchStatus(false)
                      }}
                      renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Stage"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                    />
                  </Grid>
                  <Grid item display={{ xs: "none", md: "block" }} width="25%">
                    <Autocomplete
                      disablePortal
                      // disableClearable
                      options={categoriesListing}
                      fullWidth
                      // value={queryParams?.category}
                      value={Object.keys(queryParams)?.length && queryParams?.category ? { label: queryParams?.category, value: queryParams?.category } : null}
                      autoHighlight
                      onChange={(e, newValue) => {
                        // Cookies.set("backrr_startups_query", JSON.stringify({ ...queryParams, category: newValue?.label })); 
                        setQueryParams({ ...queryParams, category: newValue?.label }); setCategory(newValue); setSearchStatus(false);
                        setTimeout(() => {
                          updateSector({ ...queryParams, category: newValue?.label })
                        }, 10)
                      }}
                      renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Category"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                    />
                  </Grid>
                  {/* {console.log(startup_sub_categories,"startup_sub_categories")} */}
                  {startup_sub_categories && queryParams?.category && queryParams?.category != null && <Grid item display={{ xs: "none", md: "block" }} width="25%">
                    <Autocomplete
                      disablePortal
                      // disableClearable
                      options={subCategoriesListing}
                      fullWidth
                      // value={queryParams?.sub_category}
                      value={Object.keys(queryParams)?.length && queryParams?.sector ? { label: queryParams?.sector, value: queryParams?.sector } : null}
                      autoHighlight
                      onChange={(e, newValue) => {
                        // Cookies.set("backrr_startups_query", JSON.stringify({ ...queryParams, sector: newValue?.label })); 
                        setQueryParams({ ...queryParams, sector: newValue?.label });
                        setSector(newValue);
                        setSearchStatus(false)
                      }}
                      renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Sector"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                    />
                  </Grid>}
                  <Grid item display={{ xs: "none", md: "block" }} width="25%">
                    <B300 onClick={() => {

                      if (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) {
                        setSearch('')
                        Cookies.set("search_backrr_investors", "");
                        setQueryParams({})
                        Cookies.set("backrr_investors_query", JSON.stringify({}));
                        setStage(null)
                        setCategory(null)
                        setSector(null);
                        // setStatus('')
                        setReset(!reset)
                      } else {
                        Cookies.set("backrr_investors_query", JSON.stringify({ ...queryParams }));
                        refetchListing()
                      }
                      setSearchStatus((oldVal) => !oldVal)
                      setPage(1);
                    }} sx={{ padding: ".7rem 1.25rem", bgcolor: (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? "#e9f5f8" : theme.palette.customColors.b300, ":hover": { bgcolor: (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? "#e9f5f8" : theme.palette.customColors.b400 }, border: `0px solid ${(searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? "black" : "inherit"}`, color: (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? theme.palette.customColors.b300 : "white" }}>
                      {(searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? "Reset" : "Search"}
                    </B300>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }} marginRight={"0rem"} >
                <Grid xs={12} container sx={{ bgcolor: 'white', borderRadius: '8px 8px 0 0', borderInline: `1px solid ${theme.palette.customColors.b50}`, borderBlockStart: `1px solid ${theme.palette.customColors.b50}` }}>
                  <Grid width="80%">
                    <CustomTabs
                      variant="submenu"
                      titleStyle={{ fontSize: '1rem' }}
                      tabStyle={{ padding: { xs: '0.5rem 1rem 0rem' } }}
                      sx={{ border: '0px solid black !important' }}
                      tabFlexContainerSx={{ gap: { xs: '1rem', sm: '2.5rem', md: '5rem' } }}
                      bgColor={"transparent"}
                      allowScrollButtonsMobile={true}
                      data={
                        [...startupTabs?.map(item => {
                          return ({ ...item, label: `${item?.label} ${value === item?.value && count && count !== null ? `(${count})` : ""}` })
                        })]
                      }
                      value={value} handleChange={handleTabChange}
                      isSmallScreen={isSmallScreen} className={classes.scrollTabs} key={"Tabs Tabs"} indicatorFullSize={false} />
                  </Grid>
                  <Grid width="20%" pt={0.75} pr={1} marginLeft={"auto"}>
                    <Actions />
                  </Grid>
                </Grid>
              </Grid>
              <Box width={"100%"} height="100%" paddingLeft={"2rem"} maxWidth={"100%"} overflow={"hidden"} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                {value === "all" && (
                  <AllInvestor setCount={setCount} count={count} limit={limit} setLimit={setLimit} page={page} setPage={setPage} search={search} setSearch={setSearch} sector={sector} stage={stage} category={category} searchStatus={searchStatus} reset={reset} queryParams={queryParams} openImportInvestorModal={openImportInvestorModal} setOpenImportInvestorModal={setOpenImportInvestorModal} />
                )}
                {value === "backrr" && (
                  <BackrrInvestor setCount={setCount} count={count} limit={limit} setLimit={setLimit} page={page} setPage={setPage} search={search} setSearch={setSearch} sector={sector} stage={stage} category={category} searchStatus={searchStatus} reset={reset} queryParams={queryParams} openImportInvestorModal={openImportInvestorModal} setOpenImportInvestorModal={setOpenImportInvestorModal} />
                )}
                {value === "registered" && (
                  <RegisteredInvestor setCount={setCount} count={count} limit={limit} setLimit={setLimit} page={page} setPage={setPage} search={search} setSearch={setSearch} category={category} searchStatus={searchStatus} reset={reset} sector={sector} stage={stage} queryParams={queryParams} openImportInvestorModal={openImportInvestorModal} setOpenImportInvestorModal={setOpenImportInvestorModal} />
                )}
                {value === "duplicate" && (
                  <DuplicateInvestor setCount={setCount} count={count} limit={limit} setLimit={setLimit} page={page} setPage={setPage} search={search} setSearch={setSearch} sector={sector} stage={stage} category={category} searchStatus={searchStatus} reset={reset} queryParams={queryParams} openImportInvestorModal={openImportInvestorModal} setOpenImportInvestorModal={setOpenImportInvestorModal} />
                )}
              </Box>
            </Grid>
          </TabContext>
        </Grid>
      </ThemeBGWrapper>
    </>
  )
}
export default StartupsListing