import { useTheme } from "@emotion/react";
import { Autocomplete, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { B300 } from "../../../ui-components/CustomButton";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../../ui-components/CustomInputField";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AutoComplete } from "rsuite";
import { FetchAllCategories, FetchAllStages, FetchAllSubCategories } from "api/utils";
import CustomLabel from "ui-components/CustomLabel";

const source_list = [
  { label: "Via Startup Profile", value: "startup_profile" },
  { label: "Via Pitch Deck", value: "pitch_deck" },
  { label: "Via Dashboard", value: "dashboard" },
  { label: "Via Investor Profile", value: "investor_profile" },
]

const FilterPopup = ({ queryParams, setQueryParams, handleClose, handleSubmit, stage, category, sector, handleReset, setStage, setCategory, setSector, setProfileSource }) => {
  const theme = useTheme();
  const { id: round_id } = useParams();
  const startup_id = useSelector((state) => state.investorDetails.data)?._id;
  const [filterQueryParams, setFilterQueryParams] = React.useState(queryParams);
  const { data: startup_stages, isRefetching: isRefetchingAllStages, isLoading: isLoadingAllStages, refetch: refetchAllStages } = FetchAllStages();
  const { data: startup_categories, isRefetching: isRefetchingAllCategories, isLoading: isLoadingAllCategories, refetch: refetchAllCategories } = FetchAllCategories();
  const { data: startup_sub_categories, isRefetching: isRefetchingAllSubcategories, isLoading: isLoadingAllSubcategories, refetch: refetchAllSubcategories } = FetchAllSubCategories({ category: filterQueryParams?.category });

  const stagesListing = startup_stages && startup_stages?.length > 0 ? startup_stages?.map(item => ({ label: item?.name, value: item?.name })) : []
  const categoriesListing = startup_categories && startup_categories?.length > 0 ? startup_categories?.map(item => ({ label: item?.name, value: item?.name })) : []
  const subCategoriesListing = startup_sub_categories && startup_sub_categories?.length > 0 ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?.sub_category })) : []

  const updateSector = (params) => {
    let removedSectorParamFromQuery = params;
    removedSectorParamFromQuery.sector && delete removedSectorParamFromQuery.sector;
    // Cookies.set("backrr_startups_query", JSON.stringify({ ...removedSectorParamFromQuery }));
    setFilterQueryParams({ ...removedSectorParamFromQuery });
    setSector(null);
  }

  const formik = useFormik({
    initialValues: {
      ...filterQueryParams
    },
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      // console.log(
      //   {
      //     invitations: [
      //       {
      //         startup_id: startup_id,
      //         startup_round_id: round_id,
      //         message: values?.message,
      //       },
      //     ],
      //     email: values?.email,
      //   },
      //   "values"
      // // );
      // handleSubmit({query});
      // console.log(filterQueryParams,"filterQueryParams")
      // console.log(values, "ffff")
      const result = Object.fromEntries(Object.entries(filterQueryParams).filter(([_, v]) => v !== undefined))
      handleSubmit({ query: result })
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "8px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Filter
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Simply add their email addresses and send the invitation.
              </Typography> */}

            </Grid>
            <Grid xs={12} item container columnSpacing={2} rowSpacing={2} justifyContent={"space-between"}>
              <Grid item xs={12} sm={6}>
                <CustomLabel>Stage</CustomLabel>
                <Autocomplete
                  disablePortal
                  options={stagesListing}
                  // disableClearable
                  fullWidth
                  // value={filterQueryParams?.stage}
                  value={Object.keys(filterQueryParams)?.length && filterQueryParams?.stage ? { label: filterQueryParams?.stage, value: filterQueryParams?.stage } : null}
                  autoHighlight
                  onChange={(e, newValue) => {
                    // Cookies.set("backrr_startups_query", JSON.stringify({ ...queryParams, stage: newValue?.label }));
                    setFilterQueryParams({ ...filterQueryParams, stage: newValue?.label }); setStage(newValue);
                  }}
                  renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Stage"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomLabel>Category</CustomLabel>
                <Autocomplete
                  disablePortal
                  // disableClearable
                  options={categoriesListing}
                  fullWidth
                  // value={filterQueryParams?.category}
                  value={Object.keys(filterQueryParams)?.length && filterQueryParams?.category ? { label: filterQueryParams?.category, value: filterQueryParams?.category } : null}
                  autoHighlight
                  onChange={(e, newValue) => {
                    // Cookies.set("backrr_startups_query", JSON.stringify({ ...filterQueryParams, category: newValue?.label })); 
                    setFilterQueryParams({ ...filterQueryParams, category: newValue?.label }); setCategory(newValue);
                    setTimeout(() => {
                      updateSector({ ...filterQueryParams, category: newValue?.label })
                    }, 10)
                  }}
                  renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Category"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                />
              </Grid>
              {startup_sub_categories && filterQueryParams?.category && filterQueryParams?.category != null && <Grid item xs={12} sm={6}>
                <CustomLabel>Sector</CustomLabel>
                <Autocomplete
                  disablePortal
                  // disableClearable
                  options={subCategoriesListing}
                  fullWidth
                  // value={filterQueryParams?.sub_category}
                  value={Object.keys(filterQueryParams)?.length && filterQueryParams?.sector ? { label: filterQueryParams?.sector, value: filterQueryParams?.sector } : null}
                  autoHighlight
                  onChange={(e, newValue) => {
                    // Cookies.set("backrr_startups_query", JSON.stringify({ ...filterQueryParams, sector: newValue?.label })); 
                    setFilterQueryParams({ ...filterQueryParams, sector: newValue?.label });
                    setProfileSource(newValue);
                  }}
                  renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Sector"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                />
              </Grid>}
              <Grid item xs={12} sm={6}>
                <CustomLabel>Source</CustomLabel>
                <Autocomplete
                  disablePortal
                  // disableClearable
                  options={source_list}
                  fullWidth
                  // value={filterQueryParams?.sub_category}
                  value={Object.keys(filterQueryParams)?.length && filterQueryParams?.profile_source ? { label: source_list?.find(item => item?.value === filterQueryParams?.profile_source)?.label, value: filterQueryParams?.profile_source } : null}
                  autoHighlight
                  onChange={(e, newValue) => {
                    // Cookies.set("backrr_startups_query", JSON.stringify({ ...filterQueryParams, sector: newValue?.label })); 
                    setFilterQueryParams({ ...filterQueryParams, profile_source: newValue?.value });
                    setSector(newValue);
                  }}
                  renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Source"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} gap={2} display={"flex"} flexWrap={"nowrap"} justifyContent={"center"}>
              <B300
                type="reset"
                onClick={() => {
                  handleSubmit({ query: {} })
                }}
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", bgcolor: "#e9f5f8", ":hover": { bgcolor: "#e9f5f8" }, color: theme.palette.customColors.b300, minWidth: 120 }}
              >
                Reset
              </B300>
              <B300
                type="submit"
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", bgcolor: theme.palette.customColors.b300, ":hover": { bgcolor: theme.palette.customColors.b400 }, minWidth: 120 }}
              >
                Filter
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default FilterPopup;
