import React, { useState } from 'react';
import { Formik, Form, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Box,
  useMediaQuery,
  Select,
  Divider,
  CircularProgress,
  capitalize,
  Card,
  Checkbox,
} from '@mui/material';
import { B400, BLUEBORDER, B300 as CustomButton, SECONDARY, WHITEBG } from '../../ui-components/CustomButton';
import { ReactComponent as DeleteIcon } from "../../assets/rounds_icons/delete_icon.svg";
import CustomInputField from '../../ui-components/formik/CustomInputField';
import CustomDatePicker from '../../ui-components/formik/CustomDatePicker';
import SelectField from '../../ui-components/CustomDropdown';
import CustomLabel from '../../ui-components/CustomLabel';
import CustomMenu from '../../ui-components/CustomMenu';
import PerfectScrollbar from "react-perfect-scrollbar";
import theme from '../../themes/theme';
import { ReactComponent as UploadDocumentsIcon } from "../../assets/rounds_icons/upload_documents_icon.svg";
import CustomTable from '../../ui-components/CustomTable';
import { TabContext, TabPanel } from '@mui/lab';
import { useTheme } from '@emotion/react';
import CustomTabs from '../../ui-components/CustomTabs';
import { makeStyles, styled } from '@mui/styles';
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper';
import { ReactComponent as RoundInvestorsIcon } from "../../assets/rounds_icons/round_investor_icon.svg"
// import { ReactComponent as ReportIcon } from '../../assets/nav_icons/life-buoy_nav_icon2.svg';
import { ReactComponent as ReportsIcon } from '../../assets/nav_icons/reports.svg';
import { DateRangePicker } from 'rsuite';
import format from 'date-fns/format';
import { ReactComponent as InfoIcon } from '../../assets/help-circle.svg';
import { Download, MailOutlineRounded, RemoveRedEyeOutlined } from '@mui/icons-material';
import ModalComponent from '../../ui-components/ModalComponent';
import InviteInvestorPopopup from './popups/InviteInvestorPopup';
import { AddReport, DeleteReportDocument, FetchAllDocumentTypes, FetchReportDetails } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader';
import CustomValidationText from '../../ui-components/CustomValidationText';
import CustomToast from '../../ui-components/CustomToast';
import toast from 'react-hot-toast';
import { containerRef, downloadFile, handleFormSubmit, hasPermission } from '../../utils';
import NeedHelpButton from 'ui-components/NeedHelpButton';
import AsyncTableListing from 'ui-components/AsyncTableListing';
import Cookies from 'js-cookie';
import CustomRichTextEditor from 'ui-components/formik/CustomRichTextEditor';

const RichTextDisplay = ({ htmlContent, color = "" }) => {
  const [open, setOpen] = React.useState(false);

  let content = open ? htmlContent : htmlContent?.substring(0, 300);

  return (
    <>
      {content && content?.length > 0 ?
        <>
          <div dangerouslySetInnerHTML={{ __html: content }} className='row rich-text' style={{ color: color, textAlign: 'justify', minHeight: '5rem', border: "1px solid #a1a3a7", borderRadius: '4px', padding: "1rem" }} />
          {htmlContent?.length > 300 &&
            <BLUEBORDER
              component="label"
              role={undefined}
              tabIndex={-1}
              sx={{
                backgroundColor: 'transparent',
                border: "0",
                padding: "0"
              }}
              onClick={() => setOpen(!open)}
            > <div>{!open ? 'See more' : 'See less'}</div>
            </BLUEBORDER>
          }
        </>
        : "-"
      }
    </>
  );
};


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    '& > .Mui-disabled.MuiTabs-scrollButtons': {
      display: 'none',
      transition: 'all 1s ease'
    },
  },
  DateRangePicker: {
    '& > .rs-picker-input-group > input': {
      fontSize: "1rem",
      padding: '13.2px 15px',
    },
    '& .rs-input-group:focus-within': {
      borderColor: theme.palette.customColors.g75,
      boxShadow: 'none',
      outline: 'none',
      borderRadius: '4px'


    },
    '& .rs-input-group': {
      borderColor: theme.palette.customColors.g75 + " !important",
      // borderRadius:'8px'
    }


  }
}));

const validationSchema = Yup.object({
  name: Yup.string().required('Report name is required'),
  overview: Yup.string().required('Overview is required'),
  duration: Yup.object().required("Duration is required").nonNullable("Duration is required"),
  report_documents: Yup.array().of(
    Yup.object().shape({
      document_name: Yup.string().required('Document name is required'),
      type_id: Yup.string()
        .required('Document type is required')
        .test("type-id", "Document type is required", (value) => value !== '0'),
      reports: Yup.string().required('Document file is required'),
    })
  ).required('At least one document is required')
});

const initialValues = {
  name: '',
  overview: '',
  duration: null,
  // duration: {
  //   from_date: '',
  //   to_date: ''
  // },
  report_documents: [
    {
      document_name: '',
      type_id: "0",
      reports: null
    }
  ]
};
const CardHeader = ({ title, action, actionText, actionStyle = {}, CardHeaderStyle = {} }) => {
  return <>
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"} sx={{ ...CardHeaderStyle }}>
      <Typography fontWeight={700} fontSize={"1.25rem"}>
        {title}
      </Typography>
      {/* {action && <WHITEBG
          component="label"
          role={undefined}
          tabIndex={-1}
          sx={{
              minWidth: "0 !important",
              border: `1px solid #D0D5DD !important`,
              display: "flex",
              gap: '0.5rem',
              paddingInline: '0.5rem 0.875rem',
              ...actionStyle,
          }}
          onClick={action}
      >
          <EditIcon
              style={{
                  width: 20,
                  height: 20,
              }} />
          <Typography>
              {actionText ? actionText : "Edit"}
          </Typography>
      </WHITEBG>} */}
    </Box>
    <Divider />
  </>
}

const CreateReport = () => {
  const userPermissions = useSelector((state) => state?.permissions?.userPermissions);
  const { data: document_types, isRefetching: isRefetchingDocumentTypes, isLoading: isLoadingDocumentTypes, refetch: refetchDocumentTypes } = FetchAllDocumentTypes()
  const startupDetails = useSelector((state) => state?.startupDetails?.data);
  const [step, setStep] = useState(1);
  // const { id: report_id } = useParams();
  const {
    mutateAsync: addReportAsync,
    isLoading: isAddingReport,
    status: addReportStatus,
    error: addReportError,
    data: addReportResponse
  } = AddReport();
  const {
    mutateAsync: deleteReportDocumentAsync,
    isLoading: isdeletingReportDocument,
    status: deleteReportDocumentStatus,
    error: deleteReportDocumentError,
    data: deleteReportDocumentResponse
  } = DeleteReportDocument();

  // const reportDetailData = Cookies.get("reportDetailData") ? JSON.parse(Cookies.get("reportDetailData")) : {
  //   report_name: 'MIS Q1 24',
  //   name: 'Redesyn',
  //   image: "https://startups-prod-pub.s3.ap-south-1.amazonaws.com/redesyn/logo.jpeg",
  //   email: 'puneetmadaan369@gmail.com',
  //   duration: '01-Mar-2024 - 30-Jun-2024',
  //   overview: 'Here is the MIS report of quarter 1 of 2024',
  // }

  const report_id = Cookies.get("report_id");

  // console.log(report_id,"report_id")


  const [roundID, setRoundID] = React.useState(report_id || null)
  const { data: reportDetailData, isRefetching: isRefetchingReportsDetail, isLoading: isLoadingReportDetail, refetch: refetchReportDetail } = FetchReportDetails(roundID)
  // console.log(reportDetailData ,"+++++++++++++++++++++++")
  const isEditMode = !!report_id
  const reportTabs = [
    {
      label: `View Report`,
      icon: ReportsIcon,
      useFill: true,
      value: "report",
    },
    // {
    //   label: "Investors",
    //   icon: RoundInvestorsIcon,
    //   value: "investors",
    // },
  ]

  const handleSubmit = (values) => {
    // console.log(values);
    const formData = new FormData();
    formData.append("name", values.name)
    formData.append("overview", values.overview)
    formData.append("from_date", values?.duration?.from_date)
    formData.append("to_date", values?.duration?.to_date)

    values?.report_documents?.forEach((doc, index) => {
      if (doc?.reports?.size) {
        formData.append(`document_name[${index}]`, doc?.document_name)
        formData.append(`type_id[${index}]`, doc?.type_id)
        formData.append(`reports${index}`, doc?.reports)
      } else {
        formData.append(`document_name[${index}]`, doc?.document_name)
        formData.append(`type_id[${index}]`, doc?.type_id)
        formData.append(`reports${index}`, doc?.reports)
      }
    });

    formData.append(`startup_id`, startupDetails?._id)

    if (isEditMode) {
      if (value != "investors") {
        formData.append("report_id", roundID)
        addReportAsync(formData)
      }
      else {
        navigate("/reports")
      }
    }
    else {
      if (value != "investors") {
        addReportAsync(formData)
      }
      else {
        navigate("/reports")
      }
    }

  };

  // reportDetailData?.documents = [
  //   {
  //     "_id": "674031eec9ac2f12698967a8",
  //     "filename": "document-1732260334149.pdf",
  //     "document_typesss": "Monthly MIS Report",
  //     "tags": [],
  //     "owner_id": "66fbc305c05bcd8c5743ad7e",
  //     "report_id": "674031eec9ac2f12698967a6",
  //     "startup_id": "66fbc3b99ce0f2c56165bd23",
  //     "type_id": "6704cd14c110f788508ad8ba",
  //     "document_name": "Monthly MIS",
  //     "shared_with": [],
  //     "__v": 0,
  //     "document_url": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/reports/document-1732260334149.pdf"
  //   },
  //   {
  //     "_id": "6745cb0c59459811f8d2d14e",
  //     "filename": "download_(1)-1732627211402.jpeg",
  //     "document_typesss": "Budget MIS Report",
  //     "tags": [],
  //     "owner_id": "66fbc305c05bcd8c5743ad7e",
  //     "report_id": "674031eec9ac2f12698967a6",
  //     "startup_id": "66fbc3b99ce0f2c56165bd23",
  //     "type_id": "6704cd14c110f788508ad8b5",
  //     "document_name": "Dfgfg",
  //     "shared_with": [],
  //     "__v": 0,
  //     "document_url": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/reports/download_(1)-1732627211402.jpeg"
  //   },
  //   {
  //     "_id": "6745cb0c59459811f8d2d150",
  //     "filename": "sample_grey_background-1732627211379.pdf",
  //     "document_typesss": "Sales MIS Report",
  //     "tags": [],
  //     "owner_id": "66fbc305c05bcd8c5743ad7e",
  //     "report_id": "674031eec9ac2f12698967a6",
  //     "startup_id": "66fbc3b99ce0f2c56165bd23",
  //     "type_id": "6704cd14c110f788508ad8b5",
  //     "document_name": "Yugwe",
  //     "shared_with": [],
  //     "__v": 0,
  //     "document_url": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/reports/sample_grey_background-1732627211379.pdf"
  //   }
  // ]

  const formik = useFormik({
    initialValues: reportDetailData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true,
  })

  React.useEffect(() => {
    if (addReportResponse && addReportResponse?.data?.statusCode == 200) {
      // if (step == 2 && value !== "investors") {
      //   // toast.success(`Report ${!isEditMode ? 'Created' : 'Updated'}!`)
      //   setValue("investors");

      //   addReportResponse?.data?.data?._id && navigate(`/reports/${window.location.pathname?.includes("create_report") ? "create_report" : 'edit_report'}/${addReportResponse?.data?.data?._id}`)
      //   addReportResponse?.data?.data?._id && setRoundID(addReportResponse?.data?.data?._id)
      //   addReportResponse?.data?.data?._id && refetchReportDetail()
      //   addReportResponse?.data?.data?._id && formik.setFieldValue("report_documents", [])
      //   // addReportResponse?.data?.data?._id && 
      //   window.document
      //     .getElementById("sidebar-scroller")
      //     .scrollTo(0, 0);

      // }
      // else 
      if (step == 1 && value !== "investors") {
        // toast.success(`Report ${!isEditMode ? 'Created' : 'Updated'}!`)
        navigate("/reports");
      }
      else {
        navigate("/reports");
      }
    }
    else if (addReportResponse && addReportResponse?.data?.statusCode != 200) {
      // toast.error(
      //   <CustomToast message={createRoundError?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
      //   {
      //     duration: 2000,
      //     position: "top-right",
      //   }
      // );
      // toast.error("Internal Server Error")
      // console.log("error", addReportError)
    }
  }, [addReportResponse?.data?.statusCode]);
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let stepIndex = new URLSearchParams(window.location.search).get("page");
  const [value, setValue] = React.useState(stepIndex || "report")
  const columns = React.useMemo(
    () => [
      { Header: 'Document Name', accessor: 'document_name', minWidth: 300 },
      { Header: 'Document Type', accessor: 'type_info.name', minWidth: 200 },
      {
        Header: "Actions", accessor: 'actions', minWidth: 100, align: 'center'
      },
    ],
    []
  );
  // const documents = [
  //   {
  //       filename: 'Tech requirements.pdf',
  //       type: 'MIS Reports',
  //   },
  //   {
  //       filename: 'Tech requirements.pdf',
  //       type: 'MIS Reports',
  //   }
  // ]


  const columnsInvestors = React.useMemo(
    () => [
      { Header: "", accessor: 'checkbox', minWidth: 50 },
      { Header: 'Name', accessor: 'name', minWidth: 200 },
      { Header: 'Sent', accessor: 'amountCommitted', minWidth: 200 },
      { Header: 'Viewed', accessor: 'amountAllocated', minWidth: 100 },

    ],
    []
  );
  const dataInvestors = React.useMemo(
    () => [
      {
        "name": "Olivia Rhye",
        "amountCommitted": "20 July, 2024",
        "amountAllocated": "Yes",
      },
      {
        "name": "Phoenix Baker",
        "amountCommitted": "19 July, 2024",
        "amountAllocated": "Yes",
      },
      {
        "name": "Lana Steiner",
        "amountCommitted": "Send Now",
        "amountAllocated": "No",
      },
      {
        "name": "Candice Wu",
        "amountCommitted": "Send Now",
        "amountAllocated": "No",
      },
    ]
    ,
    []
  );
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [openInvitePopup, setOpenInvitePopup] = React.useState(false);

  const handleInvitePopupOpen = () => {
    setOpenInvitePopup(true);
  }

  const handleInvitePopupClose = () => {
    setOpenInvitePopup(false);
  }

  const handleInviteInvestor = () => {
    handleInvitePopupClose()
  }

  const Actions = () => {
    return <React.Fragment>
      <B400 sx={{ background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem" }} startIcon={<MailOutlineRounded sx={{ width: 20, height: 20 }} />} onClick={() => handleInvitePopupOpen()}>Invite{isSmallScreen ? "" : " Investor"}</B400>
    </React.Fragment>
  }
  const RowActions = ({ row }) => {
    // console.log(formik?.rows?.original,"formik")
    // let document_id = formik?.row?.original?._id;
    return <React.Fragment>
      {/* <IconButton onClick={() => {
        if (window.confirm("Are you sure you want to delete this document?") == true) {
          deleteReportDocumentAsync({ document_id: document_id }, {
            onSuccess: (succ) => {
              // console.log(succ, 'succ')
              toast.success(
                <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                {
                  duration: 2000,
                  position: "top-right",
                }
              );
              // refetchReportDetail();
            },
            onError: (err) => {
              toast.error(
                <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                {
                  duration: 2000,
                  position: "top-right",
                }
              );
            }
          });
        }
        else {
          return;
        }

      }
      }>
        <DeleteIcon />
      </IconButton> */}

      <Box width={"100%"} textAlign={"center"}>
        {/* <Typography sx={{ cursor: 'pointer' }} onClick={() => { window.open(row?.original?.document_url) }} textAlign={"center"} color={"#0075FF"} fontSize={"0.875rem"}>
                View Document
            </Typography> */}
        <IconButton onClick={() => { window.open(row?.original?.document_url) }}>
          <RemoveRedEyeOutlined style={{ color: theme.palette.customColors.indigo600 }} />
        </IconButton>
        <IconButton onClick={() => {
          downloadFile(row?.original?.document_url)
        }}>
          <Download style={{ color: theme.palette.customColors.indigo600 }} />
        </IconButton>
      </Box>
    </React.Fragment>
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.document
      .getElementById("sidebar-scroller")
      .scrollTo(0, 0);
  }

  const BreadCrumbActions = () => {
    return (
      <>
        {/* {(isCreatingRound || isUploadingDocuments) && matchesUpMd && <Typography sx={{ marginRight: "1rem" }} display={"flex"} alignItems={'center'} gap={1} fontSize={"14px"} fontWeight={600} color={theme.palette.customColors.g200}>
                    <SavingIcon width={24} height={24} className="saving-icon" />
                    <span>Saving...</span>
                </Typography>} */}
        <NeedHelpButton />
      </>
    );
  };

  const scrollbarRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  const scrollToBottom = () => {

    // Find the first visible validation message
    setTimeout(() => {
      if (buttonRef && buttonRef.current && buttonRef.current.offsetParent !== null) {
        buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 10)
  }

  return (
    <ThemeBGWrapper>
      <BreadcrumbHeader
        title={isLoadingReportDetail ? "Loading..." : `${isEditMode ? `Edit ${reportDetailData?.report?.name?.length > 0 ? "(" + reportDetailData?.report?.name + ")" : "Report"}` : "Add"}`}
        // title={isLoadingRoundDetails ? "Loading..." : isEditMode ? `Edit ${matchesUpMd ? roundDetails?.round_name : 'Report'}` : 'Create Report'}
        Actions={BreadCrumbActions}
      />
      {(isLoadingReportDetail && isEditMode && !(window.location.pathname?.includes("create_report"))) ?
        <Grid container xs={12} display={"flex"} height={"100%"} maxHeight={"85vh"} sx={{ padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" }, width: '100%' }}>
          <Grid item xs={12} className="skeleton" height={"100%"} width={"100%"} borderRadius={"24px"}>

          </Grid>
        </Grid> :
        <>
          <Grid
            container
            xs={12}
            sx={{
              // height: "calc(100vh - 118px)",
              height: '100%',
              maxHeight: { xs: "90%", sm: "93%", md: '85vh' },
              overflow: "hidden",
              padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" },
            }}
          >
            <Grid
              xs={12}
              item
              container
              sx={{
                backgroundColor: "#FFFAF5",
                borderRadius: { xs: "24px", sm: "24px" },
                height: "100%",
              }}
            >
              <TabContext value={step}>
                <Grid
                  item
                  container
                  // xs={12}
                  // // sm={5}
                  // md={6}
                  // lg={7.4}
                  sx={{
                    width: { xs: "100%", md: '60%', lg: '60%', xl: '70%' }, padding: { xs: '1rem', md: '2rem', lg: "2.5rem" }
                    , paddingBlock: "0.5rem !important"
                  }}
                  // gap={3}
                  gap={"1rem"}
                  marginTop={0}
                  marginInline={"auto"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  // alignContent={"space-around"}
                  alignContent={"space-between"}
                  justifyContent={"space-between"}
                  // padding={"2.5rem"}
                  paddingBottom={0}
                >
                  {/* {!matchesUpMd &&
                    <Box width={"100%"}>
                      {reportTabs &&
                        reportTabs?.length > 0 ?
                        <>
                          <Typography marginInline={"auto"} flexWrap={"wrap"} variant="h6"
                            fontSize="1rem"
                            color={theme.palette.customColors.g300}
                            fontWeight={700} textAlign={"center"} marginBottom={1}>
                            <span>{reportTabs[currentIndex]?.label} ({`${currentIndex + 1}/${reportTabs?.length}`})</span>
                            <Divider sx={{ marginTop: 1, marginBottom: 2, marginInline: 'auto', maxWidth: '50%' }} />
                          </Typography>
                        </>
                        : ''}
                    </Box>
                  } */}

                  {/* <TabPanel sx={{ paddingInline: 0 }} value={0}> */}
                  <FormikProvider value={formik}>
                    <Form onSubmit={e => e.preventDefault()}>

                      <Card sx={{ borderRadius: "10px", border: `1px solid ${theme.palette.customColors.b50}`, overflow: 'hidden', boxShadow: 'none', paddingTop: "1rem" }}>
                        <CardHeader title={"Report Details"} CardHeaderStyle={{ paddingInline: "1rem" }} />
                        <PerfectScrollbar containerRef={containerRef} style={{
                          minHeight: "58vh",
                          height: "100%",
                          width: "100%",
                          maxHeight: "calc(-270px + 100vh)",
                          paddingRight: "1rem",
                          marginTop: 0,
                          paddingBottom: "1rem",
                        }}
                          ref={scrollbarRef}
                          id='sidebar-scroller'
                          className="scrollbar-container"
                        >
                          <Grid container columnSpacing={2} rowSpacing={0} sx={{ paddingInline: "1rem", paddingTop: '1rem' }}>
                            <Grid item xs={12} sm={6} className='report-read-only'>
                              <CustomInputField
                                fullWidth
                                name="name"
                                label="NAME"
                                placeholder="Enter Name"
                                disabled={true}
                                value={formik?.values?.report?.name}
                              // onChange={(e) => formik?.setFieldValue("name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
                              // sx={{
                              //   marginBottom: "0.5rem"
                              // }}
                              />
                            </Grid>


                            <Grid item xs={12} sm={6} height={"min-content"} className='report-read-only'>
                              <CustomLabel>DURATION</CustomLabel>
                              <DateRangePicker
                                className={`${classes.DateRangePicker}`}
                                ranges={[]}
                                style={{ width: '100%' }}
                                // placeholder={formik.values?.duration}
                                format="dd-MMM-yyyy"
                                disabled={true}
                                onChange={(value) => {
                                  formik?.setFieldValue(
                                    'duration',
                                    value !== null
                                      ? { from_date: moment(value[0])?.toISOString(), to_date: moment(value[1])?.toISOString() }
                                      : null
                                  );
                                }}
                                value={
                                  formik.values?.duration !== null
                                    ? [new Date(formik.values?.report?.duration?.from_date), new Date(formik.values?.report?.duration?.to_date)]
                                    : null
                                }
                                renderValue={([from_date, to_date]) => {
                                  return (
                                    format(from_date, 'dd-MMM-yyyy') + ' - ' + format(to_date, 'dd-MMM-yyyy')
                                  );
                                }}
                              />

                              <CustomValidationText value={formik.errors.duration} />
                            </Grid>


                            <Grid item xs={12} marginTop={{ xs: 2, sm: 0 }} marginBottom={2} className='report-read-only'>
                              <CustomLabel>Overview</CustomLabel>
                              <RichTextDisplay htmlContent={formik?.values?.report?.overview} color='rgba(0, 0, 0, 0.38)' />
                            </Grid>

                            {/* <FieldArray
                          name="report_documents"
                          render={(arrayHelpers) => (
                            <React.Fragment>
                              {formik?.values?.report_documents?.length > 0 &&
                                formik?.values.report_documents?.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <Grid container item columnSpacing={2}>
                                      <Grid item xs={12} sm={3} pt={1}>
                                        <CustomInputField
                                          name={`report_documents.${index}.document_name`}
                                          label="DOCUMENT NAME"
                                          placeholder="Enter document name"
                                          value={item?.document_name}
                                          onChange={(e) => formik?.setFieldValue(`report_documents.${index}.document_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
                                          fullWidth
                                          sx={{
                                            '& > .MuiInputBase-root > input.MuiInputBase-input': { padding: "13.5px 14px 13.5px 14px" },
                                            borderRadius: '8px',
                                            height: "100%",
                                            margin: 0,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3} height={"min-content"} pt={1}>
                                        <CustomLabel>DOCUMENT TYPE</CustomLabel>
                                        <Select
                                          size="small"
                                          fullWidth
                                          name={`report_documents.${index}.type_id`}
                                          placeholder='Select document type'
                                          value={item?.type_id}
                                          // onChange={(e) => {}
                                          //   // formik.setFieldValue(
                                          //   //   "runway_left",
                                          //   //   e.target.value
                                          //   // )
                                          // }
                                          onChange={formik?.handleChange}
                                          sx={{
                                            background: theme.palette.customColors.whiteBG,
                                            paddingBlock: "6px",
                                            margin: 0,
                                          }}
                                        >
                                          <MenuItem value={"0"}>Select document type</MenuItem>
                                          {document_types && document_types?.length > 0 && document_types?.filter(item => item?.name != "Pitch Deck" && item?.name != "Pitch Deck Round")?.map((type, index) => {
                                            return <MenuItem key={index} value={type?.value}>{type?.name}</MenuItem>
                                          })}
                                        </Select>
                                        {formik?.errors?.report_documents && formik.errors.report_documents[index] && formik.errors.report_documents[index].type_id && <CustomValidationText value={formik?.errors?.report_documents[index]?.type_id} />}
                                      </Grid>
                                      <Grid item xs={12} sm={3} pt={1}>
                                        <CustomLabel>UPLOAD FILE</CustomLabel>
                                        <CustomMenu>
                                          <WHITEBG
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            fullWidth
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              border: `1px solid ${theme.palette.customColors.g75}`,
                                              borderRadius: "8px",
                                              height: "52px",
                                              padding: "10px, 14px, 10px, 14px",
                                              color: '#7A7D83',
                                              textTransform: 'none'
                                            }}
                                          >
                                            {item?.reports != null ? String(item?.reports?.name)?.length > 19 ? String(item?.reports?.name)?.substring(0, 19) + "..." : item?.reports?.name : "Upload"}
                                            <UploadDocumentsIcon
                                              style={{
                                                width: 15,
                                                height: 15,
                                                stroke: "#344054",
                                                padding: "8px",
                                                borderRadius: "8px",
                                                border: `1px solid ${theme.palette.customColors.gray300}`,
                                              }}
                                            />
                                            <VisuallyHiddenInput
                                              accept=".pdf,.png,.jpeg,.jpg,.mp4,.mov,.xlsx,.csv,.xls,.doc,.docx"
                                              type="file"
                                              name={`report_documents.${index}.reports`}
                                              //  value={item?.reports}
                                              id={`report_documents.${index}.reports`}
                                              onChange={(e) => {
                                                const file = e.target.files[0];
                                                formik.setFieldValue(`report_documents.${index}.reports`, file);
                                              }
                                              }
                                            />
                                          </WHITEBG>
                                        </CustomMenu>
                                        {formik?.errors?.report_documents && formik.errors.report_documents[index] && formik?.errors.report_documents[index]?.reports && <CustomValidationText value={formik?.errors.report_documents[index]?.reports} />}
                                      </Grid>

                                      <Grid item xs={12} sm={3} pt={1}>
                                        <CustomLabel sx={{ opacity: 0, display: { xs: 'none', sm: "block" } }}> Delete</CustomLabel>

                                        <Grid item xs={12}>
                                          <BLUEBORDER

                                            onClick={() => {
                                              arrayHelpers.remove(index);

                                            }}
                                            fullWidth
                                            sx={{
                                              color: theme.palette.customColors.r200,
                                              padding: "12px, 24px, 12px, 24px",
                                              border: `1px solid ${theme.palette.customColors.r100}`,
                                              height: "52px",
                                            }}
                                          >
                                            Delete
                                          </BLUEBORDER>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                ))}
                              <Grid container item columnSpacing={2}>
                                <Grid item xs={12} sm={3} pt={2} ml={"auto"}>
                                  <BLUEBORDER
                                    ref={buttonRef}
                                    onClick={() => {
                                      arrayHelpers.insert(formik.values?.report_documents?.length, {
                                        document_name: '',
                                        type_id: "0",
                                        reports: null
                                      });
                                      scrollToBottom()
                                    }}
                                    fullWidth
                                    sx={{
                                      // padding: "12px, 24px, 12px, 24px",
                                      border: `1px solid ${theme.palette.customColors.b75}`,
                                      height: "52px",
                                    }}
                                  >
                                    Add Document
                                  </BLUEBORDER>

                                </Grid>
                              </Grid>
                            </React.Fragment>
                          )}
                        /> */}

                            {/* {console.log(reportDetailData, 'reportDetailData?.documents')} */}
                            {reportDetailData?.documents && reportDetailData?.documents?.length > 0 && <Grid item container xs={12} paddingTop={1} id="report-documents-table" maxHeight={"100%"}>
                              <AsyncTableListing sx={{ borderRadius: "8px" }} columns={columns} data={reportDetailData?.documents} actions={() => { }} RowActions={RowActions} backgroundColor="#fff" />
                            </Grid>}

                          </Grid>
                        </PerfectScrollbar>
                      </Card>
                    </Form>
                  </FormikProvider>
                  {/* </TabPanel> */}
                  <Grid
                    item
                    container
                    display={"flex"}
                    xs={12}
                    sm={12}
                    height={"min-content"}
                    gap={"1.5rem"}
                    justifyContent={"center"}
                    padding={{ xs: "0 0rem 1rem 0rem", md: "0 0rem 0rem 0rem" }}
                  >
                    {/* {currentIndex !== 1 &&  */}
                    {/* {currentIndex !== 0 && <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                    <BLUEBORDER
                      sx={{
                        textTransform: "none",
                        padding: 2,
                        borderWidth: 1,
                        borderRadius: "8px",
                      }}
                      type="no usetr"
                      onClick={() => {
                        if (currentIndex === 0) {
                          // navigate(-1);
                        } else {
                          setCurrentIndex(currentIndex - 1);
                        }
                        window.document
                          .getElementById("scrollable-form-round")
                          .scrollTo(0, 0);
                        // window.scrollTo(0, 0);
                      }}
                      fullWidth
                    >
                      Back
                    </BLUEBORDER>
                  </Grid>} */}
                    {/* } */}
                    <Grid xs={5} sm={5} md={4} lg={4} item>
                      <BLUEBORDER
                        sx={{
                          textTransform: "none",
                          padding: 2,
                          borderWidth: 1,
                          borderRadius: "8px",
                        }}
                        disabled={isAddingReport}
                        // sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' } }}
                        onClick={(e) => {
                          navigate(-1)
                          // formik.resetForm();
                          // handleFormSubmit(e, formik);
                          // formik.setValues({ ...initialValues, month: savedMetricDataByID?.month });
                        }}
                        fullWidth
                      >
                        Close
                      </BLUEBORDER>
                    </Grid>
                    {/* <Grid xs={5} sm={5} md={4} lg={4} item>
                      <CustomButton
                        sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' } }}
                        onClick={(e) => {
                          // formik.handleSubmit();
                          // handleSubmit(e)
                          handleFormSubmit(e, formik)
                        }}
                        disabled={!hasPermission("reports", "edit", userPermissions) || !hasPermission("reports", "add", userPermissions) || isAddingReport}
                        // onClick={() => { setCurrentIndex(currentIndex + 1) }}
                        fullWidth
                      >
                        {(isAddingReport) && !matchesUpMd ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : step == "reports" ? "Submit" : "Next"}
                      </CustomButton>
                    </Grid> */}
                  </Grid>
                </Grid>
              </TabContext>
            </Grid>
          </Grid>
        </>}
    </ThemeBGWrapper>
  )
}

export default CreateReport