import { useTheme } from '@emotion/react'
import { Box, Card, Chip, Grid, IconButton, Rating, Tooltip, useMediaQuery } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import AsyncTableListing from 'ui-components/AsyncTableListing'
import { FetchAllMatchedInvestorList } from 'api/backrr_startups'
import { convertCurrencyAmountRange } from 'utils/index'
import placeHolderImage from "assets/no_data_images/BackrrStartups/no_data_feedbacks_views.svg"


const MatchedInvestors = ({startup_id ,setCount, count}) => {
    const theme = useTheme()
  const [allInvestorData, setAllInvestorData] = React.useState(null);
  const { mutateAsync: fetchAllInvestor, isLoading: isLoadingfetchAllInvestor, status: fetchAllInvestorStatus, error: fetchAllInvestorError } = FetchAllMatchedInvestorList();
    const column = [
      { Header: 'Investor Name', accessor: 'name', minWidth: 230, align: 'start', fixed: true },
      { Header: 'Type', accessor: 'formatted_investor_type', minWidth: 100, align: 'center' },
      { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
      { Header: 'Stage', accessor: 'focused_stage', minWidth: 150, align: 'center' },
      { Header: 'Categories', accessor: 'focused_categories', minWidth: 150, align: 'center', },
      { Header: 'Sectors', accessor: 'focused_sectors', minWidth: 150, align: 'center', },
      { Header: 'Business Models', accessor: 'focused_business_models', minWidth: 200, align: 'center', },
      { Header: 'Cheque Size', accessor: 'cheque_size', minWidth: 150, align: 'center' },
      // { Header: 'Actions', accessor: 'actions', minWidth: 100 },
  ];  
    const [page, setPage] = React.useState(1);
    const userDetails = useSelector((state) => state?.investorDetails?.data);
    // console.log(userDetails,"nameee")
    const [limit, setLimit] = React.useState(10);
    const refetchListing = () => {
      startup_id && fetchAllInvestor(
        {
          payload: {
            page,
            limit,
          },
          startup_id: startup_id,
        },
        {
          onSuccess: (res) => {
            if (res.data.data) {
              setAllInvestorData(res.data.data)
              setCount(res?.data?.totalCount)

            }
            else {
              setAllInvestorData([])
            }
            // setCount(res?.data?.totalCount)
  
          },
          onError: (err) => {
            console.error("API Error:", err);
          },
        })
    };
    React.useEffect(() => {
      refetchListing()
    }, [page, limit,startup_id]);

    const useFormattedData = (transformedData) => {
      return React.useMemo(() => {
        return transformedData && transformedData?.length > 0
          ? transformedData?.map((item) => {
            // console.log(item?.preferences)    
            return ({
              ...item,
              formatted_investor_type: item.investor_type
                ? String(item.investor_type)
                  .replaceAll("_", " ")
                  .toLowerCase()
                  .split(" ")
                  .map((word) => {
                    // Capitalize "VC" and "Micro VC" correctly
                    if (word.toLowerCase() === "vc") return "VC";
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  })
                  .join(" ")
                : '-',
              image: item?.custom_profile_picture_url,
              name: `${item?.personal_information?.first_name || ""} ${item?.personal_information?.last_name || ""}`.trim(),
              location: item?.personal_information?.city_id?.name && item?.personal_information?.country_id?.name ? `${item?.personal_information?.city_id?.name}, ${item?.personal_information?.country_id?.name}` : "-",
              focused_stage: item?.preferences?.startup_stages && item?.preferences?.startup_stages?.length > 0
                ? item?.preferences?.startup_stages?.length > 1
                  ?
                  <Tooltip title={item?.preferences?.startup_stages?.map((item) => <span> {item?.name} <br /></span>)}>
                    <Chip style={{ borderRadius: '4px' }} label={item?.preferences?.startup_stages[0]?.name + " + " + String(item?.preferences?.startup_stages?.length - 1)} />
                  </Tooltip>
                  : item?.preferences?.startup_stages[0]?.name : '-',
              focused_categories: item?.preferences?.category_id && item?.preferences?.category_id?.length > 0
                ? item?.preferences?.category_id?.length > 1
                  ?
                  <Tooltip title={item?.preferences?.category_id?.map((item) => <span> {item?.name} <br /></span>)}>
                    <Chip style={{ borderRadius: '4px' }} label={item?.preferences?.category_id[0]?.name + " + " + String(item?.preferences?.category_id?.length - 1)} />
                  </Tooltip>
                  : item?.preferences?.category_id[0]?.name : '-',
              focused_sectors: item?.preferences?.sector_type === "All" ? "Sector Agnostic" : item?.preferences?.preferred_sectors && item?.preferences?.preferred_sectors?.length > 0
                ? item?.preferences?.preferred_sectors?.length > 1
                  ?
                  <Tooltip title={item?.preferences?.preferred_sectors?.map((item) => <span> {item?.name} <br /></span>)}>
                    <Chip style={{ borderRadius: '4px' }} label={item?.preferences?.preferred_sectors[0]?.name + " + " + String(item?.preferences?.preferred_sectors?.length - 1)} />
                  </Tooltip>
                  : item?.preferences?.preferred_sectors[0]?.name : '-',
              focused_business_models: item?.preferences?.business_model_type === "All" ? "All Selected" : item?.preferences?.business_model_id && item?.preferences?.business_model_id?.length > 0
                ? item?.preferences?.business_model_id?.length > 1
                  ?
                  <Tooltip title={item?.preferences?.business_model_id?.map((item) => <span> {item?.name} <br /></span>)}>
                    <Chip style={{ borderRadius: '4px' }} label={item?.preferences?.business_model_id[0]?.name + " + " + String(item?.preferences?.business_model_id?.length - 1)} />
                  </Tooltip>
                  : item?.preferences?.business_model_id[0]?.name : '-',
              cheque_size:
                item?.investment_size_preference?.min_amount && item?.investment_size_preference?.max_amount ? (`${item?.investment_size_preference?.currency === "INR"
                  ? "₹" :
                  item?.investment_size_preference?.currency === "USD"
                    ? "$" :
                    ""}${convertCurrencyAmountRange(item?.investment_size_preference?.min_amount, item?.investment_size_preference?.currency, 0)} - 
                      ${item?.investment_size_preference?.currency === "INR" ? "₹" : item?.investment_size_preference?.currency === "USD" ? "$" : ""}${convertCurrencyAmountRange(item?.investment_size_preference?.max_amount, item?.investment_size_preference?.currency, 0)}`.trim()) : "-",
            })
          })
          : [];
      }, [transformedData]);
    };
    const FormattedData = useFormattedData(allInvestorData);
    return (
        <>
            <Grid container xs={12}>
                {!allInvestorData || isLoadingfetchAllInvestor ?
                    <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                        <Box className='skeleton' minHeight={"70vh"} sx={{ borderRadius: "8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

                        </Box>
                    </Grid>
                    :
                    <Box width={"100%"} height="auto" maxWidth={"100%"} overflow={"hidden"}>
                        <AsyncTableListing
                            columns={column}
                            page={page}
                            setPage={setPage}
                            noDataTitle='Don’t Worry, Your Perfect Match Is Out There!'
                            noDataSubTitle={<span> It seems like we couldn’t find any investors right now, but every big journey starts with a single step. Keep refining your profile, updating your pitch, and exploring opportunities!
                              <br />
                              Need a little help? Our team is here to guide you in finding the right investors. Reach out to us, and let’s unlock the next big chapter of your journey together.
                            </span>}
                            limit={limit}
                            noDataPlaceHolderImage={placeHolderImage}
                            setLimit={setLimit}
                            count={count}
                            data={FormattedData}
                            sx={{ borderRadius: '8px', border: FormattedData?.length > 0 ? `1px solid ${theme.palette.customColors.b50}` : "none", bgcolor: FormattedData?.length > 0 ? "white" : "transparent" }}

                        />
                    </Box>
                 }
            </Grid>
        </>
    )
}

export default MatchedInvestors
