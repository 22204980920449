import React from 'react'
import AsyncTableListing from '../../ui-components/AsyncTableListing'
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader'
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper'
import { useTheme } from '@emotion/react'
import { ReactComponent as RoundDetailsIcon } from "assets/rounds_icons/round_details_icon.svg"
import { Autocomplete, Box, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, useMediaQuery } from '@mui/material'
import CustomTabs from '../../ui-components/CustomTabs'
import { TabContext } from '@mui/lab'
import { Add, CancelRounded, Description, DoneRounded, FiberNew, FiberNewRounded, FilterAltOutlined, FilterAltRounded, LanguageOutlined, LinkedIn, More, NewReleasesOutlined, Notes, PublishedWithChanges, RateReview, RocketLaunch, Search, ThumbDown, ThumbUp } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { amountInWords, getFileIcon, hasPermission } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
import CustomInputField from 'ui-components/CustomInputField'
import { B300, B400 } from 'ui-components/CustomButton'
import { ReactComponent as MoreOptionsIcon } from "assets/rounds_icons/more_solid_icon.svg";
import NeedHelpButton from 'ui-components/NeedHelpButton'
import FilterPopup from "./popups/FilterPopup"
import ModalComponent from 'ui-components/ModalComponent'
import FeedbackPopup from './popups/FeedbackPopup'
import ShowInterestPopup from './popups/ShowInterestPopup'
import ShortlistConfirmationPopup from "ui-components/popups/ConfirmationPopup"
import RejectedConfirmationPopup from "ui-components/popups/ConfirmationPopup"
import RequestDocumentsPopup from "ui-components/popups/ConfirmationPopup"
import Cookies from 'js-cookie'
import { FetchAllCategories, FetchAllStages, FetchAllSubCategories } from 'api/utils'
import { UpdateStartupStatus } from 'api'
import InviteStartupPopup from './popups/InviteStartupPopup'
import { useSelector } from 'react-redux'
import { FetchAllStartupsListing, InviteStartupPOST, SaveFeedback, SaveInterest, SaveManageNotes, FetchInterestFeedbackStatus } from 'api'

import PDFView from './popups/PDFView'
import CustomToast from 'ui-components/CustomToast'
import AlertDialog from 'ui-components/popups/AlertPopup'
import toast from 'react-hot-toast'
import placeHolderImageNew from 'assets/no_data_images/Startups/new_startups.svg'
import placeHolderImageShortlisted from 'assets/no_data_images/Startups/shortlisted_startpus.svg'
import placeHolderImageRejected from 'assets/no_data_images/Startups/rejected_startus.svg'

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        "&": {
            padding: "3rem"
        }
        // '& .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer' : {marginLeft: '2rem', gap: '5rem'}
    }
    //     '& .MuiTabs-root > .Mui-disabled.MuiTabs-scrollButtons': {
    //         display: 'none !important',
    //         transition: 'all 1s ease'
    //     },
    // }
}));

const StartupsListing = () => {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state?.investorDetails?.data);
    const userPermissions = useSelector((state) => state?.permissions?.userPermissions);
    let [investor_id, setInvestorID] = React.useState(Cookies.get("X-INV-ID") || null);
    // console.log(userDetails,"userDetails")
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const startupTabs = [
        { value: "new", label: 'New', count: 10, read: false, icon: RocketLaunch },
        { value: "shortlisted", label: 'Shortlisted', count: 10, read: false, icon: ThumbUp },
        { value: "rejected", label: 'Rejected', count: 10, read: false, icon: ThumbDown },
    ]
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const parsedCookieForQueryParams = Cookies.get("startups_query") ? JSON.parse(Cookies.get("startups_query")) : {}
    const [queryParams, setQueryParams] = React.useState(parsedCookieForQueryParams);
    const [stage, setStage] = React.useState(null);
    const [category, setCategory] = React.useState(null);
    const [sector, setSector] = React.useState(null);
    const startups_tab_value = Cookies.get("startups_status") ? JSON.parse(Cookies.get("startups_status")) : "new"
    const [value, setValue] = React.useState(startups_tab_value ?? "new")
    const [search, setSearch] = React.useState("" || Cookies.get("search_startups"));
    const [reset, setReset] = React.useState(false);
    const [searchStatus, setSearchStatus] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const { data: transformedData, isRefetching: isRefetchingStartupListing, isLoading: isLoadingStartupListing, refetch: refetchListing } = FetchAllStartupsListing({ page, limit, status: value, investor_id: investor_id, queryParams, searchText: search })
    const { data: startup_stages, isRefetching: isRefetchingAllStages, isLoading: isLoadingAllStages, refetch: refetchAllStages } = FetchAllStages();
    const { data: startup_categories, isRefetching: isRefetchingAllCategories, isLoading: isLoadingAllCategories, refetch: refetchAllCategories } = FetchAllCategories();
    const { data: startup_sub_categories, isRefetching: isRefetchingAllSubcategories, isLoading: isLoadingAllSubcategories, refetch: refetchAllSubcategories } = FetchAllSubCategories({ category: queryParams?.category });
    const {
        mutateAsync: FetchStatusAsync,
        status: fetchStatusStatus,
        error: fetchStatusError,
        isLoading: isUpdatingFetchStatus,
        data: fetchStatusResponse,
    } = FetchInterestFeedbackStatus();


    const [isEnabledInterest, setIsEnabledInterest] = React.useState(false);
    const [isEnabledFeedback, setIsEnabledFeedback] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState(null)
    const [alertHeading, setAlertHeading] = React.useState(null)

    // React.useEffect(() => {
    //     if (fetchStatusResponse && fetchStatusResponse?.data?.statusCode == 200) {
    //         setIsEnabledInterest(fetchStatusResponse?.data?.investorInfo == null)
    //         setIsEnabledFeedback(fetchStatusResponse?.data?.feedback == null)
    //     } else {
    //         // fetchStatusResponse?.data?.message && toast.error(<CustomToast message={fetchStatusResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //         //     duration: 4000,
    //         //     position: "top-right",
    //         //     style: {
    //         //         padding: "15px 30px",
    //         //     },
    //         // });
    //     }
    // }, [fetchStatusResponse?.data?.statusCode]);

    // console.log(transformedData?.data, "startupListingData")

    React.useEffect(() => {
        let count = 0;
        if (!isLoadingStartupListing && count == 0) {
            if (transformedData?.count == 0 && value === "new") {
                if (search?.trim()?.length === 0 && Object.keys(queryParams)?.length === 0)
                    handleInviteStartupPopupOpen()
                count = 1;
            }
        }
    }, [isLoadingStartupListing, value, transformedData])

    const handleTabChange = (event, newValue) => {
        // console.log("value", newValue)
        setValue(newValue);
        Cookies.set("startups_status", JSON.stringify(newValue), { expires: 1 });
        setPage(1);
    };
    const RowActions = ({ row, iconsColor = theme.palette.customColors.indigo700, index }) => {
        // return <>

        // </>
        // console.log(row, "row")

        const [anchorEl, setAnchorEl] = React.useState(null);
        const openMenu = Boolean(anchorEl);
        const handleClickMenu = (event, index) => {
            setAnchorEl(event.currentTarget);
            // setCurrentIndex(index)
            // console.log(event.currentTarget,"re")
        };
        const handleCloseMenu = (event) => {
            // console.log(event,"event")
            setAnchorEl(null);
            // setCurrentIndex(null);
        };

        // switch (value) {
        //     case "new": return <>
        return <>          <IconButton
            sx={{ borderRadius: "4px" }}
            id={`more-details-button-${index}`}
            size="small"
            aria-controls={openMenu ? 'more-details-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={(e) => handleClickMenu(e)}
            centerRipple={false}
        >
            <MoreOptionsIcon />
        </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : 'false'}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        padding: "12px",
                        borderRadius: "8px",
                        "& > ul.MuiList-root": { padding: "0 !important" },
                        bgcolor: theme.palette.customColors.g400,
                        color: theme.palette.customColors.whiteBG,
                        // '&::before': {
                        //     content: '""',
                        //     display: 'block',
                        //     position: 'absolute',
                        //     top: 1,
                        //     right: 5,
                        //     width: 10,
                        //     height: 10,
                        //     bgcolor: theme.palette.customColors.g900,
                        //     transform: 'translateY(-50%) rotate(45deg)',
                        //     zIndex: 0,
                        // },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            >
                {value !== "rejected" && <>
                    {/* {value == "shortlisted" && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => handleRequestDocumentsPopupOpen({ row })}>
                        Request Documents
                    </MenuItem>} */}
                    {value === "new" && hasPermission("startups", "manage", userPermissions) && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => handleShortlistConfirmationPopupOpen({ row })}>
                        {/* <DoneRounded sx={{ height: 20, width: 20, color: iconsColor }} />  */}
                        Move to Shortlisted
                    </MenuItem>}
                    {hasPermission("startups", "manage", userPermissions) && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => handleRejectConfirmationPopupOpen({ row })}>
                        {/* <CancelRounded sx={{ height: 20, width: 20, color: iconsColor }} /> */}
                        {/* {value == "new" ? "Reject" : "Move to Reject"} */}
                        Move to Rejected
                    </MenuItem>}
                    {/* {value == "shortlisted" && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => handleManageNotesOpen({ row })}>
                        Manage Notes
                    </MenuItem>} */}
                    {row?.original?.startup_rounds_info && Array.isArray(row?.original?.startup_rounds_info) && row?.original?.startup_rounds_info?.length > 0 && hasPermission("startups", "submit_interest", userPermissions) && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => handleShowInterestPopupOpen({ row })}>
                        {/* <ThumbUp sx={{ height: 20, width: 20, color: iconsColor }} /> */}
                        Submit Interest
                    </MenuItem>}
                    {hasPermission("startups", "submit_feedback", userPermissions) && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => handleFeedbackPopupOpen({ row })}>
                        {/* <RateReview sx={{ height: 20, width: 20, color: iconsColor }} /> */}
                        Submit Feedback
                    </MenuItem>}
                    <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => {
                        if (row?.original?.startup_id) {
                            Cookies.set("startup_id", row?.original?.startup_id); navigate(`view-profile`)
                        }
                    }}>
                        View Details
                    </MenuItem>
                </>}
                {value == "rejected" && <MenuItem disableRipple sx={{ borderBottom: `0px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => handleShortlistConfirmationPopupOpen({ row })}>
                    {/* <DoneRounded sx={{ height: 20, width: 20, color: iconsColor }} />  */}
                    Move to Shortlisted
                </MenuItem>}
            </Menu>
        </>
    }

    const NewColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 300, align: 'start', fixed: true },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'Location', accessor: 'location', minWidth: 100, align: 'center' },
        { Header: 'Stage', accessor: 'stage', minWidth: 100, align: 'center' },
        { Header: 'Category', accessor: 'category_sub_sector', minWidth: 100, align: 'center' },
        { Header: 'Interested Amount', accessor: 'formatted_interested_amount', minWidth: 150, align: 'center' },
        { Header: 'Pitch Deck', accessor: 'pitch_deck', minWidth: 100, align: 'center' },
        { Header: 'Links', accessor: 'links', minWidth: 100, align: 'center' },
        // { Header: 'Funding round', accessor: 'funding_round', minWidth: 100, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100, align: 'right' }
    ];

    const ShortlistedColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 300, align: 'start', fixed: true },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'Location', accessor: 'location', minWidth: 100, align: 'center' },
        { Header: 'Stage', accessor: 'stage', minWidth: 100, align: 'center' },
        { Header: 'Category', accessor: 'category_sub_sector', minWidth: 100, align: 'center' },
        { Header: 'Interested Amount', accessor: 'formatted_interested_amount', minWidth: 150, align: 'center' },
        { Header: 'Pitch Deck', accessor: 'pitch_deck', minWidth: 100, align: 'center' },
        { Header: 'Links', accessor: 'links', minWidth: 100, align: 'center' },
        // { Header: 'Funding round', accessor: 'funding_round', minWidth: 100, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100, align: 'right' }
    ]

    const RejectedColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 300, align: 'start', fixed: true },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'Location', accessor: 'location', minWidth: 100, align: 'center' },
        { Header: 'Stage', accessor: 'stage', minWidth: 100, align: 'center' },
        { Header: 'Category', accessor: 'category_sub_sector', minWidth: 100, align: 'center' },
        { Header: 'Interested Amount', accessor: 'formatted_interested_amount', minWidth: 150, align: 'center' },
        { Header: 'Pitch Deck', accessor: 'pitch_deck', minWidth: 100, align: 'center' },
        { Header: 'Links', accessor: 'links', minWidth: 100, align: 'center' },
        // { Header: 'Funding round', accessor: 'funding_round', minWidth: 100, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100, align: 'right' }
    ]

    // const columns = () => {
    //     switch (value) {
    //         case "new": return NewColumns;
    //         case "shortlisted": return ShortlistedColumns;
    //         case "rejected": return NewColumns;
    //         default: return NewColumns;
    //     }
    // }

    const columns = value === "new" ? NewColumns : value === "shortlisted" ? ShortlistedColumns : value === "rejected" ? RejectedColumns : NewColumns;

    // Memoized Tooltip Icon Component
    const MemoizedTooltipIcon = React.memo(({ title, icon, onClick }) => (
        <Tooltip title={title}>
            <IconButton onClick={onClick}>{icon}</IconButton>
        </Tooltip>
    ));

    // Memoized Data Transformation
    const useFormattedData = (transformedData) => {
        return React.useMemo(() => {
            return transformedData?.data && transformedData?.data?.length > 0
                ? transformedData?.data?.map((item) => {
                    // console.log(item)
                    return ({
                        ...item,
                        name: item?.startup_name,
                        formatted_interested_amount:
                            Array.isArray(item?.startup_rounds_info)
                                && item?.startup_rounds_info?.length > 0
                                ?
                                item?.startup_rounds_info[0]?.formatted_interested_amount ? <Tooltip
                                    title={
                                        amountInWords(
                                            item?.startup_rounds_info[0]?.interested_amount,
                                            item?.currency_info
                                                ? item?.currency_info?.code
                                                : null
                                        )}
                                >
                                    {item?.startup_rounds_info[0]?.formatted_interested_amount} </Tooltip> : null : null,
                        location: item?.city && item?.country ? `${item?.city}, ${item?.country}` : "-",
                        category_sub_sector: item?.category_info && item?.sector_info
                            ? `${item?.category_info?.name} ${item?.sector_info ? "(" + item?.sector_info[0]?.sub_category + ")" : "-"}`
                            : "-",
                        stage: item?.stage_info ? `${item?.stage_info[0]?.name}` : "-",
                        image: item?.startup_logo_path,
                        pitch_deck: item?.pitch_deck_path ? (
                            <MemoizedTooltipIcon
                                title="Pitch Deck"
                                onClick={() => handleOpenPDFViewer({ row: item })}
                                icon={<Description sx={{ color: theme.palette.customColors.indigo600 }} />}
                            />
                        ) : (
                            <>-</>
                        ),
                        links: (
                            (
                                (item?.website_url && item?.website_url?.length > 0 || item?.linkedin_url && item?.linkedin_url?.length > 0 || item?.startup_rounds_info && Array.isArray(item?.startup_rounds_info) && item?.startup_rounds_info?.length > 0 && item?.startup_rounds_info[0]?._id)
                                    ? <Box display="flex" alignItems={"center"} justifyContent={"center"} >
                                        {item?.website_url && item?.website_url?.length > 0 && <MemoizedTooltipIcon
                                            title="Website"
                                            onClick={() => window.open(item?.website_url)}
                                            icon={<LanguageOutlined sx={{ color: theme.palette.customColors.indigo600 }} />}
                                        />
                                        }
                                        {
                                            item?.linkedin_url && item?.linkedin_url?.length > 0 && <MemoizedTooltipIcon
                                                title="LinkedIn"
                                                onClick={() => window.open(item?.linkedin_url)}
                                                icon={<LinkedIn sx={{ color: theme.palette.customColors.indigo600 }} />}
                                            />
                                        }
                                        {
                                            item?.startup_rounds_info && Array.isArray(item?.startup_rounds_info) && item?.startup_rounds_info?.length > 0 && item?.startup_rounds_info[0]?._id && <MemoizedTooltipIcon
                                                title="View Round Details"
                                                onClick={() => {
                                                    if (item?.startup_rounds_info && Array.isArray(item?.startup_rounds_info) && item?.startup_rounds_info?.length > 0 && item?.startup_rounds_info[0]?._id) {
                                                        Cookies.set("startup_id", item?.startup_id);
                                                        navigate("view-round");
                                                    }
                                                }}
                                                icon={<RoundDetailsIcon fill={theme.palette.customColors.indigo600} style={{ width: 20, height: 20 }} />}
                                            />
                                        }
                                    </Box> : '-')
                        )
                    })
                })
                : [];
        }, [transformedData]);
    };

    let location = window.location.pathname?.includes("startups");

    const StartupLink = React.memo(({ row, children }) => {

        // console.log(row?.original?.startup_rounds_info,"row")
        return (
            <Stack onClick={() => {
                if (row?.original?.startup_id) {
                    Cookies.set("startup_id", row?.original?.startup_id); navigate(location ? `view-profile` : `startups/view-profile`)
                }
            }} style={{ textDecoration: 'none', color: '#616161', fontWeight: 700, cursor: 'pointer' }}>
                {/* <Link to={`view/${row?._id}`} > */}
                {children}
            </Stack>
        )
    })

    const {
        mutateAsync: saveInterestAsync,
        status: saveInterestStatus,
        error: saveInterestError,
        isLoading: isSavingInterest,
        data: saveInterestResponse,
    } = SaveInterest();

    const {
        mutateAsync: saveFeedbackAsync,
        status: saveFeedbackStatus,
        error: saveFeedbackError,
        isLoading: isSavingFeedback,
        data: saveFeedbackResponse,
    } = SaveFeedback();
    const {
        mutateAsync: saveManageNotesAsync,

    } = SaveManageNotes();

    const [currentRow, setCurrentRow] = React.useState(null);
    const [selectedPDF, setSelectedPDF] = React.useState('');

    const handleFilterOpen = () => {
        setOpenFilter(true);
    }

    const handleFilterClose = () => {
        setOpenFilter(false)
    }

    const handleUpdateFilter = ({ query = {} }) => {
        // console.log(query,"query")
        setQueryParams(query);
        Cookies.set("startups_query", JSON.stringify(query));
        handleFilterClose();
    }

    const [openFeedbackPopup, setOpenFeedbackPopup] = React.useState(false)
    const [openShowInterestPopup, setOpenShowInterestPopup] = React.useState(false)
    // const [openManageNotes, setOpenManageNotes] = React.useState(false)


    const handleFeedbackPopupOpen = ({ row }) => {
        let request = {
            startup_id: row?.original?.startup_id,
            investor_id: investor_id
        }

        // console.log(request, "row")
        FetchStatusAsync(request).then((response) => {
            // console.log(response,"resssss")
            // setIsEnabledInterest(response?.data?.investorInfo == null)
            setIsEnabledFeedback(response?.data?.feedback == null)
            if (response?.data?.feedback !== null) {
                setOpenFeedbackPopup(false);
                setAlertMessage(`You have already submitted feedback for ${row?.original?.startup_name}. Please wait to hear back from the startup.`)
                setAlertHeading("Feedback")
                setShowAlert(true);
            }
            else {
                setOpenFeedbackPopup(true);
                setAlertMessage(null)
                setAlertHeading(null)
                setShowAlert(false);
            }
        }).catch(err => {
            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });


        setCurrentRow(row?.original);
    }

    const handleFeedbackPopupClose = () => {
        !Boolean(selectedPDF != null && selectedPDF?.length > 0) && setCurrentRow(null)
        setOpenFeedbackPopup(false)
    }

    const handleFeedbackSubmit = ({ row, values }) => {
        // console.log(row, "row")
        let request = {
            startup_id: row?.startup_id,
            investor_id: investor_id,
            ratings: {
                problem: values?.problem,
                market: values?.market,
                product: values?.product,
                team: values?.team,
                traction: values?.traction
            },
            investible: values?.invest,
            feedback: values?.feedback
        }
        // console.log(request, "request")
        saveFeedbackAsync(request).then(() => {
            refetchListing()
        })
        // setQueryParams(query);
        handleFeedbackPopupClose();
    }


    const handleShowInterestPopupOpen = ({ row }) => {
        let request = {
            startup_id: row?.original?.startup_id,
            investor_id: investor_id
        }

        // console.log(request, "row")
        FetchStatusAsync(request).then((response) => {
            // console.log(response,"resssss")
            setIsEnabledInterest(response?.data?.investorInfo == null)
            // setIsEnabledFeedback(response?.data?.feedback == null)
            if (response?.data?.investorInfo !== null) {
                setOpenShowInterestPopup(false);
                setAlertMessage(`You have already shown investment interest in ${row?.original?.startup_name}. Please wait to hear back from the startup.`)
                setAlertHeading("Investment Interest")
                setShowAlert(true);
            }
            else {
                setOpenShowInterestPopup(true);
                setAlertMessage(null)
                setAlertHeading(null)
                setShowAlert(false);
            }
        }).catch(err => {
            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });


        setCurrentRow(row?.original);
    }

    const handleShowInterestPopupClose = () => {
        !Boolean(selectedPDF != null && selectedPDF?.length > 0) && setCurrentRow(null);
        setOpenShowInterestPopup(false)
    }

    const handleShowInterestPopupSubmit = ({ row, values }) => {
        // console.log(row, "row")
        let request = {
            startup_id: row?.startup_id,
            intrested_amount: values?.interested_amount,
            investor_id: investor_id
        }
        // console.log(request, "request");
        saveInterestAsync(request).then(() => {
            refetchListing()
        })
        handleShowInterestPopupClose();
    }

    // const handleManageNotesOpen = ({ row }) => {
    //     setCurrentRow(row?.original);
    //     setOpenManageNotes(true);
    // }

    // const handleManageNotesClose = () => {
    //     setCurrentRow(null)
    //     setOpenManageNotes(false)
    // }

    // const handleManageNotesSubmit = ({ row, values }) => {
    //     let request = {
    //         startup_id: row?.startup_id,
    //         investor_id: investor_id,
    //         message: values?.message
    //     }
    //     saveManageNotesAsync(request).then(() => {
    //         refetchListing()
    //     })
    //     handleManageNotesClose();
    // }

    const {
        mutateAsync: updateStartupStatusAsync,
        status: updateStartupStatusStatus,
        error: updateStartupStatusError,
        isLoading: isUpdatingStartupStatus,
        data: updateStartupStatusResponse,
    } = UpdateStartupStatus({ investor_id: investor_id });

    const {
        mutateAsync: inviteStartupAsync,
        status: inviteStartupStatus,
        error: inviteStartupError,
        isLoading: isInvitingStartup,
        data: inviteStartupResponse,
    } = InviteStartupPOST({ investor_id: investor_id });

    const [openShortlistConfirmationPopup, setOpenShortlistConfirmationPopup] = React.useState(false);

    const handleShortlistConfirmationPopupClose = () => {
        setOpenShortlistConfirmationPopup(false);
        setCurrentRow(null);
    }

    const handleShortlistConfirmationPopupOpen = ({ row }) => {
        setOpenShortlistConfirmationPopup(true);
        setCurrentRow(row?.original);
    }

    const handleSubmissionShortlist = ({ row }) => {
        // setCurrentRow(row);
        // console.log(row);
        // updateStartupStatusAsync({
        //     "startup_id": "66f285de9ce0f2c5615f20e5",
        //     "status_code": "shortlisted"
        // }).then((response) => {
        //     refetchListing();
        //     handleShortlistConfirmationPopupClose();
        // }).catch((error) => {

        // })
        updateStartupStatusAsync({
            "startup_id": row?.startup_id,
            "status_code": "shortlisted"
        }).then((response) => {
            refetchListing();
            handleShortlistConfirmationPopupClose();
        }).catch((error) => {

        })

    }

    const [openRejectConfirmationPopup, setOpenRejectConfirmationPopup] = React.useState(false);

    const handleRejectConfirmationPopupClose = () => {
        setOpenRejectConfirmationPopup(false);
        setCurrentRow(null);
    }

    const handleRejectConfirmationPopupOpen = ({ row }) => {
        setOpenRejectConfirmationPopup(true);
        setCurrentRow(row?.original);
    }

    const handleSubmissionReject = ({ row }) => {
        // setCurrentRow(row);
        // console.log(row);
        // updateStartupStatusAsync({
        //     "startup_id": "66f285de9ce0f2c5615f20e5",
        //     "status_code": "rejected"
        // }).then((response) => {
        //     refetchListing();
        //     handleRejectConfirmationPopupClose();
        // }).catch((error) => {

        // })

        updateStartupStatusAsync({
            "startup_id": row?.startup_id,
            "status_code": "rejected"
        }).then((response) => {
            refetchListing();
            handleRejectConfirmationPopupClose();
        }).catch((error) => {

        })
    }

    const [openRequestDocumentsPopup, setOpenRequestDocumentsPopup] = React.useState(false);

    const handleRequestDocumentsPopupClose = () => {
        setOpenRequestDocumentsPopup(false);
        setCurrentRow(null);
    }

    const handleRequestDocumentsPopupOpen = ({ row }) => {
        setOpenRequestDocumentsPopup(true);
        setCurrentRow(row?.original);
    }

    const handleRequestDocuments = ({ row }) => {
        // setCurrentRow(row);
        // console.log(row);
        handleShortlistConfirmationPopupClose();
    }

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [openInviteStartupPopup, setOpenInviteStartupPopup] = React.useState(false);

    const handleInviteStartupPopupClose = () => {
        setOpenInviteStartupPopup(false);
    }

    const handleInviteStartupPopupOpen = () => {
        setOpenInviteStartupPopup(true);
    }

    const handleInviteStartup = async ({ payload }) => {
        // console.log(payload, "Please enter startup name");
        await inviteStartupAsync({
            payload, _id: investor_id
        }).then((response) => {
            refetchListing();
            handleInviteStartupPopupClose();
        }).catch((error) => {
            console.log(error?.message)
        })
    }

    const Actions = ({ hasNoData = false }) => {
        return hasPermission("startups", "invite", userPermissions) && <>
            <B400
                // disabled={!hasPermission("startups", "invite", userPermissions)}
                sx={{
                    display: 'flex',
                    marginLeft: hasNoData ? "none" : 'auto',
                    background: theme.palette.customColors.indigo700,
                    padding: "0.625rem 1rem",
                    "& > .MuiButton-icon.MuiButton-startIcon":
                    {
                        marginRight: isMediumScreen ? 0 : '0.5rem',
                        marginLeft: isMediumScreen ? 0 : '-0.25rem'
                    }
                }} startIcon={<Add sx={{ width: 20, height: 20 }} />}
                onClick={() => {
                    hasPermission("startups", "invite", userPermissions) && handleInviteStartupPopupOpen()
                }}>{isMediumScreen ? "" : "Invite"}</B400>
        </>
    }

    React.useEffect(() => {
        refetchListing();
    }, [reset]);

    const isShortlisting = false;
    const isRejecting = false;
    const isRequestingDocuments = false;
    const stagesListing = startup_stages && startup_stages?.length > 0 ? startup_stages?.map(item => ({ label: item?.name, value: item?._id })) : []
    const categoriesListing = startup_categories && startup_categories?.length > 0 ? startup_categories?.map(item => ({ label: item?.name, value: item?._id })) : []
    const subCategoriesListing = startup_sub_categories && startup_sub_categories?.length > 0 ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?._id })) : []
    // console.log(queryParams, subCategoriesListing, "queryParams")

    const FormattedData = useFormattedData(transformedData);



    const handleOpenPDFViewer = ({ row }) => {
        setCurrentRow(row);
        // console.log(currentRow,"currentRow")
        setSelectedPDF(row?.pitch_deck_path);
    }

    const handleClosePDFViewer = () => {
        setSelectedPDF(null);
        setCurrentRow(null);
    }

    const updateSector = (params) => {
        let removedSectorParamFromQuery = params;
        removedSectorParamFromQuery.sector && delete removedSectorParamFromQuery.sector;
        // Cookies.set("startups_query", JSON.stringify({ ...removedSectorParamFromQuery }));
        setQueryParams({ ...removedSectorParamFromQuery });
        setSector(null);
        setSearchStatus(false)
    }


    return (
        <>
            <AlertDialog open={showAlert} handleClose={() => { setShowAlert(false); setTimeout(() => { setAlertMessage(null); setAlertHeading(null) }, 1000) }} heading={alertHeading} subheading={alertMessage} />
            <PDFView open={Boolean(selectedPDF != null && selectedPDF?.length > 0)} pdfUrl={selectedPDF} handleClose={handleClosePDFViewer} handleFeedbackPopupOpen={handleFeedbackPopupOpen} handleShowInterestPopupOpen={handleShowInterestPopupOpen} currentRow={currentRow} isEnabledFeedback={isEnabledFeedback} isEnabledInterest={isEnabledInterest} />
            <InviteStartupPopup open={openInviteStartupPopup} handleClose={handleInviteStartupPopupClose} isSharingDocuments={false} refetchListing={refetchListing} handleSubmission={handleInviteStartup} isInvitingStartup={isInvitingStartup} />
            <ModalComponent open={openFilter} onClose={handleFilterClose} style={{ maxWidth: 640, width: "100%" }}>
                <FilterPopup queryParams={queryParams} setQueryParams={setQueryParams} handleClose={handleFilterClose} handleSubmit={handleUpdateFilter} stage={stage} category={category} sector={sector} setStage={setStage} setCategory={setCategory} setSector={setSector} />
            </ModalComponent>
            <ShortlistConfirmationPopup open={openShortlistConfirmationPopup} handleClose={handleShortlistConfirmationPopupClose} handleSubmission={handleSubmissionShortlist} heading={"Move to Shortlist"} ActionText={"Submit"} subheading={"Are you sure you want to move to shortlisted startups?"} isLoading={isUpdatingStartupStatus} currentRow={currentRow} optional={true} />
            <RejectedConfirmationPopup open={openRejectConfirmationPopup} handleClose={handleRejectConfirmationPopupClose} handleSubmission={handleSubmissionReject} heading={"Move to Rejected"} ActionText={"Reject"} subheading={"Are you sure you want to move to rejected startups?"} isLoading={isUpdatingStartupStatus} currentRow={currentRow} optional={false} />
            <RequestDocumentsPopup open={openRequestDocumentsPopup} handleClose={handleRequestDocumentsPopupClose} handleSubmission={handleRequestDocuments} heading={"Request Documents"} ActionText={"Request"} subheading={"Are you sure you want to request documents for selected startup?"} isLoading={isRequestingDocuments} currentRow={currentRow} />
            <ModalComponent open={openFeedbackPopup} onClose={handleFeedbackPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                <FeedbackPopup currentRow={currentRow} handleClose={handleFeedbackPopupClose} handleSubmit={handleFeedbackSubmit} />
            </ModalComponent>
            <ModalComponent open={openShowInterestPopup} onClose={handleShowInterestPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                <ShowInterestPopup currentRow={currentRow} handleClose={handleShowInterestPopupClose} handleSubmit={handleShowInterestPopupSubmit} />
            </ModalComponent>
            {/* <ModalComponent open={openManageNotes} onClose={handleManageNotesClose} style={{ maxWidth: 800, width: "100%" }}>
                <ManageNotes currentRow={currentRow} handleClose={handleManageNotesClose} handleSubmit={handleManageNotesSubmit} />
            </ModalComponent> */}
            <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
                <BreadcrumbHeader title={"Startups"} sx={{ paddingBottom: 0 }} Actions={() => <NeedHelpButton />} />
                <Grid container xs={12} padding={{ xs: "0 0rem 1rem 0rem", md: "0 0rem 2rem 0rem" }} justifyContent={"space-between"}>
                    <TabContext value={value}>
                        <Grid container xs={12} pt={1} pb={0} spacing={0} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
                            {/* <Grid item xs={10} lg={10}> */}
                            <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{ gap: "0.5rem" }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }} marginRight={"0rem"} mb={1}>
                                <Grid item xs={12} sm={12} md={4} lg={3} style={{ display: 'flex', height: 'min-content', alignItems: 'center', gap: "0.5rem" }}>
                                    <CustomInputField
                                        autoComplete="off"
                                        sx={{
                                            marginBottom: 0,
                                            "& > .MuiFilledInput-root": {
                                                borderColor: `${theme.palette.customColors.b50} !important`,
                                                borderRadius: '8px'
                                            }
                                        }}
                                        fullWidth
                                        value={search}
                                        name={`search_${Math.random().toString(36).substring(7)}`}  // Set a random name attribute
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                // alert(e.target.value);
                                                refetchListing()
                                                if (search) {
                                                    setSearchStatus(true)
                                                }
                                                setPage(1);
                                            }
                                        }}
                                        onChange={(event) => {
                                            setSearch(event.target.value)
                                            Cookies.set("search_startups", event.target.value);
                                            setSearchStatus(false)
                                        }}
                                        size="small"
                                        startAdornment={<>
                                            <Search sx={{ padding: "0rem 0rem 0rem 0.5rem" }} />
                                        </>}
                                        placeholder={'Search by name'}
                                    />
                                    <B300 onClick={() => {
                                        if (searchStatus && (search !== '')) {
                                            setSearch('')
                                            Cookies.set("search_startups", '');
                                            setReset(!reset)
                                        } else {
                                            refetchListing()
                                        }
                                        setSearchStatus((oldVal) => !oldVal)
                                        setPage(1);
                                    }} sx={{ minWidth: '6rem !important', display: { xs: "block", md: 'none' }, padding: ".7rem 1.25rem", bgcolor: searchStatus && (search !== '') ? "#e9f5f8" : theme.palette.customColors.b300, ":hover": { bgcolor: searchStatus && (search !== '') ? "#e9f5f8" : theme.palette.customColors.b400 }, border: `0px solid ${searchStatus && (search !== '') ? "black" : "inherit"}`, color: searchStatus && (search !== '') ? theme.palette.customColors.b300 : "white" }}  >{(searchStatus && (search !== '')) ? "Reset" : "Search"}</B300>
                                    <IconButton sx={{ marginLeft: "auto", display: { xs: 'flex', md: "none" }, marginBlock: 'auto' }} onClick={() => handleFilterOpen()}>
                                        {Object.keys(queryParams)?.length > 0 ? <FilterAltRounded /> : <FilterAltOutlined />}
                                    </IconButton>
                                </Grid>
                                <Grid item xs={0} sm={0} md={8} lg={9} display={{ xs: "none", md: "flex" }} container spacing={1} alignItems={"center"}>
                                    <Grid item display={{ xs: "none", md: "block" }} width="25%">
                                        <Autocomplete
                                            disablePortal
                                            options={stagesListing}
                                            // disableClearable
                                            fullWidth
                                            // value={queryParams?.stage}
                                            value={Object.keys(queryParams)?.length && queryParams?.stage ? { label: queryParams?.stage, value: queryParams?.stage } : null}
                                            autoHighlight
                                            onChange={(e, newValue) => { setQueryParams({ ...queryParams, stage: newValue?.label }); setStage(newValue); setSearchStatus(false) }}
                                            renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Stage"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                                        />
                                    </Grid>
                                    <Grid item display={{ xs: "none", md: "block" }} width="25%">
                                        <Autocomplete
                                            disablePortal
                                            // disableClearable
                                            options={categoriesListing}
                                            fullWidth
                                            // value={queryParams?.category}
                                            value={Object.keys(queryParams)?.length && queryParams?.category ? { label: queryParams?.category, value: queryParams?.category } : null}
                                            autoHighlight
                                            onChange={(e, newValue) => {
                                                // Cookies.set("startups_query", JSON.stringify({ ...queryParams, category: newValue?.label })); 
                                                setQueryParams({ ...queryParams, category: newValue?.label }); setCategory(newValue); setSearchStatus(false);
                                                setTimeout(() => {
                                                    updateSector({ ...queryParams, category: newValue?.label })
                                                }, 10)
                                            }}
                                            renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Category"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                                        />
                                    </Grid>
                                    {/* {console.log(startup_sub_categories,"startup_sub_categories")} */}
                                    {startup_sub_categories && queryParams?.category && queryParams?.category != null && <Grid item display={{ xs: "none", md: "block" }} width="25%">
                                        <Autocomplete
                                            disablePortal
                                            // disableClearable
                                            options={subCategoriesListing}
                                            fullWidth
                                            // value={queryParams?.sub_category}
                                            value={Object.keys(queryParams)?.length && queryParams?.sector ? { label: queryParams?.sector, value: queryParams?.sector } : null}
                                            autoHighlight
                                            onChange={(e, newValue) => {
                                                // Cookies.set("startups_query", JSON.stringify({ ...queryParams, sector: newValue?.label })); 
                                                setQueryParams({ ...queryParams, sector: newValue?.label });
                                                setSector(newValue);
                                                setSearchStatus(false)
                                            }}
                                            renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Sector"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                                        />
                                    </Grid>}
                                    <Grid item display={{ xs: "none", md: "block" }} width="25%">
                                        <B300 onClick={() => {

                                            if (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) {
                                                setSearch('')
                                                Cookies.set("search_startups", "");
                                                setQueryParams({})
                                                Cookies.set("startups_query", JSON.stringify({}));
                                                setStage(null)
                                                setCategory(null)
                                                setSector(null);
                                                // setStatus('')
                                                setReset(!reset)
                                            } else {
                                                Cookies.set("startups_query", JSON.stringify({ ...queryParams }));
                                                refetchListing()
                                            }
                                            setSearchStatus((oldVal) => !oldVal)
                                            setPage(1);
                                        }} sx={{
                                            padding: ".7rem 1.25rem",
                                            minWidth: '6rem !important', bgcolor: (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? "#e9f5f8" : theme.palette.customColors.b300, ":hover": { bgcolor: (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? "#e9f5f8" : theme.palette.customColors.b400 }, border: `0px solid ${(searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? "black" : "inherit"}`, color: (searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? theme.palette.customColors.b300 : "white"
                                        }}>
                                            {(searchStatus && (search !== '' || Object.keys(queryParams)?.length > 0)) ? "Reset" : "Search"}
                                        </B300>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }} marginRight={"0rem"} >
                                <Grid xs={12} container sx={{ bgcolor: 'white', borderRadius: '8px 8px 0 0', borderInline: `1px solid ${theme.palette.customColors.b50}`, borderBlockStart: `1px solid ${theme.palette.customColors.b50}` }}>
                                    <Grid width="75%">
                                        <CustomTabs
                                            variant="submenu"
                                            titleStyle={{ fontSize: '1rem' }}
                                            tabStyle={{ padding: { xs: '0.5rem 1rem 0rem' } }}
                                            sx={{ border: '0px solid black !important' }}
                                            bgColor={"transparent"}
                                            allowScrollButtonsMobile={true}
                                            data={
                                                [...startupTabs?.map(item => {
                                                    return ({ ...item, label: `${item?.label} ${value === item?.value && transformedData?.count ? `(${transformedData?.count})` : ""}` })
                                                })]
                                            } value={value} handleChange={handleTabChange}
                                            isSmallScreen={isSmallScreen} className={classes.scrollTabs} key={"Tabs Tabs"} indicatorFullSize={false} />
                                    </Grid>
                                    <Grid width="20%" pt={0.75} pr={1} marginLeft={"auto"}>
                                        <Actions />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* { */}
                            {/* <Grid item xs={12} md={12} pt={"0 !important"} marginInline={"auto"} maxWidth={"100%"}> */}

                            {isLoadingStartupListing ?
                                <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                                    <Box className='skeleton' minHeight={"60vh"} sx={{ borderRadius: "0 0 8px 8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

                                    </Box>
                                </Grid> :
                                <Box className='startups-listing' width={"100%"} height="100%" paddingLeft={"2rem"} maxWidth={"100%"} overflow={"hidden"} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                                    <AsyncTableListing
                                        columns={columns}
                                        // data={[]}
                                        tabValue={value}
                                        data={FormattedData}
                                        // title={"Investors"}
                                        // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
                                        // actions={Actions}
                                        noDataPlaceHolderImage={value == "new" ? placeHolderImageNew : value == "shortlisted" ? placeHolderImageShortlisted : placeHolderImageRejected}
                                        noDataTitle={"No Startups"}
                                        noDataSubTitle={value == "new" ? "You do not have any new startups. Start adding now" : value == "shortlisted" ? "You do not have any shortlisted startups. Start adding now" : "You do not have any rejected startups."}
                                        noDataPlaceHolderSx={value == "shortlisted" ? { width: 300 } : {}}
                                        actions={value != "rejected" ? () => <Actions hasNoData={true} /> : () => { }}
                                        CustomLink={StartupLink}
                                        page={page}
                                        setPage={setPage}
                                        limit={limit}
                                        setLimit={setLimit}
                                        count={transformedData?.count}
                                        RowActions={RowActions}
                                        sx={{ borderRadius: '0 0 8px 8px', border: `1px solid ${theme.palette.customColors.b50}` }}
                                        refetchListing={refetchListing}
                                    />
                                </Box>
                            }

                            {/* </Grid>} */}
                            {/* <Grid item xs={2} lg={2}> */}

                        </Grid>
                    </TabContext>
                </Grid>
            </ThemeBGWrapper>
        </>
    )
}

export default StartupsListing