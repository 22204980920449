import React, { lazy } from 'react'
import MainContent from './MainContent'
import Sidebar from './Sidebar'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from "./Header"
import BreadcrumbsHeader from "./BreadcrumbsHeader"
import Loadable from 'common-components/Loadable'
import PermissionsHandler from 'views/PermissionHandler'
import Cookies from 'js-cookie'
import { FetchProfileDetails } from 'api/profile'
import { useDispatch } from 'react-redux'
import { RESET_STATE, SET_INVESTOR_DETAILS } from 'store/actions'
// const Sidebar =Loadable(lazy(() => import('./Sidebar')))

const MainLayout = () => {
    const [investor_id, setInvestorID] = React.useState(Cookies.get('X-INV-ID') || null)
    const currentBackground = document.getElementsByTagName("body");
    currentBackground[0].style.backgroundColor = "#3538CD";
    const dispatch = useDispatch();
    const { data: userData, isLoadingUserData } = FetchProfileDetails({ investor_id });

    React.useEffect(() => {
        // console.log(userData,"mesage")
        if (userData?.data && !isLoadingUserData) dispatch({ type: SET_INVESTOR_DETAILS, data: userData?.data });
    }, [userData?.data])
    const navigate = useNavigate();
    if (!Cookies.get("X-INV-ID")) {
        const handleLogOut = () => {
            // dispatch({ type: SET_MENU, opened: false });
            dispatch({ type: RESET_STATE });
            Cookies.remove('jwt', { path: '/' });
            Cookies.remove('name', { path: '/' });
            Cookies.remove('user_id', { path: '/' });
            Cookies.remove('startup_id', { path: '/' });
            Cookies.remove('email', { path: '/' });
            Cookies.remove('is_profile_complete', { path: '/' });
            Cookies.remove('startup_id', { path: '/' });
            Cookies.remove('step', { path: '/' });
            Cookies.remove('permissions', { path: '/' });
            Cookies.remove('ur', { path: '/' });
            Cookies.remove('search_startups', { path: '/' });
            Cookies.remove('search_backrr_startups', { path: '/' });
            Cookies.remove('backrr_startups_query', { path: '/' });
            Cookies.remove('backrr_startups_status', { path: '/' });
            const cookies = document.cookie.split(";");
            cookies.forEach(cookie => {
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            });
            navigate('/login');
        };
        handleLogOut();
    }

    // console.log(currentBackground[0].style.backgroundColor === "#3538CD","currentBackground")
    return (
        <div style={{ display: 'flex' }}>
            <PermissionsHandler investor_id={investor_id} />
            <Sidebar />
            <div style={{ flexGrow: 1 }}>
                {/* <Header /> */}
                <MainContent>
                    {/* <BreadcrumbsHeader /> */}
                    <Outlet />
                </MainContent>
            </div>
        </div>
    )
}

export default MainLayout