import pdfIcon from "../assets/doc_icons/pdf.svg"
import imageIcon from "../assets/doc_icons/image.svg"
import docsIcon from "../assets/doc_icons/docx2.svg"
import excelIcon from "../assets/doc_icons/excel.svg"
import videoIcon from "../assets/doc_icons/video.svg"
import documentIcon from "../assets/doc_icons/document.svg"
import imageIcon2 from "../assets/doc_icons/image2.svg"
import filmIcon from "../assets/doc_icons/film.svg"
import Cookies from "js-cookie"

const handleDateValues = (date, placeholder = "No Date") => {
  return date && String(date)?.trim()?.length > 0 ? date : placeholder
}


function getDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for iOS devices
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  // Check for Android devices
  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // Default to web-desktop for other cases
  return 'web';
}

function redirectTo(host) {
  switch (host?.includes("backrr.com")) {
    case true:
      if (host?.includes("startup")) {
        window.open("https://investor.backrr.com", "_blank");
      }
      else if (host?.includes("investor")) {
        window.open("https://startup.backrr.com", "_blank");
      }
      break;
    default:
      if (host?.includes("startup")) {
        window.open("https://investor.wedighq.com", "_blank");
      }
      else if (host?.includes("investor")) {
        window.open("https://startup.wedighq.com", "_blank");
      }
      break;
  }
  return null
}

function navigateToMain(host) {
  if (host?.includes("backrr.com")) {
    window.location.href = "https://backrr.com"
  }
  else {
    window.location.href = "https://wedighq.com"
  }
}

const getFileIcon = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return pdfIcon; // Replace with actual path to your PDF icon image
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return imageIcon; // Replace with actual path to your image icon
    case 'doc':
    case 'docx':
      return docsIcon; // Replace with actual path to your DOC/DOCX icon
    case 'xls':
    case 'xlsx':
    case 'csv':
      return excelIcon; // Replace with actual path to your Excel icon
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
    case 'wmv':
      return videoIcon; // Replace with actual path to your video icon
    default:
      return docsIcon; // Replace with actual path to a default icon for unsupported types
  }
};

//   // Example usage
//   const fileName = 'example.pdf';
//   const iconPath = getFileIcon(fileName);
//   console.log(iconPath); // Outputs the path to the corresponding icon based on the file extension

const getFileCategory = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
    case 'doc':
    case 'docx':
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'ppt':
    case 'pptx':
    case 'txt':
      return documentIcon; // Category for document files

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return imageIcon2; // Category for image files

    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
    case 'wmv':
    case 'flv':
      return filmIcon; // Category for video files

    default:
      return documentIcon; // Default case for unsupported or unknown file types
  }
};

//   // Example usage
//   const fileName = 'example.mp4';
//   const fileCategory = getFileCategory(fileName);
//   console.log(fileCategory); // Outputs 'video'

const currencyInputProps = {
  inputMode: "numeric", // Use numeric keyboard on mobile devices
  pattern: "[0-9]*", // Only allow digits 0-9
  // title: "Please enter a number between 0 and 9",
  style: {
    // textAlign: 'center', // Center the text in the input
  },
  min: 0,
  onKeyDown: (event) => {
    // Prevent the minus sign (-) from being entered
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Get the input value
    const inputValue = event.key;

    // Allow only numeric characters (0-9) and certain control keys
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(inputValue)) {
      // If not, prevent the key from being entered
      event.preventDefault();
    }
  },
  onInput: (event) => {
    // Get the input value
    const inputValue = event.target.value;

    // Remove non-numeric characters using a regular expression
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Update the input field value with the filtered numeric value
    event.target.value = numericValue;
  },
  onKeyDown: (event) => {
    // Prevent the input of minus sign (key code 189)
    if (event.keyCode === 189 || event.key === "-") {
      event.preventDefault();
    }
  },
  disableUnderline: true,
};

const containerRef = (ref) => {
  if (ref) {
    // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
    ref._getBoundingClientRect = ref.getBoundingClientRect;

    ref.getBoundingClientRect = () => {
      const original = ref._getBoundingClientRect();

      return {
        ...original,
        width: Math.floor(original.width),
        height: Math.floor(original.height),
      };
    };
  }
}

const isRequired = (requiredObject, field) => {
  return requiredObject[field]?.required
}

const popularCountrySort = ["India", "United States", "United Arab Emirates", "Singapore", "United Kingdom", "Canada"]

// const linkedInTestURL = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/
// const linkedInTestURL = /linkedin\.com\/[a-zA-Z0-9_-]+\/?$/
const linkedInTestURL = /linkedin\.com\/.*$/


// function stringAvatar(current_name) {
//   let name = (current_name)?.length > 0 ? String(current_name)?.replace(" ", "") : ""
//   if (name && name?.includes(" ")) {
//     return {
//       sx: {
//         bgcolor: "#F9F5FF",
//         color: "#3538CD",
//         width: "40px",
//         height: "40px",
//         fontSize: '1rem',
//         textTransform: 'capitalize'
//       },
//       children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
//     };
//   } else if (name && !(name?.includes(" "))) {
//     return {
//       sx: {
//         bgcolor: "#F9F5FF",
//         color: "#3538CD",
//         width: "40px",
//         height: "40px",
//         fontSize: '1rem',
//         textTransform: 'capitalize'
//       },
//       children: name[0],
//     };
//   }
//   else {
//     return {
//       sx: {
//         bgcolor: "#F9F5FF",
//         color: "#3538CD",
//         width: "40px",
//         height: "40px",
//         fontSize: '1rem',
//         textTransform: 'capitalize'
//       },
//       children: '',
//     }
//   }
// }

const stringAvatar = (name) => {
  const initials = name
    ? String(name)
      ?.split(' ')
      ?.map((n) => n[0])
      ?.join('')
      ?.toUpperCase()
    : '';
  return {
    sx: {
      bgcolor: "#F9F5FF",
      color: "#3538CD",
      width: "40px",
      height: "40px",
      fontSize: '1rem',
      textTransform: 'capitalize'
    },
    children: (initials)?.slice(0, 2),
  };
};

const capitalizedSentence = (sentence) => sentence && sentence?.length > 0 ? sentence?.split(' ')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1)).join(' ') : '';

const handleCopyClick = (copyLink, copied = false, setCopied) => {
  // Perform the copy action (e.g., copying text to clipboard)
  navigator.clipboard.writeText(copyLink);

  // Change the state to show the clicked (copied) icon
  setCopied(true);

  // Optionally reset the icon back after a delay
  setTimeout(() => setCopied(false), 2000);
};

const interestOptions = (value) => {
  switch (value) {
    case '$': return [
      { label: '+ $1,000', value: "1000" },
      { label: '+ $2,000', value: "2000" },
      { label: '+ $3,000', value: "3000" },
      { label: '+ $5,000', value: "5000" },
      { label: '+ $10,000', value: "10000" },
    ];
    case '₹': return [
      { label: '+ ₹1,00,000', value: "100000" },
      { label: '+ ₹2,00,000', value: "200000" },
      { label: '+ ₹3,00,000', value: "300000" },
      { label: '+ ₹5,00,000', value: "500000" },
      { label: '+ ₹10,00,000', value: "1000000" },
    ];
    default: return [
      { label: '+ ₹1,00,000', value: "100000" },
      { label: '+ ₹2,00,000', value: "200000" },
      { label: '+ ₹3,00,000', value: "300000" },
      { label: '+ ₹5,00,000', value: "500000" },
      { label: '+ ₹10,00,000', value: "1000000" },
    ];
  }
}

const ones = [
  '',
  'One ',
  'Two ',
  'Three ',
  'Four ',
  'Five ',
  'Six ',
  'Seven ',
  'Eight ',
  'Nine ',
  'Ten ',
  'Eleven ',
  'Twelve ',
  'Thirteen ',
  'Fourteen ',
  'Fifteen ',
  'Sixteen ',
  'Seventeen ',
  'Eighteen ',
  'Nineteen '
];
const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

const largeUnits = ['Billion', 'Million', 'Thousand', '']; // Adjust for international units

export const convertToIndianCurrencyWords = (number) => {
  // if (number != undefined) {
  if (typeof number === 'number') {
    number = number.toString();
  }
  if (number === undefined || typeof number !== 'string') {
    return 'Please provide a valid input';
  }
  number = number.replace(/,/g, '');
  const parts = number.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1];
  number = integerPart;
  // console.log(parts[1], "parts[1]")

  if ((number = number.toString()).length > 13) return '';
  const n = ('0000000000000' + number).substr(-13).match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

  if (!n) return '';


  let str = '';
  str += n[1] !== '00' ? (
    (ones[Number(n[1])] || tens[n[1][0]] + ' ' + ones[n[1][1]]) +
    (n[2] === "00" ? 'Thousand Crore ' : 'Thousand ')
  ) : '';


  str += n[2] !== '00' ? (
    (ones[Number(n[2])] || tens[n[2][0]] + ' ' + ones[n[2][1]]) +
    (n[3] === "00" ? 'Hundred Crore ' : 'Hundred ')
  ) : '';

  str += n[3] !== '00' ? (ones[Number(n[3])] || tens[n[3][0]] + ' ' + ones[n[3][1]]) + 'Crore ' : '';
  str += n[4] !== '00' ? (ones[Number(n[4])] || tens[n[4][0]] + ' ' + ones[n[4][1]]) + 'Lakh ' : '';
  str += n[5] !== '00' ? (ones[Number(n[5])] || tens[n[5][0]] + ' ' + ones[n[5][1]]) + 'Thousand ' : '';
  str += n[6] !== '0' ? (ones[Number(n[6])] || tens[n[6][0]] + ' ' + ones[n[6][1]]) + 'Hundred ' : '';
  str += n[7] !== '00' ? (str !== '' ? ' ' : '') + (ones[Number(n[7])] || tens[n[7][0]] + ' ' + ones[n[7][1]]) : '';

  return str.trim() + (decimalPart != "00" && decimalPart != undefined ? " Point " + convertToIndianCurrencyWords(decimalPart) : "") || null;
  // }
  // else{
  //     return ""
  // }
};

export const convertToDollarWords = (number) => {
  if (typeof number === 'number') {
    number = number.toString();
  }
  if (number === undefined || typeof number !== 'string') {
    return 'Please provide a valid input';
  }
  number = number.replace(/,/g, '');
  const parts = number.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1];
  number = integerPart;

  if (number.length > 12) return ''; // limit to billions

  const n = ('000000000000' + number).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{3})$/);
  if (!n) return '';

  let str = '';
  str += n[1] !== '000' ? convertThreeDigitToWords(n[1]) + 'Billion ' : '';
  str += n[2] !== '000' ? convertThreeDigitToWords(n[2]) + 'Million ' : '';
  str += n[3] !== '000' ? convertThreeDigitToWords(n[3]) + 'Thousand ' : '';
  str += n[4] !== '000' ? convertThreeDigitToWords(n[4]) : '';

  return str.trim() + (decimalPart != "00" && decimalPart !== undefined ? " Point " + convertDecimalToWords(decimalPart) : "") || null;
};

function convertThreeDigitToWords(number) {
  const hundreds = Math.floor(number / 100);
  const remainder = number % 100;
  let str = '';

  if (hundreds > 0) {
    str += ones[hundreds] + 'Hundred ';
  }
  if (remainder > 0) {
    str += remainder < 20 ? ones[remainder] : tens[Math.floor(remainder / 10)] + ones[remainder % 10];
  }
  return str;
}

function convertDecimalToWords(decimalPart) {
  return decimalPart.split('').map(digit => ones[parseInt(digit)]).join('').trim();
}

const amountInWords = (value, currency) => {
  switch (currency) {
    case '$': return convertToDollarWords(value);
    case '₹': return convertToIndianCurrencyWords(value);
    default: return convertToIndianCurrencyWords(value);
  }
}

const scrollToFirstVisibleValidation = () => {
  // Query all elements with the class "validation-message"
  const validationMessages = document.querySelectorAll('.validation-message');
  // console.log(validationMessages, "dddd")

  // Find the first visible validation message
  for (const message of validationMessages) {
    if (message && message.offsetParent !== null) {
      message.scrollIntoView({ behavior: 'smooth', block: 'center' });
      break;
    }
  }
};

const handleFormSubmit = async (e, formik) => {
  e.preventDefault();
  const isValid = await formik.validateForm(); // Validate form before submission

  if (Object.keys(isValid).length > 0) {
    // If validation errors exist, trigger scroll
    formik.setTouched(isValid); // Mark all fields as touched
    setTimeout(() => {
      scrollToFirstVisibleValidation();
    }, 10)
  } else {
    formik.handleSubmit(); // Submit if there are no errors
  }
}

const notEmpty = (value) => {
  return !(value === null || value === undefined || value === '' || (typeof value == "string" && value?.trim()?.length === 0) ||
    (Array.isArray(value) && value.length === 0) ||
    (!(value instanceof Date) && typeof value === 'object' && Object.keys(value).length === 0));
}

function convertCurrencyAmountRange(amount, currency, noofDecimal = 2) {
  let result = '';

  if (amount != null) {
    // Check the currency type
    if (currency === 'INR') {
      if (amount >= 10000000) {
        result = (amount / 10000000).toFixed(noofDecimal) + 'Cr'; // Crores (10 million)
      } else if (amount >= 100000) {
        result = (amount / 100000).toFixed(noofDecimal) + 'L'; // Lakhs (100 thousand)
      } else if (amount >= 1000) {
        result = (amount / 1000).toFixed(noofDecimal) + 'K'; // Thousands
      } else {
        result = amount.toString(); // No conversion for smaller amounts
      }
    } else if (currency === 'USD') {
      if (amount >= 1000000) {
        result = (amount / 1000000).toFixed(noofDecimal) + 'M'; // Millions
      } else if (amount >= 1000) {
        result = (amount / 1000).toFixed(noofDecimal) + 'K'; // Thousands
      } else {
        result = amount.toString(); // No conversion for smaller amounts
      }
    } else {
      result = 'Invalid currency type';
    }
  }

  return result;
}

const getINRMarks = () => [
  // { value: 0, label: '< 5L', selectedValue: 0 },
  { value: 0, label: '₹5L', selectedValue: 500000 },
  { value: 1, label: '₹25L', selectedValue: 2500000 },
  { value: 2, label: '₹50L', selectedValue: 5000000 },
  { value: 3, label: '₹2CR', selectedValue: 20000000 },
  { value: 4, label: '₹5CR', selectedValue: 50000000 },
  { value: 5, label: '₹20CR', selectedValue: 200000000 },
  { value: 6, label: '₹50CR +', selectedValue: 500000000 },
];

const getUSDMarks = () => [
  { value: 0, label: '$10K', selectedValue: 10000 },
  { value: 1, label: '$50K', selectedValue: 50000 },
  { value: 2, label: '$100K', selectedValue: 100000 },
  { value: 3, label: '$250K', selectedValue: 250000 },
  { value: 4, label: '$500K', selectedValue: 500000 },
  { value: 5, label: '$1M', selectedValue: 1000000 },
  { value: 6, label: '$5M', selectedValue: 5000000 },
  { value: 7, label: '$10M', selectedValue: 10000000 },
  // { value: 7, label: '> 10M', selectedValue: 10000001 },
];

const getMarks = (currency) => {
  return currency === 'INR' ? getINRMarks(currency) : getUSDMarks(currency)
}

const technologyColor = "#FFF8DE"
const consumerBrandColor = "#FFE3E3"
const marketplaceColor = "#F2FFE9"
const servicesColor = "#F5EFFF"
const sectorAgnostic = "#F0EBE3"

const colorArray = [
  "#D4F6FF",
  "#F6EFBD",
  "#FFE3E3",
  "#FFECC8",
  "#EDE8DC",
  "#F5EFFF",
  "#FEF9D9",
  "#F6EACB",
  "#FAF4B7",
  "#FFE6E6",
  "#FFF8DE",
  "#FFE8C5",
  "#F5EEE6",
  "#F2FFE9",
  "#F8E8EE",
  "#F8EDE3",
  "#F0EBE3",
  "#FEF2F4",
]

const isTitleToShow = (title) => {
  return ["Dr.", "CA"].includes(title)
}

const investor_types = [
  { label: "Angel", value: "angel" },
  { label: "Super Angel", value: "super_angel" },
  { label: "Micro VC", value: "micro_vc" },
  { label: "VC", value: "vc" },
  { label: "Family Office", value: "family_office" },
  { label: "Accelerator", value: "accelerator" },
  { label: "Incubator", value: "incubator" },
  { label: "Investment Banker", value: "investment_banker" },
];

const websiteTestURL = /^[a-zA-Z0-9.-]+\.(com|in|org|net|edu|gov|info|io|co|us|uk|de|fr|au|ca|jp|cn|ru|br|za|biz|online|store|tech|ai|app|xyz|site|me|tv|pro|name|club|live|today|news|digital|dev|global|solutions|agency|group|space|design|foundation|media|network|shop|systems|works|ventures|school|restaurant|market|coffee|chat|partners)$/;
// const downloadFile = (url) => {
//   const link = document.createElement('a');
//   link.href = url;
//   link.target = '_blank';
//   link.setAttribute('download', 'filename.pdf'); // Specify the desired filename here
//   link.click();
// }
const downloadFile = (url) => {
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob(); // Convert the response to a Blob
    })
    .then(blob => {
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);

      // Extract the original filename from the URL
      const urlParts = url.split('/');
      const filename = urlParts[urlParts.length - 1] || 'downloaded_file';

      link.href = objectUrl;
      link.setAttribute('download', filename); // Use the extracted filename
      document.body.appendChild(link); // Append the link to the DOM
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up the DOM
      URL.revokeObjectURL(objectUrl); // Release the Blob URL
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
};

const mergePermissions = ({ fetchedPermissions, permissionsTemplate }) => {
  return permissionsTemplate ? Object.fromEntries(
    Object.entries(permissionsTemplate).map(([module, { label, actions }]) => [
      module,
      {
        label,
        actionLabels: actions, // Keep the action labels as is
        actions: Object.fromEntries(
          Object.keys(actions).map((action) => [
            action,
            fetchedPermissions && fetchedPermissions[module]
              ? fetchedPermissions[module][action] ?? false // Check if fetchedPermissions is valid
              : false  // Default to false if fetchedPermissions is null or empty
          ])
        )
      }
    ])
  ) : {};
};

const defaultPermissions = ({ permissionsTemplate }) => {
  return mergePermissions({ permissionsTemplate: permissionsTemplate, fetchedPermissions: { profile: { view: true } } })
};

const adminDefaultPermissions = ({ permissionsTemplate }) => {
  return Object.entries(defaultPermissions({ permissionsTemplate }))?.reduce((acc, [module, item]) => {
    const updatedActions = Object.keys(item?.actions || {}).reduce((actionsAcc, action) => {
      // console.log(actionsAcc, action)
      actionsAcc[action] = true;
      return actionsAcc;
    }, {});


    acc[module] = {
      ...item,
      actions: updatedActions
    };
    return acc;
  }, {});
}

const collaboratorDefaultPermissions = ({ permissionsTemplate }) => {
  return Object.entries(defaultPermissions({ permissionsTemplate }))?.reduce((acc, [module, item]) => {
    const updatedActions = Object.keys(item?.actions || {}).reduce((actionsAcc, action) => {
      if (module === "profile" && action === "view") {
        actionsAcc[action] = true;
      }
      else {
        actionsAcc[action] = false;
      }

      return actionsAcc;
    }, {});

    acc[module] = {
      ...item,
      actions: updatedActions
    };
    return acc;
  }, {});
}

function convertToPermissionOnly(permissions) {
  const savedPermissions = {}
  Object.keys(permissions)?.map((key) => {
    savedPermissions[key] = permissions[key]["actions"]
  });
  return savedPermissions
}

const adminPermission = ({ permissionsTemplate }) => {
  return convertToPermissionOnly(adminDefaultPermissions({ permissionsTemplate }))
};

/**
 * Get permission value for a given module and key.
 * 
 * @param {"profile"|"documents"|"funding_rounds"|"reports"|"performance"|"find_investors"|"settings"} module - The name of the module.
 * @param {string} key - The permission key within the module.
 * @param {Object} permissions - The permissions object.
 * @returns {boolean} - The permission value or false if not found.
 */
function hasPermission(module, key, permissions) {
  return permissions && permissions[module] && key in permissions[module]
    ? permissions[module][key]
    : false;  // Default for non-existing keys
}


/**
 * Check if a user has permission for a specific route.
 * 
 * @param {Object} userPermissions - An object containing user permissions for various modules.
 * @param {string} route - The route to check permission for.
 * @returns {boolean} - True if the user has permission for the route, false otherwise.
 */
function hasPermissionForRoute(userPermissions, route) {
  const backrrPermissionCookie = Cookies.get("permissions") || null;
  if (route?.includes("backrr_startups") && backrrPermissionCookie && JSON.parse(backrrPermissionCookie)?.backrr_startups) {
    return true;
  }
  if (route?.includes("backrr_investors") && backrrPermissionCookie && JSON.parse(backrrPermissionCookie)?.backrr_investors) {
    return true;
  }
  if (!userPermissions || Object.keys(userPermissions).length === 0) {
    return false;  // Handle null, undefined, or empty userPermissions
  }

  const routeToModuleMap = {
    '/': { module: 'startups', key: 'view' },
    '/startups': { module: 'startups', key: 'view' },
    '/startups/view-profile': { module: 'startups', key: 'view' },
    '/startups/view-round': { module: 'startups', key: 'view' },
    '/startups/view-documents': { module: 'startups', key: 'view' },
    '/startups/view-funding': { module: 'startups', key: 'view' },
    '/startups/view-reports': { module: 'startups', key: 'view' },
    '/startups/view-performance': { module: 'startups', key: 'view' },
    '/startups/view-feedback': { module: 'startups', key: 'submit_feedback' },
    '/startups/view-notes': { module: 'startups', key: 'manage_internal_notes' },
    '/profile': { module: 'profile', key: 'view' },
    '/profile/edit_profile': { module: 'profile', key: 'edit' },
    '/portfolio': { module: 'portfolio', key: 'view' },
    '/portfolio/create_portfolio': { module: 'portfolio', key: 'add' },
    '/portfolio/create_portfolio': { module: 'portfolio', key: 'edit' },
    '/reports': { module: 'reports', key: 'view' },
    '/reports/view': { module: 'reports', key: 'view' },
    '/settings': { module: 'settings', key: 'manage_users' },
    '/settings/add_user': { module: 'settings', key: 'manage_users' },
  };

  const routeInfo = routeToModuleMap[route];

  if (!routeInfo) {
    return false;  // Route not mapped
  }

  const { module, key } = routeInfo;
  return hasPermission(module, key, userPermissions);
}

function findFirstTrueValue(permissions) {
  for (const module in permissions) {
    const keys = permissions[module];
    for (const key in keys) {
      if (keys[key] === true) {
        return { module, key };  // Return the first match found
      }
    }
  }
  return null;  // Return null if no true value is found
}

function getFirstRoute({ module, key }) {
  switch (module) {
    case 'profile':
      return `/`;
    case 'funding_rounds':
      return `/rounds`
    case 'reports':
      return `/reports`
    case 'performance':
      return `/performance`
    case 'find_investors':
      return `/find-investors`
    case 'settings':
      return `/settings`
    default:
      return '/'
  }
}

// function hasPermissionForRoute(userPermissions, route) {
//   console.log(userPermissions,"userPermissions")
//   if (!userPermissions || Object.keys(userPermissions).length === 0) {
//     return false;  // Handle null, undefined, or empty userPermissions
//   }

//   const routeToModuleMap = {
//     '/': 'profile',
//     '/update': 'profile',
//     '/rounds': 'funding_rounds',
//     '/reports': 'reports',
//     '/performance': 'performance',
//     '/find-investors': 'find_investors',
//     '/settings': 'settings',
//   };

//   const module = routeToModuleMap[route];

//   if (!module || !userPermissions[module]) {
//     return false;  // Route not mapped or no permissions for the module
//   }

//   const permissions = userPermissions[module];
//   console.log(userPermissions, route, module)
//   return Object.values(permissions).some(permission => permission === true);
// }




export {
  getDeviceType,
  redirectTo,
  navigateToMain,
  handleDateValues,
  getFileIcon,
  getFileCategory,
  containerRef,
  isRequired,
  popularCountrySort,
  linkedInTestURL,
  stringAvatar,
  capitalizedSentence,
  handleCopyClick,
  interestOptions,
  notEmpty,
  currencyInputProps,
  amountInWords,
  handleFormSubmit,
  convertCurrencyAmountRange,
  getINRMarks,
  getUSDMarks,
  getMarks,
  technologyColor,
  consumerBrandColor,
  marketplaceColor,
  servicesColor,
  sectorAgnostic,
  colorArray,
  isTitleToShow,
  investor_types,
  websiteTestURL,
  downloadFile,
  // permissions 
  mergePermissions,
  defaultPermissions,
  adminDefaultPermissions,
  collaboratorDefaultPermissions,
  convertToPermissionOnly,
  adminPermission,
  hasPermission,
  hasPermissionForRoute,
  findFirstTrueValue,
  getFirstRoute
}