import { Box, Grid } from '@mui/material'
import React from 'react'
// import AuthWrapper1 from 'ui-components/AuthWrapper1'
import CustomBackgroundComponent from 'ui-components/CustomBackgroundComponent'
import AuthLogin from 'views/pages/AuthLogin'
import { useTheme } from '@emotion/react'
import { Logout } from '@mui/icons-material'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SET_MENU } from 'store/actions'


const OnboardingLayout = ({ children }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleLogOut = () => {
        dispatch({ type: SET_MENU, opened: false });
        Cookies.remove('jwt', { path: '/' });
        Cookies.remove('name', { path: '/' });
        Cookies.remove('user_id', { path: '/' });
        Cookies.remove('startup_id', { path: '/' });
        Cookies.remove('email', { path: '/' });
        Cookies.remove('is_profile_complete', { path: '/' });
        Cookies.remove('startup_id', { path: '/' });
        Cookies.remove('step', { path: '/' });
        Cookies.remove('permissions', { path: '/' });
        Cookies.remove('ur', { path: '/' });
        Cookies.remove('search_startups', { path: '/' });
        Cookies.remove('search_backrr_startups', { path: '/' });
        Cookies.remove('backrr_startups_query', { path: '/' });
        Cookies.remove('backrr_startups_status', { path: '/' });
        const cookies = document.cookie.split(";");
        cookies.forEach(cookie => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
          });
          navigate('/login');
      };
    return (
        <Grid xs={12} container sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", minHeight: "100vh", backgroundColor: theme.palette.customColors.yellow_bg }}>
            <Box className="container" sx={{ padding: { xs: "0 !important", md: "1rem !important", lg: "1rem !important" }, maxHeight: { xs: "100%", md: 600, lg: 730 } }}>
                <div class="form-section">
                    {children}
                </div>
                <div className='illustration-section' />
            </Box>
            {/* // Only for Development Use */}
            {/* {(window.location.href?.includes("localhost") && window.location.href?.includes("onboarding")) || (window.location.href?.includes(":3000") && window.location.href?.includes("onboarding")) ? <Logout sx={{position: 'absolute', top: 5, right: 10}} onClick={() => {handleLogOut()}} /> : <></>}  */}
        </Grid>
    )
}

export default OnboardingLayout